import React from 'react'
import "./template1.scss"
import {getTemplateSettingsById} from "../../inviteTemplates";
import {Icon} from "rsuite";
import {
    getCustomClasses, getCustomStyles,
    getDetailedDate, getEditSectionTitle,
    getFormattedLocationString,
    openMaps,
    openWaze
} from "../../../InviteTemplatesFunctions";
import Countdown from "react-countdown";
import {SiGooglemaps, SiWaze} from "react-icons/si";
import {translateInviteTexts} from "../../../../functions";
import PlayAudioCustom from "../../../../organizer/Invites/Template1/PlayAudioCustom";

export default function InviteTemplate1Content({
                                                   templateDetails,
                                                   weddingData,
                                                   locations,
                                                   mode,
                                                   audioLink,
                                                   image1Link,
                                                   image2Link,
                                                   image3Link,
                                                   toggleModal,
                                                   hideCountdown
                                               }) {

    const settings = getTemplateSettingsById(templateDetails.id)
    const detailedDate = getDetailedDate(templateDetails.section2.date, weddingData)

    const renderLocationRow = (location, i) => {
        return (<div key={i} className={"item"}>


            <div className={"description " + getCustomClasses(templateDetails, 'section3', 'locationsStyles')}
                 style={getCustomStyles(templateDetails, 'section3', 'locationsStyles', ['color'])}
            >
                {getFormattedLocationString(location, weddingData, getCustomStyles(templateDetails, 'section3', 'locationsStyles', ['color']))}

                {
                    location.pinx && location.piny && !hideCountdown ?
                        <div className={"buttons"}>
                            <div className={"waze"}
                                 style={getCustomStyles(templateDetails, 'section3', 'locationsStyles', ['borderColor'])}
                                 onClick={() => openWaze(location.pinx, location.piny, mode)}><SiWaze
                                style={{marginRight: "5px"}}/> Waze
                            </div>
                            <div className={"maps"}
                                 style={getCustomStyles(templateDetails, 'section3', 'locationsStyles', ['borderColor'])}
                                 onClick={() => openMaps(location.pinx, location.piny, mode)}><SiGooglemaps
                                style={{marginRight: "5px"}}/> Google Maps
                            </div>
                        </div>
                        : null
                }
            </div>

        </div>)
    }

    const renderCountdown = ({days, hours, minutes, seconds, completed}) => {
        if (completed || hideCountdown) {
            // Render a completed state
            return '';
        } else {
            // Render a countdown
            return <div className={"countdown " + getCustomClasses(templateDetails, 'section2', 'dateStyles')}
            >
                <div className={"item"}>
                    <div className={"value"}
                         style={getCustomStyles(templateDetails, 'section2', 'dateStyles', ['borderColor', 'activeColor'])}>{days}</div>
                    <div className={"title"}
                         style={getCustomStyles(templateDetails, 'section2', 'dateStyles', ['color'])}>{days === 1 ? translateInviteTexts('Zi', weddingData.invite.language) : translateInviteTexts('Zile', weddingData.invite.language)}</div>
                </div>

                <div className={"item"}>
                    <div className={"value"}
                         style={getCustomStyles(templateDetails, 'section2', 'dateStyles', ['borderColor', 'activeColor'])}>{hours}</div>
                    <div className={"title"}
                         style={getCustomStyles(templateDetails, 'section2', 'dateStyles', ['color'])}>{hours === 1 ? translateInviteTexts('Oră', weddingData.invite.language) : translateInviteTexts('Ore', weddingData.invite.language)}</div>
                </div>

                <div className={"item"}>
                    <div className={"value"}
                         style={getCustomStyles(templateDetails, 'section2', 'dateStyles', ['borderColor', 'activeColor'])}>{minutes}</div>
                    <div className={"title"}
                         style={getCustomStyles(templateDetails, 'section2', 'dateStyles', ['color'])}>{minutes === 1 ? translateInviteTexts('Minut', weddingData.invite.language) : translateInviteTexts('Minute', weddingData.invite.language)}</div>
                </div>

                <div className={"item"}>
                    <div className={"value"}
                         style={getCustomStyles(templateDetails, 'section2', 'dateStyles', ['borderColor', 'activeColor'])}>{seconds}</div>
                    <div className={"title"}
                         style={getCustomStyles(templateDetails, 'section2', 'dateStyles', ['color'])}>{translateInviteTexts('Secunde', weddingData.invite.language)}</div>
                </div>

            </div>
        }
    }


    return (<div className={"inviteTemplate1"} style={{backgroundImage: 'url("' + settings.background + '")'}}>

        <div className={"section1 " + (mode === 2 ? "section-edit" : "")} onClick={() => {
            if (mode === 2)
                toggleModal('section1')
        }}>

            {mode === 2 ? <div className={"edit-section-handler"}><Icon icon="pencil"/> {getEditSectionTitle(1, weddingData.eventType)}</div> : null}

            <div
                className={"text1 " + getCustomClasses(templateDetails, 'section1', 'text1Styles')}
                style={getCustomStyles(templateDetails, 'section1', 'text1Styles')}
            >{templateDetails.section1.text1}</div>

            <div className={"line"}>
                <div className={"image1-cont"}>
                    {
                        templateDetails.section1.image1 && image1Link && image1Link.length > 0 ?
                            <div className={"frame"}>
                                <img  alt={""} src={image1Link}/>
                            </div>
                            : null
                    }
                </div>

                <div className={"persons"}>
                    <div className={"inner"}>
                        <div
                            className={"groomName " + getCustomClasses(templateDetails, 'section1', 'groomNameStyles')}
                            style={getCustomStyles(templateDetails, 'section1', 'groomNameStyles')}>{templateDetails.section1.groomName}</div>
                        <div className={"separator " + getCustomClasses(templateDetails, 'section1', 'separatorStyles')}
                             style={getCustomStyles(templateDetails, 'section1', 'separatorStyles')}>{templateDetails.section1.separator && templateDetails.section1.separator === 1 ? translateInviteTexts(translateInviteTexts('și', weddingData.invite.language), weddingData.invite.language) : '&'}</div>
                        <div
                            className={"brideName " + getCustomClasses(templateDetails, 'section1', 'brideNameStyles')}
                            style={getCustomStyles(templateDetails, 'section1', 'brideNameStyles')}>{templateDetails.section1.brideName}</div>
                    </div>
                </div>

                <div className={"image2-cont"}>
                    {
                        templateDetails.section1.image2 && image2Link && image2Link.length > 0 ?
                            <div className={"frame"}>
                                <img  alt={""} src={image2Link}/>
                            </div>
                            : null
                    }
                </div>

            </div>

            <div className={"text2 " + getCustomClasses(templateDetails, 'section1', 'text2Styles')}
                 style={getCustomStyles(templateDetails, 'section1', 'text2Styles')}>{templateDetails.section1.text2}</div>

            {
                templateDetails.section1.nuntaSiBotez ?
                    <>
                    <div className={"line"} style={{flexDirection:'column'}}>
                        <div className={"persons"}>
                            <div className={"inner"}>
                                <div
                                    className={"groomName " + getCustomClasses(templateDetails, 'section1', 'childrenNameStyles')}
                                    style={getCustomStyles(templateDetails, 'section1', 'childrenNameStyles')}>{templateDetails.section1.childrenName}</div>
                            </div>
                        </div>
                        <div className={"image1-cont"} style={{marginTop:"15px"}}>
                            {
                                templateDetails.section1.image3 && image3Link && image3Link.length > 0 ?
                                    <div className={"frame"}>
                                        <img  alt={""} src={image3Link}/>
                                    </div>
                                    : null
                            }
                        </div>
                    </div>
                    </>
                    : null
            }

            {
                audioLink && audioLink.length > 0 ?
                    <div className={"audio-player"}>
                        <PlayAudioCustom
                            language={weddingData.invite.language}
                            link={audioLink}
                            color={getCustomStyles(templateDetails, 'section2', 'dateStyles') ? getCustomStyles(templateDetails, 'section2', 'dateStyles').color : '#000000'}
                        />
                    </div>
                    : null
            }

        </div>

        <div className={"section2 " + (mode === 2 ? "section-edit" : "")} onClick={() => {
            if (mode === 2)
                toggleModal('section2')
        }}>
            {mode === 2 ? <div className={"edit-section-handler"}><Icon icon="pencil"/> {getEditSectionTitle(2, weddingData.eventType)}</div> : null}

            <div className={"date-inner"}>
                <div className={"col-left"}
                     style={getCustomStyles(templateDetails, 'section2', 'dateStyles', ['borderColor'])}>
                    <div className={getCustomClasses(templateDetails, 'section2', 'dateStyles')}
                         style={getCustomStyles(templateDetails, 'section2', 'dateStyles', ['color'])}>{detailedDate.dayLiteral}</div>
                </div>
                <div className={"col-center"}
                     style={getCustomStyles(templateDetails, 'section2', 'dateStyles', ['borderColor'])}>
                    <div
                        style={getCustomStyles(templateDetails, 'section2', 'dateStyles', ['activeColor'])}
                        className={getCustomClasses(templateDetails, 'section2', 'dateStyles')}>{detailedDate.day}</div>
                </div>
                <div className={"col-right"}>
                    <div className={getCustomClasses(templateDetails, 'section2', 'dateStyles')}
                         style={getCustomStyles(templateDetails, 'section2', 'dateStyles', ['color'])}>{detailedDate.monthLiteral} {detailedDate.year}</div>
                </div>
            </div>


            <div className={"countdown-inner"}>
                {
                    templateDetails.section2 && (templateDetails.section2.show_countdown !== false) && new Date(templateDetails.section2.date).getTime() > new Date().getTime() ?
                        <Countdown
                            date={templateDetails.section2.date}
                            renderer={renderCountdown}
                        />
                        : null
                }
            </div>

            <div
                className={"text1 " + getCustomClasses(templateDetails, 'section2', 'text1Styles')}
                style={getCustomStyles(templateDetails, 'section2', 'text1Styles')}
            >{templateDetails.section2.text1}</div>
            <div className={"row-1"}>
                <div className={"col-left"}>
                    <div
                        className={"text5 " + getCustomClasses(templateDetails, 'section2', 'groomParentsStyles')}
                        style={getCustomStyles(templateDetails, 'section2', 'groomParentsStyles')}
                    >{templateDetails.section2.groomParents}</div>
                </div>
                <div className={"col-right"}>
                    <div className={"text5 " + getCustomClasses(templateDetails, 'section2', 'brideParentsStyles')}
                         style={getCustomStyles(templateDetails, 'section2', 'brideParentsStyles')}>
                        {templateDetails.section2.brideParents}</div>
                </div>
            </div>
            <div
                className={"text2 " + getCustomClasses(templateDetails, 'section2', 'text2Styles')}
                style={getCustomStyles(templateDetails, 'section2', 'text2Styles')}
            >{templateDetails.section2.text2}</div>
            <div className={"text3 " + getCustomClasses(templateDetails, 'section2', 'text3Styles')}
                 style={getCustomStyles(templateDetails, 'section2', 'text3Styles')}>{templateDetails.section2.text3}</div>

        </div>

        <div className={"section3 " + (mode === 2 ? "section-edit" : "")} onClick={() => {
            if (mode === 2)
                toggleModal('section3')
        }}>
            {mode === 2 ? <div className={"edit-section-handler"}><Icon icon="pencil"/> {getEditSectionTitle(3, weddingData.eventType)}</div> : null}

            {
                locations && locations.length > 0 ?
                    <div className={"locations-inner"}>

                        {
                            locations.map((location, i) => (
                                renderLocationRow(location, i)
                            ))
                        }

                    </div>
                    : null
            }
            <div className={"text4 " + getCustomClasses(templateDetails, 'section3', 'text4Styles')}
                 style={getCustomStyles(templateDetails, 'section3', 'text4Styles')}>{templateDetails.section3.text4}</div>

        </div>


    </div>)
}
