export const introAnimations = [
    {
        id: 1,
        configuration: [
            {
                title: 'optionalAnimation1',
                titleIntl: 'optional_animation',
                type: 'enumeration',
                value: 'birds',
                values: [
                    {
                        value: 'none',
                        label: 'none'
                    },
                    {
                        value: 'birds',
                        label: 'birds_animation'
                    },
                    {
                        value: 'confetti',
                        label: 'confetti_animation'
                    },
                    {
                        value: 'autumn_leaves',
                        label: 'autumn_leaves_animation'
                    },
                    {
                        value: 'rose_petals',
                        label: 'rose_petals_animation'
                    },
                    {
                        value: 'balloons',
                        label: 'balloons_animation'
                    },
                    {
                        value: 'fireworks',
                        label: 'fireworks_animation'
                    }
                    ],
            },
            {
                title: 'birdsColor',
                titleIntl: 'birds_color',
                type: 'color',
                value: '#333333',
                conditionFieldTitle: 'optionalAnimation1',
                conditionFieldValue: 'birds'
            },
            {
                title: 'autumnLeavesColor',
                titleIntl: 'leaves_color',
                type: 'color',
                value: '#9b5300',
                conditionFieldTitle: 'optionalAnimation1',
                conditionFieldValue: 'autumn_leaves'
            },
            {
                title: 'balloonsType',
                titleIntl: 'balloons_type',
                type: 'enumeration',
                value: 'normal',
                values: [
                    {
                        value: 'normal',
                        label: 'balloons_type_normal'
                    },
                    {
                        value: 'heart',
                        label: 'balloons_type_heart'
                    },
                    {
                        value: 'normal_heart',
                        label: 'balloons_type_normal_heart'
                    }
                ],
                conditionFieldTitle: 'optionalAnimation1',
                conditionFieldValue: 'balloons'
            },
            {
                title: 'balloonsColorsType',
                titleIntl: 'balloons_colors_type',
                type: 'enumeration',
                value: 'multicolor',
                values: [
                    {
                        value: 'single_color',
                        label: 'single_color'
                    },
                    {
                        value: 'multicolor',
                        label: 'multicolor'
                    },
                    {
                        value: 'random_colors',
                        label: 'random_colors'
                    }
                    ],
                conditionFieldTitle: 'optionalAnimation1',
                conditionFieldValue: 'balloons'
            },
            {
                title: 'balloonsColor',
                titleIntl: 'balloons_color',
                type: 'color',
                value: '#9b5300',
                conditionFieldTitle: 'optionalAnimation1',
                conditionFieldValue: 'balloons',
                conditionFieldTitle1: 'balloonsColorsType',
                conditionFieldValue1: 'single_color'
            },
            {
                title: 'balloonsColor1',
                titleIntl: 'balloons_color_1',
                type: 'color',
                value: '#7637AF',
                conditionFieldTitle: 'optionalAnimation1',
                conditionFieldValue: 'balloons',
                conditionFieldTitle1: 'balloonsColorsType',
                conditionFieldValue1: 'multicolor'
            },
            {
                title: 'balloonsColor2',
                titleIntl: 'balloons_color_2',
                type: 'color',
                value: '#F1D136',
                conditionFieldTitle: 'optionalAnimation1',
                conditionFieldValue: 'balloons',
                conditionFieldTitle1: 'balloonsColorsType',
                conditionFieldValue1: 'multicolor'
            },
            {
                title: 'balloonsColor3',
                titleIntl: 'balloons_color_3',
                type: 'color',
                value: '#009966',
                conditionFieldTitle: 'optionalAnimation1',
                conditionFieldValue: 'balloons',
                conditionFieldTitle1: 'balloonsColorsType',
                conditionFieldValue1: 'multicolor'
            },
            {
                title: 'balloonsColor4',
                titleIntl: 'balloons_color_4',
                type: 'color',
                value: '#02A7D1',
                conditionFieldTitle: 'optionalAnimation1',
                conditionFieldValue: 'balloons',
                conditionFieldTitle1: 'balloonsColorsType',
                conditionFieldValue1: 'multicolor'
            },
            {
                title: 'balloonsColor5',
                titleIntl: 'balloons_color_5',
                type: 'color',
                value: '#F6B21A',
                conditionFieldTitle: 'optionalAnimation1',
                conditionFieldValue: 'balloons',
                conditionFieldTitle1: 'balloonsColorsType',
                conditionFieldValue1: 'multicolor'
            },

        ],
    }
]
