import React, {useState} from 'react'
import {Icon} from "rsuite";

export default function IntroButtonStyleComp(
    {
        buttonFontStyles,
        setButtonFontStyles,
        buttonColor,
        setButtonColor,
        buttonIconColor,
        setButtonIconColor,
        borderBottomRadius = false
    }) {

    const [showSection, setShowSection] = useState('')


    const fonts = [
        {
            title: 'Dancing Script',
            class: 'style-font-dancing-script'
        },
        {
            title: 'Mynerve',
            class: 'style-font-mynerve'
        },
        {
            title: 'Kaushan',
            class: 'style-font-kaushan'
        },
        {
            title: 'Merienda',
            class: 'style-font-merienda'
        },
        {
            title: 'Pacifico',
            class: 'style-font-pacifico'
        },
        {
            title: 'Caveat',
            class: 'style-font-caveat'
        },
        {
            title: 'Allura',
            class: 'style-font-allura'
        },
        {
            title: 'Allison',
            class: 'style-font-allison'
        },
        {
            title: 'Karla',
            class: 'style-font-karla'
        },
        {
            title: 'Marhey',
            class: 'style-font-marhey'
        },
        {
            title: 'Style Script',
            class: 'style-font-style-script'
        },
        {
            title: 'Arial',
            class: 'style-font-arial'
        },
        {
            title: 'Verdana',
            class: 'style-font-verdana'
        },
        {
            title: 'Signika',
            class: 'style-font-signika'
        },
        {
            title: 'Roboto',
            class: 'style-font-roboto'
        },
    ]


    const toggleSection = (section) => {

        if(showSection === section) {
            setShowSection('')
        } else {
            setShowSection(section)
        }

    }

    return (
        <div className={"date-container-style-comp " + (borderBottomRadius ? 'border-bottom-radius' : '')}>

            <div className={"controls"}>
                <div className={"text-style-col " + (showSection === 'font' ? 'selected' : '')} onClick={() => toggleSection('font')}>
                    <div><Icon icon="font"/></div>
                </div>

                <div className={"text-style-col " + (showSection === 'color' ? 'selected' : '')} onClick={() => toggleSection('color')}>
                    <div className={"text-style-col-title"}>Buton</div>
                    <div className={"text-style-color-swatch"}
                         style={{background: buttonColor ? buttonColor.color : '#000000'}}
                    ></div>
                </div>

                <div className={"text-style-col " + (showSection === 'activeColor' ? 'selected' : '')} onClick={() => toggleSection('buttonIconColor')}>
                    <div className={"text-style-col-title"}>Iconiță</div>
                    <div className={"text-style-color-swatch"}
                         style={{background: buttonIconColor ? buttonIconColor.color : '#000000'}}
                    ></div>
                </div>

            </div>

            <div className={"sections"}>
                {/* sections */}
                {
                    showSection === 'font' ?
                        <div className={"selector"}>

                            {
                                fonts.map((font,index) => (
                                    <div
                                        key={index}
                                        className={"selector-item " + (buttonFontStyles && buttonFontStyles.font === font.class ? 'selected' : '')}
                                        onClick={() => setButtonFontStyles({...buttonFontStyles, font: font.class})}
                                    >
                                        <span className={font.class}>{font.title}</span>
                                    </div>
                                ))
                            }

                        </div>
                        :null
                }


                {
                    showSection === 'color' ?
                        <div className={"selector"}>
                            <input
                                style={{width:'100%', height:'40px', border:0}}
                                value={buttonColor ? buttonColor.color : ''}
                                type={"color"} onChange={(e) => setButtonColor({color: e.target.value})} />
                        </div>
                        :null
                }

                {
                    showSection === 'buttonIconColor' ?
                        <div className={"selector"}>
                            <input
                                style={{width:'100%', height:'40px', border:0}}
                                value={buttonIconColor ? buttonIconColor.color : ''}
                                type={"color"} onChange={(e) => setButtonIconColor({color: e.target.value})} />
                        </div>
                        :null
                }


            </div>

        </div>
    )

}
