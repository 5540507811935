import React from "react";
import {ConfirmButton} from "../Helper";

export default class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    componentDidCatch(error, info) {

    }

    render() {

        if (this.state.hasError) {
            // You can render any custom fallback UI

            return (<div style={{marginTop:'20vh'}}>
                <div style={{maxWidth: '400px', textAlign: "center", margin:"auto"}}>
                    <h3>A apărut o eroare!</h3>
                    <p>Vă rugăm reporniți aplicația!</p>

                    <ConfirmButton
                        style={{padding:'8px 10px', width:'100%', marginTop:'10px'}}
                        text={'Apasă aici pentru repornire'}
                        function={() => window.location.href = "/"}
                    />
                </div>
            </div>)


        }

        return this.props.children;
    }
}
