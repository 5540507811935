import React from 'react'
import './WebsiteInviteModulePreview.scss'
import 'react-add-to-calendar/dist/react-add-to-calendar.css'
import {GiChurch, GiFamilyHouse} from 'react-icons/gi';
import {ImLocation} from 'react-icons/im';
import {IoMdRestaurant} from 'react-icons/io'
import {Button, Icon} from 'rsuite'
import {
    getUrlFromImage,
    LoadingScreen,
    PlayAudio,
    PlayVideo
} from '../components/Helper';
import moment from "moment-timezone";
import {
    getDefaultTemplateValues,
    getTemplateConfigValues,
    openMaps,
    openWaze
} from "../../components/InviteTemplatesFunctions";
import {SiGooglemaps, SiWaze} from "react-icons/si";
import CustomInput from "../../components/custom/CustomInput";
import {toast} from "react-toastify";
import InviteTemplateRenderLazy from "../../components/inviteTemplates/InviteTemplateRenderLazy";
import IntroAnimationRenderComp
    from "../../components/inviteTemplates/components/IntroAnimationRenderComp/IntroAnimationRenderComp";
import Modal from "react-responsive-modal"
import IntroScreenTemplateRenderLazy
    from "../../components/inviteTemplates/IntroScreen/IntroScreenTemplateRenderLazy";
import {translateInviteTexts} from "../../functions";
import gsap from "gsap";
import {useGSAP} from "@gsap/react";
import "./WebsiteInviteModuleDemo.scss"

gsap.registerPlugin(useGSAP);
class WebsiteInviteModuleDemo extends React.Component {
    state = {
        eventType: "",
        inviteType: "",
        inviteContents: [],
        current_event: [],
        locations: [],
        loading: true,
        topRef: React.createRef(),
        formRef: React.createRef(),
        answer: "",
        nume: "",
        prenume: "",
        insotit: false,
        nume_insotitor: "",
        cuCopii: false,
        nr_copii: 1,
        kidsNumberOptions: ["1", "2", "3", "4", "5"],
        customQuestions: [],
        shouldUseCustomQuestions: true,
        answers: [],
        isUsingAudio: false,
        serverAudioUrl: null,
        showIntroAnimation: false,
        showAnimationAskModal: false,
        showIntroScreen: false,
        animationState: null
    }
    queryParams = new URLSearchParams(this.props.location.search);
    eventType = this.queryParams.get('eventType')
    templateId = this.queryParams.get('templateId')

    getLocatii = () => {
        if (this.eventType === 'Nunta' || this.eventType === 'NuntaBotez') {
            return [
                {
                    "day": moment().add('1', 'year').toISOString(),
                    "name": "Locatie petrecere",
                    "pinx": 44.451627,
                    "piny": 26.085265,
                    "time": moment().add('1', 'year').add('1', 'hour').toISOString(),
                    "address": "Creative Ality, Romania"
                }
            ]
        } else {
            return [
                {
                    "day": moment().add('1', 'year').toISOString(),
                    "name": "Locatie petrecere",
                    "pinx": 44.451627,
                    "piny": 26.085265,
                    "time": moment().add('1', 'year').add('1', 'hour').toISOString(),
                    "address": "Creative Ality, Romania"
                }
            ]
        }


    }

    getTemplateDetails = () => {

        let defaultTemplateValues = getDefaultTemplateValues({
            data: {
                eventType: this.eventType === 'NuntaBotez' ? 'Nunta' : this.eventType
            }
        }, parseInt(this.templateId + ''), 'ro')

        let templateConfigValues = getTemplateConfigValues(parseInt(this.templateId + ''))

        if (templateConfigValues.introScreen) {
            defaultTemplateValues.introScreenSection1 = {
                "brideName": defaultTemplateValues.section1.brideName ? defaultTemplateValues.section1.brideName : 'Mireasă',
                "groomName": defaultTemplateValues.section1.groomName ? defaultTemplateValues.section1.groomName : 'Mire',
                "dateStyles": {
                    'color': templateConfigValues.colors.fontColor,
                    'activeColor': templateConfigValues.colors.baseColor,
                    'borderColor': templateConfigValues.colors.borderColor
                },
                "envelopeColor": {color: templateConfigValues.introScreen.config.envelopeColor},
                "groomNameStyles": {'color': templateConfigValues.colors.fontColor},
                "separatorStyles": {'color': templateConfigValues.colors.fontColor},
                "brideNameStyles": {'color': templateConfigValues.colors.fontColor},
                "envelopeTextStyle": {'color': templateConfigValues.introScreen.config.fontColor},
                "envelopeText": translateInviteTexts("Apasă pentru a deschide invitația", 'ro')
            }
            defaultTemplateValues.introScreenSection2 = {
                "confirmButtonIconColor": {'color': templateConfigValues.introScreen.config.fontColor},
                "viewLocationButtonIconColor": {'color': templateConfigValues.introScreen.config.fontColor},
                "openInvitationButtonIconColor": {'color': templateConfigValues.introScreen.config.fontColor},
                "text4": translateInviteTexts("Vă așteptăm cu drag!", 'ro'),
                "text4Styles": {'color': templateConfigValues.colors.fontColor},
                "showConfirmButton": true,
                "confirmButtonColor": defaultTemplateValues.introScreenSection1.envelopeColor,
                "showViewLocationButton": true,
                "viewLocationButtonColor": defaultTemplateValues.introScreenSection1.envelopeColor,
                "showOpenInvitationButton": true,
                "openInvitationButtonColor": defaultTemplateValues.introScreenSection1.envelopeColor
            }
            defaultTemplateValues.introScreenId = templateConfigValues.introScreen.templateId ?? null
            defaultTemplateValues.introScreenConfiguration = templateConfigValues.introScreen.config ?? []
        }
        if (templateConfigValues.introAnimations) {
            if (this.queryParams.get('animation') && this.queryParams.get('animation') === 'true') {
                defaultTemplateValues.introAnimationId = templateConfigValues.introAnimations[0]
                defaultTemplateValues.introAnimationConfiguration = {
                    "birdsColor": "#333333",
                    "autumnLeavesColor": "#9b5300",
                    "optionalAnimation1": "birds"
                }
            } else {
                this.setState({showAnimationAskModal: true})
            }
        }
        if (this.eventType === 'Nunta') {

            defaultTemplateValues["id"] = this.templateId ? parseInt(this.templateId + '') : null
            defaultTemplateValues["section1"]["text1"] = "Cu inimile pline de emoție și bucurie, noi"
            defaultTemplateValues["section1"]["text2"] = "Vă invităm să fiți alături de noi la celebrarea căsătoriei noastre."
            defaultTemplateValues["section1"]["image1"] = false
            defaultTemplateValues["section1"]["image2"] = false
            defaultTemplateValues["section1"]["brideName"] = "Mireasă"
            defaultTemplateValues["section1"]["groomName"] = "Mire"

            defaultTemplateValues["section2"]["date"] = moment().add('1', 'year').toISOString()
            defaultTemplateValues["section2"]["text1"] = "Alături ne vor fi părinții"
            defaultTemplateValues["section2"]["text2"] = "Și nașii"
            defaultTemplateValues["section2"]["text3"] = "Nașul & Nașa"
            defaultTemplateValues["section2"]["brideParents"] = "Soț & Soție"
            defaultTemplateValues["section2"]["groomParents"] = "Soț & Soție"

            defaultTemplateValues["section3"]["text4"] = "Vă așteptăm cu drag!"
            defaultTemplateValues['eventType'] = "Nunta"


            return defaultTemplateValues
        }

        if (this.eventType === 'NuntaBotez') {

            defaultTemplateValues["id"] = this.templateId ? parseInt(this.templateId + '') : null
            defaultTemplateValues["section1"]["text1"] = "Cu inimile pline de emoție și bucurie, noi"
            defaultTemplateValues["section1"]["text2"] = "Vă invităm să fiți alături de noi la celebrarea căsătoriei căsătoriei noastre și a botezului micuței"
            defaultTemplateValues["section1"]["image1"] = false
            defaultTemplateValues["section1"]["image2"] = false
            defaultTemplateValues["section1"]["brideName"] = "Mireasă"
            defaultTemplateValues["section1"]["groomName"] = "Mire"
            defaultTemplateValues["section1"]["nuntaSiBotez"] = true
            defaultTemplateValues["section1"]["childrenName"] = "Copil"

            defaultTemplateValues["section2"]["date"] = moment().add('1', 'year').toISOString()
            defaultTemplateValues["section2"]["text1"] = "Alături ne vor fi părinții"
            defaultTemplateValues["section2"]["text2"] = "Și nașii"
            defaultTemplateValues["section2"]["text3"] = "Nașul & Nașa"
            defaultTemplateValues["section2"]["brideParents"] = "Soț & Soție"
            defaultTemplateValues["section2"]["groomParents"] = "Soț & Soție"

            defaultTemplateValues["section3"]["text4"] = "Vă așteptăm cu drag!"
            defaultTemplateValues['eventType'] = "Nunta"

            return defaultTemplateValues
        }

        if (this.eventType === 'Cununie') {
            defaultTemplateValues["id"] = this.templateId ? parseInt(this.templateId + '') : null
            defaultTemplateValues["section1"]["text1"] = "Cu inimile pline de emoție și bucurie, noi"
            defaultTemplateValues["section1"]["text2"] = "Vă invităm să fiți alături de noi la celebrarea cununiei noastre."
            defaultTemplateValues["section1"]["image1"] = false
            defaultTemplateValues["section1"]["image2"] = false
            defaultTemplateValues["section1"]["brideName"] = "Mireasă"
            defaultTemplateValues["section1"]["groomName"] = "Mire"

            defaultTemplateValues["section2"]["date"] = moment().add('1', 'year').toISOString()
            defaultTemplateValues["section2"]["text1"] = "Alături ne vor fi părinții"
            defaultTemplateValues["section2"]["text2"] = "Și nașii"
            defaultTemplateValues["section2"]["text3"] = "Nașul & Nașa"
            defaultTemplateValues["section2"]["brideParents"] = "Soț & Soție"
            defaultTemplateValues["section2"]["groomParents"] = "Soț & Soție"

            defaultTemplateValues["section3"]["text4"] = "Vă așteptăm cu drag!"
            defaultTemplateValues['eventType'] = "Cununie"


            return defaultTemplateValues
        }

        if (this.eventType === 'Botez') {

            defaultTemplateValues["id"] = this.templateId ? parseInt(this.templateId + '') : null
            defaultTemplateValues["section1"]["text1"] = "Sunt cel mai frumos dar pe care Dumnezeu l-a făcut părinților mei și mă numesc"
            defaultTemplateValues["section1"]["text2"] = "Împreună cu părinții mei,"
            defaultTemplateValues["section1"]["image1"] = false
            defaultTemplateValues["section1"]["image2"] = false
            defaultTemplateValues["section1"]["brideName"] = "Copil"
            defaultTemplateValues["section1"]["groomName"] = "Copil"
            defaultTemplateValues["section1"]["text3"] = 'Având alături nașii,'
            defaultTemplateValues["section1"]["text4"] = 'Nașul & Nașa'
            defaultTemplateValues["section1"]["text5"] = 'Avem plăcerea de a vă invita la botezul meu'
            defaultTemplateValues["section2"]["date"] = moment().add('1', 'year').toISOString()
            defaultTemplateValues["section3"]["text4"] = "Vă așteptăm cu drag!"
            defaultTemplateValues['eventType'] = "Botez"

            if (templateConfigValues.introAnimations) {
                if (this.queryParams.get('animation') && this.queryParams.get('animation') === 'true') {
                    defaultTemplateValues.introAnimationId = templateConfigValues.introAnimations[0]
                    defaultTemplateValues.introAnimationConfiguration = {
                        "birdsColor": "#333333",
                        "autumnLeavesColor": "#9b5300",
                        "optionalAnimation1": "confetti"
                    }
                } else {
                    this.setState({showAnimationAskModal: true})
                }
            }

            return defaultTemplateValues
        }

        if (this.eventType === 'Mot') {

            defaultTemplateValues["id"] = this.templateId ? parseInt(this.templateId + '') : null
            defaultTemplateValues["section1"]["text1"] = "Sunt cel mai frumos dar pe care Dumnezeu l-a făcut părinților mei și mă numesc"
            defaultTemplateValues["section1"]["text2"] = "Împreună cu părinții mei,"
            defaultTemplateValues["section1"]["image1"] = false
            defaultTemplateValues["section1"]["image2"] = false
            defaultTemplateValues["section1"]["brideName"] = "Copil"
            defaultTemplateValues["section1"]["groomName"] = "Copil"
            defaultTemplateValues["section1"]["text3"] = 'Având alături nașii,'
            defaultTemplateValues["section1"]["text4"] = 'Nașul & Nașa'
            defaultTemplateValues["section1"]["text5"] = 'Avem plăcerea de a vă invita la tăierea moțului meu'
            defaultTemplateValues["section2"]["date"] = moment().add('1', 'year').toISOString()
            defaultTemplateValues["section3"]["text4"] = "Vă așteptăm cu drag!"
            defaultTemplateValues['eventType'] = "Mot"

            if (templateConfigValues.introAnimations) {
                if (this.queryParams.get('animation') && this.queryParams.get('animation') === 'true') {
                    defaultTemplateValues.introAnimationId = templateConfigValues.introAnimations[0]
                    defaultTemplateValues.introAnimationConfiguration = {
                        "birdsColor": "#333333",
                        "autumnLeavesColor": "#9b5300",
                        "optionalAnimation1": "confetti"
                    }
                } else {
                    this.setState({showAnimationAskModal: true})
                }
            }

            return defaultTemplateValues
        }

        if (this.eventType === 'Turta') {
            defaultTemplateValues["id"] = this.templateId ? parseInt(this.templateId + '') : null
            defaultTemplateValues["section1"]["text1"] = "Sunt cel mai frumos dar pe care Dumnezeu l-a făcut părinților mei și mă numesc"
            defaultTemplateValues["section1"]["text2"] = "Împreună cu părinții mei,"
            defaultTemplateValues["section1"]["image1"] = false
            defaultTemplateValues["section1"]["image2"] = false
            defaultTemplateValues["section1"]["brideName"] = "Copil"
            defaultTemplateValues["section1"]["groomName"] = "Copil"
            defaultTemplateValues["section1"]["text3"] = 'Având alături nașii,'
            defaultTemplateValues["section1"]["text4"] = 'Nașul & Nașa'
            defaultTemplateValues["section1"]["text5"] = 'Avem plăcerea de a vă invita la ruperea turtei mele'
            defaultTemplateValues["section2"]["date"] = moment().add('1', 'year').toISOString()
            defaultTemplateValues["section3"]["text4"] = "Vă așteptăm cu drag!"
            defaultTemplateValues['eventType'] = "Turta"

            if (templateConfigValues.introAnimations) {
                if (this.queryParams.get('animation') && this.queryParams.get('animation') === 'true') {
                    defaultTemplateValues.introAnimationId = templateConfigValues.introAnimations[0]
                    defaultTemplateValues.introAnimationConfiguration = {
                        "birdsColor": "#333333",
                        "autumnLeavesColor": "#9b5300",
                        "optionalAnimation1": "confetti"
                    }
                } else {
                    this.setState({showAnimationAskModal: true})
                }
            }

            return defaultTemplateValues
        }

        if (this.eventType === 'Petrecere') {
            defaultTemplateValues["id"] = this.templateId ? parseInt(this.templateId + '') : null
            defaultTemplateValues["section1"]["text2"] = "Vă invităm să ne distrăm împreună"
            defaultTemplateValues["section1"]["image1"] = false
            defaultTemplateValues["section1"]["image2"] = false
            defaultTemplateValues["section1"]["brideName"] = "Aurora"
            defaultTemplateValues["section1"]["groomName"] = "Aniversarea mea"
            defaultTemplateValues["section2"]["date"] = moment().add('1', 'year').toISOString()
            defaultTemplateValues["section3"]["text4"] = "Vă așteptăm cu drag!"
            defaultTemplateValues['eventType'] = "Petrecere"
            return defaultTemplateValues
        }


    }

    componentDidMount = async () => {

        document.body.classList.add('body-scroll')

        let invite = {
            inviteType: this.queryParams.get('inviteType') ? this.queryParams.get('inviteType') : 'Custom',
            parinti: [
                {
                    "mamaMire": "Alina"
                },
                {
                    "tataMire": "Marian"
                },
                {
                    "mamaMireasa": "Cristina"
                },
                {
                    "tataMireasa": "Adrian"
                },
                {
                    "numeParintiMire": ""
                },
                {
                    "numeParintiMireasa": ""
                }
            ],
            description: 'Vă invităm să petreceți alături de noi',
            nasi: [],
            templateDetails: this.getTemplateDetails(),
            image1: null,
            image2: null,
            fontSize: '20px',
            audioType: 'song',
            song: {
                "id": 10,
                "url": "https://ality-audio.s3.eu-north-1.amazonaws.com/invitation-213664.mp3",
                "name": "Invitation"
            }
        }

        let event_info = {
            data: {
                eventType: this.eventType,
                dataEvent: moment().add('1', 'year').toISOString(),
                prenumeMire: 'Mire',
                prenumeMireasa: 'Mireasă',
                locatii: this.getLocatii(),
                invite: invite
            }
        }
        this.setState({
            inviteType: invite.inviteType,
            inviteContents: invite,
            locations: event_info.data.locatii,
            current_event: event_info.data,
            serverAudioUrl: "https://ality-audio.s3.eu-north-1.amazonaws.com/invitation-213664.mp3",
            isUsingAudio: true,
            eventType: event_info.data.eventType,
            showIntroAnimation: event_info.data.invite.templateDetails && event_info.data.invite.templateDetails.introAnimationId,
            showIntroScreen: event_info.data.invite.templateDetails && event_info.data.invite.templateDetails.introScreenId,
            loading: false
        })
    }

    componentWillUnmount() {
        document.body.classList.remove('body-scroll')
        gsap.globalTimeline.clear()
    }

    moveToEvent = () => {
        this.props.history.push('/organizer/eventPage/' + this.props.match.params.id)
    }

    setAnswer = (ans) => {
        this.setState({
            answer: ans
        })

        this.timer = setTimeout(
            () => this.state.topRef.current.scrollIntoView(),
            100
        )
    }

    setter = (key, value) => {
        this.setState({
            [key]: value
        })
    }

    changeAnswers = (newAnswers) => {
        this.setState({
            answers: newAnswers
        })
    }

    sendAnswer = () => {
        toast.info('Trimiterea răspunsurilor este dezactivată în modul de previzualizare!')
    }

    showBottomButtons = () => {


        return <>

            <Modal
                classNames={{
                    overlay: 'modal-overlay',
                    modal: 'modal-no-top',
                    closeButton: 'modal-close-button',
                }}
                open={this.state.showAnimationAskModal}
                focusTrapped={false}
                onClose={() => {
                    this.setState({
                        showAnimationAskModal: false
                    })
                }}
            >

                <div className={"WebsiteInviteModuleDemo-intro-modal"}>
                    <h4>Invitație animată</h4>
                    <br/>
                    <p>
                        Am creat o variantă animată și configurabilă a acestei invitații, pe care o
                        oferim gratuit pentru o perioadă limitată. Doriți să încercați invitația
                        animată?
                    </p>

                    <div className={"cards-title"}>Alege:</div>
                    <div className={"cards"}>
                        <div
                            className={"cards-item"}
                             onClick={() => {
                                 this.setState({showAnimationAskModal: false})
                                 window.location.replace(window.location.href + '&animation=true')
                             }}>

                            <div className={"image"}>
                                <img src={getTemplateConfigValues(parseInt(this.templateId + '')).preview} alt={""} />

                                <div className={"icon first"}>
                                    <Icon icon="play" />
                                </div>
                            </div>

                            <div className={"title"}>
                                Invitație animată
                            </div>
                        </div>
                        <div
                            className={"cards-item"}
                             onClick={() => this.setState({showAnimationAskModal: false})}>

                            <div className={"image"}>
                            <img src={getTemplateConfigValues(parseInt(this.templateId + '')).preview} alt={""} />
                            </div>

                            <div className={"title"}>
                                Invitație standard
                            </div>
                        </div>
                    </div>

                </div>


            </Modal>

            <div><br/>
                <Button className="action btn-back"
                        style={{marginRight: "10px", padding: "10px 15px"}}
                        onClick={() => this.props.history.push('/organizer/websiteInviteModule?templateId=' + this.templateId + '&eventType=' + this.eventType + '&selectTemplate=true')}
                        color="blue"> Vezi toate modelele </Button>

                <Button className="action btn-back" style={{padding: "10px 15px"}}
                        onClick={() => this.props.history.push('/organizer/websiteInviteModule?templateId=' + this.templateId + '&eventType=' + this.eventType)}
                        color="green"> Personalizează invitația</Button>
            </div>
        </>

    }
    scrollContainerBottom = () => {
        if (this.state.formRef && this.state.formRef.current) {
            setTimeout(
                () => this.state.formRef.current.scrollIntoView(),
                10
            )
        }
    }
    scrollToLocations = () => {
        setTimeout(
            () => {
                let element = document.getElementsByClassName("locations-inner")[0];
                if(element) {
                    element.scrollIntoView()
                }
            },
            10
        )
    }
    render() {
        if (this.state.loading) {
            return <LoadingScreen/>
        }

        if (this.state.inviteContents && this.state.inviteContents.templateDetails && this.state.inviteContents.templateDetails.id) {
            return (<>
                        {this.state.showIntroScreen ? <Button className="action btn-back" style={{
                            padding: "10px 15px",
                            position: "absolute", left: '20px', top: '20px',
                            backgroundColor: "darkgrey", zIndex: 202
                        }}
                                 onClick={() => this.props.history.push('/organizer/websiteInviteModule?templateId=' +
                                     this.templateId + '&eventType=' + this.eventType)}
                                 color="red">Personalizează invitația</Button> : null}

                      {this.state.inviteContents.templateDetails.introScreenId && this.state.inviteContents.templateDetails.introScreenId > 0 ?
                                <IntroScreenTemplateRenderLazy
                                    showIntroScreen={this.state.showIntroScreen}
                                    templateDetails={this.state.inviteContents.templateDetails}
                                    weddingData={this.state.current_event}
                                    showIntroAnimation={() => {
                                        if (this.state.showIntroAnimation !== true && this.state.current_event.invite.templateDetails && this.state.current_event.invite.templateDetails.introAnimationId)
                                            this.setState({
                                                showIntroAnimation: true
                                            })
                                    }}
                                    openInvitation={() => {
                                        this.setState({
                                            autoPlayIntroAnimation: true,
                                            showIntroScreen: false,
                                        })
                                    }}
                                    mode={0}
                                    goToAnswer={() => {
                                        this.setState({
                                            showIntroScreen: false,
                                            showIntroAnimation: false,
                                        }, () => {
                                            this.setState({
                                                animationState: 'ended'
                                            })
                                            this.scrollContainerBottom()
                                        })
                                    }}
                                    goToLocations={() => {
                                        this.setState({
                                            showIntroScreen: false,
                                            showIntroAnimation: false,
                                        }, () => {
                                            this.setState({
                                                animationState: 'ended'
                                            })
                                            this.scrollToLocations()
                                        })
                                    }}
                                    goToInvitation={() => {
                                        this.setState({
                                                showIntroScreen: false,
                                                showIntroAnimation: false,
                                            }, () =>
                                                this.setState({
                                                    animationState: 'ended'
                                                })
                                        )

                                    }}
                                    showResponseButton={!this.props.disableAction && !this.state.inviteContents.hideConfirmForm}
                                />:null}

                    <IntroAnimationRenderComp
                        show={this.state.inviteContents.templateDetails && this.state.inviteContents.templateDetails.introAnimationId && !this.state.showIntroScreen}
                        showIntroAnimation={this.state.showIntroAnimation}
                        showInvite={!(this.state.showIntroAnimation || this.state.showIntroScreen)}
                        templateDetails={this.state.inviteContents.templateDetails}
                        weddingData={this.state.current_event}
                        setShowInvite={(value) => this.setState({showIntroAnimation: !value})}
                        setAutoPlayIntroAnimation={(value) => this.setState({autoPlayIntroAnimation: value})}
                        autoPlayIntroAnimation={this.state.autoPlayIntroAnimation}
                        animationState={this.state.animationState}
                        setAnimationState={(value) => {
                            this.setState({
                                animationState:value
                            })
                        }}
                    />
                    {!this.state.showIntroScreen && !this.state.showIntroAnimation &&
                    this.state.current_event && this.state.current_event.invite &&
                    this.state.current_event.invite.templateDetails && this.state.current_event.invite.templateDetails.introScreenId ?
                        <Button className="action btn-back inv-top-btn"
                                style={{
                                    position: "absolute",
                                    top: '20px',
                                    left: '20px',
                                    zIndex: 1000,
                                    backgroundColor: "darkgrey"
                                }}
                                onClick={() => this.setState({
                                    animationState: 'ended',
                                    showIntroAnimation: false,
                                    showIntroScreen: true
                                })}
                                color="red"> <Icon icon="home" style={{color: "white"}}/>
                        </Button> : null}
                    <div className={"new-invite-container " + (this.state.showIntroAnimation || this.state.showIntroScreen ? "inviteHiddenCont" : "")}
                         style={this.state.showIntroAnimation || this.state.showIntroScreen ? {
                             //position: 'absolute',
                             //left: '-200vw'
                         } : {zIndex: 1001}}>

                        <div className={"new-invite-container-inner"}>
                            <div className={"invite-container"}>
                                <InviteTemplateRenderLazy
                                    templateDetails={this.state.inviteContents.templateDetails}
                                    weddingData={this.state.current_event}
                                    locations={this.state.current_event.locatii}
                                    audioLink={this.state.isUsingAudio && this.state.serverAudioUrl ? this.state.serverAudioUrl : ''}
                                    image1Link={this.state.current_event.invite.image1 ? `https://${process.env.REACT_APP_IMAGE_DOMAIN}${this.state.current_event.invite.image1.url}` : ''}
                                    image2Link={this.state.current_event.invite.image2 ? `https://${process.env.REACT_APP_IMAGE_DOMAIN}${this.state.current_event.invite.image2.url}` : ''}
                                    mode={1}
                                />


                                {!this.props.disableAction &&
                                    <div className="Answer" ref={this.state.formRef}>
                                        <p style={{fontSize: "20px"}}>Doriți să participați?</p>

                                        <div className="Options">
                                            <div className="Yes"
                                                 onClick={() => this.setAnswer("yes")}>Particip
                                            </div>

                                            <div className="No"
                                                 onClick={() => this.setAnswer("no")}>Nu
                                                Particip
                                            </div>

                                            {
                                                this.state.inviteContents.hideDontKnowButton ?
                                                    null
                                                    :
                                                    <div className="NotSure"
                                                         onClick={() => this.setAnswer("don't know")}>Nu
                                                        știu</div>
                                            }
                                        </div>
                                    </div>
                                }

                                {this.state.answer !== "" && <div className='Form'>
                                    <div ref={this.state.topRef}></div>

                                    <div style={{textAlign: "left", fontSize: "18px"}}>Vă rugăm
                                        introduceți următoarele informații:
                                    </div>

                                    <CustomInput value={this.state.prenume}
                                                 onChange={value => this.setter("prenume", value)}
                                                 placeholder={"Prenume, Nume"}/>

                                    <CustomInput value={this.state.email}
                                                 onChange={value => this.setter("email", value)}
                                                 style={{marginTop: 0}}
                                                 placeholder={"Adresa de email"}/>

                                    {/* <CustomInput value={this.state.nume} onChange={value => this.setter("nume", value)} placeholder={"Nume"} /> */}
                                    {this.state.answer === "no" &&
                                        <p>Ne pare rău să auzim asta, daca vă răzgândiți vă
                                            așteptam</p>}
                                    {this.state.answer === "don't know" &&
                                        <p>Nu este obligatoriu să răspundeți acum, când sunteți
                                            sigur</p>}

                                    {this.state.answer === "yes" && <div>
                                        <div className="data-row">
                                            <div
                                                className={!this.state.insotit ? "active-option" : "inactive-option"}
                                                onClick={_ => this.setter("insotit", false)}>
                                                Vin singur/ă
                                            </div>

                                            <div
                                                className={this.state.insotit ? "active-option" : "inactive-option"}
                                                onClick={_ => this.setter("insotit", true)}>
                                                Vin insoțit/ă
                                            </div>
                                        </div>
                                        {this.state.insotit && (
                                            <CustomInput value={this.state.nume_insotitor}
                                                         onChange={value => this.setter("nume_insotitor", value)}
                                                         placeholder={"Prenume, Nume Insotitor"}/>
                                        )}


                                        {
                                            this.state.inviteContents.hideChildrenQuestion ?
                                                null
                                                :
                                                this.state.current_event.eventType !== 'Petrecere' ?
                                                    <>
                                                        <div style={{
                                                            fontSize: "18px",
                                                            textAlign: "left"
                                                        }}>Veniți însoțiți de
                                                            copii?
                                                        </div>
                                                        <div className="data-row">
                                                            <div
                                                                className={!this.state.cuCopii ? "active-option" : "inactive-option"}
                                                                onClick={_ => this.setter("cuCopii", false)}>
                                                                Nu
                                                            </div>

                                                            <div
                                                                className={this.state.cuCopii ? "active-option" : "inactive-option"}
                                                                onClick={_ => this.setter("cuCopii", true)}>
                                                                Da
                                                            </div>
                                                        </div>
                                                    </>
                                                    : null
                                        }
                                        {this.state.cuCopii && (
                                            <div>
                                                <div
                                                    style={{fontSize: "18px", textAlign: "left"}}>Cu
                                                    câți copii veniți?
                                                </div>

                                                <div className="data-row">
                                                    {this.state.kidsNumberOptions.map((nr, index) =>
                                                        <div key={index}
                                                             className={this.state.nr_copii === nr ? "active-option" : "inactive-option"}
                                                             onClick={_ => this.setter("nr_copii", nr)}>
                                                            {nr}
                                                        </div>)}
                                                </div>
                                            </div>
                                        )}

                                    </div>}

                                    <br/>
                                    <Button onClick={this.sendAnswer} className="add-button"
                                            style={{
                                                backgroundColor: "#00afcc",
                                                color: "white",
                                                height: '60px'
                                            }}>
                                        Trimite răspuns
                                    </Button>

                                    <div style={{
                                        marginTop: "50px",
                                        marginBottom: "50px",
                                        fontSize: "18px"
                                    }}>
                                        Vă mulțumim!
                                    </div>
                                </div>}

                            </div>

                            {this.showBottomButtons()}
                        </div>
                    </div>
                </>
            )
        }


        let style = {
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            backgroundPosition: 'top',
            backgroundImage: this.state.inviteContents.image !== null ? `url(https://${process.env.REACT_APP_IMAGE_DOMAIN}${getUrlFromImage(this.state.inviteContents.image)})` : ""
        }

        let customStyle = {
            color: this.state.inviteContents.fontColor,
            fontFamily: this.state.inviteContents.fontFamily,
            fontSize: this.state.inviteContents.fontSize,
        }

        let locations = this.state.locations.map((location, index) => {
            let time = new Date(location.time)
            let day = new Date(location.time)

            if (location.day !== undefined) {
                day = new Date(location.day)
            }

            let icon = <ImLocation/>

            if (location.name === "Locatie petrecere") {
                icon = <IoMdRestaurant/>
            } else if (location.name === "Locatie biserica") {
                icon = <GiChurch/>
            } else if (location.name === "Locatie cununie") {
                icon = <GiFamilyHouse/>
            }

            return <div key={index} style={{margin: "10px 0px"}}>
                <div style={{
                    color: this.state.inviteContents.fontColor,
                    fontFamily: this.state.inviteContents.fontFamily,
                    fontSize: Number(this.state.inviteContents.fontSize.split("px")[0]) + 5 + "px"
                }}>{location.name}</div>

                {icon}

                <div style={customStyle}>{location.label ? location.label : location.address}</div>

                <div
                    style={customStyle}>{moment(day).format('DD-MM-YYYY')} {moment(time).format('HH:mm')}</div>

                {
                    location.pinx && location.piny ?
                        <div className={"buttons"}>
                            <div className={"waze"}
                                 onClick={() => openWaze(location.pinx, location.piny, 0)}><SiWaze
                                style={{marginRight: "5px"}}/> Waze
                            </div>
                            <div className={"maps"}
                                 onClick={() => openMaps(location.pinx, location.piny, 0)}>
                                <SiGooglemaps style={{marginRight: "5px"}}/> Google Maps
                            </div>
                        </div>
                        : null
                } </div>
        })

        let body = <div></div>

        if (this.state.inviteType === "Personal") {
            body = <div style={{marginTop: "20px"}}>
                <img
                    src={`https://${process.env.REACT_APP_IMAGE_DOMAIN}${getUrlFromImage(this.state.inviteContents.image)}`}
                    alt={"Invitatie personala"} style={{width: "80vw"}}/>
                {this.state.isUsingAudio && <PlayAudio
                    link={this.state.serverAudioUrl}/>}
                {this.showBottomButtons()}
            </div>
        }

        if (this.state.inviteType === "Video") {
            // console.log(this.state.inviteContents.linkVideo);
            let linkParts = this.state.inviteContents.linkVideo.split("watch?v=")
            let link = ""
            if (linkParts.length === 1) {
                link = linkParts[0]
            } else {
                link = linkParts[0] + "embed/" + linkParts[1]
            }

            let videoId = this.state.inviteContents.linkVideo.split("watch?v=")[1].split("&")[0]
// console.log(link);
            body = <div className="MainInvite-contents" style={customStyle}>
                {/* <iframe src={link.split("&")[0]}
					frameBorder='0'
					allow='autoplay; encrypted-media'
					allowFullScreen={true}
					title='video'
					style={{ width: "80vw", height: "500px", margin: "20px auto" }}
				/> */}
                <PlayVideo videoId={videoId} link={link.split("&")[0]}
                           style={{width: "80vw", height: "500px", margin: "20px auto"}}/>

                {this.showBottomButtons()}
            </div>
        }

        if (this.state.inviteType === "Custom") {
            let has_both_parents_mire = this.state.inviteContents.parinti[1].tataMire !== "" && this.state.inviteContents.parinti[0].mamaMire !== ""
            let has_at_least_one_parent_mire = this.state.inviteContents.parinti[1].tataMire !== "" || this.state.inviteContents.parinti[0].mamaMire !== ""

            let has_both_parents_mireasa = this.state.inviteContents.parinti[3].tataMireasa !== "" && this.state.inviteContents.parinti[4].mamaMireasa !== ""
            let has_at_least_one_parent_mireasa = this.state.inviteContents.parinti[3].tataMireasa !== "" || this.state.inviteContents.parinti[4].mamaMireasa !== ""

            if (this.state.eventType === "Nunta") {
                body = <div className="MainInvite-contents" style={customStyle}>
                    <h3 style={{
                        marginBottom: "40px",
                        marginTop: "40px"
                    }}>{this.state.current_event.prenumeMire} și {this.state.current_event.prenumeMireasa}</h3>

                    <p>Spre bucuria părinților,</p>
                    <div className="parents">
                        {has_at_least_one_parent_mire && <div>
                            <div>{this.state.inviteContents.parinti[1].tataMire} {has_both_parents_mire ? "și" : ""} {this.state.inviteContents.parinti[0].mamaMire}</div>

                            <div>{this.state.inviteContents.parinti[4].numeParintiMire}</div>
                        </div>}

                        {has_at_least_one_parent_mireasa && <div>
                            <div>{this.state.inviteContents.parinti[3].tataMireasa} {has_both_parents_mireasa ? "și" : ""} {this.state.inviteContents.parinti[2].mamaMireasa}</div>

                            <div>{this.state.inviteContents.parinti[5].numeParintiMireasa}</div>
                        </div>}
                    </div>

                    {this.state.inviteContents.nasi.length > 0 &&
                        <p style={{marginBottom: "20px"}}>și a nașilor,</p>}
                    {this.state.inviteContents.nasi.length > 0 &&
                        <div>{this.state.inviteContents.nasi[0].numeNas} și {this.state.inviteContents.nasi[0].numeNasa} {this.state.inviteContents.nasi[0].numeFamilie}</div>}

                    <p style={{margin: "20px 10px"}}>{this.state.inviteContents.description}</p>


                    {this.state.isUsingAudio && <PlayAudio
                        link={this.state.serverAudioUrl}/>}
                    <div className="locations">
                        {locations}
                    </div>

                    {this.showBottomButtons()}
                </div>
            }

            if (this.state.eventType === "Botez") {
                body = <div className="MainInvite-contents" style={customStyle}>
                    <h3 style={{margin: "10px auto"}}>Salut, ma
                        numesc {this.state.current_event.prenumeMire}</h3>

                    <div style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: 'space-around',
                        margin: "10px 0px 0px 0px"
                    }}>
                        <div>Alături de parinții mei,</div>
                        <div>{this.state.inviteContents.parinti[1].tataMire} și {this.state.inviteContents.parinti[0].mamaMire}</div>
                    </div>

                    {this.state.inviteContents.nasi.length > 0 &&
                        <div style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: 'space-around'
                        }}>
                            <div>și nașii,</div>
                            {this.state.inviteContents.nasi[0].numeNas} și {this.state.inviteContents.nasi[0].numeNasa} {this.state.inviteContents.nasi[0].numeFamilie}
                        </div>}

                    <p style={{margin: "20px 10px"}}>{this.state.inviteContents.description}</p>
                    {this.state.isUsingAudio && <PlayAudio
                        link={this.state.serverAudioUrl}/>}
                    <div className="locations">
                        {locations}
                    </div>

                    {this.showBottomButtons()}
                </div>
            }

            if (this.state.eventType === "Petrecere") {
                body = <div className="MainInvite-contents" style={customStyle}>
                    <h3 style={{marginBottom: "10px"}}>Salut, sunteti invitati la
                        petrecerea {this.state.current_event.prenumeMire}</h3>

                    <p style={{margin: "20px 10px"}}>{this.state.inviteContents.description}</p>

                    {this.state.isUsingAudio && <PlayAudio
                        link={this.state.serverAudioUrl}/>}
                    <div className="locations">
                        {locations}
                    </div>

                    {this.showBottomButtons()}
                </div>
            }
        }

        return (
            <div className="MainInvite-container"
                 style={this.state.inviteType === "Custom" ? style : {}}>
                {body}
            </div>
        )
    }
}

export default WebsiteInviteModuleDemo;
