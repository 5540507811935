import React, {useEffect, useRef, useState} from "react";
import {useHistory, useParams} from "react-router-dom";
import HeaderWhite from "../../../components/HeaderWhite";
import Loader from "../../../components/Loader";
import "./styles.scss"
import axios from "axios";
import {getWeddingParticipantsPackageNumber} from "../../../functions";
import {Icon, Uploader} from "rsuite";

export default function WeddingParticipantsPhotosUpload() {

    const {id: eventID} = useParams()
    const history = useHistory()
    const uploaderRef = useRef()

    //Loaders
    const [initialLoading, setInitialLoading] = useState(true)

    useEffect(() => {
        getData()
        // eslint-disable-next-line
    }, []);

    const getData = async () => {

        //Get wedding data
        await axios.get(process.env.REACT_APP_DOMAIN + 'weddings/getWedding/' + eventID)
            .then(response => {

                if(getWeddingParticipantsPackageNumber(response.data) !== 2 && getWeddingParticipantsPackageNumber(response.data) !== 4) {
                    history.push("/")
                    return
                }
                setInitialLoading(false)
            })
            .catch(err => {
                history.push("/")
            })
    }

    const goBack = () => {
        history.push("/")
    }

    return (<div className="WeddingParticipantsPhotosUpload-container">
        <HeaderWhite shadow={true} goBack={goBack}/>

        {initialLoading ?
            <Loader/>
            :
            <div className="WeddingParticipantsPhotosUpload-contents">
                <Uploader
                    accept=".jpg,.jpeg,.png,.gif,.svg,.heic"
                    disabled={false}
                    className="uploader"
                    autoUpload={true}
                    /*
                    onChange={(files) => {
                        setFilesToUpload(files)
                    }}*/
                    multiple={true}
                    listType="picture-text"
                    //value={filesToUpload}
                    //fileList={filesToUpload.reverse()}
                    /*
                    onRemove={(file) => {
                            let newFilesToUpload = [...filesToUpload]
                            newFilesToUpload = newFilesToUpload.filter(item => item.fileKey !== file.fileKey)
                            setFilesToUpload(newFilesToUpload)
                    }}*/
                    action={process.env.REACT_APP_DOMAIN + 'weddings/participants-photos/uploadPhoto/' + eventID}
                    ref={uploaderRef}
                    removable={false}
                    renderFileInfo={(file, fileElement) => {
                        return (
                            <div key={file.fileKey}>
                                {file.status === 'finished' ?
                                    <span style={{color:'green'}}>Fotografia a fost încărcată!</span>
                                    :null}
                                {file.status === 'uploading' ?
                                    <span>Fotografia se încarcă ...</span>
                                    :null}
                                {file.status === 'inited' ?
                                    <span>Se așteaptă încărcarea ...</span>
                                    :null}
                                {file.status === 'error' ?
                                    <span style={{color:'red'}}>Eroare</span>
                                    :null}
                            </div>
                        );
                    }}
                >
                    <button>
                        <Icon icon='camera-retro' size="lg" /> Alege fotografiile
                    </button>
                </Uploader>
                <span style={{marginBottom:'1rem', marginTop:'1rem'}}>Acceptăm doar fișiere de tip imagine.</span>

                {/*<ConfirmButton
                            style={{width:'100%', marginTop:'30px'}}
                            text={"Încarcă fotografiile"}
                            function={() => {
                                handleUpload()
                            }}
                        />*/}


            </div>
        }







    </div>)
}
