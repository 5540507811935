import React from 'react'
import './EditEvent.scss'
import HeaderWhite from '../../components/HeaderWhite'
import {Button, SelectPicker, Icon, DatePicker} from 'rsuite'
import Modal from "react-responsive-modal";
import GooglePlacesAutocomplete, {geocodeByAddress} from 'react-google-places-autocomplete';

import NavbarColor from '../../components/NavbarColor'
import axios from 'axios'
import {toast} from 'react-toastify'
import isBefore from 'date-fns/isBefore'
import CustomInput from '../components/custom/CustomInput';
import {
    BackAndConfirmButtons,
    getBase64FromImage,
    getUrlFromImage,
    RedButton, reorderLocations, resizeBlob,
    textarea_max_length
} from '../components/Helper';
import LoadingModal from "./LoadingModal/LoadingModal";
import moment from "moment-timezone";
import {isAfter} from "date-fns";
import {
    convertDateTimeToBrowserTimezone,
    convertDateTimeToDefaultTimezone,
    getMaxYearsPrivateEvent
} from "../../functions";
import {corporateWeddingEvents} from "../../const";

let locatiiNunta = [{label: "Locație petrecere", value: "Locatie petrecere"}, {
    label: "Locație biserică",
    value: "Locatie biserica"
}, {label: "Locație cununie", value: "Locatie cununie"}, {label: "Alta locație", value: "Alta locatie"}]
let locatiiBotez = [{label: "Locație petrecere", value: "Locatie petrecere"}, {
    label: "Locație biserică",
    value: "Locatie biserica"
}, {label: "Alta locație", value: "Alta locatie"}]
let locatiiPetrecere = [{label: "Locație petrecere", value: "Locatie petrecere"}, {
    label: "Alta locație",
    value: "Alta locatie"
}]
let locatiiCorporate = [{label: "Locație eveniment", value: "Locatie eveniment"}, {
    label: "Alta locație",
    value: "Alta locatie"
}]

class EditEvent extends React.Component {
    state = {
        event: {},
        loading: false,
        showModal: false,
        prenumeMireasa: "",
        prenumeMire: "",
        dataEvent: "",
        eventType: "",
        eventId: "",
        eventDescription: "",
        backgroundImageLink: "",
        backgroundImage: null,
        croppedImage: null,
        locationsTypes: [],
        isPublished: false,

        base64ImageLink: "",
        showPublicNoticeModal: false,

        codEvent: "",
        codEventEdit: '',
        showCodeModal: false,
        newCodeValid: true,

        locations: [],
        locationType: "",
        locationName: "",
        locationData: new Date(),
        locationDay: new Date(),
        locationAddress: "",
        locationLabel: "",

        editIndex: -1,
        mapsRestrictions: true,

        waitModal: false
    }

    params = new URLSearchParams(window.location.search) // id=123

    async componentDidMount() {

        if(this.params && this.params.get('redirectToPreview')) {
            this.props.history.push('/organizer/eventPage/' + this.props.match.params.id)
        }

        let auth = {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('jwt')
            }
        }

        let info = await axios.get(process.env.REACT_APP_DOMAIN + 'weddings/getThisWedding/' + this.props.match.params.id, auth);

        if (info !== null) {
            this.setState({
                eventType: info.data.eventType,
                eventId: info.data.id,
                locationsTypes: (info.data.eventType === "Nunta" || info.data.eventType === "Cununie") ? locatiiNunta :
                    (info.data.eventType === "Botez" || info.data.eventType === "Mot" || info.data.eventType === "Turta") ?
                        locatiiBotez :
                        info.data.eventType === "Petrecere" ?
                            locatiiPetrecere
                            :
                        corporateWeddingEvents.includes(info.data.eventType) ?
                            locatiiCorporate
                            :
                            locatiiCorporate
                ,
                prenumeMireasa: info.data.prenumeMireasa,
                prenumeMire: info.data.prenumeMire,
                eventDescription: info.data.eventDescription,
                dataEvent: new Date(info.data.dataEvent),
                locationDay: new Date(info.data.dataEvent),
                backgroundImageLink: info.data.backgroundImage !== null ? `https://${process.env.REACT_APP_IMAGE_DOMAIN}${getUrlFromImage(info.data.backgroundImage)}` : null,
                codEvent: info.data.id_eveniment,
                codEventEdit: info.data.id_eveniment,
                isPublished: !!info.data.isPublished,
                locations: info.data.locatii ? info.data.locatii : [],
                event: info.data
            })
        }
    }

    selectLocationType = (value) => {
        this.setState({
            locationType: value
        })
    }

    changeLocationName = (value) => {
        this.setState({
            locationName: value
        })
    }

    changeLocationDate = (value) => {
        this.setState({
            locationData: value
        })
    }

    changeLocationDay = (value) => {
        this.setState({
            locationDay: value
        })
    }

    changeEventDescription = (value) => {
        if (this.state.eventDescription.length > textarea_max_length - 1 && value.length > textarea_max_length - 1) {
            return
        }

        this.setState({
            eventDescription: value
        })
    }

    setLocationAddress = (value) => {
        this.setState({
            locationAddress: value.description,
            locationLabel: value.description
        })
    }

    addLocation = () => {
        let {locationAddress, locationData, locationDay, locationName, locationLabel} = this.state;

        if (this.state.locationType === "") {
            toast.error("Adaugati tipul sau numele locatiei")
            return
        }

        if (this.state.locationType === "Alta locatie" && locationName === "") {
            toast.error("Adaugati numele locatiei ")
            return
        }

        if (locationName === "") {
            locationName = this.state.locationType
        }

        if (locationData === "") {
            toast.error("Adaugati ora evenimentului ")
            return
        }

        if (locationDay === "") {
            toast.error("Adaugati ziua evenimentului ")
            return
        }

        if (locationAddress === "" && locationLabel === "") {
            toast.error("Adaugati adresa evenimentului")
            return
        }

        let current = [...this.state.locations]
if(locationAddress && locationAddress.length > 0) {
    geocodeByAddress(locationAddress)
        .then(results => {

            let pinx = parseFloat(results[0].geometry.location.lat())
            let piny = parseFloat(results[0].geometry.location.lng())

            let location = {
                label: locationLabel && locationLabel.length > 0 ? locationLabel : '',
                address: locationAddress,
                pinx: pinx,
                piny: piny,
                time: locationData,
                day: locationDay,
                name: locationName
            }

            if (this.state.editIndex !== -1) {
                current[this.state.editIndex] = location
            } else {
                current.push(location)
            }

            current = reorderLocations(current)

            this.setState({
                locations: current,
                locationName: "",
                locationAddress: "",
                locationLabel: "",
                locationType: "",
                locationData: new Date(),
                locationDay: new Date(this.state.dataEvent),
                editIndex: -1,
                showModal: false
            })
        })
} else {
    let location = {
        label: locationLabel && locationLabel.length > 0 ? locationLabel : '',
        address: '',
        pinx: null,
        piny: null,
        time: locationData,
        day: locationDay,
        name: locationName
    }

    if (this.state.editIndex !== -1) {
        current[this.state.editIndex] = location
    } else {
        current.push(location)
    }

    current = reorderLocations(current)

    this.setState({
        locations: current,
        locationName: "",
        locationAddress: "",
        locationLabel: "",
        locationType: "",
        locationData: new Date(),
        locationDay: new Date(this.state.dataEvent),
        editIndex: -1,
        showModal: false
    })
}
    }

    editLocation = (index) => {
        let location = this.state.locations[index]
        let list = this.state.locationsTypes.filter(elem => elem.value === location.name)

        this.setState({
            editIndex: index,
            locationAddress: location.address ? location.address : '',
            locationLabel: location.label ? location.label : '',
            locationType: list.length === 0 ? "Alta locatie" : location.name,
            locationData: new Date(location.time),
            locationDay: location.day === undefined ? new Date(location.time) : new Date(location.day),
            locationName: list.length === 0 ? location.name : "",

            showModal: true
        })
    }

    removeLocation = () => {
        let index = this.state.editIndex
        let newLocations = [...this.state.locations]

        newLocations.splice(index, 1)

        this.setState({
            locations: newLocations,
            locationName: "",
            locationAddress: "",
            locationLabel: "",
            locationType: "",
            locationData: new Date(),
            locationDay: new Date(this.state.dataEvent),
            editIndex: -1,
            showModal: false
        })
    }

    openModal = () => {
        this.setState({
            showModal: true
        })
    }

    closeModal = () => {
        this.setState({
            locationName: "",
            locationAddress: "",
            locationType: "",
            locationData: new Date(),
            locationDay: new Date(this.state.dataEvent),
            editIndex: -1,
            showModal: false
        })
    }

    handleError = err => {
        console.error(err)
    }

    handleChange = (value, event) => {
        this.setState({
            [event.target.name]: value
        })
    }

    onChange = (value, key) => {
        this.setState({
            [key]: value
        })
    }

    handlePoza = async (fileList) => {
        if (fileList.length === 0) {
            this.setState({
                backgroundImage: null
            })
            return
        }

        if (fileList[0].blobFile.size > 7000000) {
            toast.error("Fisier prea mare, maxim 7MB")
        }

        let blob = await resizeBlob(fileList[0].blobFile)

        this.setState({
            backgroundImage: [blob]
        })
    }

    saveCroppedImage = async (croppedImg) => {
        let img = {}
        img.blobFile = croppedImg
        let data = await getBase64FromImage(img)
        // console.log(data);

        this.setState({
            croppedImage: croppedImg,
            base64ImageLink: data
        })
    }

    saveEvent = async (noRedirect = false, callback = () => {}) => {

        this.setState({
            showCodeModal: false
        })

        if (this.state.eventType === "" || this.state.prenumeMire === "" || this.state.dataEvent === "") {
            toast.error("Nu ati completat toate campurile")
            return;
        }

        if ((this.state.eventType === "Nunta" || this.state.eventType === "Cununie") && this.state.prenumeMireasa === "") {
            toast.error("Nu ati completat toate campurile")
            return;
        }

        this.setState({
            waitModal: true
        })

        let obj = {
            id_eveniment: this.state.codEventEdit && this.state.newCodeValid ? this.state.codEventEdit : this.state.codEvent,
            prenumeMireasa: (this.state.eventType === "Nunta" || this.state.eventType === "Cununie") ? this.state.prenumeMireasa : "Mireasa",
            prenumeMire: this.state.prenumeMire,
            dataEvent: this.state.dataEvent,
            eventDescription: this.state.eventDescription,
            locations: this.state.locations,
            imageLink: this.state.base64ImageLink,
            isPublished: this.state.isPublished
        }

        //for image
        // let formData = new FormData()
        // formData.append("data", JSON.stringify(obj))

        // if (this.state.backgroundImage !== null) {
        // 	formData.append("files.backgroundImage", this.state.backgroundImage[0])
        // }

        //for imga cropped
        // if (this.state.backgroundImage !== null && this.state.croppedImage !== null) {
        // 	formData.append("files.backgroundImage", this.state.croppedImage)
        // }

        let auth = {
            headers: {
                // "Content-Type": `multipart/form-data;`,
                'Authorization': 'Bearer ' + localStorage.getItem('jwt')
            }
        }

        axios.put(process.env.REACT_APP_DOMAIN + 'weddings/edit/' + this.state.eventId, obj, auth)//formData, auth)
            .then(response => {
                if(response.data.status === 1) {
                    if(!noRedirect) {
                        toast.success("Eventul a fost actualizat!")
                        this.props.history.push('/organizer/info/' + response.data.id_eveniment)
                    }
                    callback()
                } else {
                    toast.error("A aparut o eroare!")
                }
            })
            .catch(err => {
                toast.error("Eroare")
                this.setState({
                    waitModal: false
                })
            })
    }

    saveAndCheckNewEventCode = async (newCode) => {

        newCode = newCode.replace(' ','')
        newCode = newCode.replace('\'','_')
        newCode = newCode.replace(/[^a-z0-9]/gi,'')

        this.setState({
            codEventEdit: newCode
        })

        if(!newCode || newCode.length < 4 || newCode.length > 20) {
            this.setState({
                newCodeValid: false
            })
            return
        }

        if(this.state.codEvent === newCode) {
            this.setState({
                newCodeValid: true
            })
            return
        }

        let auth = {
            headers: {
                // "Content-Type": `multipart/form-data;`,
                'Authorization': 'Bearer ' + localStorage.getItem('jwt')
            }
        }

        let obj = {
            new_id_eveniment: newCode
        }

       await axios.post(process.env.REACT_APP_DOMAIN + 'weddings/checkWeddingNewCode', obj, auth)
            .then(response => {
              if(response.data.status === 1) {
                  this.setState({
                      newCodeValid: true
                  })
              } else {
                  this.setState({
                      newCodeValid: false
                  })
              }
            })
            .catch(err => {
            })
    }

    goBack = () => {
        this.props.history.push("/organizer/info/" + this.props.match.params.id)
    }

    handleStartDate = (value) => {
        this.setState({
            dataEvent: value.toISOString(),
            locationDay: value.toISOString()
        })
    }

    render() {
        let locations = <div></div>

        if (this.state.locations && this.state.locations.length > 0) {
            let columnStyle = {width: "30%"}
            locations = <div className="location">
                <div className="header">
                    <div style={columnStyle}>Nume Locatie</div>

                    <div style={columnStyle}>Adresa</div>

                    <div style={columnStyle}>Data</div>

                    <div style={{width: "10%"}}>Edit</div>
                </div>

                {this.state.locations.map((element, index) => {
                    let addr = element.address.split(',')[0]
                    if(element.label) {
                        addr = element.label
                    }
                    let time = element.time

                    if (typeof time === "string") {
                        time = new Date(element.time);
                    }



                    return <div className={index % 2 === 0 ? "even-line" : "uneven-line"} key={index}
                                onClick={() => this.editLocation(index)}>
                        <div style={columnStyle}> {element.name}</div>
                        <div style={columnStyle}> {addr.toString()} </div>
                        <div
                            style={columnStyle}>
                            {moment(element.day).format('DD-MM-YYYY')} {moment(time).format('HH:mm')} </div>
                        <div style={{width: "10%"}}><Icon icon={"edit"}/></div>
                    </div>
                })}
            </div>
        }

        let customInputStyle = {
            margin: "10px 0px",
            backgroundColor: "#E5F7FE",
            borderRadius: "10px"
        }

        return (
            <div className="EditEvent-container">
                <HeaderWhite goBack={this.goBack}/>

                <div className="EditEvent-contents">
                    <h4 id="title"> Editează evenimentul </h4>

                    <div className="input-zone">
                        <br/>

                        {(this.state.eventType === "Nunta" || this.state.eventType === "Cununie") &&
                        <CustomInput value={this.state.prenumeMireasa}
                                     onChange={(value) => this.onChange(value, "prenumeMireasa")}
                                     style={customInputStyle} placeholder={"Prenume Mireasă"}
                                     icon={<Icon icon="user"/>}/>
                        }

                        <CustomInput value={this.state.prenumeMire}
                                     onChange={(value) => this.onChange(value, "prenumeMire")} style={customInputStyle}
                                     placeholder={(this.state.eventType === "Nunta" || this.state.eventType === "Cununie") ? "Prenume Mire" :
                                         (this.state.eventType === "Botez" || this.state.eventType === "Mot" || this.state.eventType === "Turta") ? "Nume Copil" :
                                             this.state.eventType === 'Petrecere' ? "Titlu aniversare" :
                                                 corporateWeddingEvents.includes(this.state.eventType) ? 'Titlu eveniment' : 'Titlu eveniment'

                                     }
                                     icon={<Icon icon="user"/>}/>

                        <div className="AddHours-date-picker">
                            <p>Vă rog alegeți data evenimentului:</p>
                            <DatePicker
                                format={"DD/MM/YYYY"}
                                value={new Date(this.state.dataEvent)}
                                style={{width: "100%", borderRadius: "5px", backgroundColor: "#E5F7FE"}}
                                placement="bottomStart"
                                disabledDate={date => isBefore(date, new Date(new Date().setDate(new Date().getDate() - 1))) || isAfter(date, moment().add(getMaxYearsPrivateEvent(this.state.eventType), 'years').toDate())}

                                defaultOpen={false}
                                oneTap onSelect={this.handleStartDate}
                                appearance="subtle"
                                placeholder="Data evenimentului"
                                locale={{
                                    sunday: 'Sa',
                                    monday: 'Lu',
                                    tuesday: 'Ma',
                                    wednesday: 'Mi',
                                    thursday: 'Jo',
                                    friday: 'Vi',
                                    saturday: 'Sa',
                                    ok: 'OK',
                                    today: 'Azi',
                                    yesterday: 'Ieri',
                                    hours: 'Ore',
                                    minutes: 'Minute',
                                    seconds: 'Secunde'
                                }}/>

                            <p>Vă rog alegeți ora evenimentului:</p>
                            <DatePicker ranges={[]}

                                        style={{
                                            width: "100%",
                                            borderRadius: "5px",
                                            backgroundColor: "#E5F7FE",
                                            marginBottom: '10px'
                                        }}
                                        hideMinutes={minute => minute % 5 !== 0}
                                        disabledDate={date => isBefore(date, new Date(new Date().setDate(new Date().getDate() - 1)))}
                                        defaultOpen={false} oneTap
                                        appearance="subtle" placeholder="Ora evenimentului"
                                        locale={{
                                            sunday: 'Sa',
                                            monday: 'Lu',
                                            tuesday: 'Ma',
                                            wednesday: 'Mi',
                                            thursday: 'Jo',
                                            friday: 'Vi',
                                            saturday: 'Sa',
                                            ok: 'OK',
                                            today: 'Azi',
                                            yesterday: 'Ieri',
                                            hours: 'Ore',
                                            minutes: 'Minute',
                                            seconds: 'Secunde'
                                        }}
                                        onSelect={(date) => {
                                            //setLocationData(moment(date).tz('Europe/Bucharest', true).toDate())
                                            //date = moment(date.getHours() + ':' + date.getMinutes(), "HH:mm").toDate()
                                            this.handleStartDate(convertDateTimeToDefaultTimezone(date))
                                        }}
                                        value={convertDateTimeToBrowserTimezone(new Date(this.state.dataEvent))}
                                        placement="bottomStart"
                                        format="HH:mm"
                                        renderValue={(date) => moment(date).format('HH:mm')}
                            />
                        </div>

                        {this.state.eventType !== "" && <div className="Locations" style={{margin: "10px 0px"}}>
                            <Modal classNames={{
                                overlay: 'modal-overlay',
                                modal: 'modal-add-location',
                                closeButton: 'modal-close-button'
                            }} open={this.state.showModal} onClose={this.closeModal}>
                                <div style={{margin: "10px 0px"}}>

                                    <SelectPicker
                                        size="lg"
                                        onSelect={this.selectLocationType}
                                        value={this.state.locationType !== "Alta locatie" ? this.state.locationType : "Alta locatie"}
                                        searchable={false}
                                        appearance="subtle"
                                        placement="bottom"
                                        id="job-select"
                                        data={this.state.locationsTypes}
                                        style={{
                                            width: "100%",
                                            marginTop: "20px",
                                            borderRadius: "5px",
                                            backgroundColor: "#E5F7FE"
                                        }}
                                        placeholder="Selecteaza locatie"
                                    >
                                    </SelectPicker>
                                </div>

                                <div style={{marginBottom: "10px"}}>
                                    {this.state.locationType === "Alta locatie"
                                        ? <CustomInput style={{
                                            height: "40px",
                                            width: "100%",
                                            borderRadius: "5px",
                                            padding: "5px",
                                            backgroundColor: "#E5F7FE"
                                        }}
                                                       value={this.state.locationName}
                                                       onChange={this.changeLocationName} placeholder={"Nume Locație"}/>
                                        : null
                                    }
                                </div>

                                <div style={{margin: "10px 0px"}}>
                                    <DatePicker
                                        appearance="subtle"
                                        style={{
                                            width: "100%",
                                            borderRadius: "5px",
                                            height: "40px",
                                            backgroundColor: "#E5F7FE"
                                        }}
                                        ranges={[]}
                                        block
                                        locale={{
                                            sunday: 'Sa',
                                            monday: 'Lu',
                                            tuesday: 'Ma',
                                            wednesday: 'Mi',
                                            thursday: 'Jo',
                                            friday: 'Vi',
                                            saturday: 'Sa',
                                            ok: 'OK',
                                            today: 'Azi',
                                            yesterday: 'Ieri',
                                            hours: 'Ore',
                                            minutes: 'Minute',
                                            seconds: 'Secunde'
                                        }}
                                        placeholder="Ora eveniment"
                                        size="lg"
                                        onSelect={this.changeLocationDay}
                                        value={new Date(this.state.locationDay)}
                                        placement="bottomEnd"
                                    />
                                </div>

                                <div style={{marginBottom: "10px"}}>
                                    <DatePicker
                                        appearance="subtle"
                                        style={{
                                            width: "100%",
                                            borderRadius: "5px",
                                            height: "40px",
                                            backgroundColor: "#E5F7FE"
                                        }}
                                        ranges={[]}
                                        block
                                        hideMinutes={minute => minute % 5 !== 0}
                                        locale={{
                                            sunday: 'Sa',
                                            monday: 'Lu',
                                            tuesday: 'Ma',
                                            wednesday: 'Mi',
                                            thursday: 'Jo',
                                            friday: 'Vi',
                                            saturday: 'Sa',
                                            ok: 'OK',
                                            today: 'Azi',
                                            yesterday: 'Ieri',
                                            hours: 'Ore',
                                            minutes: 'Minute',
                                            seconds: 'Secunde'
                                        }}
                                        placeholder="Ora eveniment"
                                        size="lg"
                                        onSelect={(date) => {
                                            //setLocationData(moment(date).tz('Europe/Bucharest', true).toDate())
                                            //date = moment(date.getHours() + ':' + date.getMinutes(), "HH:mm").toDate()
                                            this.changeLocationDate(convertDateTimeToDefaultTimezone(date))
                                        }}
                                        value={convertDateTimeToBrowserTimezone(new Date(this.state.locationData))}
                                        placement="bottomEnd"
                                        format="HH:mm"
                                        renderValue={(date) => moment(date).format('HH:mm')}
                                    />
                                </div>

                                <div>
                                    <label>
                                        <input type={"checkbox"} checked={this.state.mapsRestrictions === true}
                                               onChange={() => this.setState({mapsRestrictions: true})}/> Locații din România
                                    </label>
                                    <label style={{marginLeft:'10px'}}>
                                        <input type={"checkbox"} checked={this.state.mapsRestrictions === false}
                                               onChange={() => this.setState({mapsRestrictions: false})}/> Locații din toată lumea
                                    </label>
                                </div>

                                <div style={{width: "100%", marginBottom: "5px"}} id='fromGoogleAutocomplete'>
                                    <GooglePlacesAutocomplete
                                        debounce={500}
                                        minLengthAutocomplete={3}
                                        currentLocation={false}
                                        placeholder={"Adresa"}
                                        onSelect={value => this.setLocationAddress(value)}
                                        autocompletionRequest={{
                                            componentRestrictions: this.state.mapsRestrictions ? {
                                                country: 'ro'
                                            }: {}
                                        }}
                                        initialValue={this.state.locationAddress}
                                        inputStyle={{
                                            width: "100%",
                                            padding: "5px",
                                            backgroundColor: "#E5F7FE",
                                            border: "0px",
                                            borderRadius: "5px",
                                            height: "40px"
                                        }}
                                    />
                                </div>

                                <p style={{marginTop:"10px"}}>Căutarea adreselor se face prin intermediul Google Maps.
                                    În cazul în care adresa dvs. nu există, puteți completa doar câmpul ”Denumire” însă în acest caz
                                    integrarea cu Google Maps și Waze va fi indisponibilă.
                                    <br/>
                                    În cazul în care adresa dvs. există dar doriți să modificați textul sau să adăugați detalii suplimentare trebuie doar să modificați câmpul ”Denumire”.
                                </p>

                                <CustomInput
                                    style={{
                                        height: "40px",
                                        width: "100%",
                                        borderRadius: "5px",
                                        padding: "5px",
                                        backgroundColor: "#E5F7FE",
                                        marginTop: "15px"
                                    }}
                                    value={this.state.locationLabel}
                                    onChange={(val) => {
                                        this.setState({locationLabel: val})
                                    }} placeholder={"Denumire"} />

                                <BackAndConfirmButtons textBack={"Înapoi"} textConfirm={"Salvează"}
                                                       functionBack={this.closeModal}
                                                       functionConfirm={this.addLocation}/>

                                {this.state.editIndex !== -1 &&
                                <RedButton text={"Șterge"} function={this.removeLocation}/>}
                            </Modal>

                            <div className='add-small-button' onClick={() => {

                                this.setState({
                                    locationType: "",
                                    locationName: "",
                                    locationData: new Date(),
                                    locationDay: new Date(),
                                    locationAddress: "",
                                    locationLabel: "",
                                })
                                this.openModal()
                            }}>
                                <Icon icon="plus-circle" size="lg" style={{color: "#05AFF2", marginRight: "10px"}}/>
                                <div style={{fontSize: '16px'}}>Adaugă dată și locație</div>
                            </div>


                            {locations}
                        </div>}

                        <div className="Footer">

                            <Button onClick={this.goBack} className="back-button" style={{color: "#1E1C59"}}>
                                Înapoi
                            </Button>

                            <Button onClick={() => this.saveEvent()} className="add-button"
                                    style={{backgroundColor: "#00afcc", color: "white"}}>
                                Salvează
                            </Button>
                        </div>
                    </div>

                    <LoadingModal loading={this.state.waitModal}/>
                </div>




                <NavbarColor history={this.props.history}/>
            </div>
        )
    }
}

export default EditEvent;
