import React from 'react'
import './MenuAdmin.scss'
import MenuBlocks from './components/MenuBlocks'
import Countdown from "react-countdown";
import {Icon} from "rsuite";
import {getWeddingTitle} from "../../../../../functions";

class MenuAdmin extends React.Component {
    state = {
        current_event: this.props.event
    }

    componentDidMount() {
        let isAndroid = navigator.userAgent.toLowerCase().indexOf("android") > -1

        if (isAndroid) {
            let viewHeight = window.innerHeight
            let viewport = document.querySelector("meta[name=viewport]");
            viewport.setAttribute("content", "height=" + viewHeight + "px, width=device-width, initial-scale=1.0")
            //document.write('<meta name ="viewport" content="width=device-width, height=' + window.innerHeight+'initial-scale=1.0">');
        }
    }

    renderCountdown = ({days, hours, minutes, seconds, completed}) => {
        if (completed) {
            // Render a completed state

            let date = new Date(this.state.current_event.dataEvent).toString()

            date = date.split(" ")

            return <div className="Date"> {date[2] + " " + date[1] + " " + date[3]} </div>;
        } else {
            // Render a countdown
            return <div className={"countdown"}>
                <div className={"item"}>
                    <div className={"value"}>{days}</div>
                    <div className={"title"}>{days === 1 ? 'Zi' : 'Zile'}</div>
                </div>

                <div className={"item"}>
                    <div className={"value"}>{hours}</div>
                    <div className={"title"}>{hours === 1 ? 'Oră' : 'Ore'}</div>
                </div>

                <div className={"item"}>
                    <div className={"value"}>{minutes}</div>
                    <div className={"title"}>{minutes === 1 ? 'Minut' : 'Minute'}</div>
                </div>

                <div className={"item"}>
                    <div className={"value"}>{seconds}</div>
                    <div className={"title"}>{'Secunde'}</div>
                </div>

            </div>
        }
    }

    render() {

        return (
            <div className="MenuAdmin-container">

                <p className="Name"> {getWeddingTitle(this.state.current_event)}</p>
                <Countdown
                    date={this.state.current_event.dataEvent}
                    renderer={this.renderCountdown}
                />
                <div> Acest eveniment are {
                    !this.state.current_event.packages || !this.state.current_event.packages.wedding_participants ?
                        <div className={"active-package free"}>
                            Pachetul gratuit
                        </div>
                        :
                        <div className={"active-package invites"}>
                            <Icon icon="money" style={{marginRight:'5px'}}/>
                            {'Pachetul '+ this.state.current_event.packages.wedding_participants.details.title}
                            {
                                this.state.current_event.packages && this.state.current_event.packages.wedding_participants &&
                                this.state.current_event.packages.wedding_participants.details && this.state.current_event.packages.wedding_participants.details.addons &&
                                this.state.current_event.packages.wedding_participants.details.addons.length > 0 ?
                                    ' (+' + this.state.current_event.packages.wedding_participants.details.addons[0].title + ')'
                                    :
                                    ''
                            }
                        </div>
                }
                   </div>

                <div className="MenuAdmin-contents"
                     style={this.props.walkthroughActiveIndex !== 0 ? {overflowY: 'inherit'} : {}}>
                    <MenuBlocks walkthroughActiveIndex={this.props.walkthroughActiveIndex}
                                history={this.props.history} event={this.props.event}
                                moveTo={this.props.moveTo} eventID={this.props.id}
                                eventType={this.state.current_event.eventType}
                                hasNewResponses={this.props.hasNewResponses}/>
                </div>
            </div>
        )
    }
}

export default MenuAdmin;
