import template1Content from "./templates/template1/template1";

export const inviteTemplates = [
    {
        id: 1,
        title: "Model 1",
        preview: "/images/inviteTemplates/1/preview.jpg",
        background: "/images/inviteTemplates/1/background.jpg",
        type: "Nunta",
        otherTypes: [
            "Cununie"
        ],
        colors: {
            baseColor: "#CC8F78",
            borderColor: "#3a3a3a",
            fontColor: "#3a3a3a"
        },
        usage: 2,
        introAnimations: [
            1
        ],
        introAnimationsConfig: {
            1: {
                optionalAnimation1: 'autumn_leaves',
            }
        },
        staticBackground: true,
        introScreen: {
            templateId: 2,
            config: {
                fontColor: "#ffffff",
                envelopeColor: "#dfc5ae",
                image1: "/1/intro_screen_1.png"
            }
        }
    },
    {
        id: 2,
        title: "Model 2",
        preview: "/images/inviteTemplates/2/preview.jpg",
        background: "/images/inviteTemplates/2/background.jpg",
        type: "Nunta",
        otherTypes: [
            "Cununie"
        ],
        colors: {
            baseColor: "#e89fd0",
            borderColor: "#e89fd0",
            fontColor: "#ffffff"
        },
        usage: 5,
        introAnimations: [
            1
        ],
        staticBackground: true,
        introScreen: {
            templateId: 3,
            config: {
                fontColor: "#ffffff",
                envelopeColor: "#e89fd0",
                image1: "/2/intro_screen_1.png"
            }
        }
    },
    {
        id: 3,
        title: "Model 3",
        preview: "/images/inviteTemplates/3/preview.jpg",
        background: "/images/inviteTemplates/3/background.jpg",
        type: "Nunta",
        otherTypes: [
            "Cununie"
        ],
        colors: {
            baseColor: "#ffffff",
            borderColor: "#ffffff",
            fontColor: "#ffffff"
        },
        usage: 2,
        introAnimations: [
            1
        ],
        staticBackground: true,
        introScreen: {
            templateId: 3,
            config: {
                fontColor: "#e38e92",
                envelopeColor: "#eee9e9",
                image1: "/3/intro_screen_1.png"
            }
        }
    },
    {
        id: 4,
        title: "Model 4",
        preview: "/images/inviteTemplates/4/preview.jpg",
        background: "/images/inviteTemplates/4/background.jpg",
        type: "Nunta",
        otherTypes: [
            "Cununie"
        ],
        colors: {
            baseColor: "#3F3351",
            borderColor: "#203239",
            fontColor: "#3F3351"
        },
        usage: 8,
        introAnimations: [
            1
        ],
        staticBackground: true,
        introScreen: {
            templateId: 3,
            config: {
                fontColor: "#ffffff",
                envelopeColor: "#3F3351",
                image1: "/4/background.jpg"
            }
        }
    },
    {
        id: 7,
        title: "Model 5",
        preview: "/images/inviteTemplates/7/preview.jpg",
        background: "/images/inviteTemplates/7/background.jpg",
        type: "Nunta",
        otherTypes: [
            "Cununie"
        ],
        colors: {
            baseColor: "#ECCD69",
            borderColor: "#ECCD69",
            fontColor: "#ECCD69"
        },
        usage: 13,
        introAnimations: [
            1
        ],
        introAnimationsConfig: {
            1: {
                birdsColor: '#aaaaaa'
            }
        },
        staticBackground: true,
        introScreen: {
            templateId: 3,
            config: {
                fontColor: "#3a3a3a",
                envelopeColor: "#ECCD69",
                image1: "/7/background.jpg"
            }
        }
    },
    {
        id: 8,
        title: "Model 6",
        preview: "/images/inviteTemplates/8/preview.jpg",
        background: "/images/inviteTemplates/8/background.jpg",
        type: "Nunta",
        otherTypes: [
            "Cununie"
        ],
        colors: {
            baseColor: "#E2CC8E",
            borderColor: "#E2CC8E",
            fontColor: "#E2CC8E"
        },
        usage: 4,
        introAnimations: [
            1
        ],
        introAnimationsConfig: {
            1: {birdsColor: '#aaaaaa'}
        },
        staticBackground: true,
        introScreen: {
            templateId: 3,
            config: {
                fontColor: "#3a3a3a",
                envelopeColor: "#E2CC8E",
                image1: "/8/background.jpg"
            }
        }
    },
    {
        id: 9,
        title: "Model 7",
        preview: "/images/inviteTemplates/9/preview.jpg",
        background: "/images/inviteTemplates/9/background.jpg",
        type: "Nunta",
        otherTypes: [
            "Cununie"
        ],
        colors: {
            baseColor: "#E2CC8E",
            borderColor: "#E2CC8E",
            fontColor: "#E2CC8E"
        },
        usage: 4,
        introAnimations: [
            1
        ],
        introAnimationsConfig: {
            1: {birdsColor: '#aaaaaa'}
        },
        staticBackground: true,
        introScreen: {
            templateId: 3,
            config: {
                fontColor: "#3a3a3a",
                envelopeColor: "#E2CC8E",
                image1: "/9/background.jpg"
            }
        }
    },
    {
        id: 17,
        title: "Model 8",
        preview: "/images/inviteTemplates/17/preview.jpg",
        background: "/images/inviteTemplates/17/background.jpg",
        type: "Nunta",
        otherTypes: [
            "Cununie"
        ],
        colors: {
            baseColor: "#664945",
            borderColor: "#664945",
            fontColor: "#211110"
        },
        usage: 11,
        introAnimations: [
            1
        ],
        staticBackground: true,
        introScreen: {
            templateId: 3,
            config: {
                fontColor: "#ffffff",
                envelopeColor: "#664945",
                image1: "/17/background.jpg"
            }
        }
    },
    {
        id: 18,
        title: "Model 9",
        preview: "/images/inviteTemplates/18/preview.jpg",
        background: "/images/inviteTemplates/18/background.jpg",
        type: "Nunta",
        otherTypes: [
            "Cununie"
        ],
        colors: {
            baseColor: "#afeaa6",
            borderColor: "#afeaa6",
            fontColor: "#ffffff"
        },
        usage: 75,
        introAnimations: [
            1
        ],
        introAnimationsConfig: {
            1: {birdsColor: '#aaaaaa'}
        },
        staticBackground: true,
        introScreen: {
            templateId: 3,
            config: {
                fontColor: "#3a3a3a",
                envelopeColor: "#8ccc83",
                image1: "/18/background.jpg"
            }
        }
    },
    {
        id: 19,
        title: "Model 10",
        preview: "/images/inviteTemplates/19/preview.jpg",
        background: "/images/inviteTemplates/19/background.jpg",
        type: "Nunta",
        otherTypes: [
            "Cununie"
        ],
        colors: {
            baseColor: "#bb6a18",
            borderColor: "#bb6a18",
            fontColor: "#4f6c01"
        },
        usage: 2,
        introAnimations: [
            1
        ],
        introAnimationsConfig: {
            1: {
                optionalAnimation1: 'autumn_leaves',
                autumnLeavesColor: '#86986f'
            }
        },
        staticBackground: true,
        introScreen: {
            templateId: 3,
            config: {
                fontColor: "#ffffff",
                envelopeColor: "#bb6a18",
                image1: "/19/background.jpg"
            }
        }
    },
    {
        id: 23,
        title: "Model 11",
        preview: "/images/inviteTemplates/23/preview.jpg",
        background: "/images/inviteTemplates/23/background.jpg",
        type: "Nunta",
        otherTypes: [
            "Cununie"
        ],
        colors: {
            baseColor: "#000000",
            borderColor: "#000000",
            fontColor: "#000000"
        },
        usage: 1,
        introAnimations: [
            1
        ],
        introAnimationsConfig: {
            1: {
                optionalAnimation1: 'autumn_leaves',
            }
        },
        introScreen: {
            templateId: 2,
            config: {
                envelopeColor: "#595350",
                fontColor: "#ffffff",
                image1: "/23/intro_screen_1.png",
                image2: "/23/intro_screen_2.png"
            }
        }
    },
    {
        id: 24,
        title: "Model 12",
        preview: "/images/inviteTemplates/24/preview.jpg",
        background: "/images/inviteTemplates/24/background.jpg",
        type: "Nunta",
        otherTypes: [
            "Cununie"
        ],
        colors: {
            baseColor: "#492C32",
            borderColor: "#492C32",
            fontColor: "#000000"
        },
        usage: 0,
        introAnimations: [
            1
        ],
        introAnimationsConfig: {
            1: {
                optionalAnimation1: 'autumn_leaves',
            }
        },
        staticBackground: true,
        introScreen: {
            templateId: 3,
            config: {
                fontColor: "#492C32",
                envelopeColor: "#bdb4ac",
                image1: "/24/intro_screen_1.png"
            }
        }
    },
    {
        id: 25,
        title: "Model 13",
        preview: "/images/inviteTemplates/25/preview.jpg",
        background: "/images/inviteTemplates/25/background.jpg",
        type: "Nunta",
        otherTypes: [
            "Cununie"
        ],
        colors: {
            baseColor: "#b27676",
            borderColor: "#b27676",
            fontColor: "#d3d3d3"
        },
        usage: 11,
        introAnimations: [
            1
        ],
        introAnimationsConfig: {
            1: {
                birdsColor: '#aaaaaa'
            }
        },
        staticBackground: true,
        introScreen: {
            templateId: 3,
            config: {
                fontColor: "#ffffff",
                envelopeColor: "#b27676",
                image1: "/25/background.jpg"
            }
        }
    },
    {
        id: 28,
        title: "Model 14",
        preview: "/images/inviteTemplates/28/preview.jpg",
        background: "/images/inviteTemplates/28/background.jpg",
        type: "Nunta",
        otherTypes: [
            "Cununie"
        ],
        colors: {
            baseColor: "#476C4E",
            borderColor: "#476C4E",
            fontColor: "#0A0A0A"
        },
        usage: 13,
        introAnimations: [
            1
        ],
        introAnimationsConfig: {
            1: {
                optionalAnimation1: 'autumn_leaves',
                autumnLeavesColor: '#86986f'
            }
        },
        introScreen: {
            templateId: 2,
            config: {
                envelopeColor: "#476C4E",
                fontColor: "#ffffff",
                image1: "/27/intro_screen_1.png"
            }
        }
    },
    {
        id: 32,
        title: "Model 15",
        preview: "/images/inviteTemplates/32/preview.jpg",
        background: "/images/inviteTemplates/32/background.jpg",
        type: "Nunta",
        otherTypes: [
            "Cununie"
        ],
        colors: {
            baseColor: "#476C4E",
            borderColor: "#476C4E",
            fontColor: "#0A0A0A"
        },
        usage: 4,
        introAnimations: [
            1
        ],
        introAnimationsConfig: {
            1: {
                optionalAnimation1: 'autumn_leaves',
                autumnLeavesColor: '#86986f'
            }
        },
        introScreen: {
            templateId: 2,
            config: {
                envelopeColor: "#476C4E",
                fontColor: "#ffffff",
                image1: "/30/intro_screen_1.png",
                image2: "/30/intro_screen_2.png"
            }
        }
    },
    {
        id: 33,
        title: "Model 16",
        preview: "/images/inviteTemplates/33/preview.jpg",
        background: "/images/inviteTemplates/33/background.jpg",
        type: "Nunta",
        otherTypes: [
            "Cununie"
        ],
        colors: {
            baseColor: "#476C4E",
            borderColor: "#476C4E",
            fontColor: "#0A0A0A"
        },
        usage: 1,
        introAnimations: [
            1
        ],
        introAnimationsConfig: {
            1: {
                optionalAnimation1: 'autumn_leaves',
                autumnLeavesColor: '#86986f'
            }
        },
        introScreen: {
            templateId: 2,
            config: {
                envelopeColor: "#476C4E",
                fontColor: "#ffffff",
                image1: "/33/intro_screen_1.png",
                image2: "/33/intro_screen_2.png"
            }
        }
    },
    {
        id: 40,
        title: "Model 17",
        preview: "/images/inviteTemplates/40/preview.jpg",
        background: "/images/inviteTemplates/40/background.jpg",
        type: "Nunta",
        otherTypes: [
            "Cununie"
        ],
        colors: {
            baseColor: "#d3b150",
            borderColor: "#d3b150",
            fontColor: "#0A0A0A"
        },
        usage: 13,
        introAnimations: [
            1
        ],
        introAnimationsConfig: {
            1: {
                optionalAnimation1: 'autumn_leaves',
                autumnLeavesColor: '#86986f'
            }
        },
        introScreen: {
            templateId: 2,
            config: {
                envelopeColor: "#d3b150",
                fontColor: "#ffffff",
                image1: "/40/intro_screen_1.png"
            }
        }
    },
    {
        id: 41,
        title: "Model 18",
        preview: "/images/inviteTemplates/41/preview.jpg",
        background: "/images/inviteTemplates/41/background.jpg",
        type: "Nunta",
        otherTypes: [
            "Cununie"
        ],
        colors: {
            baseColor: "#d3b150",
            borderColor: "#d3b150",
            fontColor: "#0A0A0A"
        },
        usage: 8,
        introAnimations: [
            1
        ],
        introAnimationsConfig: {
            1: {
                optionalAnimation1: 'autumn_leaves',
                autumnLeavesColor: '#86986f'
            }
        },
        introScreen: {
            templateId: 2,
            config: {
                envelopeColor: "#87938f",
                fontColor: "#ffffff",
                image1: "/41/intro_screen_1.png",
                image2: "/41/intro_screen_2.png"
            }
        }
    },
    {
        id: 42,
        title: "Model 19",
        preview: "/images/inviteTemplates/42/preview.jpg",
        background: "/images/inviteTemplates/42/background.jpg",
        type: "Nunta",
        otherTypes: [
            "Cununie"
        ],
        colors: {
            baseColor: "#d3b150",
            borderColor: "#d3b150",
            fontColor: "#0A0A0A"
        },
        usage: 0,
        introAnimations: [
            1
        ],
        introAnimationsConfig: {
            1: {
                optionalAnimation1: 'balloons',
                balloonsType: 'heart',
                balloonsColorsType: 'single_color',
                balloonsColor: '#f40002'
            }
        },
        introScreen: {
            templateId: 2,
            config: {
                envelopeColor: "#d3b150",
                fontColor: "#ffffff",
                image1: "/42/intro_screen_1.png",
                image2: "/42/intro_screen_2.png"
            }
        }
    },
    {
        id: 45,
        title: "Model 20",
        preview: "/images/inviteTemplates/45/preview.jpg",
        background: "/images/inviteTemplates/45/background.jpg",
        type: "Nunta",
        otherTypes: [
            "Cununie"
        ],
        colors: {
            baseColor: "#74BBAD",
            borderColor: "#74BBAD",
            fontColor: "#0B4F0F"
        },
        usage: 1,
        introAnimations: [
            1
        ],
        introAnimationsConfig: {
            1: {
                optionalAnimation1: 'autumn_leaves',
                autumnLeavesColor: '#86986f'
            }
        },
        introScreen: {
            templateId: 2,
            config: {
                envelopeColor: "#99c7a5",
                fontColor: "#ffffff",
                image1: "/45/intro_screen_1.png"
            }
        }
    },
    {
        id: 46,
        title: "Model 21",
        preview: "/images/inviteTemplates/46/preview.jpg",
        background: "/images/inviteTemplates/46/background.jpg",
        type: "Nunta",
        otherTypes: [
            "Cununie"
        ],
        colors: {
            baseColor: "#D6C1BE",
            borderColor: "#D6C1BE",
            fontColor: "#493C3A"
        },
        usage: 2,
        introAnimations: [
            1
        ],
        introScreen: {
            templateId: 2,
            config: {
                envelopeColor: "#D6C1BE",
                fontColor: "#ffffff",
                image1: "/47/intro_screen_1.png"
            }
        }
    },
    {
        id: 48,
        title: "Model 22",
        preview: "/images/inviteTemplates/48/preview.jpg",
        background: "/images/inviteTemplates/48/background.jpg",
        type: "Nunta",
        otherTypes: [
            "Cununie"
        ],
        colors: {
            baseColor: "#E7BE73",
            borderColor: "#E7BE73",
            fontColor: "#49423A"
        },
        usage: 2,
        introAnimations: [
            1
        ],
        introAnimationsConfig: {
            1: {
                optionalAnimation1: 'autumn_leaves',
            }
        },
        introScreen: {
            templateId: 2,
            config: {
                envelopeColor: "#E7BE73",
                fontColor: "#ffffff",
                image1: "/48/intro_screen_1.png"
            }
        }
    },
    {
        id: 49,
        title: "Model 23",
        preview: "/images/inviteTemplates/49/preview.jpg",
        background: "/images/inviteTemplates/49/background.jpg",
        type: "Nunta",
        otherTypes: [
            "Cununie"
        ],
        colors: {
            baseColor: "#BD5033",
            borderColor: "#BD5033",
            fontColor: "#49423A"
        },
        usage: 1,
        introAnimations: [
            1
        ],
        introScreen: {
            templateId: 2,
            config: {
                envelopeColor: "#BD5033",
                fontColor: "#ffffff",
                image1: "/49/intro_screen_1.png",
                image2: "/49/intro_screen_2.png"
            }
        }
    },
    {
        id: 50,
        title: "Model 24",
        preview: "/images/inviteTemplates/50/preview.jpg",
        background: "/images/inviteTemplates/50/background.jpg",
        type: "Nunta",
        otherTypes: [
            "Cununie"
        ],
        colors: {
            baseColor: "#EDE0D0",
            borderColor: "#EDE0D0",
            fontColor: "#FFFFFF"
        },
        usage: 0,
        introAnimations: [
            1
        ],
        introAnimationsConfig: {
            1: {
                birdsColor: '#aaaaaa',
            }
        },
        introScreen: {
            templateId: 3,
            config: {
                fontColor: "#464a55",
                envelopeColor: "#EDE0D0",
                image1: "/50/background.jpg"
            }
        }
    },
    {
        id: 51,
        title: "Model 25",
        preview: "/images/inviteTemplates/51/preview.jpg",
        background: "/images/inviteTemplates/51/background.jpg",
        type: "Nunta",
        otherTypes: [
            "Cununie"
        ],
        colors: {
            baseColor: "#804600",
            borderColor: "#5e3500",
            fontColor: "#3B2100"
        },
        usage: 22,
        backgroundAnimation: "/images/inviteTemplates/51/background_animation.jpg",
        introAnimations: [
            1
        ],
        introAnimationsConfig: {
            1: {
                optionalAnimation1: 'autumn_leaves',
                autumnLeavesColor: '#86986f'
            }
        },
        introScreen: {
            templateId: 2,
            config: {
                fontColor: "#ffffff",
                envelopeColor: "#b7986b",
                image1: "/51/intro_screen_1.png",
                image2: "/51/intro_screen_2.png"
            }
        }
    },
    {
        id: 52,
        title: "Model 26",
        preview: "/images/inviteTemplates/52/preview.jpg",
        background: "/images/inviteTemplates/52/background.jpg",
        type: "Nunta",
        otherTypes: [
            "Cununie"
        ],
        colors: {
            baseColor: "#804600",
            borderColor: "#5e3500",
            fontColor: "#524739"
        },
        usage: 0,
        introAnimations: [
            1
        ],
        introAnimationsConfig: {
            1: {
                optionalAnimation1: 'autumn_leaves',
            }
        },
        introScreen: {
            templateId: 3,
            config: {
                fontColor: "#ffffff",
                envelopeColor: "#524739",
                image1: "/52/background.jpg"
            }
        }
    },
    {
        id: 53,
        title: "Model 27",
        preview: "/images/inviteTemplates/53/preview.jpg",
        background: "/images/inviteTemplates/53/background.jpg",
        type: "Nunta",
        otherTypes: [
            "Cununie"
        ],
        colors: {
            baseColor: "#804600",
            borderColor: "#5e3500",
            fontColor: "#524739"
        },
        usage: 8,
        introAnimations: [
            1
        ],
        introAnimationsConfig: {
            1: {
                optionalAnimation1: 'autumn_leaves',
                autumnLeavesColor: '#86986f'
            }
        },
        introScreen: {
            templateId: 2,
            config: {
                envelopeColor: "#804600",
                fontColor: "#ffffff",
                image1: "/53/intro_screen_1.png",
                image2: "/53/intro_screen_2.png"
            }
        }
    },
    {
        id: 54,
        title: "Model 28",
        preview: "/images/inviteTemplates/54/preview.jpg",
        background: "/images/inviteTemplates/54/background.jpg",
        type: "Nunta",
        otherTypes: [
            "Cununie"
        ],
        colors: {
            baseColor: "#B9389C",
            borderColor: "#B9389C",
            fontColor: "#460936"
        },
        usage: 2,
        introAnimations: [
            1
        ],
        introAnimationsConfig: {
            1: {
                optionalAnimation1: 'autumn_leaves',
                autumnLeavesColor: '#86986f'
            }
        },
        introScreen: {
            templateId: 2,
            config: {
                envelopeColor: "#693382",
                fontColor: "#ffffff",
                image1: "/54/intro_screen_1.png",
                image2: "/54/intro_screen_2.png"
            }
        }
    },
    {
        id: 55,
        title: "Model 29",
        preview: "/images/inviteTemplates/55/preview.jpg",
        background: "/images/inviteTemplates/55/background.jpg",
        type: "Nunta",
        otherTypes: [
            "Cununie"
        ],
        colors: {
            baseColor: "#B9389C",
            borderColor: "#B9389C",
            fontColor: "#460936"
        },
        usage: 5,
        backgroundAnimation: "/images/inviteTemplates/55/background_animation.jpg",
        introAnimations: [
            1
        ],
        introAnimationsConfig: {
            1: {
                optionalAnimation1: 'autumn_leaves',
                autumnLeavesColor: '#86986f'
            }
        },
        introScreen: {
            templateId: 2,
            config: {
                fontColor: "#ffffff",
                envelopeColor: "#693382",
                image1: "/55/intro_screen_1.png",
                image2: "/55/intro_screen_2.png"
            }
        }
    },
    {
        id: 56,
        title: "Model 30",
        preview: "/images/inviteTemplates/56/preview.jpg",
        background: "/images/inviteTemplates/56/background.jpg",
        type: "Nunta",
        otherTypes: [
            "Cununie"
        ],
        colors: {
            baseColor: "#D2A441",
            borderColor: "#D2A441",
            fontColor: "#86671b"
        },
        usage: 65,
        introAnimations: [
            1
        ],
        introAnimationsConfig: {
            1: {
                optionalAnimation1: 'autumn_leaves',
                autumnLeavesColor: '#86986f'
            }
        },
        introScreen: {
            templateId: 2,
            config: {
                envelopeColor: "#D8B879",
                fontColor: "#ffffff",
                image1: "/56/intro_screen_1.png",
                image2: "/56/intro_screen_2.png"
            }
        }
    },
    {
        id: 57,
        title: "Model 31",
        preview: "/images/inviteTemplates/57/preview.jpg",
        background: "/images/inviteTemplates/57/background.jpg",
        type: "Nunta",
        otherTypes: [
            "Cununie"
        ],
        colors: {
            baseColor: "#D2A441",
            borderColor: "#D2A441",
            fontColor: "#86671b"
        },
        usage: 8,
        introAnimations: [
            1
        ],
        introAnimationsConfig: {
            1: {
                optionalAnimation1: 'autumn_leaves',
            }
        },
        introScreen: {
            templateId: 2,
            config: {
                envelopeColor: "#86671b",
                fontColor: "#ffffff",
                image1: "/57/intro_screen_1.png"
            }
        }
    },
    {
        id: 58,
        title: "Model 32",
        preview: "/images/inviteTemplates/58/preview.jpg",
        background: "/images/inviteTemplates/58/background.jpg",
        type: "Nunta",
        otherTypes: [
            "Cununie"
        ],
        colors: {
            baseColor: "#5b5b5b",
            borderColor: "#5b5b5b",
            fontColor: "#6c94a9"
        },
        usage: 0,
        backgroundAnimation: "/images/inviteTemplates/58/background_animation.jpg",
        introAnimations: [
            1
        ],
        introAnimationsConfig: {
            1: {
                optionalAnimation1: 'balloons',
                balloonsType: 'normal',
                balloonsColorsType: 'multicolor',
                balloonsColor1: '#cad9e0',
                balloonsColor2: '#ffffff',
                balloonsColor3: '#6c94a8',
                balloonsColor4: '#909598',
                balloonsColor5: '#2f2e2e',
            }
        },
        introScreen: {
            templateId: 2,
            config: {
                envelopeColor: "#5b5b5b",
                fontColor: "#ffffff",
                image1: "/58/intro_screen_1.png"
            }
        }
    },
    {
        id: 59,
        title: "Model 33",
        preview: "/images/inviteTemplates/59/preview.jpg",
        background: "/images/inviteTemplates/59/background.jpg",
        type: "Nunta",
        otherTypes: [
            "Cununie"
        ],
        colors: {
            baseColor: "#D2A441",
            borderColor: "#D2A441",
            fontColor: "#6c94a9"
        },
        usage: 12,
        introAnimations: [
            1
        ],
        introAnimationsConfig: {
            1: {
                optionalAnimation1: 'autumn_leaves',
                autumnLeavesColor: '#86986f'
            }
        },
        introScreen: {
            templateId: 2,
            config: {
                envelopeColor: "#D8B879",
                fontColor: "#ffffff",
                image1: "/59/intro_screen_1.png",
                image2: "/59/intro_screen_2.png"
            }
        }
    },
    {
        id: 60,
        title: "Model 34",
        preview: "/images/inviteTemplates/60/preview.jpg",
        background: "/images/inviteTemplates/60/background.jpg",
        type: "Nunta",
        otherTypes: [
            "Cununie"
        ],
        colors: {
            baseColor: "#D2A441",
            borderColor: "#D2A441",
            fontColor: "#6c94a9"
        },
        usage: 2,
        introAnimations: [
            1
        ],
        introScreen: {
            templateId: 2,
            config: {
                envelopeColor: "#c7ab84",
                fontColor: "#ffffff",
                image1: "/60/intro_screen_1.png"
            }
        }
    },
    {
        id: 61,
        title: "Model 35",
        preview: "/images/inviteTemplates/61/preview.jpg",
        background: "/images/inviteTemplates/61/background.jpg",
        type: "Nunta",
        otherTypes: [
            "Cununie"
        ],
        colors: {
            baseColor: "#CC95A3",
            borderColor: "#CC95A3",
            fontColor: "#3a3a3a"
        },
        usage: 0,
        introAnimations: [
            1
        ],
        introAnimationsConfig: {
            1: {
                optionalAnimation1: 'autumn_leaves',
                autumnLeavesColor: '#86986f'
            }
        },
        introScreen: {
            templateId: 2,
            config: {
                envelopeColor: "#CC95A3",
                fontColor: "#ffffff",
                image1: "/61/intro_screen_1.png",
                image2: "/61/intro_screen_2.png"
            }
        }
    },
    {
        id: 62,
        title: "Model 36",
        preview: "/images/inviteTemplates/62/preview.jpg",
        background: "/images/inviteTemplates/62/background.jpg",
        type: "Nunta",
        otherTypes: [
            "Cununie"
        ],
        colors: {
            baseColor: "#C796C5",
            borderColor: "#C796C5",
            fontColor: "#4a6373"
        },
        usage: 4,
        introAnimations: [
            1
        ],
        introAnimationsConfig: {
            1: {
                optionalAnimation1: 'balloons',
                balloonsType: 'normal',
                balloonsColorsType: 'multicolor',
                balloonsColor1: '#bce1f3',
                balloonsColor2: '#e1cdef',
                balloonsColor3: '#6c94a8',
                balloonsColor4: '#909598',
                balloonsColor5: '#2f2e2e',
            }
        },
        introScreen: {
            templateId: 2,
            config: {
                envelopeColor: "#d7a6d5",
                fontColor: "#ffffff",
                image1: "/62/intro_screen_1.png"
            }
        }
    },
    {
        id: 63,
        title: "Model 37",
        preview: "/images/inviteTemplates/63/preview.jpg",
        background: "/images/inviteTemplates/63/background.jpg",
        type: "Nunta",
        otherTypes: [
            "Cununie"
        ],
        colors: {
            baseColor: "#557651",
            borderColor: "#557651",
            fontColor: "#475E44"
        },
        usage: 5,
        introAnimations: [
            1
        ],
        introAnimationsConfig: {
            1: {
                optionalAnimation1: 'autumn_leaves',
                autumnLeavesColor: '#86986f'
            }
        },
        introScreen: {
            templateId: 2,
            config: {
                envelopeColor: "#557651",
                fontColor: "#ffffff",
                image1: "/63/intro_screen_1.png"
            }
        }
    },
    {
        id: 64,
        title: "Model 38",
        preview: "/images/inviteTemplates/64/preview.jpg",
        background: "/images/inviteTemplates/64/background.jpg",
        type: "Nunta",
        otherTypes: [
            "Cununie"
        ],
        colors: {
            baseColor: "#E76B87",
            borderColor: "#E76B87",
            fontColor: "#444346"
        },
        usage: 5,
        introAnimations: [
            1
        ],
        introAnimationsConfig: {
            1: {
                optionalAnimation1: 'balloons',
                balloonsType: 'normal',
                balloonsColorsType: 'single_color',
                balloonsColor: '#E76B87',
            }
        },
        introScreen: {
            templateId: 2,
            config: {
                envelopeColor: "#E76B87",
                fontColor: "#ffffff",
                image1: "/64/intro_screen_1.png",
                image2: "/64/intro_screen_2.png"
            }
        }
    },
    {
        id: 80,
        title: "Model 39",
        preview: "/images/inviteTemplates/80/preview.jpg",
        background: "/images/inviteTemplates/80/background.jpg",
        type: "Nunta",
        otherTypes: [
            "Cununie"
        ],
        colors: {
            baseColor: "#E76B87",
            borderColor: "#E76B87",
            fontColor: "#444346"
        },
        usage: 9,
        introAnimations: [
            1
        ],
        introAnimationsConfig: {
            1: {
                optionalAnimation1: 'autumn_leaves',
                autumnLeavesColor: '#86986f'
            }
        },
        introScreen: {
            templateId: 2,
            config: {
                envelopeColor: "#E76B87",
                fontColor: "#ffffff",
                image1: "/80/intro_screen_1.png",
                image2: "/80/intro_screen_2.png"
            }
        }
    },
    {
        id: 81,
        title: "Model 40",
        preview: "/images/inviteTemplates/81/preview.jpg",
        background: "/images/inviteTemplates/81/background.jpg",
        type: "Nunta",
        otherTypes: [
            "Cununie"
        ],
        colors: {
            baseColor: "#7D464C",
            borderColor: "#7D464C",
            fontColor: "#444346"
        },
        usage: 2,
        introAnimations: [
            1
        ],
        introAnimationsConfig: {
            1: {
                optionalAnimation1: 'autumn_leaves',
            }
        },
        introScreen: {
            templateId: 2,
            config: {
                envelopeColor: "#7D464C",
                fontColor: "#ffffff",
                image1: "/81/intro_screen_1.png"
            }
        }
    },
    {
        id: 82,
        title: "Model 41",
        preview: "/images/inviteTemplates/82/preview.jpg",
        background: "/images/inviteTemplates/82/background.jpg",
        type: "Nunta",
        otherTypes: [
            "Cununie"
        ],
        colors: {
            baseColor: "#60AEA7",
            borderColor: "#60AEA7",
            fontColor: "#444346"
        },
        usage: 5,
        introAnimations: [
            1
        ],
        introAnimationsConfig: {
            1: {
                optionalAnimation1: 'autumn_leaves',
                autumnLeavesColor: '#86986f'
            }
        },
        introScreen: {
            templateId: 2,
            config: {
                envelopeColor: "#60AEA7",
                fontColor: "#ffffff",
                image1: "/82/intro_screen_1.png"
            }
        }
    },
    {
        id: 83,
        title: "Model 42",
        preview: "/images/inviteTemplates/83/preview.jpg",
        background: "/images/inviteTemplates/83/background.jpg",
        type: "Nunta",
        otherTypes: [
            "Cununie"
        ],
        colors: {
            baseColor: "#684211",
            borderColor: "#684211",
            fontColor: "#444346"
        },
        usage: 0,
        introAnimations: [
            1
        ],
        introAnimationsConfig: {
            1: {
                optionalAnimation1: 'autumn_leaves',
            }
        },
        introScreen: {
            templateId: 3,
            config: {
                envelopeColor: "#684211",
                fontColor: "#ffffff",
                image1: "/83/background.jpg"
            }
        }
    },
    {
        id: 84,
        title: "Model 43",
        preview: "/images/inviteTemplates/84/preview.jpg",
        background: "/images/inviteTemplates/84/background.jpg",
        type: "Nunta",
        otherTypes: [
            "Cununie"
        ],
        colors: {
            baseColor: "#fffcf8",
            borderColor: "#fffcf8",
            fontColor: "#fffcf8"
        },
        usage: 3,
        introAnimations: [
            1
        ],
        introAnimationsConfig: {
            1: {
                birdsColor: '#aaaaaa',
            }
        },

        introScreen: {
            templateId: 3,
            config: {
                fontColor: "#2c3f3b",
                envelopeColor: "#fffcf8",
                image1: "/84/background.jpg"
            }
        }
    },
    {
        id: 85,
        title: "Model 44",
        preview: "/images/inviteTemplates/85/preview.jpg",
        background: "/images/inviteTemplates/85/background.jpg",
        type: "Nunta",
        otherTypes: [
            "Cununie"
        ],
        colors: {
            baseColor: "#62805B",
            borderColor: "#62805B",
            fontColor: "#444346"
        },
        usage: 14,
        introAnimations: [
            1
        ],
        introAnimationsConfig: {
            1: {
                optionalAnimation1: 'autumn_leaves',
                autumnLeavesColor: '#86986f'
            }
        },
        introScreen: {
            templateId: 2,
            config: {
                fontColor: "#ffffff",
                envelopeColor: "#62805B",
                image1: "/85/intro_screen_1.png"
            }
        }
    },
    {
        id: 86,
        title: "Model 45",
        preview: "/images/inviteTemplates/86/preview.jpg",
        background: "/images/inviteTemplates/86/background.png",
        type: "Nunta",
        otherTypes: [
            "Cununie"
        ],
        colors: {
            baseColor: "#E4BF87",
            borderColor: "#E4BF87",
            fontColor: "#444346"
        },
        coverImage: true,
        usage: 4,
        backgroundAnimation: "/images/inviteTemplates/86/background_animation.jpg",
        introAnimations: [
            1
        ],
        introScreen: {
            templateId: 2,
            config: {
                fontColor: "#E4BF87",
                envelopeColor: "#444346",
                image1: "/86/intro_screen_1.png",
                image2: "/86/intro_screen_2.png"
            }
        }
    },
    {
        id: 87,
        title: "Model 46",
        preview: "/images/inviteTemplates/87/preview.jpg",
        background: "/images/inviteTemplates/87/background.png",
        type: "Nunta",
        otherTypes: [
            "Cununie"
        ],
        colors: {
            baseColor: "#E4BF87",
            borderColor: "#E4BF87",
            fontColor: "#444346"
        },
        coverImage: true,
        coverImageDefault: "/images/inviteTemplates/87/cover.jpg",
        usage: 19,
        backgroundAnimation: "/images/inviteTemplates/87/background_animation.jpg",
        introAnimations: [
            1
        ],
        introScreen: {
            templateId: 2,
            config: {
                envelopeColor: "#D8B879",
                fontColor: "#ffffff",
                image1: "/89/intro_screen_1.png"
            }
        }
    },
    {
        id: 88,
        title: "Model 47",
        preview: "/images/inviteTemplates/88/preview.jpg",
        background: "/images/inviteTemplates/88/background.png",
        type: "Nunta",
        otherTypes: [
            "Cununie"
        ],
        colors: {
            baseColor: "#BCA864",
            borderColor: "#BCA864",
            fontColor: "#444346"
        },
        coverImage: true,
        coverImageDefault: "/images/inviteTemplates/88/cover.jpg",
        usage: 3,
        backgroundAnimation: "/images/inviteTemplates/88/background_animation.jpg",
        introAnimations: [
            1
        ],
        introAnimationsConfig: {
            1: {
                optionalAnimation1: 'autumn_leaves',
                autumnLeavesColor: '#86986f'
            }
        },
        introScreen: {
            templateId: 2,
            config: {
                fontColor: "#ffffff",
                envelopeColor: "#6d7737",
                image1: "/88/intro_screen_1.png"
            }
        }
    },
    {
        id: 89,
        title: "Model 48",
        preview: "/images/inviteTemplates/89/preview.jpg",
        background: "/images/inviteTemplates/89/background.png",
        type: "Nunta",
        otherTypes: [
            "Cununie"
        ],
        colors: {
            baseColor: "#D8B879",
            borderColor: "#D8B879",
            fontColor: "#444346"
        },
        coverImage: true,
        usage: 12,
        backgroundAnimation: "/images/inviteTemplates/89/background_animation.jpg",
        introAnimations: [
            1
        ],
        introScreen: {
            templateId: 2,
            config: {
                envelopeColor: "#D8B879",
                fontColor: "#ffffff",
                image1: "/89/intro_screen_1.png"
            }
        }
    },
    {
        id: 90,
        title: "Model 49",
        preview: "/images/inviteTemplates/90/preview.jpg",
        background: "/images/inviteTemplates/90/background.png",
        type: "Nunta",
        otherTypes: [
            "Cununie"
        ],
        colors: {
            baseColor: "#D8B879",
            borderColor: "#D8B879",
            fontColor: "#444346"
        },
        coverImage: true,
        usage: 3,
        backgroundAnimation: "/images/inviteTemplates/90/background_animation.jpg",
        introAnimations: [
            1
        ],
        introAnimationsConfig: {
            1: {
                optionalAnimation1: 'autumn_leaves',
                autumnLeavesColor: '#86986f'
            }
        },
        introScreen: {
            templateId: 2,
            config: {
                envelopeColor: "#D8B879",
                fontColor: "#ffffff",
                image1: "/56/intro_screen_1.png",
                image2: "/56/intro_screen_2.png"
            }
        }
    },
    {
        id: 91,
        title: "Model 50",
        preview: "/images/inviteTemplates/91/preview.jpg",
        background: "/images/inviteTemplates/91/background.png",
        type: "Nunta",
        otherTypes: [
            "Cununie"
        ],
        colors: {
            baseColor: "#D8B879",
            borderColor: "#D8B879",
            fontColor: "#444346"
        },
        coverImage: true,
        coverImageDefault: "/images/inviteTemplates/91/cover.jpg",
        usage: 58,
        backgroundAnimation: "/images/inviteTemplates/91/background_animation.jpg",
        introAnimations: [
            1
        ],
        introAnimationsConfig: {
            1: {
                optionalAnimation1: 'autumn_leaves',
                autumnLeavesColor: '#86986f'
            }
        },
        introScreen: {
            templateId: 2,
            config: {
                envelopeColor: "#D8B879",
                fontColor: "#ffffff",
                image1: "/59/intro_screen_1.png",
                image2: "/59/intro_screen_2.png"
            }
        }
    },
    {
        id: 92,
        title: "Model 51",
        preview: "/images/inviteTemplates/92/preview.jpg",
        background: "/images/inviteTemplates/92/background.jpg",
        type: "Nunta",
        otherTypes: [
            "Cununie"
        ],
        colors: {
            baseColor: "#CF9496",
            borderColor: "#CF9496",
            fontColor: "#f4f3ff"
        },
        usage: 0,
        introAnimations: [
            1
        ],
        introAnimationsConfig: {
            1: {
                birdsColor: '#aaaaaa',
            }
        },
        introScreen: {
            templateId: 3,
            config: {
                fontColor: "#282f39",
                envelopeColor: "#dcc6c8",
                image1: "/92/background.jpg"
            }
        }
    },
    {
        id: 93,
        title: "Model 52",
        preview: "/images/inviteTemplates/93/preview.jpg",
        background: "/images/inviteTemplates/93/background.jpg",
        type: "Nunta",
        otherTypes: [
            "Cununie"
        ],
        colors: {
            baseColor: "#CF9496",
            borderColor: "#CF9496",
            fontColor: "#444346"
        },
        usage: 1,
        introAnimations: [
            1
        ],
        introScreen: {
            templateId: 2,
            config: {
                fontColor: "#ffffff",
                envelopeColor: "#CF9496",
                image1: "/93/intro_screen_1.png",
                image2: "/93/intro_screen_2.png"
            }
        }
    },
    {
        id: 94,
        title: "Model 53",
        preview: "/images/inviteTemplates/94/preview.jpg",
        background: "/images/inviteTemplates/94/background.jpg",
        type: "Nunta",
        otherTypes: [
            "Cununie"
        ],
        colors: {
            baseColor: "#444346",
            borderColor: "#444346",
            fontColor: "#444346"
        },
        usage: 4,
        introAnimations: [
            1
        ],
        introScreen: {
            templateId: 2,
            config: {
                fontColor: "#ffffff",
                envelopeColor: "#444346",
                image1: "/94/intro_screen_1.png"
            }
        }
    },
    {
        id: 95,
        title: "Model 54",
        preview: "/images/inviteTemplates/95/preview.jpg",
        background: "/images/inviteTemplates/95/background.jpg",
        type: "Nunta",
        otherTypes: [
            "Cununie"
        ],
        colors: {
            baseColor: "#e8b059",
            borderColor: "#e8b059",
            fontColor: "#444346"
        },
        usage: 7,
        introAnimations: [
            1
        ],
        introScreen: {
            templateId: 2,
            config: {
                envelopeColor: "#e8b059",
                fontColor: "#ffffff",
                image1: "/95/intro_screen_1.png",
                image2: "/95/intro_screen_2.png"
            }
        }
    },
    {
        id: 96,
        title: "Model 55",
        preview: "/images/inviteTemplates/96/preview.jpg",
        background: "/images/inviteTemplates/96/background.png",
        type: "Nunta",
        otherTypes: [
            "Cununie"
        ],
        colors: {
            baseColor: "#d99482",
            borderColor: "#d99482",
            fontColor: "#484749"
        },
        usage: 6,
        introAnimations: [
            1
        ],
        introScreen: {
            templateId: 3,
            config: {
                fontColor: "#ffffff",
                envelopeColor: "#484749",
                image1: "/96/background.png"
            }
        }
    },
    {
        id: 158,
        title: "Model 56",
        preview: "/images/inviteTemplates/158/preview.jpg",
        background: "/images/inviteTemplates/158/background.jpg",
        type: "Nunta",
        otherTypes: [
            "Cununie"
        ],
        colors: {
            baseColor: "#C49E4E",
            borderColor: "#C49E4E",
            fontColor: "#484749"
        },
        usage: 1,
        introAnimations: [
            1
        ],
        introScreen: {
            templateId: 2,
            config: {
                fontColor: "#ffffff",
                envelopeColor: "#C49E4E",
                image1: "/158/intro_screen_1.png"
            }
        }
    },
    {
        id: 159,
        title: "Model 57",
        preview: "/images/inviteTemplates/159/preview.jpg",
        background: "/images/inviteTemplates/159/background.jpg",
        type: "Nunta",
        otherTypes: [
            "Cununie"
        ],
        colors: {
            baseColor: "#813236",
            borderColor: "#813236",
            fontColor: "#484749"
        },
        usage: 2,
        introAnimations: [
            1
        ],
        introScreen: {
            templateId: 2,
            config: {
                fontColor: "#ffffff",
                envelopeColor: "#a8adce",
                image1: "/159/intro_screen_1.png"
            }
        }
    },
    {
        id: 160,
        title: "Model 58",
        preview: "/images/inviteTemplates/160/preview.jpg",
        background: "/images/inviteTemplates/160/background.jpg",
        type: "Nunta",
        otherTypes: [
            "Cununie"
        ],
        colors: {
            baseColor: "#C49E4E",
            borderColor: "#C49E4E",
            fontColor: "#484749"
        },
        usage: 11,
        introAnimations: [
            1
        ],
        introAnimationsConfig: {
            1: {
                optionalAnimation1: 'autumn_leaves',
            }
        },
        introScreen: {
            templateId: 2,
            config: {
                fontColor: "#ffffff",
                envelopeColor: "#525252",
                image1: "/160/intro_screen_1.png",
                image2: "/160/intro_screen_2.png"
            }
        }
    },
    {
        id: 161,
        title: "Model 59",
        preview: "/images/inviteTemplates/161/preview.jpg",
        background: "/images/inviteTemplates/161/background.jpg",
        type: "Nunta",
        otherTypes: [
            "Cununie"
        ],
        colors: {
            baseColor: "#B8673B",
            borderColor: "#B8673B",
            fontColor: "#484749"
        },
        usage: 0,
        introAnimations: [
            1
        ],
        introScreen: {
            templateId: 2,
            config: {
                fontColor: "#ffffff",
                envelopeColor: "#B8673B",
                image1: "/161/intro_screen_1.png"
            }
        }
    },
    {
        id: 162,
        title: "Model 60",
        preview: "/images/inviteTemplates/162/preview.jpg",
        background: "/images/inviteTemplates/162/background.jpg",
        type: "Nunta",
        otherTypes: [
            "Cununie"
        ],
        colors: {
            baseColor: "#A58468",
            borderColor: "#A58468",
            fontColor: "#f1f1f1"
        },
        usage: 4,
        introAnimations: [
            1
        ],
        introAnimationsConfig: {
            1: {
                birdsColor: '#aaaaaa',
            }
        },
        introScreen: {
            templateId: 3,
            config: {
                fontColor: "#0b3d39",
                envelopeColor: "#ceb18e",
                image1: "/162/intro_screen_1.png"
            }
        }
    },
    {
        id: 163,
        title: "Model 61",
        preview: "/images/inviteTemplates/163/preview.jpg",
        background: "/images/inviteTemplates/163/background.jpg",
        type: "Nunta",
        otherTypes: [
            "Cununie"
        ],
        colors: {
            baseColor: "#cc9e3f",
            borderColor: "#cc9e3f",
            fontColor: "#484749"
        },
        usage: 3,
        introAnimations: [
            1
        ],
        introAnimationsConfig: {
            1: {
                optionalAnimation1: 'autumn_leaves',
            }
        },
        introScreen: {
            templateId: 2,
            config: {
                fontColor: "#ffffff",
                envelopeColor: "#5d6666",
                image1: "/163/intro_screen_1.png"
            }
        }
    },
    {
        id: 164,
        title: "Model 62",
        preview: "/images/inviteTemplates/164/preview.jpg",
        background: "/images/inviteTemplates/164/background.jpg",
        type: "Nunta",
        otherTypes: [
            "Cununie"
        ],
        colors: {
            baseColor: "#cc9e3f",
            borderColor: "#cc9e3f",
            fontColor: "#484749"
        },
        usage: 0,
        introAnimations: [
            1
        ],
        introAnimationsConfig: {
            1: {
                optionalAnimation1: 'autumn_leaves',
            }
        },
        introScreen: {
            templateId: 2,
            config: {
                fontColor: "#ffffff",
                envelopeColor: "#eccba5",
                image1: "/164/intro_screen_1.png"
            }
        }
    },
    {
        id: 165,
        title: "Model 63",
        preview: "/images/inviteTemplates/165/preview.jpg",
        background: "/images/inviteTemplates/165/background.jpg",
        type: "Nunta",
        otherTypes: [
            "Cununie"
        ],
        colors: {
            baseColor: "#B77626",
            borderColor: "#B77626",
            fontColor: "#484749"
        },
        usage: 0,
        introAnimations: [
            1
        ],
        introScreen: {
            templateId: 2,
            config: {
                fontColor: "#ffffff",
                envelopeColor: "#f4c0ae",
                image1: "/165/intro_screen_1.png"
            }
        }
    },
    {
        id: 166,
        title: "Model 64",
        preview: "/images/inviteTemplates/166/preview.jpg",
        background: "/images/inviteTemplates/166/background.jpg",
        type: "Nunta",
        otherTypes: [
            "Cununie"
        ],
        colors: {
            baseColor: "#843537",
            borderColor: "#843537",
            fontColor: "#484749"
        },
        usage: 3,
        introAnimations: [
            1
        ],
        introAnimationsConfig: {
            1: {
                optionalAnimation1: 'autumn_leaves',
                autumnLeavesColor: '#80585a'
            }
        },
        introScreen: {
            templateId: 2,
            config: {
                fontColor: "#ffffff",
                envelopeColor: "#843537",
                image1: "/166/intro_screen_1.png"
            }
        }
    },
    {
        id: 167,
        title: "Model 65",
        preview: "/images/inviteTemplates/167/preview.jpg",
        background: "/images/inviteTemplates/167/background.jpg",
        type: "Nunta",
        otherTypes: [
            "Cununie"
        ],
        colors: {
            baseColor: "#66A2C0",
            borderColor: "#66A2C0",
            fontColor: "#1f1f1f"
        },
        usage: 4,
        introAnimations: [
            1
        ],
        introScreen: {
            templateId: 2,
            config: {
                fontColor: "#ffffff",
                envelopeColor: "#9e3721",
                image1: "/167/intro_screen_1.png"
            }
        }
    },
    {
        id: 180,
        title: "Model 66",
        preview: "/images/inviteTemplates/180/preview.jpg",
        background: "/images/inviteTemplates/178/background.jpg",
        type: "Nunta",
        otherTypes: [
            "Cununie"
        ],
        colors: {
            baseColor: "#E97B48",
            borderColor: "#E97B48",
            fontColor: "#171715"
        },
        coverImage: false,
        usage: 0,
        introAnimations: [
            1
        ],
        introScreen: {
            templateId: 2,
            config: {
                envelopeColor: "#E97B48",
                fontColor: "#ffffff",
                image1: "/178/intro_screen_1.png",
                image2: "/178/intro_screen_2.png"
            }
        }
    },
    {
        id: 194,
        title: "Model 67",
        preview: "/images/inviteTemplates/194/preview.jpg",
        background: "/images/inviteTemplates/194/background.jpg",
        type: "Nunta",
        otherTypes: [
            "Cununie"
        ],
        colors: {
            baseColor: "#0D6C81",
            borderColor: "#0D6C81",
            fontColor: "#171715"
        },
        coverImage: false,
        usage: 0,
        introAnimations: [
            1
        ],
        introScreen: {
            templateId: 2,
            config: {
                envelopeColor: "#0D6C81",
                fontColor: "#ffffff",
                image1: "/194/background.jpg",
            }
        }
    },
    {
        id: 197,
        title: "Model 68",
        preview: "/images/inviteTemplates/197/preview.jpg",
        background: "/images/inviteTemplates/197/background.jpg",
        type: "Nunta",
        otherTypes: [
            "Cununie"
        ],
        colors: {
            baseColor: "#4D8FFF",
            borderColor: "#4D8FFF",
            fontColor: "#171715"
        },
        coverImage: false,
        usage: 0,
        introAnimations: [
            1
        ],
        introScreen: {
            templateId: 2,
            config: {
                envelopeColor: "#4D8FFF",
                fontColor: "#ffffff",
                image1: "/197/intro_screen_1.jpg",
            }
        }
    },
    {
        id: 5,
        title: "Model 1",
        preview: "/images/inviteTemplates/5/preview.jpg",
        background: "/images/inviteTemplates/5/background.jpg",
        type: "Botez",
        otherTypes: [
            "Mot",
            "Turta"
        ],
        colors: {
            baseColor: "#CC8F78",
            borderColor: "#3a3a3a",
            fontColor: "#3a3a3a"
        },
        usage: 7,
        introAnimations: [
            1
        ],
        introAnimationsConfig: {
            1: {
                optionalAnimation1: 'autumn_leaves',
            }
        },
        staticBackground: true,
        introScreen: {
            templateId: 2,
            config: {
                fontColor: "#ffffff",
                envelopeColor: "#f4c0ae",
                image1: "/1/intro_screen_1.png"
            }
        }
    },
    {
        id: 10,
        title: "Model 2",
        preview: "/images/inviteTemplates/10/preview.jpg",
        background: "/images/inviteTemplates/10/background.jpg",
        type: "Botez",
        otherTypes: [
            "Mot",
            "Turta"
        ],
        colors: {
            baseColor: "#99BFB2",
            borderColor: "#3a3a3a",
            fontColor: "#3a3a3a"
        },
        usage: 80,
        introAnimations: [
            1
        ],
        introAnimationsConfig: {
            1: {
                optionalAnimation1: 'balloons',
                balloonsType: 'normal',
                balloonsColorsType: 'multicolor',
                balloonsColor1: '#e3e2d0',
                balloonsColor2: '#f2dcbd',
                balloonsColor3: '#e1a260',
                balloonsColor4: '#c7e7e2',
                balloonsColor5: '#d18e6b',
            }
        },
        introScreen: {
            templateId: 2,
            config: {
                envelopeColor: "#99BFB2",
                fontColor: "#ffffff",
                image1: "/10/intro_screen_1.png",
                image2: "/10/intro_screen_2.png"
            }
        }
    },
    {
        id: 11,
        title: "Model 3",
        preview: "/images/inviteTemplates/11/preview.jpg",
        background: "/images/inviteTemplates/11/background.jpg",
        type: "Botez",
        otherTypes: [
            "Mot",
            "Turta"
        ],
        colors: {
            baseColor: "#50BCED",
            borderColor: "#50BCED",
            fontColor: "#d27e26"
        },
        usage: 23,
        introAnimations: [
            1
        ],
        introAnimationsConfig: {
            1: {
                optionalAnimation1: 'balloons',
                balloonsType: 'normal',
                balloonsColorsType: 'single_color',
                balloonsColor: '#9ad5f2',
            }
        },
        introScreen: {
            templateId: 2,
            config: {
                fontColor: "#ffffff",
                envelopeColor: "#d27e26",
                image1: "/11/intro_screen_1.png",
                image2: "/11/intro_screen_2.png"
            }
        }
    },
    {
        id: 16,
        title: "Model 4",
        preview: "/images/inviteTemplates/16/preview.jpg",
        background: "/images/inviteTemplates/16/background.jpg",
        type: "Botez",
        otherTypes: [
            "Mot",
            "Turta"
        ],
        colors: {
            baseColor: "#50BCED",
            borderColor: "#50BCED",
            fontColor: "#d27e26"
        },
        usage: 5,
        introAnimations: [
            1
        ],
        introAnimationsConfig: {
            1: {
                optionalAnimation1: 'balloons',
                balloonsType: 'normal',
                balloonsColorsType: 'multicolor',
                balloonsColor1: '#e3e2d0',
                balloonsColor2: '#f2dcbd',
                balloonsColor3: '#e1a260',
                balloonsColor4: '#c7e7e2',
                balloonsColor5: '#d18e6b',
            }
        },
        staticBackground: true,
        introScreen: {
            templateId: 2,
            config: {
                fontColor: "#ffffff",
                envelopeColor: "#a9a668",
                image1: "/16/intro_screen_1.png",
                image2: "/16/intro_screen_2.png"
            }
        }
    },
    {
        id: 20,
        title: "Model 5",
        preview: "/images/inviteTemplates/20/preview.jpg",
        background: "/images/inviteTemplates/20/background.jpg",
        type: "Botez",
        otherTypes: [
            "Mot",
            "Turta"
        ],
        colors: {
            baseColor: "#F1A5CB",
            borderColor: "#F1A5CB",
            fontColor: "#887457"
        },
        usage: 36,
        introAnimations: [
            1
        ],
        introAnimationsConfig: {
            1: {
                optionalAnimation1: 'balloons',
                balloonsType: 'normal',
                balloonsColorsType: 'single_color',
                balloonsColor: '#fcdeee',
            }
        },
        introScreen: {
            templateId: 2,
            config: {
                envelopeColor: "#b8aa97",
                fontColor: "#ffffff",
                image1: "/20/intro_screen_1.png",
                image2: "/20/intro_screen_2.png"
            }
        }
    },
    {
        id: 21,
        title: "Model 6",
        preview: "/images/inviteTemplates/21/preview.jpg",
        background: "/images/inviteTemplates/21/background.jpg",
        type: "Botez",
        otherTypes: [
            "Mot",
            "Turta"
        ],
        colors: {
            baseColor: "#FE4157",
            borderColor: "#FE4157",
            fontColor: "#5C5452"
        },
        usage: 20,
        introAnimations: [
            1
        ],
        introAnimationsConfig: {
            1: {
                optionalAnimation1: 'balloons',
                balloonsType: 'normal',
                balloonsColorsType: 'single_color',
                balloonsColor: '#fa7d8b',
            }
        },
        introScreen: {
            templateId: 2,
            config: {
                envelopeColor: "#FE4157",
                fontColor: "#ffffff",
                image1: "/21/intro_screen_1.png",
                image2: "/21/intro_screen_2.png"
            }
        }
    },
    {
        id: 22,
        title: "Model 7",
        preview: "/images/inviteTemplates/22/preview.jpg",
        background: "/images/inviteTemplates/22/background.jpg",
        type: "Botez",
        otherTypes: [
            "Mot",
            "Turta"
        ],
        colors: {
            baseColor: "#ef6767",
            borderColor: "#ef6767",
            fontColor: "#564630"
        },
        usage: 2,
        introAnimations: [
            1
        ],
        introAnimationsConfig: {
            1: {
                optionalAnimation1: 'balloons',
                balloonsType: 'normal',
                balloonsColorsType: 'single_color',
                balloonsColor: '#ef6767',
            }
        },
        introScreen: {
            templateId: 2,
            config: {
                envelopeColor: "#ef6767",
                fontColor: "#ffffff",
                image1: "/22/intro_screen_1.png",
                image2: "/22/intro_screen_2.png"
            }
        }
    },
    {
        id: 26,
        title: "Model 8",
        preview: "/images/inviteTemplates/26/preview.jpg",
        background: "/images/inviteTemplates/26/background.jpg",
        type: "Botez",
        otherTypes: [
            "Mot",
            "Turta"
        ],
        colors: {
            baseColor: "#FE4157",
            borderColor: "#FE4157",
            fontColor: "#3A3635"
        },
        usage: 42,
        introAnimations: [
            1
        ],
        introScreen: {
            templateId: 3,
            config: {
                fontColor: "#ffffff",
                envelopeColor: "#ea6778",
                image1: "/26/background.jpg"
            }
        }
    },
    {
        id: 27,
        title: "Model 9",
        preview: "/images/inviteTemplates/27/preview.jpg",
        background: "/images/inviteTemplates/27/background.jpg",
        type: "Botez",
        otherTypes: [
            "Mot",
            "Turta"
        ],
        colors: {
            baseColor: "#476C4E",
            borderColor: "#476C4E",
            fontColor: "#0A0A0A"
        },
        usage: 2,
        introAnimations: [
            1
        ],
        introAnimationsConfig: {
            1: {
                optionalAnimation1: 'autumn_leaves',
                autumnLeavesColor: '#86986f'
            }
        },
        introScreen: {
            templateId: 2,
            config: {
                envelopeColor: "#476C4E",
                fontColor: "#ffffff",
                image1: "/27/intro_screen_1.png"
            }
        }
    },
    {
        id: 31,
        title: "Model 10",
        preview: "/images/inviteTemplates/31/preview.jpg",
        background: "/images/inviteTemplates/31/background.jpg",
        type: "Botez",
        otherTypes: [
            "Mot",
            "Turta"
        ],
        colors: {
            baseColor: "#476C4E",
            borderColor: "#476C4E",
            fontColor: "#0A0A0A"
        },
        usage: 5,
        introAnimations: [
            1
        ],
        introAnimationsConfig: {
            1: {
                optionalAnimation1: 'autumn_leaves',
                autumnLeavesColor: '#86986f'
            }
        },
        introScreen: {
            templateId: 2,
            config: {
                envelopeColor: "#476C4E",
                fontColor: "#ffffff",
                image1: "/30/intro_screen_1.png",
                image2: "/30/intro_screen_2.png"
            }
        }
    },
    {
        id: 34,
        title: "Model 11",
        preview: "/images/inviteTemplates/34/preview.jpg",
        background: "/images/inviteTemplates/34/background.jpg",
        type: "Botez",
        otherTypes: [
            "Mot",
            "Turta"
        ],
        colors: {
            baseColor: "#476C4E",
            borderColor: "#476C4E",
            fontColor: "#0A0A0A"
        },
        usage: 5,
        introAnimations: [
            1
        ],
        introAnimationsConfig: {
            1: {
                optionalAnimation1: 'autumn_leaves',
                autumnLeavesColor: '#86986f'
            }
        },
        introScreen: {
            templateId: 2,
            config: {
                envelopeColor: "#476C4E",
                fontColor: "#ffffff",
                image1: "/33/intro_screen_1.png",
                image2: "/33/intro_screen_2.png"
            }
        }
    },
    {
        id: 39,
        title: "Model 12",
        preview: "/images/inviteTemplates/39/preview.jpg",
        background: "/images/inviteTemplates/39/background.jpg",
        type: "Botez",
        otherTypes: [
            "Mot",
            "Turta"
        ],
        colors: {
            baseColor: "#f57503",
            borderColor: "#f57503",
            fontColor: "#0A0A0A"
        },
        usage: 50,
        introAnimations: [
            1
        ],
        introAnimationsConfig: {
            1: {
                optionalAnimation1: 'balloons',
                balloonsType: 'normal',
                balloonsColorsType: 'single_color',
                balloonsColor: '#f57503',
            }
        },
        introScreen: {
            templateId: 2,
            config: {
                envelopeColor: "#f57503",
                fontColor: "#ffffff",
                image1: "/39/intro_screen_1.png",
                image2: "/39/intro_screen_2.png"
            }
        }
    },
    {
        id: 43,
        title: "Model 13",
        preview: "/images/inviteTemplates/43/preview.jpg",
        background: "/images/inviteTemplates/43/background.jpg",
        type: "Botez",
        otherTypes: [
            "Mot",
            "Turta"
        ],
        colors: {
            baseColor: "#f57503",
            borderColor: "#f57503",
            fontColor: "#0A0A0A"
        },
        usage: 0,
        introAnimations: [
            1
        ],
        introAnimationsConfig: {
            1: {
                optionalAnimation1: 'autumn_leaves',
                autumnLeavesColor: '#86986f'
            }
        },
        introScreen: {
            templateId: 2,
            config: {
                envelopeColor: "#f57503",
                fontColor: "#ffffff",
                image1: "/43/intro_screen_1.png",
                image2: "/43/intro_screen_2.png"
            }
        }
    },
    {
        id: 44,
        title: "Model 14",
        preview: "/images/inviteTemplates/44/preview.jpg",
        background: "/images/inviteTemplates/44/background.jpg",
        type: "Botez",
        otherTypes: [
            "Mot",
            "Turta"
        ],
        colors: {
            baseColor: "#f57503",
            borderColor: "#f57503",
            fontColor: "#0A0A0A"
        },
        usage: 18,
        introAnimations: [
            1
        ],
        introAnimationsConfig: {
            1: {
                optionalAnimation1: 'balloons',
                balloonsType: 'normal',
                balloonsColorsType: 'single_color',
                balloonsColor: '#f57503',
            }
        },
        introScreen: {
            templateId: 2,
            config: {
                envelopeColor: "#f57503",
                fontColor: "#ffffff",
                image1: "/44/intro_screen_1.png",
                image2: "/44/intro_screen_2.png"
            }
        }
    },
    {
        id: 47,
        title: "Model 15",
        preview: "/images/inviteTemplates/47/preview.jpg",
        background: "/images/inviteTemplates/47/background.jpg",
        type: "Botez",
        otherTypes: [
            "Mot",
            "Turta"
        ],
        colors: {
            baseColor: "#D6C1BE",
            borderColor: "#D6C1BE",
            fontColor: "#493C3A"
        },
        usage: 2,
        introAnimations: [
            1
        ],
        introScreen: {
            templateId: 2,
            config: {
                envelopeColor: "#D6C1BE",
                fontColor: "#ffffff",
                image1: "/47/intro_screen_1.png"
            }
        }
    },
    {
        id: 65,
        title: "Model 16",
        preview: "/images/inviteTemplates/65/preview.jpg",
        background: "/images/inviteTemplates/65/background.jpg",
        type: "Botez",
        otherTypes: [
            "Mot",
            "Turta"
        ],
        colors: {
            baseColor: "#D6C1BE",
            borderColor: "#D6C1BE",
            fontColor: "#493C3A"
        },
        usage: 0,
        introAnimations: [
            1
        ],
        introAnimationsConfig: {
            1: {
                optionalAnimation1: 'autumn_leaves',
                autumnLeavesColor: '#86986f'
            }
        },
        introScreen: {
            templateId: 2,
            config: {
                envelopeColor: "#80ab9f",
                fontColor: "#ffffff",
                image1: "/65/intro_screen_1.png"
            }
        }
    },
    {
        id: 66,
        title: "Model 17",
        preview: "/images/inviteTemplates/66/preview.jpg",
        background: "/images/inviteTemplates/66/background.jpg",
        type: "Botez",
        otherTypes: [
            "Mot",
            "Turta"
        ],
        colors: {
            baseColor: "#DBA32B",
            borderColor: "#DBA32B",
            fontColor: "#493C3A"
        },
        usage: 2,
        introAnimations: [
            1
        ],
        introAnimationsConfig: {
            1: {
                optionalAnimation1: 'autumn_leaves',
                autumnLeavesColor: '#86986f'
            }
        },
        introScreen: {
            templateId: 2,
            config: {
                envelopeColor: "#DBA32B",
                fontColor: "#ffffff",
                image1: "/66/intro_screen_1.png",
                image2: "/66/intro_screen_2.png"
            }
        }
    },
    {
        id: 67,
        title: "Model 18",
        preview: "/images/inviteTemplates/67/preview.jpg",
        background: "/images/inviteTemplates/67/background.jpg",
        type: "Botez",
        otherTypes: [
            "Mot",
            "Turta"
        ],
        colors: {
            baseColor: "#993D38",
            borderColor: "#993D38",
            fontColor: "#493C3A"
        },
        usage: 1,
        introAnimations: [
            1
        ],
        introAnimationsConfig: {
            1: {
                optionalAnimation1: 'balloons',
                balloonsType: 'normal',
                balloonsColorsType: 'single_color',
                balloonsColor: '#eaccc7',
            }
        },
        introScreen: {
            templateId: 2,
            config: {
                envelopeColor: "#993D38",
                fontColor: "#ffffff",
                image1: "/67/intro_screen_1.png",
                image2: "/67/intro_screen_2.png"
            }
        }
    },
    {
        id: 68,
        title: "Model 19",
        preview: "/images/inviteTemplates/68/preview.jpg",
        background: "/images/inviteTemplates/68/background.jpg",
        type: "Botez",
        otherTypes: [
            "Mot",
            "Turta"
        ],
        colors: {
            baseColor: "#A3CCAE",
            borderColor: "#A3CCAE",
            fontColor: "#493C3A"
        },
        usage: 1,
        introAnimations: [
            1
        ],
        introAnimationsConfig: {
            1: {
                optionalAnimation1: 'confetti',
            }
        },
        introScreen: {
            templateId: 2,
            config: {
                envelopeColor: "#A3CCAE",
                fontColor: "#ffffff",
                image1: "/68/intro_screen_1.png",
                image2: "/68/intro_screen_2.png"
            }
        }
    },
    {
        id: 69,
        title: "Model 20",
        preview: "/images/inviteTemplates/69/preview.jpg",
        background: "/images/inviteTemplates/69/background.jpg",
        type: "Botez",
        otherTypes: [
            "Mot",
            "Turta"
        ],
        colors: {
            baseColor: "#A3CCAE",
            borderColor: "#A3CCAE",
            fontColor: "#493C3A"
        },
        usage: 4,
        introAnimations: [
            1
        ],
        introAnimationsConfig: {
            1: {
                optionalAnimation1: 'confetti',
            }
        },
        introScreen: {
            templateId: 2,
            config: {
                envelopeColor: "#A3CCAE",
                fontColor: "#ffffff",
                image1: "/69/intro_screen_1.png",
                image2: "/69/intro_screen_2.png"
            }
        }
    },
    {
        id: 70,
        title: "Model 21",
        preview: "/images/inviteTemplates/70/preview.jpg",
        background: "/images/inviteTemplates/70/background.jpg",
        type: "Botez",
        otherTypes: [
            "Mot",
            "Turta"
        ],
        colors: {
            baseColor: "#A3CCAE",
            borderColor: "#A3CCAE",
            fontColor: "#493C3A"
        },
        usage: 5,
        introAnimations: [
            1
        ],
        introAnimationsConfig: {
            1: {
                optionalAnimation1: 'balloons',
                balloonsType: 'normal',
                balloonsColorsType: 'multicolor',
                balloonsColor1: '#fcd99d',
                balloonsColor2: '#e48b91',
                balloonsColor3: '#dff5ef',
                balloonsColor4: '#fde5e1',
                balloonsColor5: '#b2e0d5',
            }
        },
        introScreen: {
            templateId: 2,
            config: {
                envelopeColor: "#A3CCAE",
                fontColor: "#ffffff",
                image1: "/70/intro_screen_1.png",
                image2: "/70/intro_screen_2.png"
            }
        }
    },
    {
        id: 71,
        title: "Model 22",
        preview: "/images/inviteTemplates/71/preview.jpg",
        background: "/images/inviteTemplates/71/background.jpg",
        type: "Botez",
        otherTypes: [
            "Mot",
            "Turta"
        ],
        colors: {
            baseColor: "#B31F28",
            borderColor: "#B31F28",
            fontColor: "#2f2625"
        },
        usage: 2,
        introAnimations: [
            1
        ],
        introAnimationsConfig: {
            1: {
                optionalAnimation1: 'balloons',
                balloonsType: 'normal',
                balloonsColorsType: 'multicolor',
                balloonsColor1: '#e4bc89',
                balloonsColor2: '#da7982',
                balloonsColor3: '#b47de5',
                balloonsColor4: '#dcd1ee',
                balloonsColor5: '#ecd6d0',
            }
        },
        introScreen: {
            templateId: 2,
            config: {
                fontColor: "#ffffff",
                envelopeColor: "#B31F28",
                image1: "/71/intro_screen_1.png",
                image2: "/71/intro_screen_2.png"
            }
        }
    },
    {
        id: 72,
        title: "Model 23",
        preview: "/images/inviteTemplates/72/preview.jpg",
        background: "/images/inviteTemplates/72/background.jpg",
        type: "Botez",
        otherTypes: [
            "Mot",
            "Turta"
        ],
        colors: {
            baseColor: "#D0AC67",
            borderColor: "#D0AC67",
            fontColor: "#2f2625"
        },
        usage: 6,
        introAnimations: [
            1
        ],
        introAnimationsConfig: {
            1: {
                optionalAnimation1: 'balloons',
                balloonsType: 'normal',
                balloonsColorsType: 'single_color',
                balloonsColor: '#f0cf8f',
            }
        },
        introScreen: {
            templateId: 2,
            config: {
                envelopeColor: "#D0AC67",
                fontColor: "#ffffff",
                image1: "/72/intro_screen_1.png",
                image2: "/72/intro_screen_2.png"
            }
        }
    },
    {
        id: 73,
        title: "Model 24",
        preview: "/images/inviteTemplates/73/preview.jpg",
        background: "/images/inviteTemplates/73/background.jpg",
        type: "Botez",
        otherTypes: [
            "Mot",
            "Turta"
        ],
        colors: {
            baseColor: "#FFC626",
            borderColor: "#FFC626",
            fontColor: "#2f2625"
        },
        usage: 7,
        introAnimations: [
            1
        ],
        introAnimationsConfig: {
            1: {
                optionalAnimation1: 'balloons',
                balloonsType: 'normal',
                balloonsColorsType: 'single_color',
                balloonsColor: '#FFC626',
            }
        },
        introScreen: {
            templateId: 2,
            config: {
                envelopeColor: "#FFC626",
                fontColor: "#ffffff",
                image1: "/73/intro_screen_1.png",
                image2: "/73/intro_screen_2.png"
            }
        }
    },
    {
        id: 74,
        title: "Model 25",
        preview: "/images/inviteTemplates/74/preview.jpg",
        background: "/images/inviteTemplates/74/background.jpg",
        type: "Botez",
        otherTypes: [
            "Mot",
            "Turta"
        ],
        colors: {
            baseColor: "#782344",
            borderColor: "#782344",
            fontColor: "#2f2625"
        },
        usage: 1,
        introAnimations: [
            1
        ],
        introAnimationsConfig: {
            1: {
                optionalAnimation1: 'autumn_leaves',
            }
        },
        introScreen: {
            templateId: 2,
            config: {
                fontColor: "#ffffff",
                envelopeColor: "#782344",
                image1: "/74/intro_screen_1.png",
                image2: "/74/intro_screen_2.png"
            }
        }
    },
    {
        id: 75,
        title: "Model 26",
        preview: "/images/inviteTemplates/75/preview.jpg",
        background: "/images/inviteTemplates/75/background.jpg",
        type: "Botez",
        otherTypes: [
            "Mot",
            "Turta"
        ],
        colors: {
            baseColor: "#4FB5B1",
            borderColor: "#4FB5B1",
            fontColor: "#2f2625"
        },
        usage: 3,
        introAnimations: [
            1
        ],
        introAnimationsConfig: {
            1: {
                optionalAnimation1: 'balloons',
                balloonsType: 'normal',
                balloonsColorsType: 'single_color',
                balloonsColor: '#4FB5B1',
            }
        },
        introScreen: {
            templateId: 2,
            config: {
                fontColor: "#ffffff",
                envelopeColor: "#4FB5B1",
                image1: "/75/intro_screen_1.png",
                image2: "/75/intro_screen_2.png"
            }
        }
    },
    {
        id: 76,
        title: "Model 27",
        preview: "/images/inviteTemplates/76/preview.jpg",
        background: "/images/inviteTemplates/76/background.jpg",
        type: "Botez",
        otherTypes: [
            "Mot",
            "Turta"
        ],
        colors: {
            baseColor: "#87D1E6",
            borderColor: "#87D1E6",
            fontColor: "#323e48"
        },
        usage: 11,
        introAnimations: [
            1
        ],
        introAnimationsConfig: {
            1: {
                optionalAnimation1: 'balloons',
                balloonsType: 'normal',
                balloonsColorsType: 'single_color',
                balloonsColor: '#87D1E6',
            }
        },
        introScreen: {
            templateId: 2,
            config: {
                fontColor: "#ffffff",
                envelopeColor: "#87D1E6",
                image1: "/76/intro_screen_1.png"
            }
        }
    },
    {
        id: 77,
        title: "Model 28",
        preview: "/images/inviteTemplates/77/preview.jpg",
        background: "/images/inviteTemplates/77/background.jpg",
        type: "Botez",
        otherTypes: [
            "Mot",
            "Turta"
        ],
        colors: {
            baseColor: "#C4450E",
            borderColor: "#C4450E",
            fontColor: "#323e48"
        },
        usage: 14,
        introAnimations: [
            1
        ],
        introAnimationsConfig: {
            1: {
                optionalAnimation1: 'balloons',
                balloonsType: 'normal',
                balloonsColorsType: 'single_color',
                balloonsColor: '#C4450E',
            }
        },
        introScreen: {
            templateId: 2,
            config: {
                fontColor: "#ffffff",
                envelopeColor: "#C4450E",
                image1: "/77/intro_screen_1.png",
                image2: "/77/intro_screen_2.png"
            }
        }
    },
    {
        id: 78,
        title: "Model 29",
        preview: "/images/inviteTemplates/78/preview.jpg",
        background: "/images/inviteTemplates/78/background.jpg",
        type: "Botez",
        otherTypes: [
            "Mot",
            "Turta"
        ],
        colors: {
            baseColor: "#EC123B",
            borderColor: "#EC123B",
            fontColor: "#323e48"
        },
        usage: 28,
        introAnimations: [
            1
        ],
        introAnimationsConfig: {
            1: {
                optionalAnimation1: 'balloons',
                balloonsType: 'normal',
                balloonsColorsType: 'single_color',
                balloonsColor: '#EC123B',
            }
        },
        introScreen: {
            templateId: 2,
            config: {
                fontColor: "#ffffff",
                envelopeColor: "#EC123B",
                image1: "/78/intro_screen_1.png",
                image2: "/78/intro_screen_2.png"
            }
        }
    },
    {
        id: 97,
        title: "Model 30",
        preview: "/images/inviteTemplates/97/preview.jpg",
        background: "/images/inviteTemplates/41/background.jpg",
        type: "Botez",
        otherTypes: [
            "Mot",
            "Turta"
        ],
        colors: {
            baseColor: "#d3b150",
            borderColor: "#d3b150",
            fontColor: "#0A0A0A"
        },
        usage: 4,
        introAnimations: [
            1
        ],
        introAnimationsConfig: {
            1: {
                optionalAnimation1: 'autumn_leaves',
                autumnLeavesColor: '#86986f'
            }
        },
        introScreen: {
            templateId: 2,
            config: {
                fontColor: "#ffffff",
                envelopeColor: "#87938f",
                image1: "/41/intro_screen_1.png",
                image2: "/41/intro_screen_2.png"
            }
        }
    },
    {
        id: 98,
        title: "Model 31",
        preview: "/images/inviteTemplates/98/preview.jpg",
        background: "/images/inviteTemplates/42/background.jpg",
        type: "Botez",
        otherTypes: [
            "Mot",
            "Turta"
        ],
        colors: {
            baseColor: "#d3b150",
            borderColor: "#d3b150",
            fontColor: "#0A0A0A"
        },
        usage: 0,
        introAnimations: [
            1
        ],
        introAnimationsConfig: {
            1: {
                optionalAnimation1: 'balloons',
                balloonsType: 'heart',
                balloonsColorsType: 'single_color',
                balloonsColor: '#f40002'
            }
        },
        introScreen: {
            templateId: 2,
            config: {
                fontColor: "#ffffff",
                envelopeColor: "#d3b150",
                image1: "/42/intro_screen_1.png",
                image2: "/42/intro_screen_2.png"
            }
        }
    },
    {
        id: 99,
        title: "Model 32",
        preview: "/images/inviteTemplates/99/preview.jpg",
        background: "/images/inviteTemplates/49/background.jpg",
        type: "Botez",
        otherTypes: [
            "Mot",
            "Turta"
        ],
        colors: {
            baseColor: "#BD5033",
            borderColor: "#BD5033",
            fontColor: "#49423A"
        },
        usage: 1,
        introAnimations: [
            1
        ],
        introAnimationsConfig: {
            1: {
                optionalAnimation1: 'autumn_leaves',
            }
        },
        introScreen: {
            templateId: 2,
            config: {
                fontColor: "#ffffff",
                envelopeColor: "#BD5033",
                image1: "/49/intro_screen_1.png",
                image2: "/49/intro_screen_2.png"
            }
        }
    },
    {
        id: 100,
        title: "Model 33",
        preview: "/images/inviteTemplates/100/preview.jpg",
        background: "/images/inviteTemplates/51/background.jpg",
        type: "Botez",
        otherTypes: [
            "Mot",
            "Turta"
        ],
        colors: {
            baseColor: "#804600",
            borderColor: "#5e3500",
            fontColor: "#3B2100"
        },
        usage: 12,
        backgroundAnimation: "/images/inviteTemplates/51/background_animation.jpg",
        introAnimations: [
            1
        ],
        introAnimationsConfig: {
            1: {
                optionalAnimation1: 'autumn_leaves',
            }
        },
        introScreen: {
            templateId: 2,
            config: {
                fontColor: "#ffffff",
                envelopeColor: "#b7986b",
                image1: "/51/intro_screen_1.png",
                image2: "/51/intro_screen_2.png"
            }
        }
    },
    {
        id: 101,
        title: "Model 34",
        preview: "/images/inviteTemplates/101/preview.jpg",
        background: "/images/inviteTemplates/53/background.jpg",
        type: "Botez",
        otherTypes: [
            "Mot",
            "Turta"
        ],
        colors: {
            baseColor: "#804600",
            borderColor: "#5e3500",
            fontColor: "#524739"
        },
        usage: 1,
        introAnimations: [
            1
        ],
        introAnimationsConfig: {
            1: {
                optionalAnimation1: 'autumn_leaves',
            }
        },
        introScreen: {
            templateId: 2,
            config: {
                fontColor: "#ffffff",
                envelopeColor: "#804600",
                image1: "/53/intro_screen_1.png",
                image2: "/53/intro_screen_2.png"
            }
        }
    },
    {
        id: 102,
        title: "Model 35",
        preview: "/images/inviteTemplates/102/preview.jpg",
        background: "/images/inviteTemplates/55/background.jpg",
        type: "Botez",
        otherTypes: [
            "Mot",
            "Turta"
        ],
        colors: {
            baseColor: "#B9389C",
            borderColor: "#B9389C",
            fontColor: "#460936"
        },
        usage: 6,
        backgroundAnimation: "/images/inviteTemplates/55/background_animation.jpg",
        introAnimations: [
            1
        ],
        introAnimationsConfig: {
            1: {
                optionalAnimation1: 'autumn_leaves',
            }
        },
        introScreen: {
            templateId: 2,
            config: {
                fontColor: "#ffffff",
                envelopeColor: "#693382",
                image1: "/55/intro_screen_1.png",
                image2: "/55/intro_screen_2.png"
            }
        }
    },
    {
        id: 103,
        title: "Model 36",
        preview: "/images/inviteTemplates/103/preview.jpg",
        background: "/images/inviteTemplates/54/background.jpg",
        type: "Botez",
        otherTypes: [
            "Mot",
            "Turta"
        ],
        colors: {
            baseColor: "#B9389C",
            borderColor: "#B9389C",
            fontColor: "#460936"
        },
        usage: 0,
        introAnimations: [
            1
        ],
        introAnimationsConfig: {
            1: {
                optionalAnimation1: 'autumn_leaves',
            }
        },
        introScreen: {
            templateId: 2,
            config: {
                envelopeColor: "#693382",
                fontColor: "#ffffff",
                image1: "/54/intro_screen_1.png",
                image2: "/54/intro_screen_2.png"
            }
        }
    },
    {
        id: 104,
        title: "Model 37",
        preview: "/images/inviteTemplates/104/preview.jpg",
        background: "/images/inviteTemplates/58/background.jpg",
        type: "Botez",
        otherTypes: [
            "Mot",
            "Turta"
        ],
        colors: {
            baseColor: "#5b5b5b",
            borderColor: "#5b5b5b",
            fontColor: "#6c94a9"
        },
        usage: 1,
        backgroundAnimation: "/images/inviteTemplates/58/background_animation.jpg",
        introAnimations: [
            1
        ],
        introAnimationsConfig: {
            1: {
                optionalAnimation1: 'balloons',
                balloonsType: 'normal',
                balloonsColorsType: 'multicolor',
                balloonsColor1: '#cad9e0',
                balloonsColor2: '#ffffff',
                balloonsColor3: '#6c94a8',
                balloonsColor4: '#909598',
                balloonsColor5: '#2f2e2e',
            }
        },
        introScreen: {
            templateId: 2,
            config: {
                fontColor: "#ffffff",
                envelopeColor: "#5b5b5b",
                image1: "/58/intro_screen_1.png"
            }
        }
    },
    {
        id: 105,
        title: "Model 38",
        preview: "/images/inviteTemplates/105/preview.jpg",
        background: "/images/inviteTemplates/61/background.jpg",
        type: "Botez",
        otherTypes: [
            "Mot",
            "Turta"
        ],
        colors: {
            baseColor: "#CC95A3",
            borderColor: "#CC95A3",
            fontColor: "#3a3a3a"
        },
        usage: 0,
        introAnimations: [
            1
        ],
        introAnimationsConfig: {
            1: {
                optionalAnimation1: 'autumn_leaves',
                autumnLeavesColor: '#86986f'
            }
        },
        introScreen: {
            templateId: 2,
            config: {
                fontColor: "#ffffff",
                envelopeColor: "#CC95A3",
                image1: "/61/intro_screen_1.png",
                image2: "/61/intro_screen_2.png"
            }
        }
    },
    {
        id: 106,
        title: "Model 39",
        preview: "/images/inviteTemplates/106/preview.jpg",
        background: "/images/inviteTemplates/62/background.jpg",
        type: "Botez",
        otherTypes: [
            "Mot",
            "Turta"
        ],
        colors: {
            baseColor: "#C796C5",
            borderColor: "#C796C5",
            fontColor: "#4a6373"
        },
        usage: 3,
        introAnimations: [
            1
        ],
        introAnimationsConfig: {
            1: {
                optionalAnimation1: 'balloons',
                balloonsType: 'normal',
                balloonsColorsType: 'multicolor',
                balloonsColor1: '#bce1f3',
                balloonsColor2: '#e1cdef',
                balloonsColor3: '#6c94a8',
                balloonsColor4: '#909598',
                balloonsColor5: '#2f2e2e',
            }
        },
        introScreen: {
            templateId: 2,
            config: {
                fontColor: "#ffffff",
                envelopeColor: "#d7a6d5",
                image1: "/62/intro_screen_1.png"
            }
        }
    },
    {
        id: 107,
        title: "Model 40",
        preview: "/images/inviteTemplates/107/preview.jpg",
        background: "/images/inviteTemplates/64/background.jpg",
        type: "Botez",
        otherTypes: [
            "Mot",
            "Turta"
        ],
        colors: {
            baseColor: "#E76B87",
            borderColor: "#E76B87",
            fontColor: "#444346"
        },
        usage: 22,
        introAnimations: [
            1
        ],
        introAnimationsConfig: {
            1: {
                optionalAnimation1: 'balloons',
                balloonsType: 'normal',
                balloonsColorsType: 'single_color',
                balloonsColor: '#E76B87',
            }
        },
        introScreen: {
            templateId: 2,
            config: {
                fontColor: "#ffffff",
                envelopeColor: "#E76B87",
                image1: "/64/intro_screen_1.png",
                image2: "/64/intro_screen_2.png"
            }
        }
    },
    {
        id: 108,
        title: "Model 41",
        preview: "/images/inviteTemplates/108/preview.jpg",
        background: "/images/inviteTemplates/80/background.jpg",
        type: "Botez",
        otherTypes: [
            "Mot",
            "Turta"
        ],
        colors: {
            baseColor: "#E76B87",
            borderColor: "#E76B87",
            fontColor: "#444346"
        },
        usage: 0,
        introAnimations: [
            1
        ],
        introAnimationsConfig: {
            1: {
                optionalAnimation1: 'autumn_leaves',
                autumnLeavesColor: '#86986f'
            }
        },
        introScreen: {
            templateId: 2,
            config: {
                fontColor: "#ffffff",
                envelopeColor: "#E76B87",
                image1: "/80/intro_screen_1.png",
                image2: "/80/intro_screen_2.png"
            }
        }
    },
    {
        id: 109,
        title: "Model 42",
        preview: "/images/inviteTemplates/109/preview.jpg",
        background: "/images/inviteTemplates/81/background.jpg",
        type: "Botez",
        otherTypes: [
            "Mot",
            "Turta"
        ],
        colors: {
            baseColor: "#7D464C",
            borderColor: "#7D464C",
            fontColor: "#444346"
        },
        usage: 0,
        introAnimations: [
            1
        ],
        introAnimationsConfig: {
            1: {
                optionalAnimation1: 'autumn_leaves',
            }
        },
        introScreen: {
            templateId: 2,
            config: {
                fontColor: "#ffffff",
                envelopeColor: "#7D464C",
                image1: "/81/intro_screen_1.png"
            }
        }
    },
    {
        id: 110,
        title: "Model 43",
        preview: "/images/inviteTemplates/110/preview.jpg",
        background: "/images/inviteTemplates/82/background.jpg",
        type: "Botez",
        otherTypes: [
            "Mot",
            "Turta"
        ],
        colors: {
            baseColor: "#60AEA7",
            borderColor: "#60AEA7",
            fontColor: "#444346"
        },
        usage: 4,
        introAnimations: [
            1
        ],
        introAnimationsConfig: {
            1: {
                optionalAnimation1: 'autumn_leaves',
            }
        },
        introScreen: {
            templateId: 2,
            config: {
                fontColor: "#ffffff",
                envelopeColor: "#60AEA7",
                image1: "/82/intro_screen_1.png"
            }
        }
    },
    {
        id: 111,
        title: "Model 44",
        preview: "/images/inviteTemplates/111/preview.jpg",
        background: "/images/inviteTemplates/83/background.jpg",
        type: "Botez",
        otherTypes: [
            "Mot",
            "Turta"
        ],
        colors: {
            baseColor: "#684211",
            borderColor: "#684211",
            fontColor: "#444346"
        },
        usage: 1,
        introAnimations: [
            1
        ],
        introAnimationsConfig: {
            1: {
                optionalAnimation1: 'autumn_leaves',
            }
        },
        introScreen: {
            templateId: 3,
            config: {
                envelopeColor: "#684211",
                fontColor: "#ffffff",
                image1: "/83/background.jpg"
            }
        }
    },
    {
        id: 112,
        title: "Model 45",
        preview: "/images/inviteTemplates/112/preview.jpg",
        background: "/images/inviteTemplates/84/background.jpg",
        type: "Botez",
        otherTypes: [
            "Mot",
            "Turta"
        ],
        colors: {
            baseColor: "#fffcf8",
            borderColor: "#fffcf8",
            fontColor: "#fffcf8"
        },
        usage: 3,
        introAnimations: [
            1
        ],
        introAnimationsConfig: {
            1: {
                optionalAnimation1: 'autumn_leaves',
                birdsColor: '#aaaaaa',
            }
        },
        introScreen: {
            templateId: 2,
            config: {
                fontColor: "#2c3f3b",
                envelopeColor: "#fffcf8",
                image1: "/84/background.jpg"
            }
        }
    },
    {
        id: 113,
        title: "Model 46",
        preview: "/images/inviteTemplates/113/preview.jpg",
        background: "/images/inviteTemplates/85/background.jpg",
        type: "Botez",
        otherTypes: [
            "Mot",
            "Turta"
        ],
        colors: {
            baseColor: "#62805B",
            borderColor: "#62805B",
            fontColor: "#444346"
        },
        usage: 2,
        introAnimations: [
            1
        ],
        introAnimationsConfig: {
            1: {
                optionalAnimation1: 'autumn_leaves',
            }
        },
        introScreen: {
            templateId: 2,
            config: {
                fontColor: "#ffffff",
                envelopeColor: "#62805B",
                image1: "/85/intro_screen_1.png"
            }
        }
    },
    {
        id: 114,
        title: "Model 47",
        preview: "/images/inviteTemplates/114/preview.jpg",
        background: "/images/inviteTemplates/93/background.jpg",
        type: "Botez",
        otherTypes: [
            "Mot",
            "Turta"
        ],
        colors: {
            baseColor: "#CF9496",
            borderColor: "#CF9496",
            fontColor: "#444346"
        },
        usage: 0,
        introAnimations: [
            1
        ],
        introScreen: {
            templateId: 2,
            config: {
                fontColor: "#ffffff",
                envelopeColor: "#CF9496",
                image1: "/93/intro_screen_1.png",
                image2: "/93/intro_screen_2.png"
            }
        }
    },
    {
        id: 115,
        title: "Model 48",
        preview: "/images/inviteTemplates/115/preview.jpg",
        background: "/images/inviteTemplates/95/background.jpg",
        type: "Botez",
        otherTypes: [
            "Mot",
            "Turta"
        ],
        colors: {
            baseColor: "#e8b059",
            borderColor: "#e8b059",
            fontColor: "#444346"
        },
        usage: 0,
        introAnimations: [
            1
        ],
        introScreen: {
            templateId: 2,
            config: {
                fontColor: "#ffffff",
                envelopeColor: "#e8b059",
                image1: "/95/intro_screen_1.png",
                image2: "/95/intro_screen_2.png"
            }
        }
    },
    {
        id: 149,
        title: "Model 49",
        preview: "/images/inviteTemplates/149/preview.jpg",
        background: "/images/inviteTemplates/86/background.png",
        type: "Botez",
        otherTypes: [
            "Mot",
            "Turta"
        ],
        colors: {
            baseColor: "#E4BF87",
            borderColor: "#E4BF87",
            fontColor: "#444346"
        },
        coverImage: true,
        coverImageDefault: "/images/inviteTemplates/149/cover.jpg",
        usage: 2,
        backgroundAnimation: "/images/inviteTemplates/86/background_animation.jpg",
        introAnimations: [
            1
        ],
        introScreen: {
            templateId: 2,
            config: {
                fontColor: "#E4BF87",
                envelopeColor: "#444346",
                image1: "/86/intro_screen_1.png",
                image2: "/86/intro_screen_2.png"
            }
        }
    },
    {
        id: 150,
        title: "Model 50",
        preview: "/images/inviteTemplates/150/preview.jpg",
        background: "/images/inviteTemplates/87/background.png",
        type: "Botez",
        otherTypes: [
            "Mot",
            "Turta"
        ],
        colors: {
            baseColor: "#E4BF87",
            borderColor: "#E4BF87",
            fontColor: "#444346"
        },
        coverImage: true,
        coverImageDefault: "/images/inviteTemplates/150/cover.jpg",
        usage: 49,
        backgroundAnimation: "/images/inviteTemplates/87/background_animation.jpg",
        introAnimations: [
            1
        ],
        introScreen: {
            templateId: 2,
            config: {
                fontColor: "#ffffff",
                envelopeColor: "#D8B879",
                image1: "/89/intro_screen_1.png"
            }
        }
    },
    {
        id: 151,
        title: "Model 51",
        preview: "/images/inviteTemplates/151/preview.jpg",
        background: "/images/inviteTemplates/88/background.png",
        type: "Botez",
        otherTypes: [
            "Mot",
            "Turta"
        ],
        colors: {
            baseColor: "#BCA864",
            borderColor: "#BCA864",
            fontColor: "#444346"
        },
        coverImage: true,
        coverImageDefault: "/images/inviteTemplates/151/cover.jpg",
        usage: 11,
        backgroundAnimation: "/images/inviteTemplates/88/background_animation.jpg",
        introAnimations: [
            1
        ],
        introAnimationsConfig: {
            1: {
                optionalAnimation1: 'autumn_leaves',
                autumnLeavesColor: '#86986f'
            }
        },
        introScreen: {
            templateId: 2,
            config: {
                fontColor: "#ffffff",
                envelopeColor: "#6d7737",
                image1: "/88/intro_screen_1.png"
            }
        }
    },
    {
        id: 152,
        title: "Model 52",
        preview: "/images/inviteTemplates/152/preview.jpg",
        background: "/images/inviteTemplates/89/background.png",
        type: "Botez",
        otherTypes: [
            "Mot",
            "Turta"
        ],
        colors: {
            baseColor: "#D8B879",
            borderColor: "#D8B879",
            fontColor: "#444346"
        },
        coverImage: true,
        coverImageDefault: "/images/inviteTemplates/149/cover.jpg",
        usage: 7,
        backgroundAnimation: "/images/inviteTemplates/89/background_animation.jpg",
        introAnimations: [
            1
        ],
        introScreen: {
            templateId: 2,
            config: {
                fontColor: "#ffffff",
                envelopeColor: "#D8B879",
                image1: "/89/intro_screen_1.png"
            }
        }
    },
    {
        id: 153,
        title: "Model 53",
        preview: "/images/inviteTemplates/153/preview.jpg",
        background: "/images/inviteTemplates/90/background.png",
        type: "Botez",
        otherTypes: [
            "Mot",
            "Turta"
        ],
        colors: {
            baseColor: "#D8B879",
            borderColor: "#D8B879",
            fontColor: "#444346"
        },
        coverImage: true,
        coverImageDefault: "/images/inviteTemplates/149/cover.jpg",
        usage: 2,
        backgroundAnimation: "/images/inviteTemplates/90/background_animation.jpg",
        introAnimations: [
            1
        ],
        introAnimationsConfig: {
            1: {
                optionalAnimation1: 'autumn_leaves',
                autumnLeavesColor: '#86986f'
            }
        },
        introScreen: {
            templateId: 2,
            config: {
                fontColor: "#ffffff",
                envelopeColor: "#D8B879",
                image1: "/56/intro_screen_1.png",
                image2: "/56/intro_screen_2.png"
            }
        }
    },
    {
        id: 154,
        title: "Model 54",
        preview: "/images/inviteTemplates/154/preview.jpg",
        background: "/images/inviteTemplates/91/background.png",
        type: "Botez",
        otherTypes: [
            "Mot",
            "Turta"
        ],
        colors: {
            baseColor: "#D8B879",
            borderColor: "#D8B879",
            fontColor: "#444346"
        },
        coverImage: true,
        coverImageDefault: "/images/inviteTemplates/149/cover.jpg",
        usage: 27,
        backgroundAnimation: "/images/inviteTemplates/91/background_animation.jpg",
        introAnimations: [
            1
        ],
        introAnimationsConfig: {
            1: {
                optionalAnimation1: 'autumn_leaves',
                autumnLeavesColor: '#86986f'
            }
        },
        introScreen: {
            templateId: 2,
            config: {
                fontColor: "#ffffff",
                envelopeColor: "#D8B879",
                image1: "/59/intro_screen_1.png",
                image2: "/59/intro_screen_2.png"
            }
        }
    },
    {
        id: 168,
        title: "Model 55",
        preview: "/images/inviteTemplates/168/preview.jpg",
        background: "/images/inviteTemplates/168/background.jpg",
        type: "Botez",
        otherTypes: [
            "Mot",
            "Turta"
        ],
        colors: {
            baseColor: "#34915B",
            borderColor: "#34915B",
            fontColor: "#202021"
        },
        coverImage: false,
        usage: 1,
        introAnimations: [
            1
        ],
        introScreen: {
            templateId: 2,
            config: {
                fontColor: "#ffffff",
                envelopeColor: "#34915B",
                image1: "/168/intro_screen_1.png"
            }
        }
    },
    {
        id: 169,
        title: "Model 56",
        preview: "/images/inviteTemplates/169/preview.jpg",
        background: "/images/inviteTemplates/169/background.jpg",
        type: "Botez",
        otherTypes: [
            "Mot",
            "Turta"
        ],
        colors: {
            baseColor: "#CD3A35",
            borderColor: "#CD3A35",
            fontColor: "#202021"
        },
        coverImage: false,
        usage: 5,
        introAnimations: [
            1
        ],
        introScreen: {
            templateId: 2,
            config: {
                fontColor: "#ffffff",
                envelopeColor: "#d97128",
                image1: "/169/intro_screen_1.png"
            }
        }
    },
    {
        id: 170,
        title: "Model 57",
        preview: "/images/inviteTemplates/170/preview.jpg",
        background: "/images/inviteTemplates/170/background.jpg",
        type: "Botez",
        otherTypes: [
            "Mot",
            "Turta"
        ],
        colors: {
            baseColor: "#40BBE0",
            borderColor: "#40BBE0",
            fontColor: "#202021"
        },
        coverImage: false,
        usage: 0,
        introAnimations: [
            1
        ],
        introAnimationsConfig: {
            1: {
                optionalAnimation1: 'balloons',
            }
        },
        introScreen: {
            templateId: 2,
            config: {
                fontColor: "#ffffff",
                envelopeColor: "#a6ccd7",
                image1: "/170/intro_screen_1.png",
                image2: "/170/intro_screen_2.png"
            }
        }
    },
    {
        id: 171,
        title: "Model 58",
        preview: "/images/inviteTemplates/171/preview.jpg",
        background: "/images/inviteTemplates/171/background.jpg",
        type: "Botez",
        otherTypes: [
            "Mot",
            "Turta"
        ],
        colors: {
            baseColor: "#FFF",
            borderColor: "#FFF",
            fontColor: "#FFF"
        },
        coverImage: false,
        usage: 0,
        introAnimations: [
            1
        ],
        introAnimationsConfig: {
            1: {
                optionalAnimation1: 'balloons',
            }
        },
        introScreen: {
            templateId: 2,
            config: {
                fontColor: "#ffffff",
                envelopeColor: "#b71822",
                image1: "/171/intro_screen_1.png"
            }
        }
    },
    {
        id: 172,
        title: "Model 59",
        preview: "/images/inviteTemplates/172/preview.jpg",
        background: "/images/inviteTemplates/172/background.jpg",
        type: "Botez",
        otherTypes: [
            "Mot",
            "Turta"
        ],
        colors: {
            baseColor: "#FFF",
            borderColor: "#FFF",
            fontColor: "#FFF"
        },
        coverImage: false,
        usage: 0,
        introAnimations: [
            1
        ],
        introScreen: {
            templateId: 2,
            config: {
                fontColor: "#ffffff",
                envelopeColor: "#185873",
                image1: "/172/intro_screen_1.png"
            }
        }
    },
    {
        id: 173,
        title: "Model 60",
        preview: "/images/inviteTemplates/173/preview.jpg",
        background: "/images/inviteTemplates/173/background.jpg",
        type: "Botez",
        otherTypes: [
            "Mot",
            "Turta"
        ],
        colors: {
            baseColor: "#EA548F",
            borderColor: "#EA548F",
            fontColor: "#2d2b2b"
        },
        coverImage: false,
        usage: 3,
        introAnimations: [
            1
        ],
        introAnimationsConfig: {
            1: {
                optionalAnimation1: 'balloons',
            }
        },
        introScreen: {
            templateId: 2,
            config: {
                fontColor: "#ffffff",
                envelopeColor: "#EA548F",
                image1: "/173/intro_screen_1.png",
                image2: "/173/intro_screen_2.png"
            }
        }
    },
    {
        id: 174,
        title: "Model 61",
        preview: "/images/inviteTemplates/174/preview.jpg",
        background: "/images/inviteTemplates/174/background.jpg",
        type: "Botez",
        otherTypes: [
            "Mot",
            "Turta"
        ],
        colors: {
            baseColor: "#EA548F",
            borderColor: "#EA548F",
            fontColor: "#2d2b2b"
        },
        coverImage: false,
        usage: 0,
        introAnimations: [
            1
        ],
        introAnimationsConfig: {
            1: {
                optionalAnimation1: 'balloons',
            }
        },
        introScreen: {
            templateId: 2,
            config: {
                fontColor: "#ffffff",
                envelopeColor: "#f7bf43",
                image1: "/174/intro_screen_1.png",
                image2: "/174/intro_screen_2.png"
            }
        }
    },
    {
        id: 175,
        title: "Model 62",
        preview: "/images/inviteTemplates/175/preview.jpg",
        background: "/images/inviteTemplates/175/background.jpg",
        type: "Botez",
        otherTypes: [
            "Mot",
            "Turta"
        ],
        colors: {
            baseColor: "#73CFFA",
            borderColor: "#73CFFA",
            fontColor: "#2d2b2b"
        },
        coverImage: false,
        usage: 1,
        introAnimations: [
            1
        ],
        introAnimationsConfig: {
            1: {
                optionalAnimation1: 'balloons',
            }
        },
        introScreen: {
            templateId: 2,
            config: {
                fontColor: "#ffffff",
                envelopeColor: "#9bbaef",
                image1: "/175/intro_screen_1.png",
                image2: "/175/intro_screen_2.png"
            }
        }
    },
    {
        id: 176,
        title: "Model 63",
        preview: "/images/inviteTemplates/176/preview.jpg",
        background: "/images/inviteTemplates/176/background.jpg",
        type: "Botez",
        otherTypes: [
            "Mot",
            "Turta"
        ],
        colors: {
            baseColor: "#D58E48",
            borderColor: "#D58E48",
            fontColor: "#e3e3e3"
        },
        coverImage: false,
        usage: 0,
        introAnimations: [
            1
        ],
        introAnimationsConfig: {
            1: {
                optionalAnimation1: 'balloons',
                birdsColor: '#aaaaaa'
            }
        },
        introScreen: {
            templateId: 2,
            config: {
                fontColor: "#ffffff",
                envelopeColor: "#D58E48",
                image1: "/176/intro_screen_1.png",
                image2: "/176/intro_screen_2.png"
            }
        }
    },
    {
        id: 177,
        title: "Model 64",
        preview: "/images/inviteTemplates/177/preview.jpg",
        background: "/images/inviteTemplates/177/background.jpg",
        type: "Botez",
        otherTypes: [
            "Mot",
            "Turta"
        ],
        colors: {
            baseColor: "#DACF23",
            borderColor: "#DACF23",
            fontColor: "#171715"
        },
        coverImage: false,
        usage: 0,
        introAnimations: [
            1
        ],
        introAnimationsConfig: {
            1: {
                optionalAnimation1: 'balloons',
            }
        },
        introScreen: {
            templateId: 2,
            config: {
                fontColor: "#ffffff",
                envelopeColor: "#3699e4",
                image1: "/177/intro_screen_1.png",
                image2: "/177/intro_screen_2.png"
            }
        }
    },
    {
        id: 178,
        title: "Model 65",
        preview: "/images/inviteTemplates/178/preview.jpg",
        background: "/images/inviteTemplates/178/background.jpg",
        type: "Botez",
        otherTypes: [
            "Mot",
            "Turta"
        ],
        colors: {
            baseColor: "#E97B48",
            borderColor: "#E97B48",
            fontColor: "#171715"
        },
        coverImage: false,
        usage: 2,
        introAnimations: [
            1
        ],
        introAnimationsConfig: {
            1: {
                optionalAnimation1: 'balloons',
            }
        },
        introScreen: {
            templateId: 2,
            config: {
                fontColor: "#ffffff",
                envelopeColor: "#E97B48",
                image1: "/178/intro_screen_1.png",
                image2: "/178/intro_screen_2.png"
            }
        }
    },
    {
        id: 179,
        title: "Model 66",
        preview: "/images/inviteTemplates/179/preview.jpg",
        background: "/images/inviteTemplates/179/background.jpg",
        type: "Botez",
        otherTypes: [
            "Mot",
            "Turta"
        ],
        colors: {
            baseColor: "#FF6657",
            borderColor: "#FF6657",
            fontColor: "#171715"
        },
        coverImage: false,
        usage: 1,
        introAnimations: [
            1
        ],
        introAnimationsConfig: {
            1: {
                optionalAnimation1: 'balloons',
            }
        },
        introScreen: {
            templateId: 2,
            config: {
                fontColor: "#ffffff",
                envelopeColor: "#5ecdb4",
                image1: "/179/intro_screen_1.png",
                image2: "/179/intro_screen_2.png"
            }
        }
    },
    {
        id: 181,
        title: "Model 67",
        preview: "/images/inviteTemplates/181/preview.jpg",
        background: "/images/inviteTemplates/181/background.jpg",
        type: "Botez",
        otherTypes: [
            "Mot",
            "Turta"
        ],
        colors: {
            baseColor: "#F45867",
            borderColor: "#F45867",
            fontColor: "#171715"
        },
        coverImage: false,
        usage: 1,
        introAnimations: [
            1
        ],
        introAnimationsConfig: {
            1: {
                optionalAnimation1: 'balloons',
            }
        },
        introScreen: {
            templateId: 2,
            config: {
                fontColor: "#ffffff",
                envelopeColor: "#F45867",
                image1: "/181/intro_screen_1.png",
                image2: "/181/intro_screen_2.png"
            }
        }
    },
    {
        id: 182,
        title: "Model 68",
        preview: "/images/inviteTemplates/182/preview.jpg",
        background: "/images/inviteTemplates/182/background.jpg",
        type: "Botez",
        otherTypes: [
            "Mot",
            "Turta"
        ],
        colors: {
            baseColor: "#F45867",
            borderColor: "#F45867",
            fontColor: "#171715"
        },
        coverImage: false,
        usage: 2,
        introAnimations: [
            1
        ],
        introAnimationsConfig: {
            1: {
                optionalAnimation1: 'balloons',
            }
        },
        introScreen: {
            templateId: 2,
            config: {
                fontColor: "#ffffff",
                envelopeColor: "#c5bce7",
                image1: "/182/intro_screen_1.png",
                image2: "/182/intro_screen_2.png"
            }
        }
    },
    {
        id: 183,
        title: "Model 69",
        preview: "/images/inviteTemplates/183/preview.jpg",
        background: "/images/inviteTemplates/183/background.jpg",
        type: "Botez",
        otherTypes: [
            "Mot",
            "Turta"
        ],
        colors: {
            baseColor: "#FE6798",
            borderColor: "#FE6798",
            fontColor: "#171715"
        },
        coverImage: false,
        usage: 1,
        introAnimations: [
            1
        ],
        introAnimationsConfig: {
            1: {
                optionalAnimation1: 'balloons',
            }
        },
        introScreen: {
            templateId: 2,
            config: {
                fontColor: "#ffffff",
                envelopeColor: "#FE6798",
                image1: "/183/intro_screen_1.png",
                image2: "/183/intro_screen_2.png"
            }
        }
    },
    {
        id: 195,
        title: "Model 70",
        preview: "/images/inviteTemplates/195/preview.jpg",
        background: "/images/inviteTemplates/194/background.jpg",
        type: "Botez",
        otherTypes: [
            "Mot",
            "Turta"
        ],
        colors: {
            baseColor: "#0D6C81",
            borderColor: "#0D6C81",
            fontColor: "#171715"
        },
        coverImage: false,
        usage: 1,
        introAnimations: [
            1
        ],
        introAnimationsConfig: {
            1: {
                optionalAnimation1: 'balloons',
            }
        },
        introScreen: {
            templateId: 2,
            config: {
                fontColor: "#ffffff",
                envelopeColor: "#0D6C81",
                image1: "/194/background.jpg",
            }
        }
    },
    {
        id: 198,
        title: "Model 71",
        preview: "/images/inviteTemplates/198/preview.jpg",
        background: "/images/inviteTemplates/197/background.jpg",
        type: "Botez",
        otherTypes: [
            "Mot",
            "Turta"
        ],
        colors: {
            baseColor: "#4D8FFF",
            borderColor: "#4D8FFF",
            fontColor: "#171715"
        },
        coverImage: false,
        usage: 1,
        introAnimations: [
            1
        ],
        introAnimationsConfig: {
            1: {
                optionalAnimation1: 'balloons',
            }
        },
        introScreen: {
            templateId: 2,
            config: {
                fontColor: "#ffffff",
                envelopeColor: "#4D8FFF",
                image1: "/197/intro_screen_1.jpg",
            }
        }
    },
    {
        id: 6,
        title: "Model 1",
        preview: "/images/inviteTemplates/6/preview.jpg",
        background: "/images/inviteTemplates/6/background.jpg",
        type: "Petrecere",
        colors: {
            baseColor: "#CC8F78",
            borderColor: "#3a3a3a",
            fontColor: "#3a3a3a"
        },
        usage: 0,
        introAnimations: [
            1
        ],
        introAnimationsConfig: {
            1: {
                optionalAnimation1: 'autumn_leaves',
            }
        },
        staticBackground: true,
        introScreen: {
            templateId: 2,
            config: {
                fontColor: "#ffffff",
                envelopeColor: "#f4c0ae",
                image1: "/1/intro_screen_1.png"
            }
        }
    },
    {
        id: 12,
        title: "Model 2",
        preview: "/images/inviteTemplates/12/preview.jpg",
        background: "/images/inviteTemplates/12/background.jpg",
        type: "Petrecere",
        colors: {
            baseColor: "#262626",
            borderColor: "#262626",
            fontColor: "#262626"
        },
        usage: 18,
        introAnimations: [
            1
        ],
        introAnimationsConfig: {
            1: {
                optionalAnimation1: 'balloons',
            }
        },
        staticBackground: true,
        introScreen: {
            templateId: 3,
            config: {
                fontColor: "#d7a256",
                envelopeColor: "#262626",
                image1: "/12/background.jpg"
            }
        }
    },
    {
        id: 13,
        title: "Model 3",
        preview: "/images/inviteTemplates/13/preview.jpg",
        background: "/images/inviteTemplates/13/background.jpg",
        type: "Petrecere",
        colors: {
            baseColor: "#00BCE9",
            borderColor: "#00BCE9",
            fontColor: "#81005D"
        },
        usage: 1,
        introAnimations: [
            1
        ],
        introAnimationsConfig: {
            1: {
                optionalAnimation1: 'confetti',
            }
        },
        staticBackground: true,
        introScreen: {
            templateId: 3,
            config: {
                fontColor: "#ffffff",
                envelopeColor: "#81005D",
                image1: "/13/background.jpg"
            }
        }
    },
    {
        id: 14,
        title: "Model 4",
        preview: "/images/inviteTemplates/14/preview.jpg",
        background: "/images/inviteTemplates/14/background.jpg",
        type: "Petrecere",
        colors: {
            baseColor: "#dc9d2a",
            borderColor: "#dc9d2a",
            fontColor: "#f3e4ec"
        },
        usage: 11,
        introAnimations: [
            1
        ],
        introAnimationsConfig: {
            1: {
                optionalAnimation1: 'confetti',
            }
        },
        staticBackground: true,
        introScreen: {
            templateId: 3,
            config: {
                fontColor: "#f3e4ec",
                envelopeColor: "#dc9d2a",
                image1: "/14/background.jpg"
            }
        }
    },
    {
        id: 15,
        title: "Model 5",
        preview: "/images/inviteTemplates/15/preview.jpg",
        background: "/images/inviteTemplates/15/background.jpg",
        type: "Petrecere",
        colors: {
            baseColor: "#3f851c",
            borderColor: "#3f851c",
            fontColor: "#851c1c"
        },
        usage: 1,
        introAnimations: [
            1
        ],
        introAnimationsConfig: {
            1: {
                optionalAnimation1: 'confetti',
            }
        },
        introScreen: {
            templateId: 3,
            config: {
                fontColor: "#ffffff",
                envelopeColor: "#afcff7",
                image1: "/15/background.jpg"
            }
        }
    },
    {
        id: 29,
        title: "Model 6",
        preview: "/images/inviteTemplates/29/preview.jpg",
        background: "/images/inviteTemplates/29/background.jpg",
        type: "Petrecere",
        colors: {
            baseColor: "#476C4E",
            borderColor: "#476C4E",
            fontColor: "#0A0A0A"
        },
        usage: 5,
        introAnimations: [
            1
        ],
        introAnimationsConfig: {
            1: {
                optionalAnimation1: 'autumn_leaves',
                autumnLeavesColor: '#86986f'
            }
        },
        introScreen: {
            templateId: 2,
            config: {
                fontColor: "#ffffff",
                envelopeColor: "#476C4E",
                image1: "/27/intro_screen_1.png"
            }
        }
    },
    {
        id: 30,
        title: "Model 7",
        preview: "/images/inviteTemplates/30/preview.jpg",
        background: "/images/inviteTemplates/30/background.jpg",
        type: "Petrecere",
        colors: {
            baseColor: "#476C4E",
            borderColor: "#476C4E",
            fontColor: "#0A0A0A"
        },
        usage: 0,
        introAnimations: [
            1
        ],
        introAnimationsConfig: {
            1: {
                optionalAnimation1: 'autumn_leaves',
                autumnLeavesColor: '#86986f'
            }
        },
        introScreen: {
            templateId: 2,
            config: {
                fontColor: "#ffffff",
                envelopeColor: "#476C4E",
                image1: "/30/intro_screen_1.png",
                image2: "/30/intro_screen_2.png"
            }
        }
    },
    {
        id: 35,
        title: "Model 8",
        preview: "/images/inviteTemplates/35/preview.jpg",
        background: "/images/inviteTemplates/35/background.jpg",
        type: "Petrecere",
        colors: {
            baseColor: "#476C4E",
            borderColor: "#476C4E",
            fontColor: "#0A0A0A"
        },
        usage: 0,
        introAnimations: [
            1
        ],
        introAnimationsConfig: {
            1: {
                optionalAnimation1: 'autumn_leaves',
                autumnLeavesColor: '#86986f'
            }
        },
        introScreen: {
            templateId: 2,
            config: {
                fontColor: "#ffffff",
                envelopeColor: "#476C4E",
                image1: "/33/intro_screen_1.png",
                image2: "/33/intro_screen_2.png"
            }
        }
    },
    {
        id: 36,
        title: "Model 9",
        preview: "/images/inviteTemplates/36/preview.jpg",
        background: "/images/inviteTemplates/36/background.jpg",
        type: "Petrecere",
        colors: {
            baseColor: "#476C4E",
            borderColor: "#476C4E",
            fontColor: "#0A0A0A"
        },
        usage: 0,
        introAnimations: [
            1
        ],
        introAnimationsConfig: {
            1: {
                optionalAnimation1: 'autumn_leaves',
                autumnLeavesColor: '#86986f'
            }
        },
        introScreen: {
            templateId: 2,
            config: {
                fontColor: "#ffffff",
                envelopeColor: "#476C4E",
                image1: "/36/intro_screen_1.png",
                image2: "/36/intro_screen_2.png"
            }
        }
    },
    {
        id: 79,
        title: "Model 10",
        preview: "/images/inviteTemplates/79/preview.jpg",
        background: "/images/inviteTemplates/79/background.jpg",
        type: "Petrecere",
        colors: {
            baseColor: "#EC123B",
            borderColor: "#EC123B",
            fontColor: "#323e48"
        },
        usage: 6,
        introAnimations: [
            1
        ],
        introAnimationsConfig: {
            1: {
                optionalAnimation1: 'balloons',
                balloonsType: 'normal',
                balloonsColorsType: 'single_color',
                balloonsColor: '#EC123B',
            }
        },
        introScreen: {
            templateId: 2,
            config: {
                fontColor: "#ffffff",
                envelopeColor: "#EC123B",
                image1: "/78/intro_screen_1.png",
                image2: "/78/intro_screen_2.png"
            }
        }
    },
    {
        id: 116,
        title: "Model 11",
        preview: "/images/inviteTemplates/116/preview.jpg",
        background: "/images/inviteTemplates/41/background.jpg",
        type: "Petrecere",
        colors: {
            baseColor: "#d3b150",
            borderColor: "#d3b150",
            fontColor: "#0A0A0A"
        },
        usage: 1,
        introAnimations: [
            1
        ],
        introAnimationsConfig: {
            1: {
                optionalAnimation1: 'autumn_leaves',
                autumnLeavesColor: '#86986f'
            }
        },
        introScreen: {
            templateId: 2,
            config: {
                fontColor: "#ffffff",
                envelopeColor: "#87938f",
                image1: "/41/intro_screen_1.png",
                image2: "/41/intro_screen_2.png"
            }
        }
    },
    {
        id: 117,
        title: "Model 12",
        preview: "/images/inviteTemplates/117/preview.jpg",
        background: "/images/inviteTemplates/45/background.jpg",
        type: "Petrecere",
        colors: {
            baseColor: "#74BBAD",
            borderColor: "#74BBAD",
            fontColor: "#0B4F0F"
        },
        usage: 0,
        introAnimations: [
            1
        ],
        introAnimationsConfig: {
            1: {
                optionalAnimation1: 'autumn_leaves',
                autumnLeavesColor: '#86986f'
            }
        },
        introScreen: {
            templateId: 2,
            config: {
                fontColor: "#ffffff",
                envelopeColor: "#99c7a5",
                image1: "/45/intro_screen_1.png"
            }
        }
    },
    {
        id: 118,
        title: "Model 13",
        preview: "/images/inviteTemplates/118/preview.jpg",
        background: "/images/inviteTemplates/48/background.jpg",
        type: "Petrecere",
        colors: {
            baseColor: "#E7BE73",
            borderColor: "#E7BE73",
            fontColor: "#49423A"
        },
        usage: 0,
        introAnimations: [
            1
        ],
        introAnimationsConfig: {
            1: {
                optionalAnimation1: 'autumn_leaves',
            }
        },
        introScreen: {
            templateId: 2,
            config: {
                fontColor: "#ffffff",
                envelopeColor: "#E7BE73",
                image1: "/48/intro_screen_1.png"
            }
        }
    },
    {
        id: 119,
        title: "Model 14",
        preview: "/images/inviteTemplates/119/preview.jpg",
        background: "/images/inviteTemplates/50/background.jpg",
        type: "Petrecere",
        colors: {
            baseColor: "#EDE0D0",
            borderColor: "#EDE0D0",
            fontColor: "#FFFFFF"
        },
        usage: 1,
        introAnimations: [
            1
        ],
        introAnimationsConfig: {
            1: {
                birdsColor: '#aaaaaa',
            }
        },
        introScreen: {
            templateId: 3,
            config: {
                fontColor: "#464a55",
                envelopeColor: "#EDE0D0",
                image1: "/50/background.jpg"
            }
        }
    },
    {
        id: 120,
        title: "Model 15",
        preview: "/images/inviteTemplates/120/preview.jpg",
        background: "/images/inviteTemplates/53/background.jpg",
        type: "Petrecere",
        colors: {
            baseColor: "#804600",
            borderColor: "#5e3500",
            fontColor: "#524739"
        },
        usage: 0,
        introAnimations: [
            1
        ],
        introAnimationsConfig: {
            1: {
                optionalAnimation1: 'autumn_leaves',
            }
        },
        introScreen: {
            templateId: 2,
            config: {
                fontColor: "#ffffff",
                envelopeColor: "#804600",
                image1: "/53/intro_screen_1.png",
                image2: "/53/intro_screen_2.png"
            }
        }
    },
    {
        id: 121,
        title: "Model 16",
        preview: "/images/inviteTemplates/121/preview.jpg",
        background: "/images/inviteTemplates/58/background.jpg",
        type: "Petrecere",
        colors: {
            baseColor: "#5b5b5b",
            borderColor: "#5b5b5b",
            fontColor: "#6c94a9"
        },
        usage: 0,
        backgroundAnimation: "/images/inviteTemplates/58/background_animation.jpg",
        introAnimations: [
            1
        ],
        introAnimationsConfig: {
            1: {
                optionalAnimation1: 'balloons',
                balloonsType: 'normal',
                balloonsColorsType: 'multicolor',
                balloonsColor1: '#cad9e0',
                balloonsColor2: '#ffffff',
                balloonsColor3: '#6c94a8',
                balloonsColor4: '#909598',
                balloonsColor5: '#2f2e2e',
            }
        },
        introScreen: {
            templateId: 2,
            config: {
                fontColor: "#ffffff",
                envelopeColor: "#5b5b5b",
                image1: "/58/intro_screen_1.png"
            }
        }
    },
    {
        id: 122,
        title: "Model 17",
        preview: "/images/inviteTemplates/122/preview.jpg",
        background: "/images/inviteTemplates/59/background.jpg",
        type: "Petrecere",
        colors: {
            baseColor: "#D2A441",
            borderColor: "#D2A441",
            fontColor: "#6c94a9"
        },
        usage: 0,
        introAnimations: [
            1
        ],
        introAnimationsConfig: {
            1: {
                optionalAnimation1: 'autumn_leaves',
                autumnLeavesColor: '#86986f'
            }
        },
        introScreen: {
            templateId: 2,
            config: {
                fontColor: "#ffffff",
                envelopeColor: "#D8B879",
                image1: "/59/intro_screen_1.png",
                image2: "/59/intro_screen_2.png"
            }
        }
    },
    {
        id: 123,
        title: "Model 18",
        preview: "/images/inviteTemplates/123/preview.jpg",
        background: "/images/inviteTemplates/60/background.jpg",
        type: "Petrecere",
        colors: {
            baseColor: "#D2A441",
            borderColor: "#D2A441",
            fontColor: "#6c94a9"
        },
        usage: 3,
        introAnimations: [
            1
        ],
        introScreen: {
            templateId: 2,
            config: {
                fontColor: "#ffffff",
                envelopeColor: "#D2A441",
                image1: "/60/intro_screen_1.png"
            }
        }
    },
    {
        id: 124,
        title: "Model 19",
        preview: "/images/inviteTemplates/124/preview.jpg",
        background: "/images/inviteTemplates/61/background.jpg",
        type: "Petrecere",
        colors: {
            baseColor: "#CC95A3",
            borderColor: "#CC95A3",
            fontColor: "#3a3a3a"
        },
        usage: 0,
        introAnimations: [
            1
        ],
        introAnimationsConfig: {
            1: {
                optionalAnimation1: 'autumn_leaves',
                autumnLeavesColor: '#86986f'
            }
        },
        introScreen: {
            templateId: 2,
            config: {
                fontColor: "#ffffff",
                envelopeColor: "#CC95A3",
                image1: "/61/intro_screen_1.png",
                image2: "/61/intro_screen_2.png"
            }
        }
    },
    {
        id: 125,
        title: "Model 20",
        preview: "/images/inviteTemplates/125/preview.jpg",
        background: "/images/inviteTemplates/62/background.jpg",
        type: "Petrecere",
        colors: {
            baseColor: "#C796C5",
            borderColor: "#C796C5",
            fontColor: "#4a6373"
        },
        usage: 0,
        introAnimations: [
            1
        ],
        introAnimationsConfig: {
            1: {
                optionalAnimation1: 'balloons',
                balloonsType: 'normal',
                balloonsColorsType: 'multicolor',
                balloonsColor1: '#bce1f3',
                balloonsColor2: '#e1cdef',
                balloonsColor3: '#6c94a8',
                balloonsColor4: '#909598',
                balloonsColor5: '#2f2e2e',
            }
        },
        introScreen: {
            templateId: 2,
            config: {
                fontColor: "#ffffff",
                envelopeColor: "#d7a6d5",
                image1: "/62/intro_screen_1.png"
            }
        }
    },
    {
        id: 126,
        title: "Model 21",
        preview: "/images/inviteTemplates/126/preview.jpg",
        background: "/images/inviteTemplates/80/background.jpg",
        type: "Petrecere",
        colors: {
            baseColor: "#E76B87",
            borderColor: "#E76B87",
            fontColor: "#444346"
        },
        usage: 2,
        introAnimations: [
            1
        ],
        introAnimationsConfig: {
            1: {
                optionalAnimation1: 'autumn_leaves',
                autumnLeavesColor: '#86986f'
            }
        },
        introScreen: {
            templateId: 2,
            config: {
                fontColor: "#ffffff",
                envelopeColor: "#E76B87",
                image1: "/80/intro_screen_1.png",
                image2: "/80/intro_screen_2.png"
            }
        }
    },
    {
        id: 127,
        title: "Model 22",
        preview: "/images/inviteTemplates/127/preview.jpg",
        background: "/images/inviteTemplates/63/background.jpg",
        type: "Petrecere",
        colors: {
            baseColor: "#557651",
            borderColor: "#557651",
            fontColor: "#475E44"
        },
        usage: 1,
        introAnimations: [
            1
        ],
        introAnimationsConfig: {
            1: {
                optionalAnimation1: 'autumn_leaves',
                autumnLeavesColor: '#86986f'
            }
        },
        introScreen: {
            templateId: 2,
            config: {
                fontColor: "#ffffff",
                envelopeColor: "#557651",
                image1: "/63/intro_screen_1.png"
            }
        }
    },
    {
        id: 128,
        title: "Model 23",
        preview: "/images/inviteTemplates/128/preview.jpg",
        background: "/images/inviteTemplates/64/background.jpg",
        type: "Petrecere",
        colors: {
            baseColor: "#E76B87",
            borderColor: "#E76B87",
            fontColor: "#444346"
        },
        usage: 3,
        introAnimations: [
            1
        ],
        introAnimationsConfig: {
            1: {
                optionalAnimation1: 'balloons',
                balloonsType: 'normal',
                balloonsColorsType: 'single_color',
                balloonsColor: '#E76B87',
            }
        },
        introScreen: {
            templateId: 2,
            config: {
                fontColor: "#ffffff",
                envelopeColor: "#E76B87",
                image1: "/64/intro_screen_1.png",
                image2: "/64/intro_screen_2.png"
            }
        }
    },
    {
        id: 129,
        title: "Model 24",
        preview: "/images/inviteTemplates/129/preview.jpg",
        background: "/images/inviteTemplates/82/background.jpg",
        type: "Petrecere",
        colors: {
            baseColor: "#60AEA7",
            borderColor: "#60AEA7",
            fontColor: "#444346"
        },
        usage: 0,
        introAnimations: [
            1
        ],
        introScreen: {
            templateId: 2,
            config: {
                fontColor: "#ffffff",
                envelopeColor: "#60AEA7",
                image1: "/82/intro_screen_1.png"
            }
        }
    },
    {
        id: 130,
        title: "Model 25",
        preview: "/images/inviteTemplates/130/preview.jpg",
        background: "/images/inviteTemplates/84/background.jpg",
        type: "Petrecere",
        colors: {
            baseColor: "#fffcf8",
            borderColor: "#fffcf8",
            fontColor: "#fffcf8"
        },
        usage: 1,
        introAnimations: [
            1
        ],
        introAnimationsConfig: {
            1: {
                optionalAnimation1: 'autumn_leaves',
                birdsColor: '#aaaaaa',
            }
        },
        introScreen: {
            templateId: 2,
            config: {
                fontColor: "#2c3f3b",
                envelopeColor: "#fffcf8",
                image1: "/84/background.jpg"
            }
        }
    },
    {
        id: 131,
        title: "Model 26",
        preview: "/images/inviteTemplates/131/preview.jpg",
        background: "/images/inviteTemplates/92/background.jpg",
        type: "Petrecere",
        colors: {
            baseColor: "#CF9496",
            borderColor: "#CF9496",
            fontColor: "#f4f3ff"
        },
        usage: 0,
        introAnimations: [
            1
        ],
        introAnimationsConfig: {
            1: {
                birdsColor: '#aaaaaa',
            }
        },
        introScreen: {
            templateId: 2,
            config: {
                fontColor: "#282f39",
                envelopeColor: "#dcc6c8",
                image1: "/92/background.jpg"
            }
        }
    },
    {
        id: 132,
        title: "Model 27",
        preview: "/images/inviteTemplates/132/preview.jpg",
        background: "/images/inviteTemplates/93/background.jpg",
        type: "Petrecere",
        colors: {
            baseColor: "#CF9496",
            borderColor: "#CF9496",
            fontColor: "#444346"
        },
        usage: 0,
        introAnimations: [
            1
        ],
        introScreen: {
            templateId: 2,
            config: {
                fontColor: "#ffffff",
                envelopeColor: "#CF9496",
                image1: "/93/intro_screen_1.png",
                image2: "/93/intro_screen_2.png"
            }
        }
    },
    {
        id: 133,
        title: "Model 28",
        preview: "/images/inviteTemplates/133/preview.jpg",
        background: "/images/inviteTemplates/10/background.jpg",
        type: "Petrecere",
        colors: {
            baseColor: "#99BFB2",
            borderColor: "#3a3a3a",
            fontColor: "#3a3a3a"
        },
        usage: 1,
        introAnimations: [
            1
        ],
        introAnimationsConfig: {
            1: {
                optionalAnimation1: 'balloons',
                balloonsType: 'normal',
                balloonsColorsType: 'multicolor',
                balloonsColor1: '#e3e2d0',
                balloonsColor2: '#f2dcbd',
                balloonsColor3: '#e1a260',
                balloonsColor4: '#c7e7e2',
                balloonsColor5: '#d18e6b',
            }
        },
        introScreen: {
            templateId: 2,
            config: {
                fontColor: "#ffffff",
                envelopeColor: "#99BFB2",
                image1: "/10/intro_screen_1.png",
                image2: "/10/intro_screen_2.png"
            }
        }
    },
    {
        id: 134,
        title: "Model 29",
        preview: "/images/inviteTemplates/134/preview.jpg",
        background: "/images/inviteTemplates/26/background.jpg",
        type: "Petrecere",
        colors: {
            baseColor: "#FE4157",
            borderColor: "#FE4157",
            fontColor: "#3A3635"
        },
        usage: 5,
        introAnimations: [
            1
        ],
        introScreen: {
            templateId: 3,
            config: {
                fontColor: "#3A3635",
                envelopeColor: "#cde1f1",
                image1: "/26/background.jpg"
            }
        }
    },
    {
        id: 135,
        title: "Model 30",
        preview: "/images/inviteTemplates/135/preview.jpg",
        background: "/images/inviteTemplates/39/background.jpg",
        type: "Petrecere",
        colors: {
            baseColor: "#f57503",
            borderColor: "#f57503",
            fontColor: "#0A0A0A"
        },
        usage: 4,
        introAnimations: [
            1
        ],
        introAnimationsConfig: {
            1: {
                optionalAnimation1: 'balloons',
                balloonsType: 'normal',
                balloonsColorsType: 'single_color',
                balloonsColor: '#f57503',
            }
        },
        introScreen: {
            templateId: 2,
            config: {
                fontColor: "#ffffff",
                envelopeColor: "#f57503",
                image1: "/39/intro_screen_1.png",
                image2: "/39/intro_screen_2.png"
            }
        }
    },
    {
        id: 136,
        title: "Model 31",
        preview: "/images/inviteTemplates/136/preview.jpg",
        background: "/images/inviteTemplates/43/background.jpg",
        type: "Petrecere",
        colors: {
            baseColor: "#f57503",
            borderColor: "#f57503",
            fontColor: "#0A0A0A"
        },
        usage: 0,
        introAnimations: [
            1
        ],
        introAnimationsConfig: {
            1: {
                optionalAnimation1: 'autumn_leaves',
                autumnLeavesColor: '#86986f'
            }
        },
        introScreen: {
            templateId: 2,
            config: {
                fontColor: "#ffffff",
                envelopeColor: "#f57503",
                image1: "/43/intro_screen_1.png",
                image2: "/43/intro_screen_2.png"
            }
        }
    },
    {
        id: 137,
        title: "Model 32",
        preview: "/images/inviteTemplates/137/preview.jpg",
        background: "/images/inviteTemplates/44/background.jpg",
        type: "Petrecere",
        colors: {
            baseColor: "#f57503",
            borderColor: "#f57503",
            fontColor: "#0A0A0A"
        },
        usage: 2,
        introAnimations: [
            1
        ],
        introAnimationsConfig: {
            1: {
                optionalAnimation1: 'balloons',
                balloonsType: 'normal',
                balloonsColorsType: 'single_color',
                balloonsColor: '#f57503',
            }
        },
        introScreen: {
            templateId: 2,
            config: {
                fontColor: "#ffffff",
                envelopeColor: "#f57503",
                image1: "/44/intro_screen_1.png",
                image2: "/44/intro_screen_2.png"
            }
        }
    },
    {
        id: 138,
        title: "Model 33",
        preview: "/images/inviteTemplates/138/preview.jpg",
        background: "/images/inviteTemplates/65/background.jpg",
        type: "Petrecere",
        colors: {
            baseColor: "#D6C1BE",
            borderColor: "#D6C1BE",
            fontColor: "#493C3A"
        },
        usage: 0,
        introAnimations: [
            1
        ],
        introAnimationsConfig: {
            1: {
                optionalAnimation1: 'autumn_leaves',
                autumnLeavesColor: '#86986f'
            }
        },
        introScreen: {
            templateId: 2,
            config: {
                fontColor: "#ffffff",
                envelopeColor: "#80ab9f",
                image1: "/65/intro_screen_1.png"
            }
        }
    },
    {
        id: 139,
        title: "Model 34",
        preview: "/images/inviteTemplates/139/preview.jpg",
        background: "/images/inviteTemplates/66/background.jpg",
        type: "Petrecere",
        colors: {
            baseColor: "#DBA32B",
            borderColor: "#DBA32B",
            fontColor: "#493C3A"
        },
        usage: 0,
        introAnimations: [
            1
        ],
        introAnimationsConfig: {
            1: {
                optionalAnimation1: 'autumn_leaves',
                autumnLeavesColor: '#86986f'
            }
        },
        introScreen: {
            templateId: 2,
            config: {
                fontColor: "#ffffff",
                envelopeColor: "#DBA32B",
                image1: "/66/intro_screen_1.png",
                image2: "/66/intro_screen_2.png"
            }
        }
    },
    {
        id: 140,
        title: "Model 35",
        preview: "/images/inviteTemplates/140/preview.jpg",
        background: "/images/inviteTemplates/67/background.jpg",
        type: "Petrecere",
        colors: {
            baseColor: "#993D38",
            borderColor: "#993D38",
            fontColor: "#493C3A"
        },
        usage: 1,
        introAnimations: [
            1
        ],
        introAnimationsConfig: {
            1: {
                optionalAnimation1: 'balloons',
                balloonsType: 'normal',
                balloonsColorsType: 'single_color',
                balloonsColor: '#eaccc7',
            }
        },
        introScreen: {
            templateId: 2,
            config: {
                fontColor: "#ffffff",
                envelopeColor: "#993D38",
                image1: "/67/intro_screen_1.png",
                image2: "/67/intro_screen_2.png"
            }
        }
    },
    {
        id: 141,
        title: "Model 36",
        preview: "/images/inviteTemplates/141/preview.jpg",
        background: "/images/inviteTemplates/68/background.jpg",
        type: "Petrecere",
        colors: {
            baseColor: "#A3CCAE",
            borderColor: "#A3CCAE",
            fontColor: "#493C3A"
        },
        usage: 0,
        introAnimations: [
            1
        ],
        introAnimationsConfig: {
            1: {
                optionalAnimation1: 'confetti',
            }
        },
        introScreen: {
            templateId: 2,
            config: {
                fontColor: "#ffffff",
                envelopeColor: "#A3CCAE",
                image1: "/68/intro_screen_1.png",
                image2: "/68/intro_screen_2.png"
            }
        }
    },
    {
        id: 142,
        title: "Model 37",
        preview: "/images/inviteTemplates/142/preview.jpg",
        background: "/images/inviteTemplates/70/background.jpg",
        type: "Petrecere",
        colors: {
            baseColor: "#A3CCAE",
            borderColor: "#A3CCAE",
            fontColor: "#493C3A"
        },
        usage: 2,
        introAnimations: [
            1
        ],
        introAnimationsConfig: {
            1: {
                optionalAnimation1: 'balloons',
                balloonsType: 'normal',
                balloonsColorsType: 'multicolor',
                balloonsColor1: '#fcd99d',
                balloonsColor2: '#e48b91',
                balloonsColor3: '#dff5ef',
                balloonsColor4: '#fde5e1',
                balloonsColor5: '#b2e0d5',
            }
        },
        introScreen: {
            templateId: 2,
            config: {
                fontColor: "#ffffff",
                envelopeColor: "#A3CCAE",
                image1: "/70/intro_screen_1.png",
                image2: "/70/intro_screen_2.png"
            }
        }
    },
    {
        id: 143,
        title: "Model 38",
        preview: "/images/inviteTemplates/143/preview.jpg",
        background: "/images/inviteTemplates/71/background.jpg",
        type: "Petrecere",
        colors: {
            baseColor: "#B31F28",
            borderColor: "#B31F28",
            fontColor: "#2f2625"
        },
        usage: 1,
        introAnimations: [
            1
        ],
        introAnimationsConfig: {
            1: {
                optionalAnimation1: 'balloons',
                balloonsType: 'normal',
                balloonsColorsType: 'multicolor',
                balloonsColor1: '#e4bc89',
                balloonsColor2: '#da7982',
                balloonsColor3: '#b47de5',
                balloonsColor4: '#dcd1ee',
                balloonsColor5: '#ecd6d0',
            }
        },
        introScreen: {
            templateId: 2,
            config: {
                fontColor: "#ffffff",
                envelopeColor: "#B31F28",
                image1: "/71/intro_screen_1.png",
                image2: "/71/intro_screen_2.png"
            }
        }
    },
    {
        id: 144,
        title: "Model 39",
        preview: "/images/inviteTemplates/144/preview.jpg",
        background: "/images/inviteTemplates/72/background.jpg",
        type: "Petrecere",
        colors: {
            baseColor: "#D0AC67",
            borderColor: "#D0AC67",
            fontColor: "#2f2625"
        },
        usage: 0,
        introAnimations: [
            1
        ],
        introAnimationsConfig: {
            1: {
                optionalAnimation1: 'balloons',
                balloonsType: 'normal',
                balloonsColorsType: 'single_color',
                balloonsColor: '#f0cf8f',
            }
        },
        introScreen: {
            templateId: 2,
            config: {
                fontColor: "#ffffff",
                envelopeColor: "#D0AC67",
                image1: "/72/intro_screen_1.png",
                image2: "/72/intro_screen_2.png"
            }
        }
    },
    {
        id: 145,
        title: "Model 40",
        preview: "/images/inviteTemplates/145/preview.jpg",
        background: "/images/inviteTemplates/73/background.jpg",
        type: "Petrecere",
        colors: {
            baseColor: "#FFC626",
            borderColor: "#FFC626",
            fontColor: "#2f2625"
        },
        usage: 0,
        introAnimations: [
            1
        ],
        introAnimationsConfig: {
            1: {
                optionalAnimation1: 'balloons',
                balloonsType: 'normal',
                balloonsColorsType: 'single_color',
                balloonsColor: '#FFC626',
            }
        },
        introScreen: {
            templateId: 2,
            config: {
                fontColor: "#ffffff",
                envelopeColor: "#FFC626",
                image1: "/73/intro_screen_1.png",
                image2: "/73/intro_screen_2.png"
            }
        }
    },
    {
        id: 146,
        title: "Model 41",
        preview: "/images/inviteTemplates/146/preview.jpg",
        background: "/images/inviteTemplates/74/background.jpg",
        type: "Petrecere",
        colors: {
            baseColor: "#782344",
            borderColor: "#782344",
            fontColor: "#2f2625"
        },
        usage: 0,
        introAnimations: [
            1
        ],
        introAnimationsConfig: {
            1: {
                optionalAnimation1: 'autumn_leaves',
            }
        },
        introScreen: {
            templateId: 2,
            config: {
                fontColor: "#ffffff",
                envelopeColor: "#782344",
                image1: "/74/intro_screen_1.png",
                image2: "/74/intro_screen_2.png"
            }
        }
    },
    {
        id: 147,
        title: "Model 42",
        preview: "/images/inviteTemplates/147/preview.jpg",
        background: "/images/inviteTemplates/76/background.jpg",
        type: "Petrecere",
        colors: {
            baseColor: "#87D1E6",
            borderColor: "#87D1E6",
            fontColor: "#323e48"
        },
        usage: 1,
        introAnimations: [
            1
        ],
        introAnimationsConfig: {
            1: {
                optionalAnimation1: 'balloons',
                balloonsType: 'normal',
                balloonsColorsType: 'single_color',
                balloonsColor: '#87D1E6',
            }
        },
        introScreen: {
            templateId: 2,
            config: {
                fontColor: "#ffffff",
                envelopeColor: "#87D1E6",
                image1: "/76/intro_screen_1.png"
            }
        }
    },
    {
        id: 148,
        title: "Model 43",
        preview: "/images/inviteTemplates/148/preview.jpg",
        background: "/images/inviteTemplates/77/background.jpg",
        type: "Petrecere",
        colors: {
            baseColor: "#C4450E",
            borderColor: "#C4450E",
            fontColor: "#323e48"
        },
        usage: 0,
        introAnimations: [
            1
        ],
        introAnimationsConfig: {
            1: {
                optionalAnimation1: 'balloons',
                balloonsType: 'normal',
                balloonsColorsType: 'single_color',
                balloonsColor: '#C4450E',
            }
        },
        introScreen: {
            templateId: 2,
            config: {
                fontColor: "#ffffff",
                envelopeColor: "#C4450E",
                image1: "/77/intro_screen_1.png",
                image2: "/77/intro_screen_2.png"
            }
        }
    },
    {
        id: 155,
        title: "Model 44",
        preview: "/images/inviteTemplates/155/preview.jpg",
        background: "/images/inviteTemplates/89/background.png",
        type: "Petrecere",
        colors: {
            baseColor: "#D8B879",
            borderColor: "#D8B879",
            fontColor: "#444346"
        },
        coverImage: true,
        coverImageDefault: "/images/inviteTemplates/155/cover.jpg",
        usage: 6,
        backgroundAnimation: "/images/inviteTemplates/89/background_animation.jpg",
        introAnimations: [
            1
        ],
        introScreen: {
            templateId: 2,
            config: {
                fontColor: "#ffffff",
                envelopeColor: "#D8B879",
                image1: "/89/intro_screen_1.png"
            }
        }
    },
    {
        id: 156,
        title: "Model 45",
        preview: "/images/inviteTemplates/156/preview.jpg",
        background: "/images/inviteTemplates/90/background.png",
        type: "Petrecere",
        colors: {
            baseColor: "#D8B879",
            borderColor: "#D8B879",
            fontColor: "#444346"
        },
        coverImage: true,
        coverImageDefault: "/images/inviteTemplates/155/cover.jpg",
        usage: 0,
        backgroundAnimation: "/images/inviteTemplates/90/background_animation.jpg",
        introAnimations: [
            1
        ],
        introAnimationsConfig: {
            1: {
                optionalAnimation1: 'autumn_leaves',
                autumnLeavesColor: '#86986f'
            }
        },
        introScreen: {
            templateId: 2,
            config: {
                fontColor: "#ffffff",
                envelopeColor: "#D8B879",
                image1: "/56/intro_screen_1.png",
                image2: "/56/intro_screen_2.png"
            }
        }
    },
    {
        id: 157,
        title: "Model 46",
        preview: "/images/inviteTemplates/157/preview.jpg",
        background: "/images/inviteTemplates/91/background.png",
        type: "Petrecere",
        colors: {
            baseColor: "#D8B879",
            borderColor: "#D8B879",
            fontColor: "#444346"
        },
        coverImage: true,
        coverImageDefault: "/images/inviteTemplates/155/cover.jpg",
        usage: 3,
        backgroundAnimation: "/images/inviteTemplates/91/background_animation.jpg",
        introAnimations: [
            1
        ],
        introAnimationsConfig: {
            1: {
                optionalAnimation1: 'autumn_leaves',
                autumnLeavesColor: '#86986f'
            }
        },
        introScreen: {
            templateId: 2,
            config: {
                fontColor: "#ffffff",
                envelopeColor: "#D8B879",
                image1: "/59/intro_screen_1.png",
                image2: "/59/intro_screen_2.png"
            }
        }
    },
    {
        id: 184,
        title: "Model 47",
        preview: "/images/inviteTemplates/184/preview.jpg",
        background: "/images/inviteTemplates/181/background.jpg",
        type: "Petrecere",
        colors: {
            baseColor: "#F45867",
            borderColor: "#F45867",
            fontColor: "#171715"
        },
        coverImage: false,
        usage: 0,
        introAnimations: [
            1
        ],
        introAnimationsConfig: {
            1: {
                optionalAnimation1: 'balloons',
            }
        },
        introScreen: {
            templateId: 2,
            config: {
                fontColor: "#ffffff",
                envelopeColor: "#F45867",
                image1: "/181/intro_screen_1.png",
                image2: "/181/intro_screen_2.png"
            }
        }
    },
    {
        id: 185,
        title: "Model 48",
        preview: "/images/inviteTemplates/185/preview.jpg",
        background: "/images/inviteTemplates/182/background.jpg",
        type: "Petrecere",
        colors: {
            baseColor: "#F45867",
            borderColor: "#F45867",
            fontColor: "#171715"
        },
        coverImage: false,
        usage: 1,
        introAnimations: [
            1
        ],
        introAnimationsConfig: {
            1: {
                optionalAnimation1: 'balloons',
            }
        },
        introScreen: {
            templateId: 2,
            config: {
                fontColor: "#ffffff",
                envelopeColor: "#c5bce7",
                image1: "/182/intro_screen_1.png",
                image2: "/182/intro_screen_2.png"
            }
        }
    },
    {
        id: 186,
        title: "Model 49",
        preview: "/images/inviteTemplates/186/preview.jpg",
        background: "/images/inviteTemplates/183/background.jpg",
        type: "Petrecere",
        colors: {
            baseColor: "#FE6798",
            borderColor: "#FE6798",
            fontColor: "#171715"
        },
        coverImage: false,
        usage: 0,
        introAnimations: [
            1
        ],
        introAnimationsConfig: {
            1: {
                optionalAnimation1: 'balloons',
            }
        },
        introScreen: {
            templateId: 2,
            config: {
                fontColor: "#ffffff",
                envelopeColor: "#FE6798",
                image1: "/183/intro_screen_1.png",
                image2: "/183/intro_screen_2.png"
            }
        }
    },
    {
        id: 196,
        title: "Model 50",
        preview: "/images/inviteTemplates/196/preview.jpg",
        background: "/images/inviteTemplates/194/background.jpg",
        type: "Petrecere",
        colors: {
            baseColor: "#0D6C81",
            borderColor: "#0D6C81",
            fontColor: "#171715"
        },
        coverImage: false,
        usage: 0,
        introAnimations: [
            1
        ],
        introAnimationsConfig: {
            1: {
                optionalAnimation1: 'balloons',
            }
        },
        introScreen: {
            templateId: 2,
            config: {
                fontColor: "#ffffff",
                envelopeColor: "#0D6C81",
                image1: "/194/background.jpg"
            }
        }
    },
    {
        id: 199,
        title: "Model 51",
        preview: "/images/inviteTemplates/199/preview.jpg",
        background: "/images/inviteTemplates/197/background.jpg",
        type: "Petrecere",
        colors: {
            baseColor: "#4D8FFF",
            borderColor: "#4D8FFF",
            fontColor: "#171715"
        },
        coverImage: false,
        usage: 0,
        introAnimations: [
            1
        ],
        introAnimationsConfig: {
            1: {
                optionalAnimation1: 'balloons',
            }
        },
        introScreen: {
            templateId: 2,
            config: {
                fontColor: "#ffffff",
                envelopeColor: "#4D8FFF",
                image1: "/197/intro_screen_1.jpg"
            }
        }
    },
    {
        id: 187,
        title: "Model 1",
        preview: "/images/inviteTemplates/187/preview.jpg",
        background: "/images/inviteTemplates/187/background.jpg",
        type: "corporate",
        otherTypes: [
            "conferinta",
            "corporate_party",
            "team_building",
            "congress"
        ],
        colors: {
            baseColor: "#FFFFFF",
            borderColor: "#FFFFFF",
            fontColor: "#FFFFFF"
        },
        fonts: {
            general: 'style-font-signika',
            groomNameStyles: 'style-font-signika'
        },
        usage: 0,
        introAnimations: [
            1
        ],
        introAnimationsConfig: {
            1: {
                optionalAnimation1: 'fireworks'
            }
        },
        introScreen: {
            templateId: 2,
            config: {
                fontColor: "#2D87FF",
                envelopeColor: "#f5f5f5",
                image1: "/187/background.jpg",
                groomNameStyles: {fontWeight: 'style-font-weight-600'}
            }
        }
    },
    {
        id: 188,
        title: "Model 2",
        preview: "/images/inviteTemplates/188/preview.jpg",
        background: "/images/inviteTemplates/188/background.jpg",
        type: "corporate",
        otherTypes: [
            "conferinta",
            "corporate_party",
            "team_building",
            "congress"
        ],
        colors: {
            baseColor: "#FECB1E",
            borderColor: "#FECB1E",
            fontColor: "#FFFFFF"
        },
        fonts: {
            general: 'style-font-signika',
            groomNameStyles: 'style-font-signika'
        },
        usage: 0,
        introAnimations: [
            1
        ],
        introAnimationsConfig: {
            1: {
                optionalAnimation1: 'fireworks'
            }
        },
        config: {
            groomNameStyles: {color:'#FECB1E'}
        },
        introScreen: {
            templateId: 2,
            config: {
                fontColor: "#181818",
                envelopeColor: "#f5f5f5",
                image1: "/188/background.jpg",
                groomNameStyles: {fontWeight: 'style-font-weight-600',color:'#FECB1E'}
            }
        }
    },
    {
        id: 189,
        title: "Model 3",
        preview: "/images/inviteTemplates/189/preview.jpg",
        background: "/images/inviteTemplates/189/background.jpg",
        type: "corporate",
        otherTypes: [
            "conferinta",
            "corporate_party",
            "team_building",
            "congress"
        ],
        colors: {
            baseColor: "#9b75d7",
            borderColor: "#9b75d7",
            fontColor: "#444346"
        },
        fonts: {
            general: 'style-font-signika',
            groomNameStyles: 'style-font-signika'
        },
        usage: 0,
        introAnimations: [
            1
        ],
        introAnimationsConfig: {
            1: {
                optionalAnimation1: 'fireworks'
            }
        },
        config: {
            groomNameStyles: {color:'#9b75d7'}
        },
        introScreen: {
            templateId: 2,
            config: {
                fontColor: "#f5f5f5",
                envelopeColor: "#9b75d7",
                image1: "/189/background.jpg",
                groomNameStyles: {fontWeight: 'style-font-weight-600',color:'#9b75d7'}
            }
        }
    },
    {
        id: 190,
        title: "Model 4",
        preview: "/images/inviteTemplates/190/preview.jpg",
        background: "/images/inviteTemplates/190/background.jpg",
        type: "corporate",
        otherTypes: [
            "conferinta",
            "corporate_party",
            "team_building",
            "congress"
        ],
        colors: {
            baseColor: "#0083C8",
            borderColor: "#0083C8",
            fontColor: "#f5f5f5"
        },
        fonts: {
            general: 'style-font-signika',
            groomNameStyles: 'style-font-signika'
        },
        usage: 0,
        introAnimations: [
            1
        ],
        introAnimationsConfig: {
            1: {
                optionalAnimation1: 'fireworks'
            }
        },
        config: {
            groomNameStyles: {color:'#E8EB62'}
        },
        introScreen: {
            templateId: 2,
            config: {
                fontColor: "#0E292B",
                envelopeColor: "#E8EB62",
                image1: "/190/background.jpg",
                groomNameStyles: {fontWeight: 'style-font-weight-600',color:'#E8EB62'}
            }
        }
    },
    {
        id: 191,
        title: "Model Crăciun 1",
        preview: "/images/inviteTemplates/191/preview.jpg",
        background: "/images/inviteTemplates/191/background.jpg",
        type: "corporate",
        otherTypes: [
            "conferinta",
            "corporate_party",
            "team_building",
            "congress"
        ],
        colors: {
            baseColor: "#DCC572",
            borderColor: "#DCC572",
            fontColor: "#f5f5f5"
        },
        fonts: {
            general: 'style-font-signika',
            groomNameStyles: 'style-font-signika'
        },
        usage: 0,
        introAnimations: [
            1
        ],
        introAnimationsConfig: {
            1: {
                optionalAnimation1: 'fireworks'
            }
        },
        config: {
            groomNameStyles: {color:'#DCC572'}
        },
        introScreen: {
            templateId: 2,
            config: {
                fontColor: "#1F1F1D",
                envelopeColor: "#DCC572",
                image1: "/191/background.jpg",
                groomNameStyles: {fontWeight: 'style-font-weight-600',color:'#DCC572'}
            }
        }
    },
    {
        id: 192,
        title: "Model Crăciun 2",
        preview: "/images/inviteTemplates/192/preview.jpg",
        background: "/images/inviteTemplates/192/background.jpg",
        type: "corporate",
        otherTypes: [
            "conferinta",
            "corporate_party",
            "team_building",
            "congress"
        ],
        colors: {
            baseColor: "#B10A1D",
            borderColor: "#B10A1D",
            fontColor: "#483F37"
        },
        fonts: {
            general: 'style-font-signika',
            groomNameStyles: 'style-font-signika'
        },
        usage: 0,
        introAnimations: [
            1
        ],
        introAnimationsConfig: {
            1: {
                optionalAnimation1: 'fireworks'
            }
        },
        config: {
            groomNameStyles: {color:'#B10A1D'}
        },
        introScreen: {
            templateId: 2,
            config: {
                fontColor: "#F7EDE1",
                envelopeColor: "#B10A1D",
                image1: "/192/background.jpg",
                groomNameStyles: {fontWeight: 'style-font-weight-600',color:'#B10A1D'}
            }
        }
    },
    {
        id: 193,
        title: "Model Crăciun 3",
        preview: "/images/inviteTemplates/193/preview.jpg",
        background: "/images/inviteTemplates/193/background.jpg",
        type: "corporate",
        otherTypes: [
            "conferinta",
            "corporate_party",
            "team_building",
            "congress"
        ],
        colors: {
            baseColor: "#E1B76C",
            borderColor: "#E1B76C",
            fontColor: "#534C3D"
        },
        fonts: {
            general: 'style-font-signika',
            groomNameStyles: 'style-font-signika'
        },
        usage: 0,
        introAnimations: [
            1
        ],
        introAnimationsConfig: {
            1: {
                optionalAnimation1: 'fireworks'
            }
        },
        config: {
            groomNameStyles: {color:'#E1B76C'}
        },
        introScreen: {
            templateId: 2,
            config: {
                fontColor: "#FEFAEE",
                envelopeColor: "#534C3D",
                image1: "/193/background.jpg",
                groomNameStyles: {fontWeight: 'style-font-weight-600',color:'#E1B76C'}
            }
        }
    },
    {
        id: 1000,
        title: "Model custom template",
        preview: "/images/inviteTemplates/1000/preview.jpg",
        background: "/images/inviteTemplates/1000/background.jpg",
        type: "Nunta",
        colors: {
            baseColor: "#CC8F78",
            borderColor: "#3a3a3a",
            fontColor: "#3a3a3a"
        },
        usage: 39,
        introAnimations: [
            1
        ],
        introScreen: {
            templateId: 1,
            config: {
                fontColor: "#ffffff",
                envelopeColor: "#3a3a3a"
            }
        }
    },
    {
        id: 1001,
        title: "Model custom template",
        preview: "/images/inviteTemplates/1001/preview.jpg",
        background: "/images/inviteTemplates/1001/background.jpg",
        type: "Botez",
        otherTypes: [
            "Mot",
            "Turta"
        ],
        colors: {
            baseColor: "#CC8F78",
            borderColor: "#3a3a3a",
            fontColor: "#3a3a3a"
        },
        usage: 27,
        introAnimations: [
            1
        ],
        introScreen: {
            templateId: 1,
            config: {
                fontColor: "#ffffff",
                envelopeColor: "#3a3a3a"
            }
        }
    },
    {
        id: 1002,
        title: "Model custom template",
        preview: "/images/inviteTemplates/1002/preview.jpg",
        background: "/images/inviteTemplates/1002/background.jpg",
        type: "Petrecere",
        colors: {
            baseColor: "#CC8F78",
            borderColor: "#3a3a3a",
            fontColor: "#3a3a3a"
        },
        usage: 11,
        introAnimations: [
            1
        ],
        introScreen: {
            templateId: 1,
            config: {
                fontColor: "#ffffff",
                envelopeColor: "#3a3a3a"
            }
        }
    },
    {
        id: 1003,
        title: "Model custom template",
        preview: "/images/inviteTemplates/1002/preview.jpg",
        background: "/images/inviteTemplates/1002/background.jpg",
        type: "corporate",
        otherTypes: [
            "conferinta",
            "corporate_party",
            "team_building",
            "congress"
        ],
        colors: {
            baseColor: "#0083C8",
            borderColor: "#3a3a3a",
            fontColor: "#3a3a3a"
        },
        fonts: {
            general: 'style-font-signika',
            groomNameStyles: 'style-font-signika'
        },
        usage: 0,
        introAnimations: [
            1
        ],
        introAnimationsConfig: {
            1: {
                optionalAnimation1: 'fireworks'
            }
        },
        config: {

        },
        introScreen: {
            templateId: 2,
            config: {
                fontColor: "#ffffff",
                envelopeColor: "#3a3a3a",
                groomNameStyles: {fontWeight: 'style-font-weight-600'}
            }
        }
    }
]

export const getTemplateSettingsById = (id) => {


    return inviteTemplates.find((el) => {
        return el.id === id
    })

}

/*
    mode:
        0 - default render
        1 - preview (for tabs)
        2 - preview for edit
 */
export function renderTemplate(templateDetails, mode = 0) {

    // eslint-disable-next-line
    switch (templateDetails.id) {
        case 1:
            return template1Content(templateDetails, mode)
    }
}
