import React, {useState, useEffect} from 'react';
import './PaymentConfirm.scss'
import { useHistory } from "react-router-dom";
import HeaderWhite from "../../../components/HeaderWhite";
import axios from "axios";
import {toast} from "react-toastify";
import {ConfirmButton} from "../../components/Helper";
import Loader from "../../components/Loader";


export default function PaymentConfirm() {

    const params = new URLSearchParams(window.location.search) // id=123
    let orderId = params.get('orderId') // 123

    let history = useHistory();

    const [loading, setLoading] = useState(true)
    const [transactionStatus, setTransactionStatus] = useState(false)

    const [refreshLoading, setRefreshLoading] = useState(false)


    useEffect(() => {

        getTransaction()

        // eslint-disable-next-line
    }, [])

    const getTransaction = async () => {

        if(refreshLoading) {
            return
        }

        setRefreshLoading(true)

        let auth = {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('jwt')
            }
        }

        await axios.get(process.env.REACT_APP_DOMAIN + 'transactions/getStatus/' + orderId , auth)
            .then(response => {
                setTransactionStatus(response.data)
                setLoading(false)
                setRefreshLoading(false)
            })
            .catch(err => {
                setLoading(false)
                setRefreshLoading(false)
                toast.error("Eroare")
                console.log(err)
            })


    }


    return( <div className="PaymentConfirm-container">
        <HeaderWhite shadow={true} goBack={() => {
            history.push("/organizer/viewEvents")
        }} />

        <div className={"PaymentConfirm-contents"}>

            {
                loading ?
                    <div>Se încarcă...</div>
                    :
                    <>
                        {
                            transactionStatus === 'confirmed' ?
                                <>
                                    <h1 style={{color:'green'}}>Tranzactie confirmata!</h1>
                                </>

                                :
                                (transactionStatus === 'confirmed_pending' || transactionStatus === 'paid_pending' || transactionStatus === 'paid') ?
                                    <>
                                        <h1 style={{color:'green',marginBottom:'30px'}}>Tranzactie in procesare!</h1>
                                        Veti primi un email de confirmare cand tranzactia va fi aprobata!</>
                                    :
                                    transactionStatus === 'credit' ?
                                        <>
                                            <h1 style={{color:'green',marginBottom:'30px'}}>Tranzactie in creditata!</h1>
                                        </>
                                        :
                                        transactionStatus === 'initiated' ?
                                            <>
                                                <h1 style={{color:'orange',marginBottom:'15px'}}>Așteptăm confirmarea plății!</h1>
                                                <p style={{marginBottom:'30px'}}>Răspunsul din partea băncii poate întârzia câteodata. Veți primi confirmarea plății pe adresa dumneavoastră de email!</p>
                                                <ConfirmButton
                                                    loading={refreshLoading}
                                                    function={() => getTransaction()}
                                                    text={'Reîncearcă'}
                                                    />
                                            </>
                                            :
                                    <>
                                        <h1 style={{color:'red'}}>Tranzacție anulată!</h1>
                                    </>
                        }


                    </>
            }



        </div>

    </div>)
}

