import React, {useContext, useEffect} from 'react'
import './HeaderWhite.scss'
import logo from '../assets/logo.png'
import { Icon } from 'rsuite'
import SVG_Walkthrough_Arrow from "../assets/icons/walkthrough_arrow.svg";
import LanguageContext from "../contexts/LanguageContext";
import axios from "axios";
import moment from "moment-timezone";
import {useHistory} from "react-router-dom";


function HeaderWhite ({shadow = true, goBack = undefined, overlayBack = false}) {
	let history = useHistory();
	const {language, setLanguage, unreadNotifications, setUnreadNotifications} = useContext(LanguageContext)
	const intervalSeconds = 10


	useEffect(() => {
		let jwt = localStorage.getItem('jwt')

		if(typeof goBack !== "undefined") {
			localStorage.removeItem('first_launch')
		}


		if(jwt) {
			getUnseenNotification()

			let interval = setInterval(() => {
				getUnseenNotification()
			}, intervalSeconds * 1000)

			return () => {
				clearInterval(interval)
			}
		}


		// eslint-disable-next-line
	}, []);

	const getUnseenNotification = async () => {
		let jwt = localStorage.getItem('jwt')


		if(jwt) {

			let notificationsCheckDate = localStorage.getItem('notifications_check_date')
			if(notificationsCheckDate && notificationsCheckDate.length > 6) {
				//Compare dates
				let duration = moment.duration(moment().diff(moment(notificationsCheckDate)))
				if(duration.asSeconds() < intervalSeconds) {
					return
				}
			}

			localStorage.setItem('notifications_check_date', moment().toISOString())

			let auth = {
				headers: {
					'Authorization': 'Bearer ' + localStorage.getItem('jwt')
				}
			}

			axios.get(process.env.REACT_APP_DOMAIN + 'notifications/unseen_notifications_count', auth)
				.then(response => {
					if(response && response.data && response.data.status === 1) {
						if(unreadNotifications !== response.data.unseen_notifications_count) {
							setUnreadNotifications(response.data.unseen_notifications_count)
						}

						if(process.env.REACT_APP_DOMAIN == 'https://admin.ality.ro/') {
							if (response.data.frontend_version && response.data.frontend_version !== 'unknown') {
								const shouldForceRefresh = semverGreaterThan(response.data.frontend_version, global.appVersion);
								if (shouldForceRefresh) {
									if (caches) {
										// Service worker cache should be cleared with caches.delete()
										caches.keys().then(function (names) {
											for (let name of names) caches.delete(name);
										});
									}
									// delete browser cache and hard reload
									window.location.reload(true);
								} else {

								}
							}
						}
					}
				})
		}
	}

	const semverGreaterThan = (versionA, versionB) => {

		versionA = parseInt(versionA.replaceAll('.',''))
		versionB = parseInt(versionB.replaceAll('.',''))

		return versionA > versionB
	}

	return (<div style={!shadow ? {boxShadow: "none" } : {}} className="HeaderWhite-container">
		<div className="HeaderWhite-contents">
			<div className="with-back">

				{
					overlayBack ?
						<>

							{goBack !== undefined &&
								<Icon onClick={goBack} icon="angle-left" className={"walkthrough-over-icon"} style={{ position: "absolute", left: "50px", cursor:"pointer", }} />}

							<div className={"walkthrough-over-arrow"}>
								<img  alt={""} src={SVG_Walkthrough_Arrow}/>
							</div>

						</>
						:<>
							{goBack !== undefined &&
								<Icon onClick={goBack} icon="angle-left" style={{ position: "absolute", left: "50px", cursor:"pointer", }} />}

						</>
				}




				<img src={logo} style={{ height: "50px" }} alt="logo" />


				<div className={"notifications-cont"}>
					<div className={"notifications-cont-inner"} onClick={() => history.push('/notifications')}>
					<img src={"/images/icons/bell.svg"} style={{width:'25px'}} alt={"notifications"}/>
						{unreadNotifications > 0 ?
							<div className={"notification-unread-count"}>{unreadNotifications}</div>
						:null}
					</div>
				</div>

				{goBack !== undefined && <div></div>}
				{
					language === 'ro' ?
						<img  alt={""} src={"/images/languages/en.png"} onClick={() => setLanguage('en')} style={{cursor:"pointer", position: "absolute", marginTop:"8px", right: "30px", width:"30px" }}/>
						:
						<img  alt={""} src={"/images/languages/ro.png"} onClick={() => setLanguage('ro')} style={{cursor:"pointer", position: "absolute", marginTop:"8px", right: "30px", width:"30px" }}/>
				}

			</div>
		</div>
	</div>)

}

export default HeaderWhite;
