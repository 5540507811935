import React from 'react'
import {Dropdown, Icon, Toggle} from 'rsuite'
import {toast} from 'react-toastify'
import axios from 'axios'
import _ from "lodash";

import './Participant.scss'
import {ConfirmButton, CustomQuestions} from '../../components/Helper'
import Loader from "../../components/Loader";
import {openLink} from "../../components/InviteTemplatesFunctions";
import {fixWebsiteUrl, getReadableWebsite} from "../../functions";
import {FormattedMessage} from "react-intl";
import {injectIntl} from 'react-intl';
import moment from "moment-timezone";
class Participant extends React.Component {
    state = {
        loading: true,
        id_participant: -1,
        isPaid: false,
        clase_serii: this.props.event_data.clase_serii === null ? [] : this.props.event_data.clase_serii.split("\n"),

        nume: "",
        prenume: "",
        seria: "",
        inviteStatus: "",

        hasCustomQuestions: false,
        answers: [],
        questions: [],

        shareProfileDetails: this.props.participantData.share_profile_details !== false,

        showSaveButton: false
    }

    componentDidMount = async () => {
        let hasQuestions = this.props.event_data.activate_questions && this.props.event_data.published_questions

        this.setState({
            hasCustomQuestions: hasQuestions
        })

        let answers = []
        let questions = []

        let participant = this.props.participantData

        if (hasQuestions) {
            if (participant.questions_answers) {
                answers = [...participant.questions_answers]
            } else {
                answers = []
            }
            questions = _.cloneDeep(this.props.event_data.questions)

            for (let i = 0; i < questions.length; i++) {
                let newOptions = []

                if (questions[i].type === 1 || questions[i].type === 2)
                    questions[i].options.map(element => {
                        newOptions.push({label: element, value: element})

                        return null
                    })

                questions[i].options = newOptions
            }

            if (answers !== null && answers.length < questions.length) {
                for (let i = 0; i < questions.length - answers.length; i++) {
                    answers.push("")
                }
            }
        }

        this.setState({
            nume: participant.nume,
            prenume: participant.prenume,
            seria: participant.clasa_serie === null ? "" : participant.clasa_serie,
            inviteStatus: participant.inviteStatus,

            loading: false,
            id_participant: participant.id,
            isPaid: participant.is_paid,

            answers: answers,
            questions: questions
        })
    }

    setter = (key, value) => {
        this.setState({
            [key]: value,
            showSaveButton: true
        })
    }

    //check trees
    checkTreeSelect = (chosenOptions, key, value) => {
        let current = [...chosenOptions];
        let myValue = value.value;

        if (myValue === 'Ambele') {
            if (current.length === 2) {
                current = [];
            } else {
                current = ['Ceremonie', 'Bal'];
            }
        } else {
            if (current.indexOf(myValue) !== -1) {
                current = current.filter(e => e !== myValue)
            } else {
                current.push(myValue);
            }
        }

        this.setState({
            [key]: current,
            showSaveButton: true
        });
    }

    checkData = () => {
        //check data
        if (this.state.nume === "") {
            toast.error(<FormattedMessage id={"event_page.participant_tab.error_fill_last_name"} />)
            return;
        }

        if (this.state.prenume === "") {
            toast.error(<FormattedMessage id={"event_page.participant_tab.error_fill_first_name"} />)
            return;
        }

        if (this.state.clase_serii.length > 0 && this.state.seria === "") {
            toast.error(<FormattedMessage id={"event_page.participant_tab.error_fill_serie"} />)
            return;
        }

        //send new data
        this.sendData()
    }

    sendData = async () => {
        let obj = {
            nume: this.state.nume,
            prenume: this.state.prenume,
            clasa_serie: this.state.seria,
            questions_answers: JSON.stringify(this.state.answers),
            inviteStatus: this.state.inviteStatus
        }

        let auth = {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('jwt')
            }
        }

        let response = await axios.put(process.env.REACT_APP_DOMAIN + "participants/updateMyParticipant/" + this.state.id_participant, obj, auth)

        if (response !== null) {
            toast.success(<FormattedMessage id={"general.alerts.changes_saved"} />)

            this.props.participantData.nume = obj.nume
            this.props.participantData.prenume = obj.prenume
            this.props.participantData.clasa_serie = obj.clasa_serie
            this.props.participantData.questions_answers = this.state.answers
            this.props.participantData.inviteStatus = obj.inviteStatus

            return;
        } else {
            toast.error(<FormattedMessage id={"general.alerts.error_occurred"} />)
        }
    }

    toggleShareProfileDetails = async (value) => {
        let obj = {
            share_profile_details: value
        }

        let auth = {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('jwt')
            }
        }

        let response = await axios.put(process.env.REACT_APP_DOMAIN + "participants/updateMyParticipant/" + this.state.id_participant, obj, auth)

        if (response !== null) {
            toast.success(<FormattedMessage id={"general.alerts.changes_saved"} />)
        }
    }

    changeAnswers = (newAnswers) => {
        this.setState({
            answers: newAnswers,
            showSaveButton: true,
        })
    }

    getInfoRow = (icon, name, text) => {
        return <div className="Row">
            <Icon icon={icon} size="2x" style={{margin: "0px 10px", color: "#06aff2"}}/>

            <div className="Data">
                <div>{name}</div>

                <p style={{color: "#121213", fontSize: "16px"}}>{text}</p>
            </div>
        </div>
    }

    getOptionRow = (icon, text, func, color) => {
        return <div className="Row" onClick={func}>
            <Icon icon={icon} size="2x" style={{margin: "0px 10px", color: color}}/>

            <div className="Data">
                {text}
            </div>
        </div>
    }

    render() {
        const SeparationLine = ({color}) => (
            <hr style={{color: color, backgroundColor: color, height: 5}}/>
        );

        if (this.state.loading === true) {
            return <div className="Participant-container">
                <Loader/>
            </div>
        }

        let customStyle = {
            backgroundColor: "#E5F7FE",
            borderRadius: "10px"
        }

        return <div className="Participant-container">

            <div>

                <div className="user-section">
                    <div style={{borderBottom: '1px solid #e0e0e0', paddingBottom: "15px"}}>

                        <div className={"avatar-cont"}>
                            {
                                this.props.user_data.image ?
                                    <div className={"image-preview"}>
                                        <img  alt={""}
                                            src={'https://' + process.env.REACT_APP_IMAGE_DOMAIN + this.props.user_data.image.url}
                                            style={{width: '100%'}}/>
                                    </div>
                                    :
                                    <div className={"image-preview"}>
                                        <img  alt={""} src={"/images/avatar_placeholder.jpg"} style={{width: '100%'}}/>
                                    </div>
                            }
                        </div>


                        <div className={"details-cont"}>
                        <div className={"details-item"}>
                            {this.getInfoRow("user-info", <FormattedMessage id={"general.last_name"} />, this.props.user_data.last_name)}
                        </div>
                        <div className={"details-item"}>
                            {this.getInfoRow("user-info", <FormattedMessage id={"general.first_name"} />, this.props.user_data.first_name)}
                        </div>


                        <div className={"details-item"}>
                            {this.getInfoRow("at", <FormattedMessage id={"general.email_account"} />, this.props.user_data.email)}
                        </div>
                        <div className={"details-item"}>
                            {this.getInfoRow("phone", <FormattedMessage id={"general.phone"} />, this.props.user_data.phone ? this.props.user_data.phone : '-')}
                        </div>


                        <div className={"details-item"}>
                            {this.getInfoRow("at", <FormattedMessage id={"general.email_work"} />, this.props.user_data.second_email ? this.props.user_data.second_email : '-')}
                        </div>
                        <div className={"details-item"}>
                            {this.getInfoRow("building", <FormattedMessage id={"general.company_or_institution"} />, this.props.user_data.company ? this.props.user_data.company : '-')}
                        </div>


                        <div className={"details-item"}>
                            {this.getInfoRow("certificate", <FormattedMessage id={"general.function_or_title"} />, this.props.user_data.function ? this.props.user_data.function : '-')}
                        </div>

                        <div className={"details-item"}>
                                    <span onClick={() => {
                                        if (this.props.user_data.website) {
                                            openLink(fixWebsiteUrl(this.props.user_data.website))
                                        }
                                    }}>
                                    {this.getInfoRow("internet-explorer", <FormattedMessage id={"general.website"} />, this.props.user_data.website ? <>
                                        <Icon icon={'link'}
                                              style={{color: "#06aff2"}}/> {getReadableWebsite(this.props.user_data.website)}</> : '-')}
                                    </span>
                        </div>

                        <div className={"details-item"}>
                                    <span onClick={() => {
                                        if (this.props.user_data.linkedin) {
                                            openLink('https://www.linkedin.com/in/' + this.props.user_data.linkedin)
                                        }
                                    }}>
                                    {this.getInfoRow("linkedin", <FormattedMessage id={"general.linkedin"} />, this.props.user_data.linkedin ? <><Icon
                                        icon={'link'}
                                        style={{color: "#06aff2"}}/> {this.props.user_data.linkedin}</> : '-')}
                                    </span>
                        </div>


                        <div className={"details-item"}>
                                    <span onClick={() => {
                                        if (this.props.user_data.instagram) {
                                            openLink('https://www.instagram.com/' + this.props.user_data.instagram)
                                        }
                                    }}>
                                    {this.getInfoRow("instagram", <FormattedMessage id={"general.instagram"} />, this.props.user_data.instagram ? <><Icon
                                        icon={'link'}
                                        style={{color: "#06aff2"}}/> {this.props.user_data.instagram}</> : '-')}
                                    </span>
                        </div>
                        <div className={"details-item"}>
                                    <span onClick={() => {
                                        if (this.props.user_data.facebook) {
                                            openLink('https://www.facebook.com/' + this.props.user_data.facebook)
                                        }
                                    }}>
                                    {this.getInfoRow("facebook-official", <FormattedMessage id={"general.facebook"} />, this.props.user_data.facebook ? <>
                                        <Icon icon={'link'}
                                              style={{color: "#06aff2"}}/> {this.props.user_data.facebook}</> : '-')}
                                    </span>
                        </div>

                            <div className={"details-item"}>
                                    <span>
                                    {this.getInfoRow("user", <FormattedMessage id={"general.id_participant"} />, this.state.id_participant ? <>
                                         {this.state.id_participant}</> : '-')}
                                    </span>
                            </div>
                        </div>


                    </div>


                    {this.getOptionRow("frame", <FormattedMessage id={"event_page.participant_tab.edit_profile"} />, () => {
                        this.props.history.push('/editProfile?redirectBackTo=/eventPage/' + this.props.event_data.id_eveniment + '/info-participant')
                    }, "#06aff2")}

                    <div className="Row" style={{display: 'flex', flexDirection: 'row', paddingLeft: "10px"}}>
                        <Toggle
                            style={{margin: "10px 0px", textAlign: 'center', maxWidth: "500px", alignSelf: 'center'}}
                            checked={this.state.shareProfileDetails}
                            size="lg"
                            checkedChildren="Da"
                            unCheckedChildren="Nu"
                            onChange={(value) => {
                                this.toggleShareProfileDetails(value)
                                this.setState({
                                    shareProfileDetails: value,
                                })
                            }}/>

                        <div className={"label"} style={{marginLeft: '10px'}}>
                            <FormattedMessage id={"event_page.participant_tab.share_profile"} />
                        </div>
                    </div>

                </div>
            </div>

            {this.state.clase_serii.length === 0
                ? <div></div>//<CustomInput value={this.state.seria} onChange={this.setClass} placeholder={'Clasa/Grupa'} />
                : <div className="grupa_serie">
                    <Dropdown style={customStyle} title={this.state.seria === "" ? <FormattedMessage id={"event_page.participant_tab.grupa_serie"} /> : this.state.seria}
                              activeKey={this.state.seria} className="dropdown_scroll">
                        {this.state.clase_serii.map((grupa, index) => {
                            return <Dropdown.Item key={index} eventKey={grupa} onClick={() => this.setState({
                                seria: grupa,
                                showSaveButton: true
                            })}>{grupa}</Dropdown.Item>
                        })}
                    </Dropdown>
                </div>
            }

            {
                this.props.event_data.tip_eveniment === 'conferinta' || this.props.event_data.tip_eveniment === 'team_building' || this.props.event_data.tip_eveniment === 'corporate_party' ?
                    <Dropdown style={customStyle}
                              disabled={
                                  (this.props.event_data.invites_deadline && moment(this.props.event_data.invites_deadline) <= moment()) ||
                                  (this.state.inviteStatus !== 1 && this.state.inviteStatus !== 3 && this.state.inviteStatus !== 0 && this.state.inviteStatus !== null)}
                              title={this.state.inviteStatus === 1 ? <FormattedMessage id={"event_page.participant_tab.invite.participate"} /> :
                                  this.state.inviteStatus === 3 ? <FormattedMessage id={"event_page.participant_tab.invite.no_participate"} /> :
                                      this.state.inviteStatus === null || this.state.inviteStatus === 0  ? <FormattedMessage id={"event_page.participant_tab.invite.please_select_an_answer"} />
                                      : <FormattedMessage id={"event_page.participant_tab.invite.waiting_for_admin"} />
                              }
                              activeKey={this.state.inviteStatus} className="dropdown_scroll">
                        <Dropdown.Item key={1} eventKey={1} onClick={() => this.setState({
                            inviteStatus: 1,
                            showSaveButton: true
                        })}><FormattedMessage id={"event_page.participant_tab.invite.participate"} /></Dropdown.Item>
                        <Dropdown.Item key={2} eventKey={2}
                                       onClick={() => this.setState({inviteStatus: 3, showSaveButton: true})}><FormattedMessage id={"event_page.participant_tab.invite.no_participate"} /></Dropdown.Item>
                    </Dropdown>
                    : null
            }

            {
                this.props.event_data.show_tables_numbers && this.props.participantData.table_nr && this.props.participantData.table_nr > 0 ?
                    <div style={{marginTop: '10px'}}><strong><FormattedMessage id={"event_page.participant_tab.invite.seating_at_table"} /></strong> {this.props.participantData.table_nr}</div>
                    : null
            }

            {this.state.hasCustomQuestions &&
                <CustomQuestions questions={this.state.questions} answers={this.state.answers}
                                 disabled={this.props.event_data.id === 97 ||
                                     (this.props.event_data.invites_deadline && moment(this.props.event_data.invites_deadline) <= moment())}
                                 changeAnswers={this.changeAnswers} intl={this.props.intl}/>}

            <SeparationLine color="black"/>

            {this.props.event_data.disable_questions_edit
                ? <div className="bottom-message">
                    {/*
                    <div className="title"><Icon icon="check-circle" style={{color: "green", marginRight: "10px"}}/>
                        <FormattedMessage id={"event_page.participant_tab.ticket_bought"} />
                    </div>*/}

                    <div>
                        <FormattedMessage id={"event_page.participant_tab.no_changes_allowed"} /> <span
                        style={{color: "#05AFF2"}} onClick={this.props.goToContactUs}>Contact Us</span>.
                    </div>
                </div>
                : <div>
                    <ConfirmButton text={<FormattedMessage id={"general.save"} />} function={this.checkData}/>
                </div>
            }
        </div>
    }
}

export default injectIntl(Participant);
