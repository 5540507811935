export const GOOGLE_LOGIN_CLIENT_ID = "424120024822-jk69vlv2td0drdf6irr6d22op9uab807.apps.googleusercontent.com"

export const currencies = [
    {
        'value': 'EUR',
        'title': 'EURO'
    },
    {
        'value': 'RON',
        'title': 'RON'
    },
    {
        'value': 'USD',
        'title': 'USD'
    },
    {
        'value': 'GBP',
        'title': 'GBP'
    }
]

export const corporateWeddingEvents = [
    'conferinta',
    'team_building',
    'corporate_party',
    'congress'
]
