import React from 'react'
import SettingCard from '../Profile/components/SettingCard'
import './GuestInfoPage.scss'

import HeaderWhite from '../../../components/HeaderWhite'
import NavbarColor from '../../../components/NavbarColor'
import { Button } from 'rsuite'
import axios from 'axios'
import { toast } from 'react-toastify'

import SVG_Hostess from '../../assets/GuestInfoSVG/hostess-icon.svg'
import SVG_Seat_Participants from '../../assets/GuestInfoSVG/seat-participants-icon.svg'
import SVG_Gift_Money from '../../assets/GuestInfoSVG/cadou-dar-icon.svg'
import SVG_Rasp_Save from '../../assets/GuestInfoSVG/rasp-save.svg'
import SVG_Check_Presence from '../../assets/GuestInfoSVG/verifica-prezenta.svg'

import Loader from "../../components/Loader";
import {getWeddingParticipantsPackageNumber, isCorporateWeddingEvent} from "../../../functions";
import WalkthroughOverlay from "../../../components/walkthrough/WalkthroughtOverlay";
import WalkthroughOverlayBack from "../../../components/walkthrough/WalkthroughOverlayBack";

let options = {
	saveTheDateResponses: "saveTheDateResponses",
	inviteResponses: "inviteResponses",
	seatParticipants: "seatParticipants",
	hostess: "hostess",
	checkPresence: "checkPresence",
	giftMoney: "giftMoney"
}

class GuestInfoPage extends React.Component {
	state = {
		eventType: "",
		hasNewResponses: false,
		hasNewSaveTheDateResponses: false,
		showOptionsFor: "",
		eventData: {},
		showWalkthrough: false,
		showWalkthroughBack: false,

		loading: true
	}

	componentDidMount = async () => {
		let auth = {
			headers: {
				'Authorization': 'Bearer ' + localStorage.getItem('jwt')
			}
		}

		await axios.get(process.env.REACT_APP_DOMAIN + 'weddings/getThisWedding/' + this.props.match.params.id, auth)
			.then(async response => {
				let hasNewResponses = false
				let hasNewSaveTheDateResponses = false

				let showWalkthrough = !response.data.tutorials || (response.data.tutorials && !response.data.tutorials.walkthrough)

				let inviteResponses = await axios.get(process.env.REACT_APP_DOMAIN + 'responses/list/byEvent/' + this.props.match.params.id, auth)
				if (inviteResponses.data.totalResponses > response.data.count_seen_invite_responses) {
					hasNewResponses = true
				}

				if (response.data.eventType === "Nunta") {
					let saveThedateResponses = await axios.get(process.env.REACT_APP_DOMAIN + 'response-save-the-dates/list/byEvent/' + this.props.match.params.id, auth)

					if (saveThedateResponses.data.totalResponses > response.data.count_seen_save_the_date_responses) {
						hasNewSaveTheDateResponses = true
					}
				}

				this.setState({
					eventData: response.data,
					eventType: response.data.eventType,
					hasNewResponses: hasNewResponses,
					hasNewSaveTheDateResponses: hasNewSaveTheDateResponses,
					showWalkthrough: showWalkthrough,
					loading: false
				})
			})
			.catch(err => {
				console.log(err);
				toast.error("A aparut o eroare, va rog reincercati")
				this.props.history.push("/organizer/viewEvents")
			})
	}

	goToResponesSaveTheDate = () => {
		this.props.history.push('/organizer/raspunsuriSaveTheDate/' + this.props.match.params.id)
	}

	goToResponsesInvite = () => {
		this.props.history.push('/organizer/raspunsuriInvitati/' + this.props.match.params.id)
	}

	goToChooseHostess = () => {
		if(getWeddingParticipantsPackageNumber(this.state.eventData) !== 2 && getWeddingParticipantsPackageNumber(this.state.eventData) !== 4) {
			this.props.history.push('/organizer/noPremiumPackage/' + this.props.match.params.id)
			return;
		}

		this.props.history.push('/organizer/hostessChoose/' + this.props.match.params.id)
	}

	goToInviteHostess = () => {
		if(getWeddingParticipantsPackageNumber(this.state.eventData) !== 2 && getWeddingParticipantsPackageNumber(this.state.eventData) !== 4) {
			this.props.history.push('/organizer/noPremiumPackage/' + this.props.match.params.id)
			return;
		}

		this.props.history.push('/organizer/hostessInvites/' + this.props.match.params.id)
	}

	goToCheckPresence = () => {
		if(getWeddingParticipantsPackageNumber(this.state.eventData) !== 2 && getWeddingParticipantsPackageNumber(this.state.eventData) !== 4) {
			this.props.history.push('/organizer/noPremiumPackage/' + this.props.match.params.id)
			return;
		}


		this.props.history.push('/organizer/checkPresence/' + this.props.match.params.id)
	}

	goToGiftMoney = () => {
		if(getWeddingParticipantsPackageNumber(this.state.eventData) !== 2 && getWeddingParticipantsPackageNumber(this.state.eventData) !== 4) {
			this.props.history.push('/organizer/noPremiumPackage/' + this.props.match.params.id)
			return;
		}
		this.props.history.push('/organizer/dar/' + this.props.match.params.id)
	}

	goToSeatParticipants = () => {
		this.props.history.push('/organizer/seatParticipants/' + this.props.match.params.id)
	}

	goBack = () => {
		this.props.history.push("/organizer/adminEventPage/" + this.props.match.params.id)
	}

	render() {
		if (this.state.loading) {
			return <div className="GuestInfoPage-container">
				<HeaderWhite style={{ width: "100%" }} goBack={this.goBack} />

				<Loader/>

				<NavbarColor history={this.props.history} />
			</div>
		}

		return (
			<div className="GuestInfoPage-container">
				<HeaderWhite style={{ width: "100%" }} goBack={this.goBack} />

				<div className="Title">Informații participanți</div>

				<div className="GuestInfoPage-contents">
					{this.state.eventType === "Nunta" && <SettingCard svg={SVG_Rasp_Save}  hasRedDot={this.state.hasNewSaveTheDateResponses}
																	  onClick={this.goToResponesSaveTheDate} name="Răspunsuri save the date" />}

					<SettingCard svg={SVG_Rasp_Save} hasRedDot={this.state.hasNewResponses}
																	  onClick={this.goToResponsesInvite} name="Răspunsuri invitație" />


					<SettingCard svg={SVG_Seat_Participants}
								 onClick={this.goToSeatParticipants} name="Aranjează invitații la mese" />


					<SettingCard svg={SVG_Hostess}
								 onClick={() => this.setState({showOptionsFor: options.hostess})} name="Alege hostess" dropDown={this.state.showOptionsFor === options.hostess}  />

					{this.state.showOptionsFor === options.hostess && <div>
						<SettingCard icon="" onClick={this.goToInviteHostess} name="Invita hostess" isSmall={true} />
						<SettingCard icon="" onClick={this.goToChooseHostess} name="Alege hostess din participanți" isSmall={true} />
					</div>}

					<SettingCard svg={SVG_Check_Presence}
								 onClick={this.goToCheckPresence} name="Verifică prezența" />


					{this.state.eventType !== "Petrecere" && !isCorporateWeddingEvent ?
						<SettingCard svg={SVG_Gift_Money}
								 onClick={this.goToGiftMoney} name="Dar" />
					:null}

					<div>
						<Button style={{ margin: "20px 20px 20px 0px", color: "#1E1C59", border: "1px solid #1E1C59", borderRadius: "80px", width: "200px" }} onClick={this.goBack}>
							Înapoi
						</Button>
					</div>
				</div>

				{
					this.state.showWalkthrough ?
						<WalkthroughOverlay id_eveniment={this.props.match.params.id} history={this.props.history}>
							<p>În acest meniu vei găsi centralizat toate răspunsurile primite de la invitați și îi poți aranja la mese în funcție de preferințele acestora</p>

							<div className={"next-button"} onClick={() => this.setState({
								showWalkthroughBack:true,
								showWalkthrough: false
							})}>Mai departe</div>
						</WalkthroughOverlay>
						:null
				}

				{
					this.state.showWalkthroughBack ?
						<WalkthroughOverlayBack
							action={() => this.props.history.push('/organizer/adminEventPage/' + this.props.match.params.id + '?walkthroughStep=7')}
						/>
						:null
				}

				<NavbarColor history={this.props.history} />
			</div>
		)
	}
}

export default GuestInfoPage;
