import React from 'react'
import Modal from 'react-responsive-modal'
import { Divider, Button } from 'rsuite'

class terms extends React.Component {
	render() {
		return (
			<Modal classNames={{
				overlay: 'modal-overlay',
				modal: 'terms-modal ' + (this.props.pb ? 'pb' : ''),
				closeButton: 'modal-close-button',
			}} focusTrapped={false} open={this.props.showModal} onClose={this.props.toggleModal} center>

				<h5>Termeni si Conditii Ality</h5>
				<p> Ultima modificare: 30 August 2024 </p>
				<Divider />

				<h3> 1. Preambul </h3>
				<p>1.1 Site-ul Ality (www.Ality.ro) și aplicația Ality pentru smartphone-uri, denumite în continuare „Site-ul”/”Aplicația” (folosirea oricăreia dintre aceste denumiri se referă și implică atât site-ul cât și aplicația Ality), sunt deținute și administrate de Digitality Smart Creative Solutions S.R.L. având următoarele date de identificare: Cod Unic de Înregistrare 45671871, înregistrată la Registrul Comerțului cu nr. J40/3147/2022  și cu sediul social în Municipiul Bucuresti, Sect. 3, Aleea Blajel, Nr. 9B, Ap. 34.</p>
				<br /><br />
				<p>1.2 Înainte de a utiliza site-ul www.Ality.ro și serviciile pe care le oferim, este necesar să luați cunoștință de prevederile prezenților termeni și condiții și să le respectați.</p>
				<br /><br />
				<p>1.3 Navigarea site-ului echivalează cu citirea, înțelegerea și acceptarea completă a termenilor de mai jos de către orice Utilizator. Dacă nu ești de acord cu orice aspect al prezenților termeni și condiții atunci nu puteți accesa Aplicația.</p>
				<br /><br />
				<p>1.4 Beneficierea de serviciile noastre prin intermediul site-ului se va putea face doar dacă sunteți de acord cu termenii și condițiile descrise mai jos.</p>
				<br /><br />
				<p>1.5 Digitality Smart Creative Solutions S.R.L. are dreptul de a efectua orice modificări ale site-ului, termenilor și condițiilor, fără a fi necesară vreo notificare prealabilă față de vizitatorii site-ului.</p>
				<br /><br />
				<p>1.6 Digitality Smart Creative Solutions S.R.L. își rezervă dreptul de a ceda/autoriza accesul/folosirea site-ului de către alți operatori (Terți autorizați) în vederea oferirea serviciilor către Utilizatori de către Terții autorizați. În acest  caz, cedarea/autorizarea accesului/folosirii site-ului de către Terții autorizați, denumirea și datele lor de contact vor fi anunțate prin intermediul site-ului. În funcție de cedarea sau autorizarea parțială / integrală, Terții autorizați vor fi cei care își asumă oferirea serviciilor către Utilizatori, raportul juridic născându-se între Utilizator și respectivul Terț autorizat, caz în care Digitality Smart Creative Solutions S.R.L. va avea doar rolul de a oferi în beneficiul Terțului autorizat  servicii de mentenanță pentru asigurarea funcționării site-ului.</p>
				<br /><br />
				<p>1.7 Orice solicitare cu privire la înțelesul și aplicarea prezenților termeni și condiții va fi adresată către adresa de e-mail: ...</p>

				<br /><br />
				<h3> 2.Definiții </h3>
				<div> 2.1 Digitality Smart Creative Solutions S.R.L.  stabilește că termenii enumerați mai jos vor avea următorul sens:
					<ul>
						<li> Furnizor - Digitality Smart Creative Solutions S.R.L., societate comercială cu sediul în Municipiul Bucuresti, Sect. 3, Aleea Blajel, Nr. 9B, Ap. 34, înregistrată la Registrul Comerțului cu nr. J40/3147/2022  și CUI 45671871.</li>
						<li> Terț autorizat - orice persoană autorizată de Furnizor să controleze și să aibă acces la site și să ofere Servicii în favoarea Utilizatorilor.</li>
						<li> Vizitator - persoană care accesează acest site.</li>
						<li> Utilizator - persoană fizică care vizitează acest site și care a acceptat Termenii și Condițiile de mai jos, îndeplinind în acest sens toate cerințele procesului de înregistrare.</li>
						<li> Conținut - toate programele software, fișierele, datele, imaginile, muzica, grafica sau orice alt conținut al www.Ality.ro sau asociat cu site-ul sau brand-ul Ality.</li>
						<li> Serviciu - acordarea Utilizatorului a accesului la funcționalitățile/datele transmise de Digitality Smart Creative Solutions S.R.L. sau terțul autorizat cu ajutorul acestui site.</li>
						<li>  Username (nume de utilizator) - pseudonim prin care Utilizatorul poate adăuga Conținut pe Site. Username-ul este asociat informațiilor din Site sub denumirea de “Nume Utilizator”.</li>
						<li> Contract - contract de utilizare prin care  Digitality Smart Creative Solutions S.R.L., sau Terțul autorizat pune la dispoziție utilizatorului dreptul neexclusiv de a folosi conținutul site-ului  www.Ality.ro.</li>
						<li> Cont Utilizator - secțiunea din Site formată dintr-o adresă de e-mail/număr de telefon și o parolă care permite Utilizatorului încărcarea unei fotografii de tip selfie și care oferă acces la fotografii cu imaginea Utilizatorului realizate în cadrul unor evenimente la care a participat Utilizatorul și organizate de Terțul autorizat.</li>
						<li> Site/Aplicație - site-ul internet  www.Ality.ro și/sau aplicația Ality pentru smartphone-uri.</li>
					</ul>
				</div>

				<br /><br />
				<h3> 3. Obligațiile părților </h3>
				<h5>3.1 Obligațiile  Digitality Smart Creative Solutions S.R.L. </h5>
				<p>3.1.1 În limita legislației în vigoare, a prezentului document și relației contractuale cu Terții autorizați,  Digitality Smart Creative Solutions S.R.L. se obligă să ofere suport și informații legate de serviciile oferite pe acest Site.</p>
				<br /><br />
				<h5>3.2 Obligațiile Utilizatorului</h5>
				<p>3.2.1 Utilizatorul se obligă să respecte prezentele condiții și termeni de mai jos, legislația existentă în România și să acționeze cu bună-credință în relația cu Digitality Smart Creative Solutions S.R.L.</p>
				<br /><br />
				<p>3.2.2 Utilizatorul are obligația de a aduce la cunoștința furnizorului prin intermediul adresei puse la dispoziție la art. 1.6  orice situație care afectează modul în care site-ul își desfășoară activitatea și orice tentativă de fraudare identificată.</p>
				<br /><br />
				<p>Utilizatorul se obligă să nu modifice, distribuie, transmită, publice, reproducă, creeze produse derivate, transfere sau vândă orice fel de informații sau servicii obținute de pe sau prin intermediul acestui site.</p>
				<br /><br />
				<p>3.2.4 Utilizatorul se obligă să nu folosească și să nu permită vreunui terț să folosească serviciul oferit de Digitality Smart Creative Solutions S.R.L. pentru a transmite sau a recepționa materiale care contravin dispozițiilor legale în vigoare sau care au un conținut obscen, abuziv, vulgar, defăimător, ofensator, care încalcă orice alt drept al oricărei persoane sau aduce atingere drepturilor de proprietate intelectuală.</p>
				<br /><br />
				<p>3.2.5 Utilizatorul se obligă să nu desfășoare și să nu permită activitatea de accesare a sistemului informatic. Utilizatorii care violează securitatea sistemelor sau a rețelelor vor fi sancționați în conformitate cu legislația în vigoare și cu oprirea temporară sau definitivă a accesului în Aplicație.</p>

				<br /><br />
				<h3>4. Comunicările electronice</h3>
				<p>4.1 Digitality Smart Creative Solutions S.R.L. își rezervă dreptul de a comunica Utilizatorilor, prin mijloace electronice, orice informații consideră de cuviință cu privire la activitatea generală a Site-ului.</p>
				<br /><br />
				<p>4.2 Digitality Smart Creative Solutions S.R.L. poate realiza comunicări către Utilizatori, fie în mod direct, fie prin intermediul partenerilor specializați și agreați pentru asigurarea confidențialității și securității informațiilor.</p>
				<br /><br />
				<p>4.3 Utilizatorul va primi comunicări în modalitate electronică de la Digitality Smart Creative Solutions S.R.L. numai dacă acesta și-a exprimat în mod prealabil consimțământul în legătură cu primirea unor astfel de notificări / comunicări electronice. Din când în când Furnizorul poate notifica Utilizatorilor modificări semnificative ale Aplicației sau termenilor și condițiilor.</p>
				<br /><br />
				<div>4.4 Utilizatorul își poate modifica opțiunea cu privire la primirea de comunicări electronice din partea Digitality Smart Creative Solutions S.R.L. în orice moment, prin următoarele mijloace:
					<ul>
						<li> Prin contactarea Site-ului la adresa indicată la art. 1.6.</li>
						<li> Prin modificarea setărilor din contul de Utilizator.</li>
						<li> Prin accesarea link-ului de dezabonare afișat în mesajele electronice primite de la Digitality Smart Creative Solutions S.R.L.</li>
						<li> Renunțarea Utilizatorului de a mai primi orice fel de comunicare electronică din partea Furnizorului nu îl exonerează de respectarea prezenților termeni și condiții și nici nu este aplicabilă cu privire la comunicarea actualizării termenilor și condițiilor.</li>
					</ul>
				</div>
				<br /><br />
				<p>4.6 Utilizatorul înțelege că va fi informat în mod automat cu privire la modificarea termenilor și condițiilor de utilizare a site-ului sau a apariției unor evenimente majore în funcționarea Aplicației, această comunicare electronică făcându-se în baza acceptării inițiale a acestor termeni și condiții.</p>

				<br /><br />
				<h3>5. Drepturi de proprietate intelectuală și industrială </h3>
				<p>5.1 Întregul conținut al Site-ului, incluzând, orice fel de material purtător de drepturi de proprietate intelectuală aparțin sau se află sub licența Digitality Smart Creative Solutions S.R.L. și sunt protejate de lege. Folosirea, fără acordul scris al Digitality Smart Creative Solutions S.R.L., a oricăror elemente enumerate mai sus va fi sancționată conform legilor în vigoare.</p>
				<br /><br />
				<p>5.2 Accesul la informațiile conținute pe Site garantează Utilizatorului acces limitat, în interes personal, pe acest site și nu îi conferă dreptul de a descărca sau de a modifica parțial sau integral site-ul, de a reproduce parțial sau integral site-ul, de a copia, de a vinde sau revinde, sau de a exploata site-ul în orice altă manieră, în scopuri comerciale sau contrare intereselor Digitality Smart Creative Solutions S.R.L., fără acordul scris al acesteia.</p>
				<br /><br />
				<p>5.3 Orice reproducere parțială sau totală a materialelor purtătoare de drepturi de autor ce figurează pe Site se va face doar cu acordul prealabil, scris al Digitality Smart Creative Solutions S.R.L.</p>
				<br /><br />
				<p>5.4 Orice material purtător de drepturi de proprietate intelectuală transmis către Utilizator nu reprezintă transferul vreunui drept cu privire la respectivele materiale.</p>
				<br /><br />
				<p>5.5 Utilizatorul are dreptul neexclusiv de a utiliza serviciile puse la dispoziție de Digitality Smart Creative Solutions S.R.L., iar acesta nu poate transmite dreptul de utilizare unui alte persoane.</p>
				<br /><br />
				<p>5.6 Răspunderea pentru încărcarea pe site de către Utilizator a oricăror fotografii de tip selfie/date/documente îi aparține Utilizatorului, acesta despăgubind Furnizorul pentru orice prejudicii cauzate acestuia prin încălcarea oricăror drepturi de autor și/sau de imagine ce deținute de alte persoane asupra materialelor încărcate de către Utilizator.</p>

				<br /><br />
				<h3>6. Politici de utilizare a Site-ului</h3>
				<h5>6.1 Utilizarea Site-ului</h5>
				<p>6.1.1 Accesul pe Site este permis tuturor persoanelor fără niciun fel de restricții, atâta vreme cât acestea au acceptat prezenții termeni și condiții. Pentru motive justificate,  Digitality Smart Creative Solutions S.R.L. își rezervă dreptul de a restricționa accesul persoanelor pe site în cazul în care se consideră că în baza conduitei sau a activității Utilizatorului, acesta ar putea prejudicia în vreun fel Digitality Smart Creative Solutions S.R.L. În cazul în care folosirea site-ului este cedată Terțului autorizat, accesul utilizatorilor la acest Site se va face în funcție de decizia Terțului autorizat. Destinatarul Serviciilor disponibile pe site nu sunt persoane cu vârsta sub 16 ani. Administratorul de date cu caracter personal nu prevede colectarea deliberată a datelor cu privire la persoanele sub 16 ani.</p>
				<br /><br />
				<p>6.1.2 Comunicarea între Digitality Smart Creative Solutions S.R.L. și Utilizator se va putea face prin orice mijloc de comunicare existent la acest moment sau care va fi inventat. Digitality Smart Creative Solutions S.R.L. are dreptul de a gestiona oricum consideră de cuviință metodele de comunicare cu Utilizatorii și are libertatea de a gestiona oricum consideră necesar informațiile primite.</p>
				<br /><br />
				<p>6.1.3 În cazul în care se detectează trafic neobișnuit provenit din partea unei rețele de internet, Digitality Smart Creative Solutions S.R.L. își rezervă dreptul de a introduce orice mecanism de validare a existenței unei persoane reale în spatele fiecărui Utilizator al Site-ului.</p>
				<br /><br />
				<p>6.1.4 Utilizatorii Site-ului pot face comentarii și orice alte comunicări, pot să transmită sugestii, întrebări sau informații, dacă limbajul acestora este civilizat, iar conținutul comunicărilor nu este ilegal, obscen, amenințător, defăimător, nu tulbură în niciun fel viața privată a altor persoane, nu încalcă drepturile de proprietate intelectuală, nu instigă la ură rasială, nu instigă la discriminare de orice fel, nu conține viruși, nu servește unor campanii de promovare care nu au legătură cu Site-ul, nu sunt e-mailuri în masă ori orice altă formă de spam.</p>
				<br /><br />
				<p>6.1.5 Dacă în îndeplinirea procesului de înregistrate pe Site, Digitality Smart Creative Solutions S.R.L. sau Terțul autorizat constată că Utilizatorii furnizează informații incomplete, inexacte, fotografii de tip selfie inadecvate sau dacă aleg un username/ nume de utilizator neadecvat, Digitality Smart Creative Solutions S.R.L.RL are dreptul de a suspenda sau închide contul Utilizatorului și de a refuza folosirea curentă sau pe viitor a Site-ului.</p>
				<br /><br />
				<p>6.1.6 Site-ul nu își asumă niciun fel de răspundere față de prejudiciile generate ca urmare a situației descrise la art. 6.1.5.</p>
				<br /><br />
				<h5>6.2 Înregistrarea contului de Utilizator</h5>
				<p>6.2.1 Pentru ca Utilizatorul să poată participa la serviciile oferite prin Site-ul www.Ality.ro, acesta trebuie să își creeze un cont pe Site.  Pentru a vă înregistra Contul de utilizator, va trebui să trimiteți anumite informații personale către Ality. Informații detaliate legate de datele cu caracter personal pe care trebuie să ni le împărtășiți și despre modul în care gestionăm datele dvs. personale, le puteți găsi în cadrul Politicii de prelucrare a datelor cu caracter personal disponibilă la adresa web …. . Sunteți responsabil pentru toate activitățile desfășurate prin intermediul Contului dvs. de utilizator și puteți deschide doar un singur cont Ality.</p>
				<br /><br />
				<p>6.2.2 În vederea înregistrării contului, Utilizatorul este obligat să pună la dispoziție date verificabile și conforme cu realitatea. Digitality Smart Creative Solutions S.R.L. poate refuza cererea de înregistrate a contului în situațiile în care constată că sunt utilizate date neconforme sau sunt încălcate prevederile prezenților termeni și condiții.</p>
				<br /><br />
				<p>6.2.3 Cu ocazia înregistrării, vă alegeți un username sub care participați la serviciile oferite pe Site, username care nu este obligatoriu să conțină numele dumneavoastră complet sau real.</p>
				<br /><br />
				<p>6.2.4 Dacă se constată că username-ul ales este neadecvat, de exemplu s-a folosit un limbaj obscen, jignitor sau se încalcă un drept de proprietate intelectuală, Digitality Smart Creative Solutions S.R.L. are dreptul de a refuza cererea de înregistrare a contului. </p>
				<br /><br />
				<p>6.2.5 Contul de utilizator nu se poate înregistra fără furnizarea următoarelor elemente: nume, prenume, username, adresă de e-mail sau număr de telefon.</p>
				<br /><br />
				<p>6.2.6 Utilizatorul răspunde de veridicitatea datelor introduse în timpul înregistrării contului și pentru toate activitățile care survin prin accesarea contului și parolei personale. Digitality Smart Creative Solutions S.R.L. nu poate fi făcută responsabilă pentru erorile survenite în urma neglijenței utilizatorului privind securitatea și confidențialitatea contului și parolei sale.</p>
				<br /><br />
				<p>6.2.7 Dacă Utilizatorul suspectează că securitatea contului său a fost compromisă acesta trebuie să contacteze Digitality Smart Creative Solutions S.R.L. la adresa de e-mail indicată la art. 1.6 și să facă demersuri pentru a schimba parola contului său sau să solicite blocarea accesului persoanei care îi accesează Site-ul folosind contul său fără drept.</p>
				<br /><br />
				<p>6.2.8 Site-ul nu poate fi accesat de Utilizator decât în condițiile în care acceptă prezenții termeni și condiții și politica de prelucrare a datelor cu caracter personal și eventualele actualizări ale acestora. Fără a exista acceptul Utilizatorului asupra ultimei variante a termenilor și condițiilor site-ului și a acordului de prelucrare a datelor cu caracter personal, accesul nu va fi permis.</p>
				<br /><br />
				<p>6.2.9 Prin înregistrarea ca Utilizator pe site, este exprimat și acordul expres al persoanei de a fi informat prin mijloace de comunicare electronică pentru orice actualizare a termenilor și condițiilor site-ului și ale politicii de prelucrare a datelor cu caracter personal.</p>
				<br /><br />
				<div>6.2.10 Pentru înregistrarea contului de Utilizator în vederea utilizării aplicației de recunoaștere facială prin intermediul căreia sunt identificate și trimise utilizatorilor fotografiile unde au fost etichetați la diferite evenimente, trebuie urmate următoarele etape concrete:
					<ul>
						<li>Pasul 1 - Utilizatorul scanează un cod QR specific evenimentului la care se află;</li>
						<li>Pasul 2 - Utilizatorul își introduce în aplicație fie numărul de telefon, fie adresa de email validă;</li>
						<li>Pasul 3 - Utilizatorul realizează un selfie, urmând indicațiile din cadrul aplicației. Selfie-ul trece printr-un proces automat de verificare a pozei pentru asigurarea că fotografia este în standarde optime pentru recunoaștere facială.</li>
						<li>Utilizatorul primește un email/SMS de confirmare ce indică înregistrarea în sistem.</li>
					</ul>
				</div>
				<br /><br />
				<p>6.2.11 Utilizatorul poate închide utilizarea contului prin simplul fapt al întreruperii serviciilor Ality.</p>

				<br /><br />
				<h3>7. Serviciile oferite și modul de funcționare</h3>
				<p>7.1. Utilizatorul folosește tehnologia Ality pentru a avea acces la fotografiile în care este identificat în funcție de imaginea facială, realizate la diferite evenimente. Pentru a accesa această funcționalitate, este necesar ca între Furnizor sau Terțul autorizat și organizatorul evenimentului / fotograful care realizează respectivele fotografii să existe o relație contractuală care să permită accesul la fotografiile realizate cu Utilizatorul. Pentru a verifica dacă evenimentul la care participă presupune accesarea gratuită a acestei aplicații, Utilizatorul va verifica codul QR specific evenimentului prin intermediul aplicației.</p>
				<br /><br />
				<p>7.2.  Raportul juridic se creează între Utilizator și organizatorul evenimentului sau persoana care a angajat serviciile fotografului care realizează fotografiile.</p>
				<br /><br />
				<p>7.3. Odată ce fotografii pun la dispoziția Furnizorului fotografiile realizate la un anumit eveniment, algoritmul de recunoaștere facială pe baza căruia funcționează aplicație, va determinarea crearea unei pagini separate cu toate fotografiile în care a fost identificat Utilizatorul, iar Utilizatorul va primi un link pe email/SMS, în funcție de datele furnizate la crearea contului, pentru a putea accesa pagina unde sunt stocate fotografiile respective. Fotografiile vor fi stocate la adresa de URL a paginii respective timp de 7 zile calendaristice.</p>
				<br /><br />
				<p>7.4. Furnizorul nu își asumă răspunderea pentru calitatea fotografiilor realizate de fotografi, aplicația având doar rolul de identificare și centralizare a fotografiilor în care sunt este etichetat facial fiecare Utilizator.</p>
				<br /><br />
				<p>7. 5. Detectarea feței și recunoașterea feței sunt procesate prin algoritmul prin care funcționează aplicația sunt stocate și prelucrate cu ajutorul unor servicii de tip Cloud.</p>

				<br /><br />
				<h3>8. Confidențialitate</h3>
				<p>8.1  Digitality Smart Creative Solutions S.R.L. va păstra confidențialitatea informațiilor de orice natură pe care Utilizatorul le furnizează sau pe care le primește de la organizatorul de evenimente sau Terțul autorizat. Dezvăluirea informațiilor se va putea face doar în condițiile menționate în prezentul Contract.</p>
				<br /><br />
				<p>8.2 Nicio declarație publică, promovare, comunicat sau orice alt mod de comunicare către terțe persoane nu va fi făcută de Utilizator cu privire la prezentul document fără consimțământul prealabil scris al Digitality Smart Creative Solutions S.R.L.</p>
				<br /><br />
				<p>8.3 Prin transmiterea de materiale sau informații prin intermediului Site-ului, Utilizatorul acordă acces asupra acestora în vederea oferirii Serviciilor, precum și partajarea acestor informații/date cu Terțul autorizat.</p>

				<br /><br />
				<h3>9. Răspundere </h3>
				<p>9.1 Prin crearea Contului și / sau vizitarea Site-ului, Utilizatorul acceptă în mod expres și fără echivoc Termenii și condițiile Site-ului în ultima versiune actualizată care este comunicată în cadrul Site-ului, existentă la data creării Contului și / sau utilizării conținutului.</p>
				<br /><br />
				<p>9.2 Ulterior creării Contului utilizarea sau vizitarea Site-ului echivalează cu acceptarea modificărilor intervenite asupra Termenilor și Condițiilor Site-ului și/sau a versiunilor actualizate ale Termenilor și Condițiilor Site-ului.</p>
				<br /><br />
				<p>9.3 Termenii și Condițiile Site-ului pot fi modificate oricând de către  Digitality Smart Creative Solutions S.R.L., aceștia fiind opozabili Utilizatorilor de la data afișării pe Site.</p>
				<br /><br />
				<p>9.4 În cazul în care Utilizatorul încalcă dispozițiile prezentului document  Digitality Smart Creative Solutions S.R.L. are dreptul de a bloca sau limita temporar accesul acestuia la Site fără vreo notificare prealabilă. Dacă încălcările au un caracter continuu, Furnizorul va bloca permanent accesul Utilizatorului.</p>
				<br /><br />
				<p>9.5 Digitality Smart Creative Solutions S.R.L. nu este responsabil pentru eventualele întreruperi, blocări, indisponibilități, viruși, erori sau omisiuni apărute în timpul accesării aplicației și care nu sunt generate de serviciile oferite de acesta.</p>
				<br /><br />
				<p>9.6. Furnizorul poate schimba, suspenda sau întrerupe serviciile oferite fără vreo notificare prealabilă.</p>
				<br /><br />
				<p>9.7. Furnizorul nu va fi responsabil pentru relația dintre Utilizator și Terțul autorizat/organizatorul de evenimente/fotograf, raportul juridic născându-se direct între Utilizator și Terțul autorizat, Furnizorul fiind responsabil doar în raport de Terțul autorizat conform contractului încheiat între aceștia.</p>
				<br /><br />
				<p>9.8. Utilizatorul își asumă utilizarea contului Ality cu bună-credință și în conformitate cu toate legile și reglementările aplicabile. Nu va utiliza serviciile noastre pentru a se angaja în activități ilegale.</p>
				<br /><br />
				<p>9.9 Utilizatorul garantează Furnizorul că toate informațiile pe care le furnizați în legătură cu serviciile sunt adevărate, complete, legale și exacte.</p>
				<br /><br />
				<p>9.10. Utilizatorul este de acord să despăgubească Digitality Smart Creative Solutions S.R.L. și cesionarii, directorii, angajații și agenții săi în cazul oricăror creanțe, pierderi, daune și cheltuieli (inclusiv onorariile avocaților) care decurg din sau în legătură cu: (i) utilizarea de către Utilizator a Serviciilor oferite; (ii) nerespectarea sau încălcarea oricăruia dintre acești Termeni sau (iii) încălcarea drepturilor oricărei terțe părți.</p>
				<br /><br />
				<p>9.11. Nu puteți atribui, în totalitate sau parțial, acest acord unei persoane sau entități.</p>
				<br /><br />
				<p>9.12. Trebuie să obțineți sfaturi profesionale sau de specialitate înainte de a lua sau de a vă abține de la orice acțiune pe baza conținutului de pe site-ul nostru, dacă nu sunteți sigur de anumite aspecte. Utilizatorul este responsabil pentru actualizarea datelor din contul de utilizator imediat ce apar modificări. Digitality Smart Creative Solutions S.R.L. nu își asumă nicio responsabilitate pentru orice daune de orice fel cauzate de această încălcare a obligației.</p>
				<br /><br />
				<p>9.13. Având în vedere dependența serviciilor oferite de accesul la Internet, eventualele limitări tehnice și de sistem care pot apărea, Digitality Smart Creative Solutions S.R.L. își asumă doar obligații de diligență cu privire la disponibilitatea serviciilor furnizate. Nicio transmisie de date prin Internet nu poate fi garantată sută la sută.</p>
				<br /><br />
				<p>9.14. Dacă la un moment dat site-ul nostru va conține link-uri către alte site-uri și resurse furnizate de terți, aceste link-uri sunt furnizate doar pentru informarea dvs. Astfel de linkuri nu trebuie interpretate ca aprobarea de către noi a acelor site-uri web conectate sau a informațiilor pe care le puteți obține de la acestea. Nu avem control asupra conținutului acestor site-uri sau resurse.</p>

				<br /><br />
				<h3>10. Soluționare conflictelor. Legea aplicabilă</h3>
				<p>10.1 Prezentul contract este guvernat de legea română.</p>
				<br /><br />
				<p>10.2 În caz de divergență sau neînțelegeri între Digitality Smart Creative Solutions S.R.L. și Utilizator, se vor aplica Termenii și Condițiile valabile la momentul utilizării Site-ului.</p>
				<br /><br />
				<p>10.3 În cazul unor eventuale conflicte între Digitality Smart Creative Solutions S.R.L. și Utilizatorii săi, se va încerca mai întâi rezolvarea acestora pe cale amiabilă. Dacă rezolvarea pe cale amiabilă nu va fi posibilă, conflictul va fi soluționat în instanță, în conformitate cu legile române în vigoare.</p>

				<br /><br />
				<h3>11. Forța majoră</h3>
				<p>11.1 Niciuna din părți nu va fi răspunzătoare pentru neexecutarea obligațiilor contractuale dacă o astfel de neexecutare este datorată unui eveniment de forță majoră. Forța majoră este un eveniment extern, imprevizibil, în afara controlului părților și care nu poate fi evitat.</p>
				<br /><br />
				<p>11.2 Părțile îți vor aduce la cunoștință de îndată cazul de forță majoră și vor lua toate măsurile necesare în vederea limitării consecințelor evenimentului.</p>
				<br /><br />
				<p>11.3 Dacă în termen de 15 zile evenimentul de forță majoră nu încetează, părțile au dreptul de a denunța unilateral contractul fără a pretinde daune-interese.</p>

				<h3>12. Dispoziții finale</h3>
				<p>12.1 Dacă oricare dintre clauzele de mai sus va fi găsită nulă sau nevalidă, acest lucru nu va afecta valabilitatea celorlalte clauze.</p>
<br/>
				<Button onClick={this.props.toggleModal} appearance="primary" > Am luat la cunostinta </Button>
			</Modal>
		)
	}
}

export default terms;
