import React, {useEffect, useRef, useState} from 'react'
import {Button} from "rsuite"
import "./Helper.scss"

import SVG_INVITE_CUSTOM from '../assets/HelperSVG/invitatie-custom-icon.svg'
import SVG_INVITE_PERSONAL from '../assets/HelperSVG/invitatie-personala-icon.svg'
import SVG_INVITE_VIDEO from '../assets/HelperSVG/invitatie-video-icon.svg'

import Modal from 'react-responsive-modal'
import YouTube from 'react-youtube';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import {
    WhatsappShareButton,
    EmailShareButton
} from "react-share";
import whatsapp from '../assets/icons/whatsapp.svg'
import messenger from '../assets/icons/messenger.svg'
import gmail from '../assets/icons/gmail.svg'
import Loader from "./Loader";
import moment from "moment-timezone";
import AvatarEditor from "react-avatar-editor";
import {toast} from "react-toastify";

export let textarea_max_length = 250

//param: image
export function getUrlFromImage(image) {
    if (image === null || image === undefined) return ""

    if (image.formats === null || image.formats === undefined) return image.url

    if (image.formats.medium !== undefined) return image.formats.medium.url

    if (image.formats.small !== undefined) return image.formats.small.url

    return image.url
}

//params: onClick function, button text
export class BackButton extends React.Component {
    render() {
        return <div className="bottom-buttons">
            <Button onClick={this.props.function} className="back-button"
                    style={{...this.props.style, color: "#1E1C59"}}>
                {this.props.text}
            </Button>
        </div>
    }
}

//params: onClick function, button text
//optional param: loading
export class ConfirmButton extends React.Component {
    render() {
        return <div className="bottom-buttons">
            <Button loading={this.props.loading} onClick={this.props.function}
                    className="add-button"
                    style={{...this.props.style, backgroundColor: "#00afcc", color: "white"}}>
                {this.props.text}
            </Button>
        </div>
    }
}

//params: onClick function and text for back and confirm buttons
export class BackAndConfirmButtons extends React.Component {
    render() {
        // let hideConfirmButton = false;
        // if (this.props.hideConfirm !== null) {
        // 	hideConfirmButton = this.props.hideConfirm
        // }

        return <div className="bottom-buttons" style={{...this.props.outerStyle}}>
            <Button onClick={this.props.functionBack} className="back-button"
                    style={{...this.props.styleBack, color: "#1E1C59"}}>
                {this.props.textBack}
            </Button>

            {/* {!hideConfirmButton &&  */}
            <Button loading={this.props.loading} onClick={this.props.functionConfirm}
                    className="add-button" style={{
                ...this.props.styleConfirm,
                backgroundColor: "#00afcc",
                color: "white"
            }}>
                {this.props.textConfirm}
            </Button>
            {/* // } */}
        </div>
    }
}

//params: onClick function, button text
export class RedButton extends React.Component {
    render() {
        return <div className="bottom-buttons" style={{margin: "0px auto"}}>
            <Button onClick={this.props.function} className="red-button"
                    style={{...this.props.style, backgroundColor: "#F26157", color: "white"}}>
                {this.props.text}
            </Button>
        </div>
    }
}

export class LoadingScreen extends React.Component {
    render() {
        return <div style={{height: '100%'}}>
            <Loader/>
        </div>
    }
}

export class ShareModal extends React.Component {
    openFb = () => {
        window.open('https://www.facebook.com/sharer/sharer.php?u=' + encodeURIComponent(process.env.REACT_APP_PAGE + "events/" + this.props.link + "/" + this.props.linkCategory))

        //window.open('fb-messenger://share?link=' + encodeURIComponent(process.env.REACT_APP_PAGE + "events/" + this.props.link + "/" + this.props.linkCategory) + '&app_id=' + encodeURIComponent("1437009096459536"));
    }

    render() {
        let size = "70px"
        let url = process.env.REACT_APP_PAGE + "events/" + this.props.link + "/" + this.props.linkCategory

        return (
            <Modal classNames={{
                overlay: 'modal-overlay',
                modal: 'helper-modal',
                closeButton: 'modal-close-button',
            }} open={this.props.showModal} onClose={this.props.closeModal} center>
                <div className="modal-content">
                    <div style={{marginTop: "10vh"}}>
                        <div style={{textAlign: "center", marginBottom: "40px"}}>Share
                            la {decodeURI(this.props.link)}</div>

                        <div className="icons">
                            <WhatsappShareButton url={url} windowWidth={"1000"}
                                                 windowHeight={"600"}>
                                <img src={whatsapp} alt="" style={{height: size, width: size}}/>
                            </WhatsappShareButton>

                            <img onClick={this.openFb} src={messenger} alt=""
                                 style={{height: size, width: size}}/>

                            <EmailShareButton url={url}>
                                <img src={gmail} alt="" style={{height: size, width: size}}/>
                            </EmailShareButton>
                        </div>
                    </div>
                </div>
            </Modal>
        )
    }
}


// //params: link to be shared
// export class ShareModal extends React.Component {
// 	openFb = () => {
// 		window.open('fb-messenger://share?link=' + encodeURIComponent(process.env.REACT_APP_PAGE + "events/" + this.props.link + "/" + this.props.linkCategory) + '&app_id=' + encodeURIComponent("1437009096459536"));
// 	}

// 	render() {
// 		let size = "70px"
// 		let url = process.env.REACT_APP_PAGE + "events/" + this.props.link + "/" + this.props.linkCategory

// 		return (
// 			<Modal classNames={{
// 				overlay: 'modal-overlay',
// 				modal: 'helper-modal',
// 				closeButton: 'modal-close-button',
// 			}} open={this.props.showModal} onClose={this.props.closeModal} center>
// 				<div className="modal-content">
// 					<div style={{ marginTop: "10vh" }}>
// 						<div style={{ textAlign: "center", marginBottom: "40px" }}>Share la {this.props.link}</div>

// 						<div className="icons">
// 							<WhatsappShareButton url={url} >
// 								<img src={whatsapp} alt="" style={{ height: size, width: size }} />
// 							</WhatsappShareButton>

// 							<img onClick={this.openFb} src={messenger} alt="" style={{ height: size, width: size }} />

// 							<EmailShareButton url={url}>
// 								<img src={gmail} alt="" style={{ height: size, width: size }} />
// 							</EmailShareButton>
// 						</div>
// 					</div>
// 				</div>
// 			</Modal>
// 		)
// 	}
// }

// //params: questions, answer, changeAnswers function
// export class CustomQuestions extends React.Component {
// 	selectQuestionAnswer = (index, value) => {
// 		let newAnswers = [...this.props.answers]

// 		if (newAnswers[index] === value || newAnswers[index] === value.value) {
// 			newAnswers[index] = ""
// 		} else {
// 			newAnswers[index] = value
// 			if (value.value !== undefined) {
// 				newAnswers[index] = value.value
// 			}
// 		}

// 		this.props.changeAnswers(newAnswers)
// 	}

// 	render() {
// 		return <div>
// 			{this.props.questions.map((question, index) => {
// 				return <div key={index} style={{ margin: "20px 0px", textAlign: "left" }}>
// 					<h5>{question.title}</h5>
// 					{/* raspuns liber */}
// 					{question.type === 0 &&
// 						<CustomInput value={this.props.answers[index]} onChange={value => this.selectQuestionAnswer(index, value)} placeholder={"Raspuns liber"} />
// 					}

// 					{/* raspuns cu variante */}
// 					{question.type === 1 &&
// 						<CheckTree
// 							defaultExpandAll
// 							renderTreeIcon={null}
// 							cascade={true}
// 							data={question.options}
// 							onSelect={value => this.selectQuestionAnswer(index, value)}
// 							value={[this.props.answers[index]]}
// 						/>
// 					}
// 				</div>
// 			})}
// 		</div>
// 	}
// }

//params: link to audio to play
export class PlayAudio extends React.Component {
    render() {
        return <audio controls id={"audioMP3"} style={this.props.style}>
            <source src={this.props.link} type={"audio/mp3"}/>
        </audio>
    }
}



//params: link to video to play
export class PlayVideo extends React.Component {
    render() {
        return <YouTube videoId={this.props.videoId} containerClassName={"youtube-video"}/>
    }
}

export class ShowPackageData extends React.Component {


    state = {
        title: this.props.packageData.package ? this.props.packageData.package.details.title : "Fara",
        limit: this.props.packageData ? this.props.packageData.limit : 5,
        totalCount: this.props.packageData.totalResponses,

        showModal: false
    }

    componentDidMount = () => {
        if (this.state.limit < this.state.totalCount) {
            this.setState({
                showModal: true
            })
        }
    }

    goToPacakge = () => {
        this.props.history.push('/organizer/package/' + this.props.eventId)
    }

    closeModal = () => {
        this.setState({
            showModal: false
        })
    }

    render() {
        return <Modal classNames={{
            overlay: 'modal-overlay',
            modal: 'helper-modal',
            closeButton: 'modal-close-button',
        }} open={this.state.showModal} onClose={this.closeModal} center>
            <div className="package-info">
                <br/>
                <div>Limita de răspunsuri a fost atinsă. Pentru a putea vizualiza mai multe
                    răspunsuri vă rugăm să achiziționați un pachet superior.
                </div>
                <br/>
                <br/>
                <ConfirmButton
                    style={{width: '100%', marginTop: '30px'}}
                    text={"Vezi pachetele"}
                    function={() => {
                        this.props.history.push('/organizer/package/' + this.props.eventId)
                    }}
                />
            </div>
        </Modal>
    }
}

//params: cards (required)
//cards: [{data (required), color (required), onClick event (optional), svgIcon (optional)}, {...}]
export class DataCards extends React.Component {
    render() {
        return <div className="data-cards">
            {this.props.cards.map((card, index) => {
                return <div key={index} className="card" onClick={card.onClickEvent}
                            style={{color: card.color, border: `1px solid ${card.color}`}}>
                    {card.data}
                    {card.svgIcon !== undefined &&
                        <div style={{display: "flex", marginLeft: "10px"}}><img src={card.svgIcon}
                                                                                alt="card"
                                                                                style={{height: "20px"}}/>
                        </div>}
                </div>
            })}
        </div>
    }
}

/*
Example
<DataCards cards={[
	{
		data: `Buget Total: ${this.state.budget} ${usedCurrency}`,
		color: "#19B899",
		onClickEvent: this.modalChangeBudget,
		svgIcon: SVG_Budget_Edit
	},
	{
		data: `Total dar: ${this.state.totalGiftMoney} ${usedCurrency}`,
		color: "#731DD8"
	},
	{
		data: `Cheltuieli totale: ${this.state.totalSpending} ${usedCurrency}`,
		color: "#F26157"
	},
	{
		data: `Buget ramas: ${this.state.budget - this.state.totalSpending + this.state.totalGiftMoney} ${usedCurrency}`,
		color: "#E3B505"
	}
	]} />
*/

//params: columnNames (required, used in header), onRowClick (optional), dataArray (required), dataToTakeFromArray (required)
//columnNames: [{name: "" (required), width: "" (optional)}, {...}, ...]
//dataArray: [{...}, ...] - get data from here
//dataToTakeFromArray: ["title", "name", ...] - one element for each column
export class DataTable extends React.Component {
    render() {
        return <div className="data-table">
            <div className="header">
                {this.props.columnNames.map((data, index) => {
                    return <div key={index} style={data.style}>{data.name}</div>
                })}
            </div>

            {this.props.dataArray.map((data, index) => {
                return <div key={index} onClick={() => this.props.onRowClick(index)}
                            className={index % 2 === 0 ? "even-line" : "uneven-line"}>
                    {this.props.dataToTakeFromArray.map((row, jIndex) => {
                        return <div key={jIndex} style={this.props.columnNames[jIndex].style}>
                            {data[row]}
                        </div>
                    })}
                </div>
            })}
        </div>
    }
}

/*
<DataTable
	columnNames={[{ name: "Cheltuieli", style: { width: "75%" } }, { name: "Cost", style: { width: "25%", textAlign: "right" } }]}
	onRowClick={this.modalChangeExpense}
	dataArray={this.state.expenses}
	dataToTakeFromArray={["title", "cost"]}
/>
*/

export class SelectInviteType extends React.Component {
    render() {
        return <div>
            {this.props.inviteType === ""
                ? <div>
                    <div className="Input_Type_Options">

                        <div className="Input_Type_Option"
                             onClick={_ => this.props.setInviteType("Custom")}>
                            <div className='Circle'><img src={SVG_INVITE_CUSTOM} alt="Custom"
                                                         style={{height: "80px"}}/></div>
                            <div><ConfirmButton text={"Creeaza " + this.props.text + " acum"}
                                                function={() => {
                                                }} style={{width: '100%', fontSize: '14px'}}/></div>
                        </div>

                        <div className="Input_Type_Option"
                             onClick={_ => this.props.setInviteType("Personal")}>
                            <div className='Circle'><img src={SVG_INVITE_PERSONAL} alt="Personal"
                                                         style={{height: "80px"}}/></div>
                            <div><ConfirmButton text={"Incarca " + this.props.text + " creata"}
                                                function={() => {
                                                }} style={{width: '100%', fontSize: '14px'}}/></div>
                        </div>

                        <div className="Input_Type_Option"
                             onClick={_ => this.props.setInviteType("Video")}>
                            <div className='Circle'><img src={SVG_INVITE_VIDEO} alt="Video"
                                                         style={{height: "80px"}}/></div>
                            <div><ConfirmButton
                                text={"Incarca " + this.props.text + " video creata"}
                                function={() => {
                                }} style={{width: '100%', fontSize: '14px'}}/></div>
                        </div>
                    </div>

                    <Button
                        onClick={() => this.props.setTutorialModal(true)}
                        color="green"
                        style={{
                            backgroundColor: "#05AFF2",
                            borderRadius: "20px",
                            width: "100%",
                            marginTop: "10px",
                            marginBottom: "20px"
                        }}>Informații utile</Button>

                    <BackButton text={"Înapoi"} function={this.props.goBack}/>
                </div>
                : <div>
                    {
                        this.props.useNewInvite && this.props.inviteType === 'Custom' ?
                            null
                            :
                            <div onClick={this.props.openModalChangeType}
                                 className="ChangeChoiceButton">
                                Alege alt tip de invitatie
                            </div>
                    }
                </div>}
        </div>
    }
}

/*
Params:
  imageLink - uploaded image link
  saveCroppedImage - function to save edited picture
*/
export class EditPicture extends React.Component {
    state = {
        scale: 1,
        src: this.props.imageLink,
        crop: {
            unit: '%',
            width: 30,
            height: 30
        },
        croppedImageBlob: null,
        croppedImageURL: null
    }

    onCropChange = (crop, percentCrop) => {
        this.setState({crop});
    };

    onImageLoaded = (image) => {
        // eslint-disable-next-line
        this.state.imageRef = image;
    };

    onCropComplete = (crop) => {
        this.makeClientCrop(crop);
    };

    async makeClientCrop(crop) {
        if (this.state.imageRef && crop.width && crop.height) {
            const croppedImageUrl = await this.getCroppedImg(
                this.state.imageRef,
                crop,
                'newFile.jpeg'
            );
            this.setState({croppedImageUrl});
        }
    }

    getCroppedImg(image, crop, fileName) {

        const canvas = document.createElement('canvas');
        const pixelRatio = window.devicePixelRatio;
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        const ctx = canvas.getContext('2d');

        canvas.width = crop.width * pixelRatio * scaleX;
        canvas.height = crop.height * pixelRatio * scaleY;

        ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
        ctx.imageSmoothingQuality = 'high';

        ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width * scaleX,
            crop.height * scaleY
        );

        return new Promise((resolve, reject) => {
            canvas.toBlob(
                (blob) => {
                    if (!blob) {
                        //reject(new Error('Canvas is empty'));
                        console.error('Canvas is empty');
                        return;
                    }

                    this.props.saveCroppedImage(blob)
                    blob.name = fileName;
                    window.URL.revokeObjectURL(this.fileUrl);

                    this.fileUrl = window.URL.createObjectURL(blob);
                    resolve(this.fileUrl);
                },
                'image/jpeg',
                1
            );
        });

    }

    render() {
        //const { crop, croppedImageUrl, src } = this.state;
        // console.log(croppedImageUrl);

        return <div className='Edit-picture-container'>
            <ReactCrop
                src={this.state.src}
                crop={this.state.crop}
                imageStyle={{width: maxScreenWidth}}
                ruleOfThirds
                onImageLoaded={this.onImageLoaded}
                onComplete={this.onCropComplete}
                onChange={this.onCropChange}
                onImageError={(event) => alert(JSON.stringify(event))}
            />

            <div>Modifica imaginea incarcata</div>

            {this.state.croppedImageUrl && (
                <img alt="Crop" style={{maxWidth: '100%'}} src={this.state.croppedImageUrl}/>
            )}
        </div>
    }
}

export function EditPicture2({
                                 uploadedImage,
                                 saveCroppedImage,
                                 width,
                                 height,
                                 border,
                                 borderRadius,
                                 style = {}
                             }) {

    const cropper1 = useRef()
    const [image1Scale, setImage1Scale] = useState(1)

    useEffect(() => {
      //  console.log(uploadedImage)
    }, [])


    return (<div className='Edit-picture2-container'>
        <AvatarEditor
            key={23}
            ref={cropper1}
            image={window.URL.createObjectURL(uploadedImage)}
            width={width}
            height={height}
            border={border}
            borderRadius={borderRadius}
            color={[0, 0, 0, 0.6]} // RGBA
            scale={parseFloat(image1Scale + '')}
            rotate={0}
            disableHiDPIScaling={true}
            //onImage
            onLoadFailure={(e) => {
                toast.error('Vă rugăm să încărcați doar fișiere .png sau .jpg!')
            }}
        />

        <div className={"actions"}>
            <div className={"line"}>
                <div className={"col-left"}>
                    Zoom
                </div>
                <div className={"col-right"}>
                    <input type="range" className="custom-range" min="1" max="7"
                           step="0.1" value={image1Scale}
                           onChange={(event) => setImage1Scale(event.target.value)}/>
                </div>
            </div>

            <div className={"line"}>
                <ConfirmButton text={"Salvează"} function={async () => {
                    const dataUrl = cropper1.current.getImage().toDataURL()
                    const result = await fetch(dataUrl)
                    const blob = await result.blob()

                    saveCroppedImage(blob)

                    //setNewImage1(blob)
                    //setNewImage1Temp(false)
                }}/>
            </div>
        </div>

    </div>)

}

const maxWidth = 1500;
const maxHeight = 1500;
const imageQuality = 0.9;

/*
return a base64 string from the provided image
*/
export async function getBase64FromImage(image) {
    // console.log(image);

    let data = null;
    let imageObj = new Image();

    if (image.blobFile) {
        imageObj.src = window.URL.createObjectURL(image.blobFile);
    } else {
        imageObj.src = window.URL.createObjectURL(image);
    }

    let promise = new Promise((resolve, reject) => {
        imageObj.onload = function () {
            // Resized image in base64
            data = resizeImage(imageObj, maxWidth, maxHeight, imageQuality);
            resolve();

            // eslint-disable-next-line
        }.bind(this);
    })

    await Promise.all([promise]);

    return data
}

export function resizeImage(image, maxWidth, maxHeight, quality, returnBase64 = true) {
    let canvas = document.createElement('canvas');

    let width = image.width;
    let height = image.height;

    if (width > height) {
        if (width > maxWidth) {
            height = Math.round(height * maxWidth / width);
            width = maxWidth;
        }
    } else {
        if (height > maxHeight) {
            width = Math.round(width * maxHeight / height);
            height = maxHeight;
        }
    }

    canvas.width = width;
    canvas.height = height;

    let ctx = canvas.getContext("2d");
    ctx.drawImage(image, 0, 0, width, height);

    if (returnBase64) {
        return canvas.toDataURL("image/jpeg", quality);
    } else {
        return canvas
    }
}

export async function resizeBlob(blobFile) {
    let imageObj = new Image();

    imageObj.src = window.URL.createObjectURL(blobFile);
    let resizedImage = false

    let promise = new Promise((resolve, reject) => {
        imageObj.onload = function () {
            // Resized image in base64
            resizedImage = resizeImage(imageObj, 2000, 2000, 0.9, false)

            resolve();

            // eslint-disable-next-line
        }.bind(this);
    })

    await Promise.all([promise]);

    let blob = false

    let promise2 = new Promise((resolve, reject) => {
        resizedImage.toBlob(function (newBlob) {
            blob = newBlob
            resolve();
        })
    })

    await Promise.all([promise2]);

    return blob
}

export function reorderLocations(locations) {

    //Combine dates
    for (let i = 0; i < locations.length; i++) {

        let date = moment(locations[i].day).format('YYYY-MM-DD')
        let time = moment(locations[i].time).format('HH:mm')
        let combinedDate = moment(date + ' ' + time, 'YYYY-MM-DD HH:mm')

        locations[i].combinedDate = combinedDate.toDate()
    }

    //Reorder
    locations = locations.sort(function (a, b) {
        // Turn your strings into dates, and then subtract them
        // to get a value that is either negative, positive, or zero.
        return new Date(a.combinedDate) - new Date(b.combinedDate);
    });

    return locations
}

//const strings
export const usedCurrency = "Euro";
export const maxScreenWidth = "700px";

export const seatingChartCanvasWidth = 1500;
export const seatingChartCanvasHeight = 800; //window.innerHeight - (10*window.innerHeight/100);

//organizer event types
export const eventTypeWedding = "Nunta";
export const eventTypeChristening = "Botez";
export const eventTypeParty = "Petrecere";
