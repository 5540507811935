import React from 'react'
import './InvitePage.scss'
import axios from 'axios'
import Modal from 'react-responsive-modal'
import { toast } from 'react-toastify';
import {ConfirmButton, getUrlFromImage} from '../../components/Helper'
import {getDetailedDate} from "../../components/InviteTemplatesFunctions";
import Loader from "../../components/Loader";
import moment from "moment-timezone";
import {getOrdinalNum} from "../../functions";

class InvitePage extends React.Component {
	state = {
		wasLogged: false,
		event_data: {},
		participant_data: {},
		denyModal: false,

		showModal: false,
		loading: true,
		language: 'ro',
		inviteDeadlinePassed: false,

		actionLoading: false
	}

	skipPersons = [
		{
			"Id": 3351,
			"Date": "2022-10-03 17:14:46",
			"Status": "unread",
			"Name": "Simona Bitanescu",
			"Email": "bitanescu.simonxxxxa@gmail.com",
			"Cont": "Nu",
			"Urmaritori": "Nu",
			"Postari": "Nu",
			"Teme Viitoare": "UI/UX design\nGoogle Analytics 4 \nDigital trends",
			"Viitori Speakeri": "'-",
			"Acceptance": 1,
			"Abonare Newsletter": ""
		}
	]

	componentDidMount = async () => {
		document.body.classList.add('body-scroll')
		let jwt = localStorage.getItem("jwt")

		if (jwt !== null) {
			//is logged in
			let auth = {
				headers: {
					'Authorization': 'Bearer ' + jwt
				}
			}

			await axios.post(process.env.REACT_APP_DOMAIN + "utils/checkJwt", {}, auth)
				.then(async _ => {
					await axios.get(process.env.REACT_APP_DOMAIN + "getParticipant/" + this.props.match.params.idParticipant, auth)
						.then(response => {

							let tempLanguage = 'ro'
							if(response.data.event.email_templates && response.data.event.email_templates.inviteLanguage && response.data.event.email_templates.inviteLanguage.length > 0) {
								tempLanguage = response.data.event.email_templates.inviteLanguage
							}

							if(this.props.match.params.template === '1') {
								if(response.data.event.email_templates && response.data.event.email_templates.inviteLanguage1 && response.data.event.email_templates.inviteLanguage1.length > 0) {
									tempLanguage = response.data.event.email_templates.inviteLanguage1
								}
							}
							if(this.props.match.params.template === '2') {
								if(response.data.event.email_templates && response.data.event.email_templates.inviteLanguage2 && response.data.event.email_templates.inviteLanguage2.length > 0) {
									tempLanguage = response.data.event.email_templates.inviteLanguage2
								}
							}

							if(response.data.participant.inviteStatus > 0) {
								toast.error(tempLanguage === 'en' ? "Error! You have already responded to this invitation!" : "Eroare! Ați răspuns deja la această invitație!")
								if(response.data.participant.inviteStatus < 3) {
									this.props.history.push('/eventPage/' + response.data.event.id_eveniment);
								} else {
									this.props.history.push('/events');
								}
							}

							//Check deadline
							if(response.data.event.invites_deadline && response.data.event.invites_deadline.length > 0) {
								if(moment(response.data.event.invites_deadline).isBefore(moment(new Date()))) {
									this.setState({
										inviteDeadlinePassed: true
									})
								}
							}

							this.setState({
								wasLogged: true,
								event_data: response.data.event,
								participant_data: response.data.participant,
								language: tempLanguage
							})
						})
						.catch(err => {
							toast.error("Eroare! Această invitație nu vi se adreseaza!")
							this.props.history.push('/events');
						})
				})
				.catch(err => {
					localStorage.clear()
					toast.error("Informatiile de autentificare au expirat, va rugam sa va autentificati")
					this.props.history.push('/login?redirectBackTo=invite/' + this.props.match.params.idParticipant + '/' + this.props.match.params.username + '/' + this.props.match.params.identifier + '/' + this.props.match.params.template);
				})
		}
		else {

			if(this.props.match.params.username === '0' && this.props.match.params.identifier === '0') {
				toast.error("Vă rugăm să vă autentificați!")
				this.props.history.push('/login?redirectBackTo=invite/' + this.props.match.params.idParticipant + '/' + this.props.match.params.username + '/' + this.props.match.params.identifier + '/' + this.props.match.params.template);
			return;
			}

			//is not logged in
			let obj = {
				identifier: this.props.match.params.username,
				password: this.props.match.params.identifier
			}

			await axios.post(process.env.REACT_APP_DOMAIN + 'auth/local', obj)
				.then(async (response) => {
					localStorage.setItem('jwt', response.data.jwt);
					localStorage.setItem('id', response.data.user.id);
					localStorage.setItem('rememberMe', "remember")

					let auth = {
						headers: {
							'Authorization': 'Bearer ' + response.data.jwt
						}
					}

					await axios.get(process.env.REACT_APP_DOMAIN + "getParticipant/" + this.props.match.params.idParticipant, auth)
						.then(response => {

							let tempLanguage = 'ro'
							if(response.data.event.email_templates && response.data.event.email_templates.inviteLanguage && response.data.event.email_templates.inviteLanguage.length > 0) {
								tempLanguage = response.data.event.email_templates.inviteLanguage
							}

							if(this.props.match.params.template === '1') {
								if(response.data.event.email_templates && response.data.event.email_templates.inviteLanguage1 && response.data.event.email_templates.inviteLanguage1.length > 0) {
									tempLanguage = response.data.event.email_templates.inviteLanguage1
								}
							}
							if(this.props.match.params.template === '2') {
								if(response.data.event.email_templates && response.data.event.email_templates.inviteLanguage2 && response.data.event.email_templates.inviteLanguage2.length > 0) {
									tempLanguage = response.data.event.email_templates.inviteLanguage2
								}
							}

							//Check deadline
							if(response.data.event.invites_deadline && response.data.event.invites_deadline.length > 0) {
								if(moment(response.data.event.invites_deadline).isBefore(moment(new Date()))) {
									this.setState({
										inviteDeadlinePassed: true
									})
								}
							}

							this.setState({
								event_data: response.data.event,
								participant_data: response.data.participant,
								language: tempLanguage
							})
						})
						.catch(err => {
							toast.error("Eroare! Această invitație nu vi se adreseaza!")
							this.props.history.push('/events');
						})

					//get push notification token if on webview
					if (window.flutter_inappwebview) {
						await window.flutter_inappwebview.callHandler('getPushNotificationToken')
							.then(token => {
								localStorage.setItem("hasToken", true);

								let data = {
									token: token
								}

								let auth = {
									headers: {
										'Authorization': 'Bearer ' + response.data.jwt
									}
								}

								axios.post(process.env.REACT_APP_DOMAIN + 'utils/storePushToken', data, auth)
							})
					}

					if(window.alityAppView) {
						window.ReactNativeWebView.postMessage(JSON.stringify({
							action: 'getPushToken',
							data: localStorage.getItem('jwt')
						}))
					}
				})
				.catch(err => {
					toast.error("A apărut o eroare!")
					// this.props.history.push("/events")
				})
		}

		this.setState({
			loading: false
		})
	}

	componentWillUnmount() {
		document.body.classList.remove('body-scroll')
	}

	confirmParticipation = () => {

		this.goToResetPass()
	}

	goToResetPass = async () => {
		if(this.state.actionLoading) {
			return
		}

		this.setState({
			actionLoading: true
		})
		localStorage.setItem("eventId", this.state.event_data.id_eveniment)

		let sendWelcomeEmail = true

		if(this.props.match.params.username === '0' && this.props.match.params.identifier === '0') {
			localStorage.removeItem("changePassword")
		} else {
			localStorage.setItem("changePassword", "yes")
		}

		let inviteTemplate = 0
		if(this.props.match.params.template === '1') {
			inviteTemplate = 1
		}
		if(this.props.match.params.template === '2') {
			inviteTemplate = 2
		}

		let obj = {
			inviteStatus: 1,
			sendConferenceWelcomeEmail: sendWelcomeEmail,
			inviteTemplate: inviteTemplate
		}

		let auth = {
			headers: {
				'Authorization': 'Bearer ' + localStorage.getItem("jwt")
			}
		}

		await axios.put(process.env.REACT_APP_DOMAIN + "participants/updateMyParticipant/" + this.state.participant_data.id, obj, auth)
			.then(_ => {
				toast.success(this.state.language === 'en' ? "The answer has been sent! Thank you!" : "Răspunsul a fost trimis! Vă mulțumim!")
				if(this.props.match.params.username === '0' && this.props.match.params.identifier === '0') {
					this.props.history.push('/eventPage/' + this.state.event_data.id_eveniment);
				} else {
					this.props.history.push("/profile")
				}
			})
			.catch(err => {
				console.log(err);
				toast.error(this.state.language === 'en' ? "Error, please try again!" : "Eroare, va rog reincercati!")
			})

		this.setState({
			actionLoading: false
		})
	}

	denyParticipation = () => {

		if(this.state.actionLoading) {
			return
		}

		/*
		this.setState({
			denyModal: true
		}) */

		localStorage.setItem("eventId", this.state.event_data.id_eveniment)

		if(this.props.match.params.username === '0' && this.props.match.params.identifier === '0') {
			localStorage.removeItem("changePassword")
		} else {
			localStorage.setItem("changePassword", "yes")
		}

		let obj = {
			inviteStatus: 3
		}

		let auth = {
			headers: {
				'Authorization': 'Bearer ' + localStorage.getItem("jwt")
			}
		}

		axios.put(process.env.REACT_APP_DOMAIN + "participants/updateMyParticipant/" + this.state.participant_data.id, obj, auth)
			.then(_ => {
				toast.success(this.state.language === 'en' ? "The answer has been sent! Thank you!" : "Răspunsul a fost trimis! Vă mulțumim!")

				if(this.props.match.params.username === '0' && this.props.match.params.identifier === '0') {
					this.props.history.push('/eventPage/' + this.state.event_data.id_eveniment);
				} else {
					this.props.history.push("/profile")
				}
			})
			.catch(err => {
				console.log(err);

				toast.error(this.state.language === 'en' ? "Error, please try again!" : "Eroare, va rog reincercati!")
			})
	}

	notSure = () => {
		let obj = {
			inviteStatus: 2
		}

		let auth = {
			headers: {
				'Authorization': 'Bearer ' + localStorage.getItem("jwt")
			}
		}

		axios.put(process.env.REACT_APP_DOMAIN + "participants/updateMyParticipant/" + this.state.participant_data.id, obj, auth)
			.then(_ => {
				toast.success(this.state.language === 'en' ? "The answer has been sent! Thank you!" : "Răspunsul a fost trimis! Vă mulțumim!")

				this.props.history.push("/events")
			})
			.catch(err => {
				console.log(err);

				toast.error(this.state.language === 'en' ? "Error, please try again!" : "Eroare, va rog reincercati!")
			})
	}

	openLink = (link) => {
		if (window.flutter_inappwebview) {
			window.flutter_inappwebview.callHandler('openLink', link)
		} else if (window.alityAppView) {
			window.ReactNativeWebView.postMessage(JSON.stringify({
				action: 'openLink',
				url: link,
			}))
		} else {
			window.open(link)
		}
	}

	render() {
		if (this.state.loading)
			return <div style={{flex:1,display:'flex', justifyContent:'center', alignItems:'center', height: '100vh'}}>
				<Loader/>
		</div>

		let invitePicture = this.state.event_data.invitePicture
		let inviteDescription = this.state.event_data.inviteDescription

		if(this.props.match.params.template === '1') {
			invitePicture = this.state.event_data.invitePicture1
			inviteDescription = this.state.event_data.inviteDescription1
		}

		if(this.props.match.params.template === '2') {
			invitePicture = this.state.event_data.invitePicture2
			inviteDescription = this.state.event_data.inviteDescription2
		}

		let justImage = false
		if(invitePicture !== null && invitePicture !== undefined && (inviteDescription === null || inviteDescription.length === 0)) {
			justImage = true
		}

		const detaliedDate = getDetailedDate(this.state.event_data.data_eveniment)


		return (
			<div className={"new-invite-based-container"}
				 style={
					 this.state.event_data && this.state.event_data.id === 163 ?
				 {minHeight:'100vh', backgroundImage:'none'} :
						 {minHeight:'100vh'}
					 }>
				<div className={"invite-based-container"}>
			<div className="InvitePage-container" >

				{
					this.state.inviteDeadlinePassed ?
						<div className="InvitePage-contents">
							<div>
								{
									this.state.language === 'ro' ?
										<div style={{padding:'10px', fontSize: '1.1rem'}}>
											Ne pare rău!<br/>
											Invitația a expirat pe {moment(this.state.event_data.invites_deadline).format('DD/MM/YYYY HH:mm')}
										</div>
										:
										<div style={{padding:'10px', fontSize: '1.1rem'}}>
											We're sorry!<br/>
											The invitation expired on {moment(this.state.event_data.invites_deadline).format('DD/MM/YYYY HH:mm')}
										</div>
								}
							</div>
						</div>
						:
						<div className="InvitePage-contents">
							{
								justImage ?
									<div style={{display: 'flex', flex: 1, flexDirection: 'column'}}>
										<img  alt={""}
											src={'https://' + process.env.REACT_APP_IMAGE_DOMAIN + "" + getUrlFromImage(invitePicture)}
											style={{width: '100%'}}/>
									</div>
									:
									<>

										<div className={"row1"}>
											<img  alt={""}
												src={'https://' + process.env.REACT_APP_IMAGE_DOMAIN + "" + getUrlFromImage(invitePicture)}
												style={{width: '100%'}}/>
										</div>

										<div className={"row2"}>

											{
												this.skipPersons.find((item) => item.Email === this.state.participant_data.email) ?
													this.state.language === 'ro' ?
														<div className="text"
															 style={{}}>Bună, {this.state.participant_data.prenume} {this.state.participant_data.nume},<br/>
															Ne bucurăm că vei lua parte la {this.state.event_data.title},
															pe data
															de {detaliedDate.day} {detaliedDate.monthLiteral} {detaliedDate.year}, la
															ora {detaliedDate.hour}:{detaliedDate.minute}
														</div>
														:
														<div className="text"
															 style={{}}>Hello {this.state.participant_data.prenume} {this.state.participant_data.nume},<br/>
															We are glad that you will take part
															in {this.state.event_data.title},
															on {detaliedDate.day} {detaliedDate.monthLiteralEn} {detaliedDate.year}, {detaliedDate.hour}:{detaliedDate.minute}
														</div>
													:
													this.state.language === 'ro' ?
														<div className="text"
															 style={{}}>Bună, {this.state.participant_data.prenume} {this.state.participant_data.nume},<br/>
															Te invităm
															la {this.state.event_data.title},
															pe data
															de {detaliedDate.day} {detaliedDate.monthLiteral} {detaliedDate.year}, la
															ora {detaliedDate.hour}:{detaliedDate.minute}.</div>
														:
														<div className="text"
															 style={{}}>Dear {this.state.participant_data.prenume} {this.state.participant_data.nume},<br/>
															We invite you to {this.state.event_data.title},
															on {getOrdinalNum(detaliedDate.day)} of {detaliedDate.monthLiteralEn} {detaliedDate.year}, {detaliedDate.hour}:{detaliedDate.minute}.</div>
											}
											<div className="text" style={{
												display: 'flex',
												alignItems: 'center'
											}}> {inviteDescription} </div>
										</div>

									</>
							}
							<div className="Answer">

								<p className={"ask-title"}>{
									this.state.language === 'en' ?
										"Do you want to participate?"
										:
										"Doriți să participați?"
								}</p>

								{
									this.skipPersons.find((item) => item.Email === this.state.participant_data.email) ?
										<div className="Options">
											<div className="Yes" onClick={this.confirmParticipation}>
												{
													this.state.language === 'en' ?
														"Confirm"
														:
														"Confirmă"
												}</div>
										</div>
										:
										<div className="Options">
											<div className="No" onClick={this.denyParticipation}>{
												this.state.language === 'en' ?
													"No"
													:
													"Nu particip"
											}</div>
											<div className="Yes" onClick={this.confirmParticipation}>{
												this.state.language === 'en' ?
													"Yes"
													:
													"Particip"
											}</div>
										</div>
								}
							</div>

							<Modal
								classNames={{
									overlay: 'modal-overlay',
									modal: 'modal-no-top',
									closeButton: 'modal-close-button',
								}}
								open={this.state.denyModal}
								focusTrapped={false}
								onClose={() => {
									this.props.history.push("/events")
								}}
							>
								<div style={{marginTop: "30px"}}>

									{
										this.state.language === 'en' ?
											<p>
												We are sorry that you will not be present at this event.
												<br/><br/>
												We look forward to seeing you at the following events organized by us.
												<br/><br/>
											</p>
											:
											<p>
												Ne pare rău că nu vei fi prezent la acest eveniment.
												<br/><br/>
												Te așteptăm cu drag la următoarele evenimente organizate de noi.
												<br/><br/>
											</p>
									}

									<ConfirmButton
										style={{maxWidth: '100%', width: 'unset'}}
										text={this.state.language === 'en' ?
											'See more events'
											:
											'Vezi aici evenimentele publicate în Ality'}
										function={() => {
											this.props.history.push("/events")
										}}
									/>
								</div>
							</Modal>

						</div>
				}
			</div>
				</div>
			</div>
		)
	}
}

export default InvitePage;
