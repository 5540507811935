import React from 'react'
import './CreateEvent.scss'
import HeaderWhiteBack from '../../../components/HeaderWhite'
import {SelectPicker, Icon, DatePicker} from 'rsuite'
import Modal from "react-responsive-modal";
import GooglePlacesAutocomplete, {geocodeByAddress} from 'react-google-places-autocomplete';
import NavbarColor from '../../../components/NavbarColor'
import axios from 'axios'
import {toast} from 'react-toastify'
import isBefore from 'date-fns/isBefore'
import CustomInput from '../../components/custom/CustomInput';
import {
    BackAndConfirmButtons, BackButton, ConfirmButton,
    getBase64FromImage,
    RedButton, reorderLocations,
    resizeBlob,
    textarea_max_length
} from '../../components/Helper';
import LoadingModal from "./../LoadingModal/LoadingModal";
import moment from "moment-timezone";
import HeaderWhite from "../../../components/HeaderWhite";
import Loader from "../../components/Loader";
import {isAfter} from "date-fns";
import {
    convertDateTimeToBrowserTimezone,
    convertDateTimeToDefaultTimezone,
    getMaxYearsPrivateEvent
} from "../../../functions";
import LanguageContext from "../../../contexts/LanguageContext";
import EventTypeSelector from "./components/EventTypeSelector/EventTypeSelector";

let locatiiNunta = [{label: "Locatie petrecere", value: "Locatie petrecere"}, {
    label: "Locatie biserica",
    value: "Locatie biserica"
}, {label: "Locatie cununie", value: "Locatie cununie"}, {label: "Alta locatie", value: "Alta locatie"}]
let locatiiBotez = [{label: "Locatie petrecere", value: "Locatie petrecere"}, {
    label: "Locatie biserica",
    value: "Locatie biserica"
}, {label: "Alta locatie", value: "Alta locatie"}]
let locatiiPetrecere = [{label: "Locatie petrecere", value: "Locatie petrecere"}, {
    label: "Alta locatie",
    value: "Alta locatie"
}]
let locatiiCorporate = [{label: "Locatie eveniment", value: "Locatie eveniment"}, {
    label: "Alta locatie",
    value: "Alta locatie"
}]

class CreateEvent extends React.Component {
    static contextType = LanguageContext
    state = {
        loading: false,
        showModal: false,
        prenumeMireasa: "",
        prenumeMire: "",
        numeCopil: "",
        numePetrecere: "",
        dataEvent: new Date(),
        eventType: "",
        eventDescription: "",
        backgroundImage: null,
        croppedImage: null,
        locationsTypes: [],

        base64ImageLink: "",

        locations: [],
        locationType: "",
        locationName: "",
        locationData: new Date(),
        locationDay: new Date(),
        locationAddress: "",

        eventTypesCount: {
            'Nunta': 0,
            'Botez': 0,
            'Petrecere': 0,
            'Mot': 0,
            'Turta': 0,
            'Cununie': 0
        },

        stage: 1,
        prenumeOrganizator: '',
        prenumePartener: '',
        organizerType: -1,
        showLocationsTutorialModal: false,
        prenumeMama: '',
        prenumeTata: '',

        editIndex: -1,
        tutorialModal: false,
        showBusinessModal: false,

        showEventLimitModal: false,

        showLanguageTempModal: this.context.language !== 'ro',

        waitModal: false,
        finalLoading: false,
        initialLoading: true
    }

    maxFreeEventsPerType = 3

    componentDidMount = () => {
        if (localStorage.getItem('jwt') === null) {
            toast.error("Trebuie sa va logati pentru a accesa pagina")
            this.props.history.push('/login?redirectBackTo=organizer/addEvent');
            return
        }

        let type = localStorage.getItem("eventType")

        if (type !== null) {
            this.handleJobSelect(type)

            localStorage.removeItem("eventType")
        }

        this.getEventsNumber()
    }

    getEventsNumber = async () => {
        let auth = {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('jwt')
            }
        }

        let eventTypesCount = {
            'Nunta': 0,
            'Botez': 0,
            'Petrecere': 0,
            'Mot': 0,
            'Turta': 0,
            'Cununie': 0
        }

        axios.get(process.env.REACT_APP_DOMAIN + 'weddings/list/myWeddings', auth)
            .then(response => {

                response.data.forEach((wedding,i) => {

                    if(wedding.packages && wedding.packages.wedding_participants) {

                    } else {

                        if (wedding.eventType === 'Nunta') {
                            eventTypesCount.Nunta = eventTypesCount.Nunta + 1
                        }
                        if (wedding.eventType === 'Cununie') {
                            eventTypesCount.Cununie = eventTypesCount.Cununie + 1
                        }
                        if (wedding.eventType === 'Botez') {
                            eventTypesCount.Botez = eventTypesCount.Botez + 1
                        }
                        if (wedding.eventType === 'Petrecere') {
                            eventTypesCount.Petrecere = eventTypesCount.Petrecere + 1
                        }
                        if (wedding.eventType === 'Mot') {
                            eventTypesCount.Mot = eventTypesCount.Mot + 1
                        }
                        if (wedding.eventType === 'Turta') {
                            eventTypesCount.Turta = eventTypesCount.Turta + 1
                        }
                    }
                })

                this.setState({
                    eventTypesCount: eventTypesCount,
                    initialLoading: false
                })

            })
    }

    handleJobSelect = (value) => {

        if(value === 'Nunta' || value === 'NuntaBotez') {
            if(this.state.eventTypesCount.Nunta >= this.maxFreeEventsPerType)  {
                this.setState({showEventLimitModal: true})
                return
            }
        }

        if(value === 'Botez') {
            if(this.state.eventTypesCount.Botez >= this.maxFreeEventsPerType)  {
                this.setState({showEventLimitModal: true})
                return
            }
        }

        if(value === 'Petrecere') {
            if(this.state.eventTypesCount.Petrecere >= this.maxFreeEventsPerType)  {
                this.setState({showEventLimitModal: true})
                return
            }
        }

        if(value === 'Mot') {
            if(this.state.eventTypesCount.Mot >= this.maxFreeEventsPerType)  {
                this.setState({showEventLimitModal: true})
                return
            }
        }

        if(value === 'Turta') {
            if(this.state.eventTypesCount.Turta >= this.maxFreeEventsPerType)  {
                this.setState({showEventLimitModal: true})
                return
            }
        }




        let locationsTypes = locatiiNunta
        if (value === "Nunta" || value === "NuntaBotez" || value === "Cununie") {
            locationsTypes = locatiiNunta
        } else if (value === "Botez" || value === "Mot" || value === "Turta") {
            locationsTypes = locatiiBotez
        } else if (value === "Petrecere") {
            locationsTypes = locatiiPetrecere
        } else {
            locationsTypes = locatiiCorporate
        }

        this.setState({
            eventType: value === 'NuntaBotez' ? 'Nunta' : value,
            prenumeMireasa: "",
            prenumeMire: "",
            numeCopil: "",
            numePetrecere: "",
            dataEvent: new Date(),
            prenumeOrganizator: '',
            prenumePartener: '',
            organizerType: -1,
            locations: [],
            locationsTypes: locationsTypes,
            stage: this.state.stage + 1
        })
    }

    selectLocationType = (value) => {
        this.setState({
            locationType: value
        })
    }

    changeLocationName = (value) => {
        this.setState({
            locationName: value
        })
    }

    changeLocationDate = (value) => {
        this.setState({
            locationData: value
        })
    }

    changeLocationDay = (value) => {
        this.setState({
            locationDay: value
        })
    }

    changeEventDescription = (value) => {
        if (this.state.eventDescription.length > textarea_max_length - 1 && value.length > textarea_max_length - 1) {
            return
        }

        this.setState({
            eventDescription: value
        })
    }

    setLocationAddress = (value) => {
        this.setState({
            locationAddress: value.description
        })
    }

    addLocation = () => {
        let {locationAddress, locationData, locationDay, locationName} = this.state;

        if (this.state.locationType === "") {
            toast.error("Adaugati tipul sau numele locatiei")
            return
        }

        if (this.state.locationType === "Alta locatie" && locationName === "") {
            toast.error("Adaugati numele locatiei ")
            return
        }

        if (locationName === "") {
            locationName = this.state.locationType
        }

        if (locationData === "") {
            toast.error("Adaugati ora evenimentului ")
            return
        }

        if (locationDay === "") {
            toast.error("Adaugati ziua evenimentului ")
            return
        }

        if (locationAddress === "") {
            toast.error("Adaugati adresa evenimentului")
            return
        }

        let current = [...this.state.locations]

        geocodeByAddress(locationAddress)
            .then(results => {

                let pinx = parseFloat(results[0].geometry.location.lat())
                let piny = parseFloat(results[0].geometry.location.lng())

                let location = {
                    address: locationAddress,
                    pinx: pinx,
                    piny: piny,
                    time: locationData,
                    day: locationDay,
                    name: locationName
                }

                if (this.state.editIndex !== -1) {
                    current[this.state.editIndex] = location
                } else {
                    current.push(location)
                }

                current = reorderLocations(current)

                this.setState({
                    locations: current,
                    locationName: "",
                    locationAddress: "",
                    locationType: "",
                    locationData: new Date(),
                    locationDay: new Date(this.state.dataEvent),
                    editIndex: -1,
                    showModal: false
                })
            })
    }

    editLocation = (index) => {
        let location = this.state.locations[index]
        let list = this.state.locationsTypes.filter(elem => elem.value === location.name)

        this.setState({
            editIndex: index,
            locationAddress: location.address,
            locationType: list.length === 0 ? "Alta locatie" : location.name,
            locationData: new Date(location.time),
            locationDay: location.day === undefined ? new Date(location.time) : new Date(location.day),
            locationName: list.length === 0 ? location.name : "",

            showModal: true
        })
    }

    removeLocation = () => {
        let index = this.state.editIndex
        let newLocations = [...this.state.locations]

        newLocations.splice(index, 1)

        this.setState({
            locations: newLocations,
            locationName: "",
            locationAddress: "",
            locationType: "",
            locationData: new Date(),
            locationDay: new Date(this.state.dataEvent),
            editIndex: -1,
            showModal: false
        })
    }

    openModal = () => {
        this.setState({
            showModal: true
        })
    }

    closeModal = () => {
        this.setState({
            locationName: "",
            locationAddress: "",
            locationType: "",
            locationData: new Date(),
            locationDay: new Date(this.state.dataEvent),
            editIndex: -1,
            showModal: false
        })
    }

    handleError = err => {
        console.error(err)
    }

    onChange = (value, key) => {
        this.setState({
            [key]: value
        })
    }

    handlePoza = async (fileList) => {
        if (fileList.length === 0) {
            this.setState({
                backgroundImage: null
            })
            return
        }

        let blob = await resizeBlob(fileList[0].blobFile)

        this.setState({
            backgroundImage: [blob]
        })
    }

    saveCroppedImage = async (croppedImg) => {
        let img = {}
        img.blobFile = croppedImg
        let data = await getBase64FromImage(img)

        this.setState({
            croppedImage: croppedImg,
            base64ImageLink: data
        })
    }

    moveToMarketplace = () => {
        this.props.history.push('/organizer/marketplace');
    }

    createEvent = async (redirectToPreview = false) => {
        if (this.state.eventType === "" || this.state.dataEvent === "") {
            toast.error("Nu ati completat toate campurile")
            return;
        }

        if (this.state.eventType === "Nunta" || this.state.eventType === "Cununie") {
            if (this.state.prenumeMireasa === "" || this.state.prenumeMire === "") {
                toast.error("Nu ați completat toate campurile")
                return;
            }
        }

        if (this.state.eventType === "Botez" || this.state.eventType === "Mot" || this.state.eventType === "Turta") {
            if (this.state.numeCopil === "") {
                toast.error("Nu ați completat toate campurile")
                return;
            }
        }

        if (this.state.eventType === "Petrecere") {
            if (this.state.numePetrecere === "") {
                toast.error("Nu ați completat toate câmpurile")
                return;
            }
        }

        this.setState({
            waitModal: true
        })

        let customQuestions = [
            {
                type: 1,
                title: "Ce meniu doriti?",
                options: ["Normal", "Vegetarian", "Vegan"]
            },
            {
                type: 1,
                title: "Ce meniu doreste insotitorul?(Daca veniti insotit)",
                options: ["Normal", "Vegetarian", "Vegan"]
            },
            {
                type: 1,
                title: "Aveti nevoie de cazare?",
                options: ["Da", "Nu"]
            },
            {
                type: 0,
                title: "Doriti cu cineva anume la masa?",
                options: []
            },
            {
                type: 0,
                title: "Nu doriti cu cineva anume la masa?",
                options: []
            },
        ]

        let numeM = this.state.prenumeMire

        if (this.state.eventType === "Botez" || this.state.eventType === "Mot" || this.state.eventType === "Turta") {
            numeM = this.state.numeCopil
        }

        if (this.state.eventType === "Petrecere") {
            numeM = this.state.numePetrecere
            customQuestions = []
        }

        let obj = {
            user: localStorage.getItem('id'),
            eventType: this.state.eventType,
            prenumeMireasa: (this.state.eventType === "Nunta" || this.state.eventType === "Cununie") ? this.state.prenumeMireasa : "Mireasa",
            prenumeMire: numeM,
            prenumeOrganizator: this.state.prenumeOrganizator,
            prenumeMama: this.state.prenumeMama,
            prenumeTata: this.state.prenumeTata,
            organizerType: this.state.organizerType,
            dataEvent: this.state.dataEvent,
            eventDescription: this.state.eventDescription,
            locations: this.state.locations,
            generalInfo: true,
            budget: 0,
            questions: customQuestions,
            isPublished: false,
            imageLink: this.state.base64ImageLink
        }


        let auth = {
            headers: {
                // "Content-Type": `multipart/form-data;`,
                'Authorization': 'Bearer ' + localStorage.getItem('jwt')
            }
        }

        axios.post(process.env.REACT_APP_DOMAIN + 'weddings/create', obj, auth)//formData, auth)
            .then(async response => {
                toast.success("Evenimentul a fost creat!")

                this.setState({
                    waitModal: false,
                    id_eveniment: response.data.id_eveniment
                })

                if((this.state.eventType === 'Nunta' || this.state.eventType === 'Cununie') ||
                    (this.state.eventType !== 'Nunta' && this.state.eventType !== 'Cununie' && moment(new Date(this.state.dataEvent)).diff(moment(new Date()), 'months') < 6)
                ) {
                    this.setState({
                        stage: this.state.stage+1
                    })
                } else {
                    this.props.history.push('/organizer/adminEventPage/' + response.data.id_eveniment)
                }


                /*
                if (redirectToPreview) {
                    this.props.history.push('/organizer/editEvent/' + response.data.id_eveniment)
                    this.props.history.push('/organizer/eventPage/' + response.data.id_eveniment)
                } else {
                    this.props.history.push('/organizer/adminEventPage/' + response.data.id_eveniment + '?tutorial=true')
                }
                 */
            })
            .catch(err => {
                toast.error("Eroare")
                this.setState({
                    waitModal: false,
                    finalLoading: false
                })
            })
    }

    updateDateAndCreateEvent = () => {

        if(this.state.eventType === 'Nunta' || this.state.eventType === 'Cununie') {
            if (this.state.organizerType === 0) {
                this.setState({
                    prenumeMire: this.state.prenumeOrganizator,
                    prenumeMireasa: this.state.prenumePartener
                }, () => {
                    this.createEvent()
                })
            }

            if (this.state.organizerType === 1) {
                this.setState({
                    prenumeMire: this.state.prenumePartener,
                    prenumeMireasa: this.state.prenumeOrganizator
                }, () => {
                    this.createEvent()
                })
            }

            if (this.state.organizerType === 2) {
                this.createEvent()
            }
        } else if(this.state.eventType === 'Botez' || this.state.eventType === 'Mot' || this.state.eventType === 'Turta') {
            if (this.state.organizerType === 0) {
                this.setState({
                    prenumeMire: this.state.numeCopil,
                    prenumeTata: this.state.prenumeOrganizator
                }, () => {
                    this.createEvent()
                })
            }

            if (this.state.organizerType === 1) {
                this.setState({
                    prenumeMire: this.state.numeCopil,
                    prenumeMama: this.state.prenumeOrganizator
                }, () => {
                    this.createEvent()
                })
            }

            if (this.state.organizerType === 2) {
                this.createEvent()
            }
        } else if(this.state.eventType === 'Petrecere') {
            this.createEvent()
        } else {
            this.createEvent()
        }
    }

    handleStartDate = (value) => {
        this.setState({
            dataEvent: value.toISOString(),
            locationDay: value.toISOString()
        })
    }

    openLink = (link) => {
        if (window.flutter_inappwebview) {
            window.flutter_inappwebview.callHandler('openLink', link)
        } else if (window.alityAppView) {
            window.ReactNativeWebView.postMessage(JSON.stringify({
                action: 'openLink',
                url: link,
            }))
        } else {
            window.open(link)
        }
    }

    goBack = () => {

        if(this.state.stage > 4) {
            this.props.history.go(-1)
            return
        }

        if (this.state.stage > 1) {
            this.setState({
                stage: this.state.stage - 1
            })
        } else {
            this.props.history.go(-1)
        }
    }

    render() {

        let locations = <div></div>

        let svgHeight = "100px"

        if (this.state.locations.length > 0) {
            let columnStyle = {width: "30%"}
            locations = <div className="location">
                <div className="header">
                    <div style={columnStyle}>Nume Locație</div>

                    <div style={columnStyle}>Adresă</div>

                    <div style={columnStyle}>Data</div>

                    <div style={{width: "10%"}}>Edit</div>
                </div>

                {this.state.locations.map((element, index) => {
                    let addr = element.address.split(',')[0]
                    let date = null;
                    let hour = null;

                    let time = element.time

                    if (typeof time === "string") {
                        time = new Date(element.time);
                    }

                    date = element.day

                    if (date === undefined) {
                        date = new Date(element.time)
                    } else {
                        date = new Date(date)
                    }
                    date = [date.getFullYear(), date.getMonth() + 1, date.getDate()]

                    hour = new Date(element.time)
                    hour = [hour.getHours(), hour.getMinutes()]

                    return <div className={index % 2 === 0 ? "even-line" : "uneven-line"} key={index}
                                onClick={() => this.editLocation(index)}>
                        <div style={columnStyle}> {element.name}</div>
                        <div style={columnStyle}> {addr.toString()} </div>
                        <div style={columnStyle}>
                            {/* eslint-disable-next-line*/}
                            {moment(element.day).format('DD-MM-YYYY')} {(hour[0].toString().length == 1 ? '0' + hour[0].toString() : hour[0].toString()) + ":" + (hour[1].toString().length == 1 ? '0' + hour[1].toString() : hour[1].toString())} </div>
                        <div style={{width: "10%"}}><Icon icon={"edit"}/></div>
                    </div>
                })}
            </div>
        }

        let customInputStyle = {
            margin: "10px 0px",
            backgroundColor: "#E5F7FE",
            borderRadius: "10px"
        }

        if(this.state.initialLoading) {
           return (<div className="CreateEvent-container font-family-poppins-300">
               <HeaderWhite/>
                <div className="CreateEvent-contents">
                    <Loader/>
                </div>

               <NavbarColor history={this.props.history} active={"Create_event"}/>
            </div>)
        }

        return (
            <div className="CreateEvent-container font-family-poppins-300">
                {
                    this.state.stage === 1 ?
                        <HeaderWhite/>
                        :
                        <HeaderWhiteBack goBack={this.goBack}/>
                }

                <div className="CreateEvent-contents">

                    <h4 className="Title"> Creează eveniment </h4>

                    <div className={"progress-bar"}>
                        <div className={"inner"} style={{
                            width: ((this.state.stage - 1) * 33.33 + '%')
                        }}>
                        </div>
                    </div>

                    {
                        this.state.stage === 1 ?
                            <div className="Input_Center">

                                    <EventTypeSelector
                                        svgHeight={svgHeight}
                                        handleJobSelect={this.handleJobSelect}
                                        />



                                <Modal
                                    classNames={{
                                        overlay: 'modal-overlay',
                                        modal: 'modal-no-top',
                                        closeButton: 'modal-close-button',
                                    }}
                                    open={this.state.showBusinessModal}
                                    focusTrapped={false}
                                    onClose={() => {
                                        this.setState({
                                            showBusinessModal: false
                                        })
                                    }}
                                >

                                    <h3>Evenimente Business</h3>

                                    <p>
                                        Va mulțumim pentru interesul arătat în a organiza un eveniment cu ajutorul aplicației Ality. Daca sunteti o firma care organizează evenimente și doriți o colaborarea cu noi dorim sa va informam ca se pot organiza următoarele tipuri de evenimente:
                                    </p>

                                    <strong>Publice:</strong>
                                    <ul>
                                        <li>Festival</li>
                                        <li>Concert</li>
                                        <li>Maraton</li>
                                        <li>Revelion</li>
                                        <li>Petrecere</li>
                                    </ul>

                                    <strong>Corporate:</strong>
                                    <ul>
                                        <li>Conferință</li>
                                        <li>Team Building</li>
                                        <li>Petrecere Corporate</li>
                                    </ul>

                                    <strong>Speciale:</strong>
                                    <ul>
                                        <li>Bal absolvire</li>
                                        <li>Ceremonie absolvire</li>
                                        <li>Bal de boboci</li>
                                        <li>Revedere</li>
                                    </ul>

                                    <p>Pentru a afla mai multe detalii si pentru a începe o colaborare cu noi vă rugăm sa accesați site-ul nostru:</p>

                                    <ConfirmButton
                                        style={{width:'100%'}}
                                        text={"Evenimente business"}
                                        function={() => this.openLink('https://ality.ro/evenimente-business/')}
                                    />


                                </Modal>
                            </div>
                            : null
                    }

                    {
                        this.state.stage === 2 ?
                            <div className={"input-zone"}>
                                <div className={"tutorial"}>
                                    Suntem fericiți că ne-ați ales să vă ajutăm în planificarea evenimentului dumneavoastră! Acum, vă rugăm să vă puneți centurile, întrucat vom avea o călătorie pe cinste! Iar Ality
                                    abia așteaptă să te cunoască mai bine, de aceea te rugăm să completezi următoarele detalii cu privire la evenimentul tău!
                                </div>

                                <ConfirmButton text={"Următorul"} function={() => {

                                    this.setState({
                                        stage: this.state.stage + 1
                                    })
                                }}/>
                            </div>
                            :null
                    }

                    {
                        this.state.stage === 3 ?
                            <div className="input-zone">

                                {
                                    (this.state.eventType === 'Nunta' || this.state.eventType === 'Cununie') ?
                                        <>
                                            <h5 className={"section-title"}>Introduceți următoarele date</h5>

                                            <CustomInput value={this.state.prenumeOrganizator}
                                                         onChange={(value) => this.onChange(value, "prenumeOrganizator")}
                                                         style={customInputStyle} placeholder={"Prenumele tău"}
                                            />

                                            <div className={"field-title"}>Ai calitatea de</div>
                                            <div className={"organizer-type-selector"}>
                                                <div
                                                    className={"item " + (this.state.organizerType === 0 ? 'active' : '')}
                                                    onClick={() => {
                                                        this.setState({
                                                            organizerType: 0,
                                                            prenumePartener: ''
                                                        })
                                                    }}>
                                                    Mire
                                                </div>
                                                <div
                                                    className={"item " + (this.state.organizerType === 1 ? 'active' : '')}
                                                    onClick={() => {
                                                        this.setState({
                                                            organizerType: 1,
                                                            prenumePartener: ''
                                                        })
                                                    }}>
                                                    Mireasă
                                                </div>
                                                <div
                                                    className={"item " + (this.state.organizerType === 2 ? 'active' : '')}
                                                    onClick={() => {
                                                        this.setState({
                                                            organizerType: 2,
                                                            prenumePartener: '',
                                                            prenumeMire: '',
                                                            prenumeMireasa: ''
                                                        })
                                                    }}>
                                                    Event planner
                                                </div>
                                            </div>

                                            {
                                                this.state.organizerType === 0 || this.state.organizerType === 1 ?
                                                    <CustomInput value={this.state.prenumePartener}
                                                                 onChange={(value) => this.onChange(value, "prenumePartener")}
                                                                 style={customInputStyle}
                                                                 placeholder={"Prenumele partenerului este"}
                                                    />
                                                    :
                                                    this.state.organizerType > -1 ?
                                                    <>
                                                        <CustomInput value={this.state.prenumeMireasa}
                                                                     onChange={(value) => this.onChange(value, "prenumeMireasa")}
                                                                     style={customInputStyle}
                                                                     placeholder={"Prenume Mireasă"}
                                                        />
                                                        <CustomInput value={this.state.prenumeMire}
                                                                     onChange={(value) => this.onChange(value, "prenumeMire")}
                                                                     style={customInputStyle}
                                                                     placeholder={"Prenume Mire"}
                                                        />
                                                    </>
                                                        :null
                                            }



                                            <div className={'controls'}>
                                                <BackButton
                                                    text={'Înapoi'}
                                                    function={this.goBack}
                                                />
                                            {
                                                this.state.organizerType > -1 && this.state.prenumeOrganizator.length > 0 &&
                                                (
                                                    ((this.state.organizerType === 0 || this.state.organizerType === 1) && this.state.prenumePartener.length > 0)
                                                    ||
                                                    (this.state.organizerType === 2 && this.state.prenumeMire.length > 0 && this.state.prenumeMireasa.length > 0)

                                                ) ?
                                                    <ConfirmButton text={"Următorul"} function={() => {

                                                        this.setState({
                                                            stage: this.state.stage + 1
                                                        })
                                                    }}/>
                                                    : null
                                            }
                                            </div>
                                        </>
                                        :
                                        this.state.eventType === 'Botez'
                                        ||  this.state.eventType === 'Mot'
                                        ||  this.state.eventType === 'Turta' ?
                                            <>
                                                <h5 className={"section-title"}>Introduceți următoarele date</h5>

                                                <CustomInput value={this.state.numeCopil}
                                                             onChange={(value) => this.onChange(value, "numeCopil")}
                                                             style={customInputStyle} placeholder={"Prenumele copilului"}
                                                             />

                                                <CustomInput value={this.state.prenumeOrganizator}
                                                             onChange={(value) => this.onChange(value, "prenumeOrganizator")}
                                                             style={customInputStyle} placeholder={"Prenumele tău"} />

                                                <div className={"field-title"}>Ai calitatea de</div>
                                                <div className={"organizer-type-selector"}>
                                                    <div
                                                        className={"item " + (this.state.organizerType === 0 ? 'active' : '')}
                                                        onClick={() => {
                                                            this.setState({
                                                                organizerType: 0,
                                                                prenumeMama: '',
                                                                prenumeTata: ''
                                                            })
                                                        }}>
                                                        Tată
                                                    </div>
                                                    <div
                                                        className={"item " + (this.state.organizerType === 1 ? 'active' : '')}
                                                        onClick={() => {
                                                            this.setState({
                                                                organizerType: 1,
                                                                prenumeMama: '',
                                                                prenumeTata: ''
                                                            })
                                                        }}>
                                                        Mamă
                                                    </div>
                                                    <div
                                                        className={"item " + (this.state.organizerType === 2 ? 'active' : '')}
                                                        onClick={() => {
                                                            this.setState({
                                                                organizerType: 2,
                                                                prenumeMama: '',
                                                                prenumeTata: ''
                                                            })
                                                        }}>
                                                        Event planner
                                                    </div>
                                                </div>

                                                {
                                                    this.state.organizerType === 0 || this.state.organizerType === 2 ?
                                                        <CustomInput value={this.state.prenumeMama}
                                                                     onChange={(value) => this.onChange(value, "prenumeMama")}
                                                                     style={customInputStyle}
                                                                     placeholder={"Prenumele mamei"}
                                                        />
                                                        :null
                                                }
                                                {
                                                    this.state.organizerType === 1 || this.state.organizerType === 2 ?
                                                        <CustomInput value={this.state.prenumeTata}
                                                                     onChange={(value) => this.onChange(value, "prenumeTata")}
                                                                     style={customInputStyle}
                                                                     placeholder={"Prenumele tatălui"}
                                                        />
                                                        :null
                                                }

                                                <div className={'controls'}>
                                                    <BackButton
                                                        text={'Înapoi'}
                                                        function={this.goBack}
                                                    />
                                                {
                                                    this.state.organizerType > -1 && this.state.prenumeOrganizator.length > 0 &&
                                                    (
                                                        ((this.state.organizerType === 0) && this.state.prenumeMama.length > 0)
                                                        ||
                                                        ((this.state.organizerType === 1) && this.state.prenumeTata.length > 0)
                                                        ||
                                                        ((this.state.organizerType === 2) && this.state.prenumeMama.length > 0 && this.state.prenumeTata.length > 0)

                                                    ) ?
                                                        <ConfirmButton text={"Următorul"} function={() => {
                                                            this.setState({
                                                                stage: this.state.stage + 1
                                                            })
                                                        }}/>
                                                        : null
                                                }
                                                </div>

                                            </>
                                            :
                                            this.state.eventType === 'Petrecere' ?
                                            <>
                                                <h5 className={"section-title"}>Introduceți următoarele date</h5>

                                                <CustomInput value={this.state.prenumeOrganizator}
                                                             onChange={(value) => this.onChange(value, "prenumeOrganizator")}
                                                             style={customInputStyle} placeholder={"Prenumele tau"}
                                                />

                                                <CustomInput value={this.state.numePetrecere}
                                                             onChange={(value) => this.onChange(value, "numePetrecere")}
                                                             style={customInputStyle} placeholder={"Nume aniversare"} />

                                                <div className={'controls'}>
                                                    <BackButton
                                                        text={'Înapoi'}
                                                        function={this.goBack}
                                                    />
                                                {
                                                    this.state.prenumeOrganizator.length > 0 && this.state.numePetrecere.length > 0 ?
                                                        <ConfirmButton text={"Următorul"} function={() => {
                                                            this.setState({
                                                                stage: this.state.stage + 1
                                                            })
                                                        }}/>
                                                        : null
                                                }
                                                </div>
                                            </>
                                                :
                                                <>
                                                    {/* Corporate events */}
                                                    <h5 className={"section-title"}>Introduceți următoarele date</h5>

                                                    <CustomInput value={this.state.prenumeOrganizator}
                                                                 onChange={(value) => this.onChange(value, "prenumeOrganizator")}
                                                                 style={customInputStyle} placeholder={"Prenumele tau"}
                                                    />

                                                    <CustomInput value={this.state.prenumeMire}
                                                                 onChange={(value) => this.onChange(value, "prenumeMire")}
                                                                 style={customInputStyle} placeholder={"Titlu eveniment"} />

                                                    <div className={'controls'}>
                                                        <BackButton
                                                            text={'Înapoi'}
                                                            function={this.goBack}
                                                        />
                                                        {
                                                            this.state.prenumeMire.length > 0 && this.state.prenumeMire.length > 0 ?
                                                                <ConfirmButton text={"Următorul"} function={() => {
                                                                    this.setState({
                                                                        stage: this.state.stage + 1
                                                                    })
                                                                }}/>
                                                                : null
                                                        }
                                                    </div>
                                                </>
                                }

                            </div>
                            : null
                    }


                    {
                        this.state.stage === 4 ?
                            <div className="input-zone">

                                <br/><br/>
                                <div className="AddHours-date-picker">
                                    <p style={{textAlign: "left"}}>Vă rog alegeți data evenimentului: </p>

                                    <div style={{display: 'flex', flexDirection: 'column'}}>
                                        <div style={{flex: 1, paddingBottom:'10px'}}>
                                            <DatePicker ranges={[]} value={new Date(this.state.dataEvent)}
                                                        format={"DD/MM/YYYY"}
                                                        style={{
                                                            width: "100%",
                                                            borderRadius: "5px",
                                                            backgroundColor: "#E5F7FE"
                                                        }}
                                                        placement="bottomStart"
                                                        disabledDate={date => isBefore(date, new Date(new Date().setDate(new Date().getDate() - 1))) || isAfter(date, moment().add(getMaxYearsPrivateEvent(this.state.eventType), 'years').toDate())}
                                                        defaultOpen={false} oneTap onSelect={this.handleStartDate}
                                                        appearance="subtle" placeholder="Data evenimentului"
                                                        locale={{
                                                            sunday: 'Sa',
                                                            monday: 'Lu',
                                                            tuesday: 'Ma',
                                                            wednesday: 'Mi',
                                                            thursday: 'Jo',
                                                            friday: 'Vi',
                                                            saturday: 'Sa',
                                                            ok: 'OK',
                                                            today: 'Azi',
                                                            yesterday: 'Ieri',
                                                            hours: 'Ore',
                                                            minutes: 'Minute',
                                                            seconds: 'Secunde'
                                                        }}
                                            />
                                        </div>

                                        <div style={{flex:1}}>
                                            <p style={{textAlign: "left"}}>Vă rog alegeți ora evenimentului: </p>
                                            <DatePicker ranges={[]}
                                                        style={{
                                                            width: "100%",
                                                            borderRadius: "5px",
                                                            backgroundColor: "#E5F7FE",
                                                            marginBottom: '10px'
                                                        }}
                                                        placement="bottomStart"
                                                        hideMinutes={minute => minute % 5 !== 0}
                                                        disabledDate={date => isBefore(date, new Date(new Date().setDate(new Date().getDate() - 1)))}
                                                        defaultOpen={false}
                                                        appearance="subtle" placeholder="Ora evenimentului"
                                                        block
                                                        locale={{
                                                            sunday: 'Sa',
                                                            monday: 'Lu',
                                                            tuesday: 'Ma',
                                                            wednesday: 'Mi',
                                                            thursday: 'Jo',
                                                            friday: 'Vi',
                                                            saturday: 'Sa',
                                                            ok: 'OK',
                                                            today: 'Azi',
                                                            yesterday: 'Ieri',
                                                            hours: 'Ore',
                                                            minutes: 'Minute',
                                                            seconds: 'Secunde'
                                                        }}
                                                        onSelect={(date) => {
                                                            //setLocationData(moment(date).tz('Europe/Bucharest', true).toDate())
                                                            //date = moment(date.getHours() + ':' + date.getMinutes(), "HH:mm").toDate()
                                                            this.handleStartDate(convertDateTimeToDefaultTimezone(date))
                                                        }}
                                                        value={convertDateTimeToBrowserTimezone(new Date(this.state.dataEvent))}
                                                        format="HH:mm"
                                                        renderValue={(date) => moment(date).format('HH:mm')}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className={"field-title"}>Ai stabilit locațiile pentru eveniment?</div>
                                <div className={"Options-Row"}>
                                    <div className={this.state.locations.length > 0 ? "active-option" : "inactive-option"} onClick={() => {

                                        if(this.state.locations.length === 0) {
                                            this.setState({
                                                showLocationsTutorialModal: true
                                            })
                                        }
                                    }}>
                                        Da
                                    </div>
                                    <div className={this.state.locations.length === 0 ? "active-option" : "inactive-option"} onClick={() => {
                                        this.setState({
                                            locations: []
                                        })
                                    }}>
                                        Nu
                                    </div>
                                </div>

                                {
                                    this.state.locations.length > 0 ?
                                        <div className="Locations" style={{margin: "10px 0px"}}>

                                    <div className='add-small-button' onClick={() => this.openModal()}>
                                        <Icon icon="plus-circle" size="lg"
                                              style={{color: "#05AFF2", marginRight: "10px"}}/>
                                        <div>Adauga data si locatie</div>
                                    </div>
                                    {locations}
                                </div>
                                :null
                                }

                                <Modal classNames={{
                                    overlay: 'modal-overlay',
                                    modal: 'modal-add-location',
                                    closeButton: 'modal-close-button'
                                }} open={this.state.showModal} onClose={this.closeModal}>
                                    <div style={{margin: "10px 0px"}}>
                                        <SelectPicker
                                            size="lg"
                                            onSelect={this.selectLocationType}
                                            value={this.state.locationType !== "Alta locatie" ? this.state.locationType : "Alta locatie"}
                                            searchable={false}
                                            appearance="subtle"
                                            placement="bottom"
                                            id="job-select"
                                            data={this.state.locationsTypes}
                                            style={{
                                                width: "100%",
                                                marginTop: "20px",
                                                borderRadius: "5px",
                                                backgroundColor: "#E5F7FE"
                                            }}
                                            placeholder="Selecteaza locatie"
                                        >
                                        </SelectPicker>
                                    </div>

                                    <div style={{margin: "10px 0px"}}>
                                        {this.state.locationType === "Alta locatie"
                                            ? <CustomInput style={{
                                                height: "40px",
                                                width: "100%",
                                                borderRadius: "5px",
                                                padding: "5px",
                                                backgroundColor: "#E5F7FE"
                                            }}
                                                           value={this.state.locationName}
                                                           onChange={this.changeLocationName}
                                                           placeholder={"Nume Locatie"}/>
                                            : null
                                        }
                                    </div>

                                    <div style={{margin: "10px 0px"}}>
                                        <DatePicker
                                            appearance="subtle"
                                            style={{
                                                width: "100%",
                                                borderRadius: "5px",
                                                height: "40px",
                                                backgroundColor: "#E5F7FE"
                                            }}
                                            ranges={[]}
                                            block
                                            hideMinutes={minute => minute % 5 !== 0}
                                            locale={{
                                                sunday: 'Sa',
                                                monday: 'Lu',
                                                tuesday: 'Ma',
                                                wednesday: 'Mi',
                                                thursday: 'Jo',
                                                friday: 'Vi',
                                                saturday: 'Sa',
                                                ok: 'OK',
                                                today: 'Azi',
                                                yesterday: 'Ieri',
                                                hours: 'Ore',
                                                minutes: 'Minute',
                                                seconds: 'Secunde'
                                            }}
                                            placeholder="Ora eveniment"
                                            size="lg"
                                            onSelect={this.changeLocationDay}
                                            value={new Date(this.state.locationDay)}
                                            placement="bottomEnd"
                                        />
                                    </div>

                                    <div style={{margin: "10px 0px"}}>
                                        <DatePicker
                                            appearance="subtle"
                                            style={{
                                                width: "100%",
                                                borderRadius: "5px",
                                                height: "40px",
                                                backgroundColor: "#E5F7FE"
                                            }}
                                            ranges={[]}
                                            block
                                            hideMinutes={minute => minute % 5 !== 0}
                                            locale={{
                                                sunday: 'Sa',
                                                monday: 'Lu',
                                                tuesday: 'Ma',
                                                wednesday: 'Mi',
                                                thursday: 'Jo',
                                                friday: 'Vi',
                                                saturday: 'Sa',
                                                ok: 'OK',
                                                today: 'Azi',
                                                yesterday: 'Ieri',
                                                hours: 'Ore',
                                                minutes: 'Minute',
                                                seconds: 'Secunde'
                                            }}
                                            placeholder="Ora eveniment"
                                            size="lg"
                                            onSelect={this.changeLocationDate}
                                            value={new Date(this.state.locationData)}
                                            placement="bottomEnd" format="HH:mm"
                                        />
                                    </div>

                                    <div style={{width: "100%", margin: "10px 0px"}} id='fromGoogleAutocomplete'>
                                        <GooglePlacesAutocomplete
                                            debounce={500}
                                            minLengthAutocomplete={3}
                                            currentLocation={false}
                                            placeholder={"Adresa"}
                                            onSelect={value => {
                                                this.setLocationAddress(value)
                                            }}
                                            autocompletionRequest={{
                                                componentRestrictions: {
                                                    country: 'ro'
                                                }
                                            }}
                                            initialValue={this.state.locationAddress}
                                            inputStyle={{
                                                width: "100%",
                                                padding: "5px",
                                                backgroundColor: "#E5F7FE",
                                                border: "0px",
                                                borderRadius: "5px",
                                                height: "40px"
                                            }}
                                        />
                                    </div>

                                    <BackAndConfirmButtons textBack={"Înapoi"} textConfirm={"Salveaza"}
                                                           functionBack={this.closeModal}
                                                           functionConfirm={this.addLocation}/>

                                    {this.state.editIndex !== -1 &&
                                    <RedButton text={"Sterge"} function={this.removeLocation}/>}
                                </Modal>


                                <Modal classNames={{
                                    overlay: 'modal-overlay',
                                    modal: 'modal-add-location',
                                    closeButton: 'modal-close-button'
                                }} open={this.state.showLocationsTutorialModal} onClose={() => {
                                    this.setState({
                                        showLocationsTutorialModal: false
                                    })
                                    this.openModal()
                                }}>
                                    <div style={{margin: "20px 0px"}}>
                                        <p>
                                            Un  aspect despre care dorim sa discutam sunt locatiile pe care le veti adăuga, acestea se vor adăuga automat pe invitație și foarte important este faptul ca aceste adrese sunt integrate automat cu Waze și Google Maps astfel invitații nu vor trebui sa își bată capul legat de cum sa ajungă la eveniment. Dacă încă nu știți aceste detalii nicio problema se pot adauga mai tarziu sau le puteti adauga direct pe invitație în momentul creării acesteia.
                                            Suntem doar la început, vom avea un journey lung impreuna!
                                        </p>

                                        <ConfirmButton text={"Ok"} function={() => {
                                            this.setState({
                                                showLocationsTutorialModal: false
                                            })
                                            this.openModal()
                                        }}/>
                                    </div>
                                </Modal>


                                <div className={'controls'}>
                                    <BackButton
                                        text={'Înapoi'}
                                        function={this.goBack}
                                    />
                                <ConfirmButton text={"Finalizează"} disabled={this.state.finalLoading} function={() => {
                                    this.setState({
                                        finalLoading: true
                                    }, () => {
                                        this.updateDateAndCreateEvent()
                                    })
                                }}/>
                                </div>

                            </div>
                            : null
                    }

                    {
                        this.state.stage === 5 ?
                            <div className={"input-zone"}>

                                <div className={"section-title"}>
                                    <h5 style={{marginTop: '50px'}}>Felicitari! Evenimentul a fost creat! Vezi cat de ușor a fost?</h5></div>
                                <div className={"tutorial"} style={{fontWeight:'400'}}>
                                    Acum e momentul sa discutam puțin mai multe despre detaliile organizatorice. Nu-ți face griji, informațiile pe care le-ai introdus pană acum se pot modifica oricand din meniul ,,Informații eveniment” -> ,,Descriere eveniment” -> ,,Editează”
                                </div>

                                {
                                    this.state.eventType === 'Nunta' ?
                                        moment(new Date(this.state.dataEvent)).diff(moment(new Date()), 'months') >= 6 ?
                                            <div>
                                                <h5 className={"section-title"}>Doriți să creați acum  “Save the date”?</h5>

                                                <BackAndConfirmButtons
                                                    textBack={'Nu, mulțumesc!'}
                                                    functionBack={() => {this.props.history.push('/organizer/adminEventPage/' + this.state.id_eveniment)}}
                                                    textConfirm={'Creează acum!'}
                                                    functionConfirm={() => {this.props.history.push('/organizer/addSaveTheDate/' + this.state.id_eveniment)}}
                                                    />
                                            </div>
                                            :
                                            <div>
                                                <h5 className={"section-title"}>Doriți să creați acum invitația?</h5>

                                                <BackAndConfirmButtons
                                                    textBack={'Nu, mulțumesc!'}
                                                    functionBack={() => {this.props.history.push('/organizer/adminEventPage/' + this.state.id_eveniment)}}
                                                    textConfirm={'Creează acum!'}
                                                    functionConfirm={() => {this.props.history.push('/organizer/editInvite/' + this.state.id_eveniment)}}
                                                />
                                            </div>
                                        :
                                        <div>
                                            <h5 className={"section-title"}>Doriți să creați acum invitația?</h5>

                                            <BackAndConfirmButtons
                                                textBack={'Nu, mulțumesc!'}
                                                functionBack={() => {this.props.history.push('/organizer/adminEventPage/' + this.state.id_eveniment)}}
                                                textConfirm={'Creează acum!'}
                                                functionConfirm={() => {this.props.history.push('/organizer/editInvite/' + this.state.id_eveniment)}}
                                            />
                                        </div>
                                }


                            </div>
                            :null
                    }
                </div>

                <LoadingModal loading={this.state.waitModal}/>

                <Modal classNames={{
                    overlay: 'modal-overlay',
                    modal: 'modal-add-location',
                    closeButton: 'modal-close-button'
                }} open={this.state.showEventLimitModal} onClose={() => {
                    this.setState({
                        showEventLimitModal: false
                    })
                }}>

                    <br/><br/>
                    <h5 style={{textAlign:'center'}}>Limita de evenimente gratuite de acest tip a fost atinsă!</h5>
                    <br/>
                    <p style={{textAlign:'center'}}>Poți avea maxim câte 3 evenimente gratuite din fiecare tip.</p>
                    <br/>
                    <ConfirmButton
                        style={{width:'100%'}}
                        text={'Am înțeles'}
                        function={() => {
                            this.setState({
                                showEventLimitModal: false
                            })
                        }} />

                    <BackButton
                        style={{width:'100%'}}
                        text={'Mergi la evenimentele tale'}
                        function={() => {
                            this.props.history.push('/organizer/viewEvents')
                        }} />

                </Modal>

                <Modal
                    classNames={{
                        overlay: 'modal-overlay',
                        modal: 'modal-profile',
                        closeButton: 'modal-close-button',
                    }}
                    styles={{
                        overlay: {zIndex: '1000000000000000'}
                    }}
                    open={this.state.showLanguageTempModal}
                    onClose={() => this.setState({showLanguageTempModal: false})}
                    focusTrapped={false}
                >
                    <div>
                        <br/><br/>
                        <h6>We apologize, this section is not yet available in English.</h6>
                        <br/>
                        <ConfirmButton
                            style={{width:'100%'}}
                            text={'Ok'}
                            function={() => {
                                this.setState({
                                    showLanguageTempModal: false
                                })
                            }} />
                    </div>
                </Modal>


                <NavbarColor history={this.props.history} active={"Create_event"}/>
            </div>
        )
    }
}

export default CreateEvent;
