import {Icon} from "rsuite";
import React from "react";
import "./PackageCard.scss"
import {getMainPackageAvailability} from "../../../../functions";

export default function PackageCard(
    {
        event,
        packageItem,
        isCurrentPackage,
        currentPackageOrder,
        currentPackage,
        showAllAvailablePackages,
        selectedPackage,
        setSelectedPackage,
        packagesDescriptions,
        voucher,
        showDetailsModal,
        addOnSelected,
        setAddOnSelected,
        addOnPrice,
        setAddOnPrice,
    }) {
    if (!showAllAvailablePackages && packageItem.order < currentPackageOrder && packageItem.order < 3) {
        return null
    }
    const currentAddOnPrice = packageItem && packageItem.addons && packageItem.addons.find(item => item.id === addOnSelected) ? packageItem.addons.find(item => item.id === addOnSelected).price : 0

    const isDisabled = () => {
        //For free package
        if (packageItem.id === 0) {
            return true
        }

        if (packageItem.order < currentPackageOrder && packageItem.order < 3) {
            return true
        }

        return false
    }

    const getDiscountValuePerPackage = () => {
        if (voucher) {
            //If applies on total
            if (voucher.applies_on_total) {
                return voucher.percentage && voucher.percentage === true ?
                    Math.ceil((packageItem.price + currentAddOnPrice - getCurrentPackagePrice()) * voucher.value / 100)
                    :
                    voucher.value
            } else {
                //applies on certain packages
                if (voucher.applies_on_packages.includes(packageItem.id)) {
                    return voucher.percentage && voucher.percentage === true ?
                        Math.ceil(((packageItem.price + currentAddOnPrice - getCurrentPackagePrice()) * voucher.value / 100))
                        :
                        voucher.value
                } else {
                    return 0
                }
            }
        } else {
            return 0
        }

    }

    const getCurrentPackagePrice = () => {
        if (currentPackage && currentPackage.details && currentPackage.details.price) {

            let oldAddOnPrice = 0
            currentPackage.details && currentPackage.details.addons &&
            currentPackage.details.addons.length > 0 &&
            currentPackage.details.addons.forEach(item => oldAddOnPrice += item.price)
            return currentPackage.details.price + oldAddOnPrice
        } else {
            return 0
        }
    }

    const getFinalPrice = () => {
        let discountValue = getDiscountValuePerPackage()
        let currentPackageValue = getCurrentPackagePrice()

        let oldAddOnPrice = 0
        event.packages && event.packages.wedding_participants && event.packages.wedding_participants.details && event.packages.wedding_participants.details.addons &&
        event.packages.wedding_participants.details.addons.length > 0 &&
        event.packages.wedding_participants.details.addons.forEach(item => oldAddOnPrice += item.price)
        let total = packageItem.price + currentAddOnPrice - discountValue - currentPackageValue

        if (event && event.packages && event.packages.wedding_participants.package === packageItem.id && currentAddOnPrice === oldAddOnPrice) {
            total = 0
        }

        return total > 0 ? total : 0
    }

    const onClick = () => {
        if (!isDisabled() && finalPrice >= 0 && !(isCurrentPackage && currentPackage && currentPackage.details && currentPackage.details.addons && currentPackage.details.addons.length > 0)) {
            if (selectedPackage === packageItem.id){
                setSelectedPackage(null)
            } else{
                setSelectedPackage(packageItem.id)
                if (addOnSelected){
                    setAddOnPrice(packageItem.addons.find(item => item.id === addOnSelected).price)
                }
            }
            /* if (!(currentPackage && currentPackage.details && currentPackage.details.addons && currentPackage.details.addons.length > 0)){
                 setAddOnSelected(null)
                 setAddOnPrice(0)
             }*/
        }
    }

    const finalPrice = getFinalPrice()
    const discountValue = getDiscountValuePerPackage()
    const availabilityDate = getMainPackageAvailability(event)

    return (<div
        className={"package-card " + (isCurrentPackage ? 'current' : (selectedPackage === packageItem.id ? 'selected' : (isDisabled() ? 'disabled' : '')))
        }
        onClick={onClick}
    >

        {isCurrentPackage ?
            <div className={"package-current-badge"}>Pachetul curent</div>
            :
            packageItem.id > 0 ?
                <div className={"package-check-cont"}>
                    {selectedPackage === packageItem.id ?
                        <Icon icon={"check-circle"} size={"2x"}/>
                        :
                        <Icon icon={"circle-thin"} size={"2x"}/>
                    }
                </div>
                : null
        }

        <div className={"package-title"}>{packageItem.title}</div>
        <div className={"package-description"}>
            {packagesDescriptions[packageItem.id] && packagesDescriptions[packageItem.id].descriptionLine1 &&
                <span>{packagesDescriptions[packageItem.id].descriptionLine1}</span>}

            {packagesDescriptions[packageItem.id] && packagesDescriptions[packageItem.id].descriptionLine2 &&
                <span>{packagesDescriptions[packageItem.id].descriptionLine2}</span>}

            {packagesDescriptions[packageItem.id] && packagesDescriptions[packageItem.id].descriptionLine3 &&
                <span>{packagesDescriptions[packageItem.id].descriptionLine3}</span>}

        </div>
        {selectedPackage === packageItem.id && packageItem.addons && packageItem.addons.length > 0 &&
            <div className={"package-addons"}>
                <div className="Options-Row">
                    {!(currentPackage && currentPackage.details && currentPackage.details.addons && currentPackage.details.addons.length > 0) &&
                        <div
                            className={addOnSelected === null ? "active-option" : "inactive-option"}
                            onClick={(e) => {
                                e.preventDefault()
                                e.stopPropagation()
                                setAddOnSelected(null)
                                setAddOnPrice(0)

                            }}
                            style={{marginRight: "30px"}}>Standard {addOnSelected !== null && currentAddOnPrice ? '(-' + currentAddOnPrice + 'lei)' : ''}
                        </div>}
                    {
                        packageItem.addons.map((item) => (
                            <div key={item.id}
                                 className={addOnSelected === item.id ? "active-option" : "inactive-option"}
                                 onClick={(e) => {
                                     e.preventDefault()
                                     e.stopPropagation()

                                     if (!(isCurrentPackage && currentPackage && currentPackage.details && currentPackage.details.addons && currentPackage.details.addons.length > 0)) {
                                         setAddOnSelected(item.id)
                                         setAddOnPrice(item.price)
                                     }

                                 }}>
                                <> {'Cu '}{item.title.toLowerCase()} {addOnSelected !== item.id && item.price && item.price !== 0 ? '(+' + (item.price - currentAddOnPrice) + 'lei)' : ''}</>
                            </div>
                        ))
                    }

                </div>
            </div>}

        <div className={"package-footer"}>

            <div className={"package-footer-left"}>
                <div className={"see-more-cont"} onClick={(e) => {
                    e.preventDefault()
                    e.stopPropagation()
                    showDetailsModal()
                }}>
                    Vezi mai multe detalii <Icon icon="angle-down"/>
                </div>

            </div>
            <div className={"package-footer-right"}>

                {isCurrentPackage && availabilityDate ?
                    <div style={{textAlign: 'end', marginTop: '0.8rem'}}> Valabil până
                        la {availabilityDate.format('DD/MM/YYYY')}</div>
                    : null}

                {!(isCurrentPackage && currentPackage && currentPackage.details && currentPackage.details.addons && currentPackage.details.addons.length > 0) && <div className={"package-current-price"}>
                    {
                        packageItem.price > 0 ?
                            <>{finalPrice ? <>
                                {packageItem.oldPrice ?
                                    <div
                                        className={"old-price"}>{packageItem.oldPrice + currentAddOnPrice} lei</div>
                                    : null}

                                {discountValue ?
                                    <div
                                        className={"old-price"}>{packageItem.price + currentAddOnPrice} lei</div>
                                    : null}

                                {getCurrentPackagePrice() ?
                                    <div
                                        className={"old-price"}>{packageItem.price + currentAddOnPrice - discountValue} lei</div>
                                    : null}

                                <div>{finalPrice < packageItem.price ? 'doar ' + finalPrice : finalPrice} lei</div>
                            </> : ''}</>
                            :
                            <div>Gratuit</div>
                    }
                </div>}
            </div>

        </div>


    </div>)

}
