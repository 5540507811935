import React from 'react'
import {injectIntl} from 'react-intl';
import {ConfirmButton} from "../../organizer/components/Helper";
import axios from "axios";
import Loader from "../../components/Loader";

class DrawTab extends React.Component {
    state = {
        event_data: this.props.event_data,
        participantData: this.props.participantData,
        participant: null,
        drawLoading: false
    }

    componentDidMount = () => {

    }

    drawParticipant = async () => {

        if (this.state.drawLoading) {
            return
        }

        this.setState({
            drawLoading: true
        })

        let auth = {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('jwt')
            }
        }

        let obj = {}

        await axios.post(process.env.REACT_APP_DOMAIN + "event/drawParticipant/" + this.state.participantData.id, obj, auth)
            .then(response => {
                this.setState({
                    participant: response.data.data.participant,
                })
            })
            .catch(err => {

            })

        this.setState({
            drawLoading: false
        })
    }

    render() {
        return (
            <div className="DiplomaTab-container">
                <div className="DiplomaTab-contents">
                    {this.props.intl.formatMessage({id: 'event_page.draw.start_info'})}
                    <ConfirmButton
                        function={() => this.drawParticipant()}
                        text={this.props.intl.formatMessage({id: 'event_page.draw.start'})}
                    />

                    {this.state.drawLoading ? <Loader/> : null}

                    {this.state.participant ?
                        <div style={{paddingTop: '15px'}}>
                            <small>{this.props.intl.formatMessage({id: 'event_page.draw.selected_participant'})}</small>
                            {this.state.event_data.draw_configuration && this.state.event_data.draw_configuration.show_name_and_email ?
                                <>
                                    <h3>{this.state.participant.prenume} {this.state.participant.nume}</h3>
                                    <p>{this.state.participant.email}</p>
                                </>
                                :
                                <h3>{this.state.participant.id}</h3>
                            }
                        </div> : null}
                </div>
            </div>
        )
    }
}

export default injectIntl(DrawTab);
