import React from 'react'
import {Button} from 'rsuite'
import './Exports.scss'
import HeaderWhite from '../../../components/HeaderWhite'
import NavbarColor from '../../../components/NavbarColor'
import axios from 'axios'
import PreviewSaveTheDateForExports from './ForExports/PreviewSaveTheDateForExports'
import SeatingChartForExports from './ForExports/SeatingChartForExports'
import {toast} from 'react-toastify'
import SettingCard from '../Profile/components/SettingCard'
import Modal from "react-responsive-modal";

import SVG_Tasks from '../../assets/PlanningSVGs/tasks-icon.svg'
import SVG_Gifts from '../../assets/PlanningSVGs/cadou-icon.svg'
import SVG_Seating_Chart from '../../assets/PlanningSVGs/plan-locatie-icon.svg'
import SVG_ProgramZiua from '../../assets/PlanningSVGs/program-ziua.svg'
import SVG_Invite from "../../assets/EventInfoSVG/invitatii-icon.svg";
import SVG_Seat_Participants from '../../assets/GuestInfoSVG/seat-participants-icon.svg'
import SVG_Rasp_Save from '../../assets/GuestInfoSVG/rasp-save.svg'
import SVG_Gift_Money from '../../assets/GuestInfoSVG/cadou-dar-icon.svg'
import {getWeddingParticipantsPackageNumber} from "../../../functions";
import BeatLoader from "react-spinners/BeatLoader";
import {css} from "@emotion/react";
import Loader from "../../components/Loader";
import {toPng} from "html-to-image";

let hasData = true;
let defaultChoice = "Alegeti ce doriti sa descarcati";
const inviteRef = React.createRef();
const saveTheDateRef = React.createRef();
const seatingChartRef = React.createRef();

class Exports extends React.Component {
    state = {
        event_data: [],
        responses_data: [],
        eventName: "",

        downloadChoice: defaultChoice,
        downloadOptions: ["Task-uri", "Cadouri", "Daruri", "Programul zilei", "Participanti la mese",
            "Toate Raspunsurile", "Participanti Confirmati", "Invitatie", "SaveTheDate", "Plan mese"],

        downloadOptionsIcons: [SVG_Tasks, SVG_Gifts, SVG_Gift_Money, SVG_ProgramZiua, SVG_Seat_Participants,
            SVG_Rasp_Save, SVG_Rasp_Save, SVG_Invite, SVG_Invite, SVG_Seating_Chart, false],

        tasks: [],
        gifts: [],
        responses: [],
        yesResponses: [],
        tutorialModal: false,

        seatingData: [],
        tableData: [],

        pdfInvitePreviewDataUrl: '',
        pdfSaveTheDatePreviewDataUrl: '',
        pdfSeatingChartPreviewDataUrl: '',

        loading: true,
        loadingData: false,

        loadingType: ''
    }

    componentDidMount = () => {
        this.getEventData("")
    }

    getEventData = async (choice) => {
        let auth = {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('jwt')
            }
        }

        // get event data
        let event_info = await axios.get(process.env.REACT_APP_DOMAIN + 'weddings/getThisWedding/' + this.props.match.params.id, auth);
        // console.log(event_info);

        if (choice === "Invitatie") {
            if (!this.state.event_data.invite) {
                toast.info("Invitatia nu este creata")
                hasData = false
            } else {

                if (!event_info.data.invitatieInfo) {
                    toast.info("Invitatia nu este creata")
                    hasData = false
                } else {
                    if (event_info.data.invite.inviteType === "Video") {
                        toast.info("Invitatia video nu se poate printa")
                        hasData = false
                    }
                }
            }

            if (window.flutter_inappwebview) {
                toast.info("Descarcarea de pdf-uri nu este disponibila momentan in aplicatie, va rugam intrati din browser")
                hasData = false
            }
        }

        if (choice === "SaveTheDate") {
            if (!this.state.event_data.saveTheDate) {
                toast.info("SaveTheDate nu este creata")
                hasData = false
            } else {
                if (!event_info.data.saveTheDateInfo) {
                    toast.info("Save the date nu este creata")
                    hasData = false
                } else {
                    if (event_info.data.saveTheDate.saveTheDateType === "Video") {
                        toast.info("SaveThedate video nu se poate printa")
                        hasData = false
                    }
                }
            }

            if (window.flutter_inappwebview) {
                toast.info("Descarcarea de pdf-uri nu este disponibila momentan in aplicatie, va rugam intrati din browser")
                hasData = false
            }
        }


        this.setState({
            event_data: event_info.data,
            eventName: event_info.data.eventType === "Nunta" ? `Nunta_${event_info.data.prenumeMire}-${event_info.data.prenumeMireasa} ` : `Eveniment_${event_info.data.prenumeMire}`,
            tutorialModal: !event_info.data.tutorials || (event_info.data.tutorials && !event_info.data.tutorials.exports),
            loading: false
        })


        //seating data
        let tableData = []
        if (event_info.data.isSeatingChartDone) {
            tableData = event_info.data.seatingChart.positions.filter(elem => elem.type.endsWith("Table"))
        } else {
            if (choice === "Participanti la mese" || choice === "Plan mese") {
                toast.info("Planul de mese nu a fost publicat")
                hasData = false
            }
        }

        if (window.flutter_inappwebview && choice === "Plan mese") {
            toast.info("Descarcarea de pdf-uri nu este disponibila momentan in aplicatie, va rugam intrati din browser")
            hasData = false
        }

        let maxPeopleAtTable = 0
        let i = 0

        //get highest number of people at a table
        for (i = 0; i < tableData.length; i++) {
            if (tableData[i].peopleAtTable.length > 0) {
                let total = 0

                for (let j = 0; j < tableData[i].peopleAtTable.length; j++) {
                    total += tableData[i].peopleAtTable[j].nrPeople
                }

                if (maxPeopleAtTable < total) {
                    maxPeopleAtTable = total
                }
            }
        }

        let seatingData = []

        //create empty array
        for (i = 0; i < maxPeopleAtTable; i++) {
            let newData = {}

            for (let j = 0; j < tableData.length; j++) {
                newData["masa" + j] = "-"
            }

            seatingData.push(newData)
        }

        //add valid data
        for (i = 0; i < tableData.length; i++) {
            if (tableData[i].peopleAtTable.length > 0) {
                let seatingIndex = 0

                for (let j = 0; j < tableData[i].peopleAtTable.length; j++) {
                    let info = tableData[i].peopleAtTable[j]

                    seatingData[seatingIndex]["masa" + i] = info.prenume
                    seatingIndex++

                    if (info.kidsCount !== null) {
                        for (let k = 0; k < Number(info.kidsCount); k++) {
                            seatingData[seatingIndex]["masa" + i] = "copil - " + info.prenume
                            seatingIndex++
                        }
                    }

                    if (seatingIndex === info.nrPeople && info.namePartner !== "") {
                        seatingData[seatingIndex]["masa" + i] = info.namePartner
                        seatingIndex++
                    }
                }
            }
        }

        this.setState({
            seatingData: seatingData,
            tableData: tableData,
            loading: false
        })
    }

    getTasksFromServer = async () => {
        let auth = {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('jwt')
            }
        }

        let tasksInfo = await axios.get(process.env.REACT_APP_DOMAIN + 'tasks/list/byEvent/' + this.props.match.params.id, auth);
        // console.log(tasksInfo);

        let tasks = tasksInfo.data.map((elem, index) => {
            elem.index = index + 1

            return elem
        })

        if (tasks.length === 0) {
            toast.info("Nu aveti task-uri create.")
            hasData = false
        }

        this.setState({
            tasks: tasks
        })
    }

    getGiftsFromServer = async () => {
        let auth = {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('jwt')
            }
        }

        let giftsInfo = await axios.get(process.env.REACT_APP_DOMAIN + 'gifts/list/byEvent/' + this.props.match.params.id, auth);
        // console.log(giftsInfo);

        let gifts = giftsInfo.data.map((elem, index) => {
            elem.index = index + 1

            return elem
        })

        if (gifts.length === 0) {
            toast.info("Nu aveti cadouri adaugate.")
            hasData = false
        }

        this.setState({
            gifts: gifts
        })
    }

    getResponsesFromServer = async () => {
        let auth = {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('jwt')
            }
        }

        let responsesInfo = await axios.get(process.env.REACT_APP_DOMAIN + 'responses/list/byEvent/' + this.props.match.params.id, auth)
        // console.log(responsesInfo);

        this.setState({
            responses_data: responsesInfo.data.responses
        })

        if (responsesInfo.data.responses.length === 0) {
            toast.info("Nu aveti raspunsuri primite.")
            hasData = false
        }

        let responses = responsesInfo.data.responses.map((elem, index) => {
            let newVal = {...elem.response}
            newVal.index = index + 1

            let newGiftMoneyValue = responsesInfo.data.responses[index].giftMoney
            if (newGiftMoneyValue === null) {
                newGiftMoneyValue = 0
            }
            newVal.giftMoney = newGiftMoneyValue

            return newVal
        })

        //get only yes answers
        let yesResponses = responses.filter(elem => elem.answer === "yes")

        this.setState({
            responses: responses,
            yesResponses: yesResponses
        })
    }

    changeDownloadChoice = async (choice) => {

        if ((getWeddingParticipantsPackageNumber(this.state.event_data) === 5) &&
            (choice === 'Invitatie' || choice !== 'SaveTheDate')) {
            toast.warn('Această funcționalitate nu este disponibilă pentru pachetul curent!')
            return;
        }

        if ((getWeddingParticipantsPackageNumber(this.state.event_data) !== 2 && getWeddingParticipantsPackageNumber(this.state.event_data) !== 4) &&
            (choice !== 'Invitatie' && choice !== 'SaveTheDate' && choice !== "Participanti la mese" && choice !== "Toate Raspunsurile")) {
            toast.warn('Această funcționalitate este disponibilă doar pentru pachetul Premium')
            return;
        }

        if (window.alityAppView && choice !== 'Invitatie') {
            toast.warn('Această funcționalitate nu este disponibilă momentan pe dispozitivele mobile. Vă rugăm folosiți un laptop / desktop.')
            return;
        }


        this.setState({
            loadingData: true
        })

        hasData = true;

        //load data
        if (choice === "Task-uri") {
            await this.getTasksFromServer()
        } else if (choice === "Cadouri") {
            await this.getGiftsFromServer()
        } else if (choice === "Toate Raspunsuri" || choice === "Participanti Confirmati" || choice === "Daruri") {
            await this.getResponsesFromServer()
        } else {
            await this.getEventData(choice)
        }
        if (choice === "Invitatie") {

            /*
            if(window.alityAppView) {
                window.ReactNativeWebView.postMessage(JSON.stringify({
                    action: 'openLink',
                    url: 'https://utils.ality.ro/api/download-image/' + this.state.event_data.id_eveniment + '/0',
                }))
            } else {
                window.open('https://utils.ality.ro/api/download-image/' + this.state.event_data.id_eveniment + '/0')
            }*/


            /*
    const element = inviteRef.current;

    await toPng(element, { cacheBust: false, skipAutoScale: true })
        .then((dataUrl) => {
            this.setState({
                pdfInvitePreviewDataUrl: dataUrl
            })
        })
        .catch((err) => {
            console.log(err)
        })
*/
        }
        if (choice === "SaveTheDate") {

            const element = saveTheDateRef.current;
            await toPng(element, {cacheBust: false, skipAutoScale: true})
                .then((dataUrl) => {
                    this.setState({
                        pdfSaveTheDatePreviewDataUrl: dataUrl
                    })
                })
                .catch((err) => {
                    console.log(err)
                })

        }
        if (choice === "Plan mese") {
            const element = seatingChartRef.current;

            await toPng(element, {cacheBust: false, skipAutoScale: true})
                .then((dataUrl) => {
                    this.setState({
                        pdfSeatingChartPreviewDataUrl: dataUrl
                    })
                })
                .catch((err) => {
                    console.log(err)
                })
        }

        //change choice & stop loading
        let newChoice = choice

        if (!hasData) {
            newChoice = defaultChoice
        }

        this.setState({
            downloadChoice: newChoice,
            loadingData: false
        })
    }

    updateTutorial = async () => {

        this.setState({
            tutorialModal: false
        })

        let auth = {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('jwt')
            }
        }

        await axios.post(process.env.REACT_APP_DOMAIN + 'weddings/update/tutorials/' + this.props.match.params.id,
            {
                exports: true
            },
            auth)

    }

    exportInvite = async (size) => {
        this.setState({
            loadingData: true
        })

        let url = 'https://utils.ality.ro/api/download-image/' + this.state.event_data.id_eveniment + '/0?size=' + size

        if (window.alityAppView) {
            window.ReactNativeWebView.postMessage(JSON.stringify({
                action: 'openLink',
                url: 'https://utils.ality.ro/api/download-image/' + this.state.event_data.id_eveniment + '/0',
            }))
            this.setState({
                loadingData: false
            })
        } else {
            axios.get(url, {
                responseType: 'blob',
            })
                .then((res) => {

                    // Create blob link to download
                    const url = window.URL.createObjectURL(
                        new Blob([res.data]),
                    );
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute(
                        'download',
                        `invitatie.png`,
                    );

                    // Append to html link element page
                    document.body.appendChild(link);

                    // Start download
                    link.click();

                    // Clean up and remove the link
                    link.parentNode.removeChild(link);

                    this.setState({
                        loadingData: false
                    })

                })
        }

    }

    goBack = () => {
        this.props.history.push("/organizer/adminEventPage/" + this.props.match.params.id)
    }

    downloadData = async (type) => {


        this.setState({loadingType: type})

        if (type === 'Programul zilei') {
            type = 'day-schedule'
        }
        if (type === 'Cadouri') {
            type = 'gifts'
        }
        if (type === 'Task-uri') {
            type = 'tasks'
        }
        if (type === 'Daruri') {
            type = 'gift-money'
        }
        if (type === 'Participanti la mese') {
            type = 'seating-participants'
        }
        if (type === 'Toate Raspunsurile') {
            type = 'participants'
        }
        if (type === 'Participanti Confirmati') {
            type = 'confirmed-participants'
        }

        let auth = {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('jwt')
            }
        }

        let data = await axios.post(process.env.REACT_APP_DOMAIN + 'weddings/exportData', {
            id_eveniment: this.props.match.params.id,
            type: type
        }, auth)
        if (data.data && data.data.status) {
            this.setState({loadingType: '-'})
            return process.env.REACT_APP_DOMAIN + data.data.url
        } else {
            this.setState({loadingType: ''})
            return ''
        }


    }

    render() {
        if (this.state.loading) {
            return <div className="Exports-container">
                <HeaderWhite goBack={this.goBack}/>

                <Loader/>

                <NavbarColor history={this.props.history}/>
            </div>
        }

        let downloadChoice = <div></div>


        if (this.state.downloadChoice === "Invitatie") {
            downloadChoice = <div className="Download-Line">
                {/* <p>Invitatie </p> */}

                <p>Alegeți formatul invitației</p>
                <div className="invite-download-size-selector"
                     style={{maxWidth: '700px', width: '100%', margin: 'auto'}}>
                    <div
                        className={"option"}
                        onClick={() => this.exportInvite(1)}
                    >Mică
                    </div>
                    <div
                        className={"option"}
                        onClick={() => this.exportInvite(2)}
                    >Normală
                    </div>
                    <div
                        className={"option"}
                        onClick={() => this.exportInvite(3)}
                    >Mare
                    </div>
                </div>
            </div>
        }

        if (this.state.downloadChoice === "SaveTheDate") {
            downloadChoice = <div className="Download-Line">
                {/* <p>PDF Preview Save The Date </p> */}

                {
                    window.alityAppView ?
                        <button onClick={async () => {
                            window.ReactNativeWebView.postMessage(JSON.stringify({
                                action: 'openLink',
                                url: this.state.pdfSaveTheDatePreviewDataUrl,
                            }))
                        }}>
                            Download
                        </button>
                        :
                        <a href={this.state.pdfSaveTheDatePreviewDataUrl}
                           download={"save-the-date.png"}>
                            <button>
                                Download
                            </button>
                        </a>
                }

                {
                    this.state.pdfSaveTheDatePreviewDataUrl ?
                        <img src={this.state.pdfSaveTheDatePreviewDataUrl}
                             style={{width: '100%', maxWidth: '700px', margin: '0 auto 0 auto'}}
                             alt={""}/>
                        : null
                }

                {/* <div style={{ display: "none" }}>
					<PreviewSaveTheDateForExports event_data={this.state.event_data} ref={el => (this.refSaveTheDate = el)} />
				</div> */}
            </div>
        }

        if (this.state.downloadChoice === "Plan mese") {
            downloadChoice = <div className="Download-Line">
                {/* <p>PDF Plan mese </p> */}

                {
                    window.alityAppView ?
                        <button onClick={async () => {
                            window.ReactNativeWebView.postMessage(JSON.stringify({
                                action: 'openLink',
                                url: this.state.pdfSeatingChartPreviewDataUrl,
                            }))
                        }}>
                            Download
                        </button>
                        :
                        <a href={this.state.pdfSeatingChartPreviewDataUrl}
                           download={"plan-mese.png"}>
                            <button>
                                Download
                            </button>
                        </a>
                }

                {
                    this.state.pdfSeatingChartPreviewDataUrl ?
                        <img src={this.state.pdfSeatingChartPreviewDataUrl}
                             style={{width: '100%', maxWidth: '700px', margin: '0 auto 0 auto'}}
                             alt={""}/>
                        : null
                }

                {/* <Pdf targetRef={seatingChartRef} filename="code-example.pdf" scale={1}>
					{({ toPdf }) => <button onClick={toPdf}>Download direct</button>}
				</Pdf> */}

                {/* <div style={{ height: "1132px", width: "803px" }}>
					<div ref={seatingChartRef} id={"inviteID"} style={{ height: "100%", width: "100%" }}>
						<SeatingChartForExports seatingChart={this.state.event_data.seatingChart} responses_data={this.state.responses_data} ref={el => (this.refSeatingChart = el)} />
					</div>
				</div> */}

            </div>
        }

        return <div className="Exports-container">
            <HeaderWhite goBack={this.goBack}/>


            <>
                <div style={{position: 'absolute', top: '-800vh', width: '700px'}}
                     className={"export-invite-cont"}>
                    <div ref={inviteRef} id={"inviteID"} style={{height: "100%", width: "100%"}}>

                    </div>
                </div>

                <div
                    style={{position: 'absolute', top: '-800vh', height: "1132px", width: "803px"}}>
                    <div ref={saveTheDateRef} id={"inviteID"}
                         style={{height: "100%", width: "100%"}}>
                        {
                            this.state.event_data.saveTheDate ?
                                <PreviewSaveTheDateForExports event_data={this.state.event_data}
                                                              ref={el => (this.refSaveTheDate = el)}/>
                                : null
                        }
                    </div>
                </div>

                <div style={{position: 'absolute', top: '-800vh'}}>
                    <div ref={seatingChartRef}>
                        {
                            this.state.event_data.seatingChart ?
                                <SeatingChartForExports
                                    seatingChart={this.state.event_data.seatingChart}
                                    responses_data={this.state.responses_data}
                                    ref={el => (this.refSeatingChart = el)}/>
                                : null
                        }
                    </div>
                </div>

                {
                    this.state.loadingData ?
                        <div className={"loading-overlay"}>
                            <BeatLoader color={"#00B7AD"} loading={true} css={css} size={30}/>
                            <span>Se încarcă ...</span>
                        </div>
                        : null
                }

                <div className="Exports-contents">
                    <div className="Title">Exporturi</div>

                    <div style={{marginTop: "20px", fontWeight: "500"}}>
                        {/* <Dropdown title={this.state.downloadChoice} activeKey={this.state.downloadChoice} className="dropdown_scroll" style={{ backgroundColor: "#E5F7FE", borderRadius: "10px" }}> */}
                        {this.state.downloadOptions.map((option, index) => {
                            return <div key={index + '_' + this.state.loadingType}>
                                <SettingCard onClick={async () => {
                                    if (this.state.loadingType.length > 1) {
                                        return
                                    }

                                    if (getWeddingParticipantsPackageNumber(this.state.event_data) === 5) {
                                        toast.warn('Această funcționalitate este disponibilă doar pentru pachetul Premium')
                                        return
                                    }

                                    if ((getWeddingParticipantsPackageNumber(this.state.event_data) !== 2 && getWeddingParticipantsPackageNumber(this.state.event_data) !== 4) &&
                                        (option !== 'Invitatie' && option !== 'SaveTheDate' && option !== "Participanti la mese" && option !== "Toate Raspunsurile")) {
                                        toast.warn('Această funcționalitate este disponibilă doar pentru pachetul Premium')
                                        return;
                                    }

                                    if (option === 'Invitatie' || option === 'Plan mese' || option === 'SaveTheDate') {
                                        this.changeDownloadChoice(option)
                                        return
                                    }

                                    if (this.state.loadingType !== option) {
                                        let url = await this.downloadData(option)
                                        if (url && url.length > 2) {
                                            if (window.alityAppView) {
                                                window.ReactNativeWebView.postMessage(JSON.stringify({
                                                    action: 'openLink',
                                                    url: url,
                                                }))
                                            } else {
                                                window.open(url)
                                            }
                                        }
                                    }
                                }}
                                             svg={this.state.downloadOptionsIcons[index]}
                                             name={
                                                 this.state.loadingType === option ?
                                                     'Se pregătește...'
                                                     :
                                                     option === 'Programul zilei' ?
                                                         this.state.event_data.eventType === 'Nunta' ?
                                                             "Program ziua nunții"
                                                             :
                                                             this.state.event_data.eventType === 'Botez' ?
                                                                 "Program ziua botezului"
                                                                 :
                                                                 this.state.event_data.eventType === 'Petrecere' ?
                                                                     "Program ziua petrecerii"
                                                                     : "Programul zilei"
                                                         :
                                                         option
                                             }
                                             dropDown={this.state.downloadChoice === option}/>

                                {this.state.downloadChoice === option && <div>
                                    {downloadChoice}
                                </div>}
                            </div>
                            // return <Dropdown.Item key={index} eventKey={option} onClick={() => this.changeDownloadChoice(option)}>{option}</Dropdown.Item>
                        })}
                        {/* </Dropdown> */}
                    </div>

                    {/* {downloadChoice} */}
                </div>


                <Button
                    onClick={() => this.setState({tutorialModal: true})}
                    color="green"
                    style={{
                        maxWidth: 'var(--container-max-width)',
                        margin: 'auto',
                        backgroundColor: "#05AFF2",
                        overflow: 'visible',
                        borderRadius: "20px",
                        width: "100%",
                        marginTop: "10px",
                        marginBottom: "20px"
                    }}>Informații utile</Button>

            </>


            <Modal
                classNames={{
                    overlay: 'modal-overlay',
                    modal: 'modal-no-top',
                    closeButton: 'modal-close-button',
                }}
                open={this.state.tutorialModal}
                focusTrapped={false}
                onClose={this.updateTutorial}
            >
                <div style={{marginTop: "20px"}}>
                    <h3>Informații utile</h3>
                    <br/>
                    <p>
                        Din acest mediu puteți exporta liste, task-uri, daruri sau planul meselor.
                        Și cum unele persoane în varsta nu pot accesa invitația digitală, puteți
                        exporta, printa invitația acestora. Nu uitați sa introduceți manual în
                        aplicație răspunsul primit.
                    </p>
                    <Button
                        onClick={this.updateTutorial}
                        color="green"
                        style={{
                            backgroundColor: "#05AFF2",
                            borderRadius: "20px",
                            width: "100%",
                            marginTop: "20px"
                        }}>Ok</Button>
                </div>
            </Modal>


            <NavbarColor history={this.props.history}/>
        </div>
    }
}

export default Exports;
