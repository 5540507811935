import React from 'react'
import { Icon, Input } from 'rsuite';
import { toast } from 'react-toastify'
import axios from 'axios'
import './Events.scss'
import HeaderWhite from '../components/HeaderWhite'
import CodeModal from '../components/Events/CodeModal'
import NavbarColor from '../components/NavbarColor'
import { BsChevronDown, BsChevronUp } from 'react-icons/bs';
import { getUrlFromImage, ShareModal } from '../components/Helper';
import { eventName_to_eventType } from '../components/Constants';
import moment from "moment-timezone";
import Loader from "../components/Loader";
import {getDefaultWeddingImage, getEventNextDate, getWeddingFirstDate, getWeddingTitle} from "../functions";
import {FormattedMessage, injectIntl} from 'react-intl';
let filterToType = eventName_to_eventType

class Events extends React.Component {
	state = {
		events: [],
		weddings: [],

		dispEvents: [],
		dispWeddings: [],

		myEvents: [],
		myWeddings: [],

		privateEvents: ["Toate", "Nunta", "Botez", "Mot", "Turta", "Petrecere", "Cununie"],
		publicEvents: ["Toate", "Concert", "Festival", "Maraton", "Revelion"],
		specialEvents: ["Toate", "Ceremonie Absolvire", "Bal Absolvire", "Ceremonie & Bal", "Bal Boboci", "Revedere"],
		corporateEvents: ["Toate", "Team Building", "Petreceri Corporate", "Conferinta", "Congres"],

		category: "",
		typeChoice: "Toate",
		showEventsTypes: false,

		eventDescriptionIndex: -1,
		weddingDescriptionIndex: -1,

		loading: true,
		showModal: false,
		showModalWedding: false,
		showRedirectModal: false,
		eventIndex: 0,
		weddingIndex: 0,
		eventCode: "",

		showSearch: false,
		searchInput: "",

		showShareModal: false,
		linkToShare: "",
		linkCategory: "",
		customUrl: false
	}

	componentDidMount = async () => {


		let jwt1 = localStorage.getItem('jwt')

		if(jwt1) {

			let guestWeddingId = localStorage.getItem("guestWeddingId")
			var eventId = localStorage.getItem("eventId")
			let responseId = localStorage.getItem("responseId")

			if (responseId !== null) {
				let data = {
					responseId: responseId
				}

				let auth = {
					headers: {
						'Authorization': 'Bearer ' + jwt1
					}
				}

				await axios.post(process.env.REACT_APP_DOMAIN + 'utils/attachResponseToUser', data, auth)
			}

			if(guestWeddingId !== null) {
				let data = {
					guestWeddingId: guestWeddingId
				}

				let auth = {
					headers: {
						'Authorization': 'Bearer ' + jwt1
					}
				}

				await axios.post(process.env.REACT_APP_DOMAIN + 'utils/attachGuestWeddingToUser', data, auth)
				localStorage.removeItem('guestWeddingId')
				localStorage.removeItem('guestWeddingType')
				this.props.history.push('/organizer/editInvite/' + guestWeddingId)
				return
			}

			if (eventId !== null) {
				if (responseId !== null) {
					localStorage.removeItem('eventId');
					localStorage.removeItem('responseId')
					this.props.history.push('/eventPageW/' + eventId)
					return
				} else {
					localStorage.removeItem('eventId');
					localStorage.removeItem('responseId')
					this.props.history.push('/eventPage/' + eventId)
					return
				}

				// eslint-disable-next-line
				localStorage.removeItem('eventId');
				localStorage.removeItem('responseId')
			}
		}






		if (this.props.location.search !== "") {
			let jwt = this.props.location.search.split("?")[1]
			if (jwt === "null") {
				localStorage.clear();
			} else {
				localStorage.setItem("jwt", this.props.location.search.split("?")[1])

				if (localStorage.getItem("id") === null) {
					let auth = {
						headers: {
							'Authorization': 'Bearer ' + localStorage.getItem('jwt')
						}
					}

					axios.get(process.env.REACT_APP_DOMAIN + 'users/me', auth)
						.then(response => {
							localStorage.setItem("id", response.data.id)
							localStorage.setItem("rememberMe", "remember")
						})
				}
			}
		}

		if (localStorage.getItem("id") === null) {
			await axios.get(process.env.REACT_APP_DOMAIN + 'getAllActiveEvents')
				.then(response => {
					this.loadData(response)
				})
				.catch(err => {
					toast.error(this.props.intl.formatMessage({id: 'general.alerts.error_occurred'}))
				})
		} else {

			let auth = {
				headers: {
					'Authorization': 'Bearer ' + localStorage.getItem('jwt')
				}
			}

			await axios.get(process.env.REACT_APP_DOMAIN + 'getAllActiveEvents', auth)
				.then(response => {
					this.loadData(response)
				})
				.catch(err => {
					if(err && err.response && err.response.status === 401) {
						localStorage.clear()
						this.props.history.push('/login');
					} else {
						toast.error(this.props.intl.formatMessage({id: 'general.alerts.error_occurred'}))
					}
					this.getAllActiveEventsNonJwt()
				})
		}
	}

	getAllActiveEventsNonJwt = async () => {
		await axios.get(process.env.REACT_APP_DOMAIN + 'getAllActiveEvents')
			.then(response => {
				this.loadData(response)
			})
			.catch(err => {
				toast.error(this.props.intl.formatMessage({id: 'general.alerts.error_occurred'}))
			})
	}

	loadData = (response) => {
		if(localStorage.getItem('first_launch') && response.data && response.data.has_weddings) {
			localStorage.removeItem('first_launch')
			this.props.history.push('/organizer/viewEvents');
			return
		}

		localStorage.removeItem('first_launch')

		let oldCategory = localStorage.getItem('choiceInEvents')

		if (oldCategory === null) {
			oldCategory = "All"
		}

		let events = [...response.data.events]
		let weddings = [...response.data.weddings]

		let filterChoice = "Toate"
		let searchQuery = this.props.match.params.searchQuery
		let searchCategory = this.props.match.params.category
		let showSearchBar = false

		if (searchQuery !== undefined) {
			filterChoice = ""
			showSearchBar = true
		} else {
			searchQuery = ""
		}

		if (searchCategory !== undefined) {
			if (searchCategory === "Pu") {
				searchCategory = "Public"
			}

			if (searchCategory === "Pr") {
				searchCategory = "Private"
			}

			if (searchCategory === "Sp") {
				searchCategory = "Speciale"
			}

			if (searchCategory === "Co") {
				searchCategory = "Corporate"
			}
		} else {
			searchCategory = oldCategory
		}

		events.forEach((event,j) => {
			events[j].data_eveniment = getEventNextDate(event).toISOString()
		})
		events = events.sort(function(a,b){
			return new Date(a.data_eveniment) - new Date(b.data_eveniment);
		});

		weddings.forEach((wedding, j) => {
			weddings[j].dataEvent = getWeddingFirstDate(wedding).date.toISOString()
		})
		weddings = weddings.sort(function(a,b){
			return new Date(a.dataEvent) - new Date(b.dataEvent);
		});

		this.setState({
			events: events,
			weddings: weddings,
			dispEvents: [],//events.filter(elem => oldCategory === 'Public' ? !elem.privat : elem.privat),
			dispWeddings: [],//oldCategory === 'Public' ? [] : weddings,
			loading: false,

			searchInput: searchQuery,
			showSearch: showSearchBar,
			typeChoice: filterChoice
			// category: searchCategory
		})

		this.changeCategory(searchCategory)

		if (showSearchBar) {
			this.changeSearch(searchQuery)
		}
	}

	showModal = () => {
		let currentModalState = this.state.showModal
		this.setState({
			showModal: !currentModalState
		})
	}

	showModalWedding = () => {
		let currentModalState = this.state.showModalWedding
		this.setState({
			showModalWedding: !currentModalState
		})
	}

	showRedirectModal = () => {
		let currentModalState = this.state.showRedirectModal
		this.setState({
			showRedirectModal: !currentModalState
		})
	}

	changeCategory = (newCategory) => {
		if (newCategory === this.state.category) {
			let prevOption = this.state.showEventsTypes

			this.setState({
				showEventsTypes: !prevOption
			})

			return
		}

		localStorage.setItem('choiceInEvents', newCategory)

		var newDispEvents = [...this.state.events]
		var newDispWeddings = [...this.state.weddings]


		if(newCategory === "All" || newCategory === 'Finished') {

			/*
			newDispWeddings.map((wedding,i ) => {
				let newWedding = wedding
				newWedding.data_eveniment = wedding.dataEvent
				newWedding.isWedding = true
				newDispEvents.push(newWedding)
			})*/

			// Order all events and weddings by date start
			newDispEvents = newDispEvents.sort(function(a,b){
				// Turn your strings into dates, get timestamp and then subtract them
				// to get a value that is either negative, positive, or zero.
				return new Date(a.data_eveniment).getTime() - new Date(b.data_eveniment).getTime();
			});

		} else {

			if (newCategory === "Private") {
				newDispEvents = [];
			} else {
				newDispWeddings = [];

				let options = this.state.publicEvents

				if (newCategory === "Speciale") {
					options = this.state.specialEvents
				}

				if (newCategory === "Corporate") {
					options = this.state.corporateEvents
				}

				newDispEvents = newDispEvents.filter(elem => {
					//at poz 0 is allTypes
					for (let i = 1; i < options.length; i++) {
						if (elem.tip_eveniment === filterToType[options[i]]) {
							return true
						}
					}

					return false
				})
			}
		}

		this.setState({
			category: newCategory,
			typeChoice: "Toate",
			dispEvents: newDispEvents,
			dispWeddings: newDispWeddings,
			showEventsTypes: true,
			eventDescriptionIndex: -1,
			weddingDescriptionIndex: -1,

			showSearch: false,
			searchInput: ""
		})
	}

	changeEventType = (type) => {
		var newDispEvents = [...this.state.events]
		var newDispWeddings = [...this.state.weddings]

		let category = this.state.category

		if (category === "Private") {
			newDispEvents = [];
		} else {
			newDispWeddings = [];

			let options = this.state.publicEvents

			if (category === "Speciale") {
				options = this.state.specialEvents
			}

			if (category === "Corporate") {
				options = this.state.corporateEvents
			}

			newDispEvents = newDispEvents.filter(elem => {
				//at poz 0 is allTypes
				for (let i = 1; i < options.length; i++) {
					if (elem.tip_eveniment === filterToType[options[i]]) {
						return true
					}
				}

				return false
			})
		}

		if (type !== "Toate") {
			newDispEvents = newDispEvents.filter(elem => elem.tip_eveniment === filterToType[type])
			newDispWeddings = newDispWeddings.filter(elem => elem.eventType === filterToType[type])
		}

		this.setState({
			typeChoice: type,
			dispEvents: newDispEvents,
			dispWeddings: newDispWeddings,
			showEventsTypes: false,
			eventDescriptionIndex: -1,
			weddingDescriptionIndex: -1,

			showSearch: false,
			searchInput: ""
		})
	}

	handleChange = (value) => {
		this.setState({
			eventCode: value
		})
	}

	checkCode = (value) => {
		if (this.state.dispEvents[this.state.eventIndex].id_eveniment !== value) {
			toast.error(this.props.intl.formatMessage({id: 'events.alerts.invalid_event_code'}))

			this.setState({
				showModal: false
			})

			return
		}

		this.moveToEvent(value, true)
	}

	askForPassIfNeeded = (index) => {

		if(localStorage.getItem('jwt')) {

			if (this.state.dispEvents[index].privat && this.state.dispEvents[index].participate !== true) {
				this.setState({
					showModal: true,
					eventIndex: index
				})
			} else {
				this.moveToEvent(this.state.dispEvents[index].id_eveniment, false)
			}
		} else {
			toast.error(this.props.intl.formatMessage({id: 'general.alerts.please_log_in'}))
			this.props.history.push('/login')
		}
	}

	moveToEvent = (id_event, is_private) => {
		if (localStorage.getItem('jwt') !== null) {
			this.props.history.push('/eventPage/' + id_event);
		}
		else {
			if (is_private) {
				toast.error(this.props.intl.formatMessage({id: 'general.alerts.please_log_in'}))
				this.props.history.push('/login');
			}
			else {
				this.props.history.push('/publicEvent/' + id_event);
			}
		}
		// this.props.history.push('/invitationEvent/' + id_event)
	}

	moveToWedding = (value) => {

		if(this.state.category === 'All') {

			if (this.state.dispEvents[this.state.weddingIndex].id_eveniment !== value) {
				toast.error(this.props.intl.formatMessage({id: 'events.alerts.invalid_event_code'}))

				this.setState({
					showModalWedding: false
				})

				return
			}

			this.props.history.push('/invitation/' + value)

		} else {

			if (this.state.dispWeddings[this.state.weddingIndex].id_eveniment !== value) {
				toast.error(this.props.intl.formatMessage({id: 'events.alerts.invalid_event_code'}))

				this.setState({
					showModalWedding: false
				})

				return
			}

			this.props.history.push('/invitation/' + value)
		}
	}

	askForWeddingEventPass = (index) => {
		if (this.state.dispWeddings[index].participate === true) {

			this.props.history.push('/eventPageW/' + this.state.dispWeddings[index].id_eveniment)

		} else {

			this.setState({
				showModalWedding: true,
				weddingIndex: index
			})
		}
	}

	askForWeddingEventPassSecond = (wedding, index) => {
		if (wedding.participate === true) {

			this.props.history.push('/eventPageW/' + wedding.id_eveniment)

		} else {
			this.setState({
				showModalWedding: true,
				weddingIndex: index
			})
		}
	}

	setShowSearch = () => {
		this.setState({
			showSearch: true,
			typeChoice: "",
			showEventsTypes: false
		})
	}

	closeSearch = () => {
		this.setState({
			showSearch: false,
			typeChoice: "Toate"
		})

		this.changeEventType("Toate")
	}

	changeSearch = (value) => {
		var newDispEvents = [...this.state.events]
		var newDispWeddings = [...this.state.weddings]

		let category = this.state.category

		if (category === "Private") {
			newDispEvents = []
		} else if(category === 'All') {

		} else {
			newDispWeddings = []
		}

		newDispEvents = newDispEvents.filter(elem => elem.title.toLocaleLowerCase().indexOf(value.toLocaleLowerCase()) !== -1)
		newDispWeddings = newDispWeddings.filter(elem => {
			let title = elem.eventType + " "
			if (elem.eventType === "Nunta") {
				title += elem.prenumeMireasa + " si " + elem.prenumeMire
			}
			if (elem.eventType === "Botez" || elem.eventType === "Petrecere") {
				title += elem.prenumeMire
			}

			title = title.toLocaleLowerCase()

			return title.indexOf(value.toLocaleLowerCase()) !== -1 || title.indexOf(value.toLocaleLowerCase()) !== -1
		})

		if(category === 'All' || category === 'Finished') {
			newDispWeddings.forEach((wedding,i ) => {
				let newWedding = wedding
				newWedding.data_eveniment = wedding.dataEvent
				newWedding.isWedding = true
				newDispEvents.push(newWedding)
			})

			// Order all events and weddings by date start
			newDispEvents = newDispEvents.sort(function(a,b){
				// Turn your strings into dates, get timestamp and then subtract them
				// to get a value that is either negative, positive, or zero.
				return new Date(a.data_eveniment).getTime() - new Date(b.data_eveniment).getTime();
			});
		}

		this.setState({
			dispEvents: newDispEvents,
			dispWeddings: newDispWeddings,
			showEventsTypes: false,
			eventDescriptionIndex: -1,
			weddingDescriptionIndex: -1,

			searchInput: value
		})
	}

	openShareModal = (data, type) => {
		let link = ""
		let category = ""

		if (type === "event") {
			link = data.title

			let categoryFound = false
			//look in public events type
			for (let i = 0; i < this.state.publicEvents.length; i++) {
				if (data.tip_eveniment === filterToType[this.state.publicEvents[i]]) {
					category = "Pu"
					categoryFound = true
					break
				}
			}

			if (!categoryFound) {
				//look in special events type
				for (let i = 0; i < this.state.specialEvents.length; i++) {
					if (data.tip_eveniment === filterToType[this.state.specialEvents[i]]) {
						category = "Sp"
						categoryFound = true
						break
					}
				}
			}

			if (!categoryFound) {
				//look in corporate events type
				for (let i = 0; i < this.state.corporateEvents.length; i++) {
					if (data.tip_eveniment === filterToType[this.state.corporateEvents[i]]) {
						category = "Co"
						categoryFound = true
						break
					}
				}
			}
		}

		if (type === "wedding") {
			category = "Pr"

			link = data.eventType + " "

			if (data.eventType === "Nunta") {
				link += data.prenumeMireasa + " si " + data.prenumeMire
			}

			if (data.eventType === "Botez" || data.eventType === "Petrecere") {
				link += data.prenumeMire
			}
		}

		if (window.flutter_inappwebview) {
			window.flutter_inappwebview.callHandler('shareEvent', `${process.env.REACT_APP_PAGE}events/${encodeURI(link)}/${category}`)
			return
		}

		let customUrl = false
		if(data.slug && data.slug.length > 0) {
			customUrl = process.env.REACT_APP_PAGE + data.slug
		}

		if (window.alityAppView) {
			window.ReactNativeWebView.postMessage(JSON.stringify({
				action: 'shareUrl',
				url: customUrl ? customUrl : `${process.env.REACT_APP_PAGE}events/${encodeURI(link)}/${category}`,
			}))
			return
		}

		this.setState({
			showShareModal: true,
			linkToShare: encodeURI(link),
			linkCategory: category,
			customUrl: customUrl
		})
	}

	closeShareModal = () => {
		this.setState({
			showShareModal: false
		})
	}

	render() {
		if (this.state.loading) {
			return <div className="Events-container">
				<HeaderWhite />

				<h4 className="Events-TopText"> {this.props.intl.formatMessage({id: 'events.title'})} </h4>

				<div className="Events-Filter">
					<p style={{ marginLeft: "20px" }}> {this.props.intl.formatMessage({id: 'general.filters'})} </p>
				</div>

				<div className="Events-contents">
					<Loader/>
				</div>

				<NavbarColor history={this.props.history} active={"Events"} />
			</div>
		}

		return (
			<div className="Events-container">
				<HeaderWhite />

				{this.state.showSearch
					? <h4 className="Events-TopText">
						<Icon className="icon-left" icon="search" color="white" />

						<Input className="search-bar" value={this.state.searchInput} onChange={this.changeSearch} placeholder={this.props.intl.formatMessage({id: 'events.search.placeholder'})} />

						<Icon className="icon-right" icon="close" style={{ color: "white", width: "20px", marginRight:'10px' }} onClick={this.closeSearch} />
					</h4>
					: <h4 className="Events-TopText">
						{this.props.intl.formatMessage({id: 'events.title'})}


						<div className={"search-box"} onClick={this.setShowSearch}>
						<Icon className="search-icon" icon="search" style={{ color: "white", width: "20px" }} />
						<span className={"search-text"}>{this.props.intl.formatMessage({id: 'general.search'})}</span>
						</div>
					</h4>
				}

				<div className="Events-Filter">
					<div className={this.state.category === "All" ? "Events-Status-Choice" : "Events-Status-Option"} onClick={() => this.changeCategory("All")}>{this.props.intl.formatMessage({id: 'events.types.all'})}</div>
					<div className={this.state.category === "Public" ? "Events-Status-Choice" : "Events-Status-Option"} onClick={() => this.changeCategory("Public")}>{this.props.intl.formatMessage({id: 'events.types.public'})}</div>
					<div className={this.state.category === "Speciale" ? "Events-Status-Choice" : "Events-Status-Option"} onClick={() => this.changeCategory("Speciale")}>{this.props.intl.formatMessage({id: 'events.types.special'})}</div>
					<div className={this.state.category === "Corporate" ? "Events-Status-Choice" : "Events-Status-Option"} onClick={() => this.changeCategory("Corporate")}>{this.props.intl.formatMessage({id: 'events.types.corporate'})}</div>
					<div className={this.state.category === "Private" ? "Events-Status-Choice" : "Events-Status-Option"} onClick={() => this.changeCategory("Private")}>{this.props.intl.formatMessage({id: 'events.types.private'})}</div>
					<div className={this.state.category === "Finished" ? "Events-Status-Choice" : "Events-Status-Option"} onClick={() => this.changeCategory("Finished")}>{this.props.intl.formatMessage({id: 'events.types.finished'})}</div>
				</div>

				{this.state.category === "Public" && this.state.showEventsTypes && <div className="Events-Filter2">
					{this.state.publicEvents.map((type, index) => {
						return <div key={index} className={this.state.typeChoice === type ? "Events-Filter-Choice" : "Events-Filter-Option"} onClick={() => this.changeEventType(type)}>
							{this.props.intl.formatMessage({id: 'events.subtypes.' + type})}
						</div>
					})}
				</div>}

				{this.state.category === "Private" && this.state.showEventsTypes && <div className="Events-Filter2">
					{this.state.privateEvents.map((type, index) => {
						return <div key={index} className={this.state.typeChoice === type ? "Events-Filter-Choice" : "Events-Filter-Option"} onClick={() => this.changeEventType(type)}>
							{/*{type === 'Petrecere' ? 'Aniversare' : type}*/}
							{this.props.intl.formatMessage({id: 'events.subtypes.' + type})}
						</div>
					})}
				</div>}

				{this.state.category === "Speciale" && this.state.showEventsTypes && <div className="Events-Filter2">
					{this.state.specialEvents.map((type, index) => {
						return <div key={index} className={this.state.typeChoice === type ? "Events-Filter-Choice" : "Events-Filter-Option"} onClick={() => this.changeEventType(type)}>
							{this.props.intl.formatMessage({id: 'events.subtypes.' + type})}
						</div>
					})}
				</div>}

				{this.state.category === "Corporate" && this.state.showEventsTypes && <div className="Events-Filter2">
					{this.state.corporateEvents.map((type, index) => {
						return <div key={index} className={this.state.typeChoice === type ? "Events-Filter-Choice" : "Events-Filter-Option"} onClick={() => this.changeEventType(type)}>
							{this.props.intl.formatMessage({id: 'events.subtypes.' + type})}
						</div>
					})}
				</div>}


				<div className="Events-contents">
					<div>
						{
							this.state.dispWeddings.length === 0 && this.state.dispEvents.length === 0 &&
								<div className="Events-placeholder">
									{this.props.intl.formatMessage({id: 'events.no_items'})}
								</div>
						}

						{this.state.dispEvents.map((event, index) => {
							if(event.isWedding) {

								if(this.state.category === 'Finished') {
									if(moment(new Date(event.dataEvent)).endOf('day') >= moment(new Date())) {
										return null
									}
								} else {
									if(moment(new Date(event.dataEvent)).endOf('day') < moment(new Date())) {
										return null
									}
								}

								 //data = event.dataEvent.split("T")[0].split("-")
								let firstDate = getWeddingFirstDate(event)

								return <div key={index} className="Events-Card-Container">
									{event.backgroundImage ?
										<div className="Picture" onClick={() => {
											this.askForWeddingEventPassSecond(event, index)
										}}>
											<img
												src={`https://${process.env.REACT_APP_IMAGE_DOMAIN}${getUrlFromImage(event.backgroundImage)}`}
												alt={""} loading="lazy"/>
										</div>
										:
										<div className="Picture" onClick={() => {
											this.askForWeddingEventPassSecond(event, index)
										}}>
											<img src={getDefaultWeddingImage(event)} alt={""} loading="lazy"/>
										</div>
									}
									<div className="Open-Event" onClick={() => { this.askForWeddingEventPassSecond(event, index) }}>
										{this.props.intl.formatMessage({id: 'events.event_card.click_to_open'})}
									</div>

									<div className="Title font-family-poppins-700-bold" onClick={() => { this.askForWeddingEventPassSecond(event, index) }}>
										{getWeddingTitle(event)}
									</div>

									<div className="Icons">

										{
											window.flutter_inappwebview ?
												<div className="Data" onClick={() => {
													window.flutter_inappwebview.callHandler('addToCalendar', getWeddingTitle(event), '', '', firstDate.date)
												}}>{firstDate.preview}</div>
												:
												window.alityAppView ?
													<div className="Data" onClick={() => {
														window.ReactNativeWebView.postMessage(JSON.stringify({
															action: 'addToCalendar',
															title: getWeddingTitle(event),
															date: firstDate.date
														}))
													}}>{firstDate.preview}</div> :
													<div className="Data">{firstDate.preview}</div>
										}

										<div className="PrivateEvent">
											<Icon icon="lock" />
											{this.props.intl.formatMessage({id: 'events.attending_events.private'})}
										</div>

										<div className="Share" onClick={() => this.openShareModal(event, "wedding")} >
											<Icon icon="share" />
											SHARE
										</div>
									</div>

									<div className={this.state.weddingDescriptionIndex === index ? "Full-Description" : "Description"}>
										{event.eventDescription}
									</div>

									<div style={{ alignSelf: "center", color: "black" }}>
										{
											event.eventDescription.length > 25
												? <p onClick={() => { this.state.weddingDescriptionIndex === index ? this.setState({ weddingDescriptionIndex: -1 }) : this.setState({ eventDescriptionIndex: -1, weddingDescriptionIndex: index }) }}>
													{this.state.weddingDescriptionIndex === index ? <FormattedMessage id={"event_page.general_tab.less_details"} /> : <FormattedMessage id={"event_page.general_tab.more_details"} />}
													{this.state.weddingDescriptionIndex === index ? <BsChevronUp /> : <BsChevronDown />}
												</p>
												: <p></p>
										}
									</div>
								</div>
							} else {

								if(event.id === 97 || event.id === 251) {
									// eslint-disable-next-line
									return
								}

								if(this.state.category === 'Finished') {
									if(event.data_sfarsit_eveniment && event.data_sfarsit_eveniment.length>0) {
										if(moment(event.data_sfarsit_eveniment).endOf('day') >= moment()) {
											// eslint-disable-next-line
											return
										}
									} else {
										if (moment(event.data_eveniment).endOf('day') >= moment()) {
											// eslint-disable-next-line
											return
										}
									}
								} else {

									if(event.data_sfarsit_eveniment && event.data_sfarsit_eveniment.length>0) {
										if(moment(event.data_sfarsit_eveniment).endOf('day') < moment()) {
											// eslint-disable-next-line
											return
										}
									} else {
										if (moment(event.data_eveniment).endOf('day') < moment()) {
											// eslint-disable-next-line
											return
										}
									}
								}


								let backgroundImageStyle = {
									backgroundRepeat: 'no-repeat',
									backgroundSize: 'cover',
									backgroundPosition: ' center',
									backgroundImage: event.imagine_eveniment.length > 0 ? `url(https://${process.env.REACT_APP_IMAGE_DOMAIN}${getUrlFromImage(event.imagine_eveniment[0])})` : ''
								}
								if(event.id_eveniment === 'G7xFEKFwMs') {
									backgroundImageStyle.backgroundPosition = 'top center'
								}

								return <div key={index} className="Events-Card-Container">

									{event.imagine_eveniment.length > 0 &&
									<div className="Picture"  onClick={() => {
										this.askForPassIfNeeded(index)
									}}>
										<img src={`https://${process.env.REACT_APP_IMAGE_DOMAIN}${getUrlFromImage(event.imagine_eveniment[0])}`} alt={""} loading="lazy" />
									</div>}

									<div className="Open-Event" onClick={() => {
										this.askForPassIfNeeded(index)
									}}>
										{this.props.intl.formatMessage({id: 'events.event_card.click_to_open'})}
									</div>

									<div className="Title font-family-poppins-700-bold" onClick={() => {
										this.askForPassIfNeeded(index)
									}}>
										{event.title}
									</div>

									<div className="Icons">

										{
											window.flutter_inappwebview ?
												<div className="Data" onClick={() => {
													window.flutter_inappwebview.callHandler('addToCalendar', event.title, '', '', event.data_eveniment)
												}}>{moment(event.data_eveniment).format('DD-MM-YYYY')}</div>
												:
												window.alityAppView ?
													<div className="Data" onClick={() => {
														window.ReactNativeWebView.postMessage(JSON.stringify({
															action: 'addToCalendar',
															title: event.title,
															date: event.data_eveniment
														}))
													}}>{moment(event.data_eveniment).format('DD-MM-YYYY')}</div> :
													<div className="Data">{moment(event.data_eveniment).format('DD-MM-YYYY')}</div>
										}

										{
											event.privat
												? <div className="PrivateEvent">
													<Icon icon="lock"/>
													{this.props.intl.formatMessage({id: 'events.attending_events.private'})}
												</div>
												: <div className="PublicEvent">
													<Icon icon="unlock"/>
													PUBLIC
												</div>
										}

										<div className="Share" onClick={() => this.openShareModal(event, "event")}>
											<Icon icon="share"/>
											SHARE
										</div>
									</div>

									<div
										className={this.state.eventDescriptionIndex === index ? "Full-Description" : "Description"}>
										{event.descriere}
									</div>

									<div style={{alignSelf: "center", color: "black", width: "200px"}}>
										{
											event.descriere && event.descriere.length > 25
												? <p onClick={() => {
													this.state.eventDescriptionIndex === index ? this.setState({eventDescriptionIndex: -1}) : this.setState({
														eventDescriptionIndex: index,
														weddingDescriptionIndex: -1
													})
												}}>
													{this.state.eventDescriptionIndex === index ? <FormattedMessage id={"event_page.general_tab.less_details"} /> : <FormattedMessage id={"event_page.general_tab.more_details"} />}
													{this.state.eventDescriptionIndex === index ? <BsChevronUp/> :
														<BsChevronDown/>}
												</p>
												: <p></p>
										}
									</div>
								</div>
							}
						})}


						{

							// eslint-disable-next-line
							this.state.category !== 'All' && this.state.category !== 'Finished' && this.state.dispWeddings.map((event, index) => {

                            if (event.data_eveniment) {
                                if (moment(event.data_eveniment).endOf('day') < moment()) {
									// eslint-disable-next-line
                                    return
                                }
                            }

                            if (event.dataEvent) {
                                if (moment(event.dataEvent).endOf('day') < moment()) {
									// eslint-disable-next-line
                                    return
                                }
                            }

							let firstDate = getWeddingFirstDate(event)


							return <div key={event.id} className="Events-Card-Container">


								{event.backgroundImage ?
									<div className="Picture" onClick={() => { this.askForWeddingEventPass(index) }}>
									<img src={`https://${process.env.REACT_APP_IMAGE_DOMAIN}${getUrlFromImage(event.backgroundImage)}`} alt={""} loading="lazy" />
								</div>
								:
									<div className="Picture" onClick={() => { this.askForWeddingEventPass(index) }}>
										<img src={getDefaultWeddingImage(event)} alt={""} loading="lazy" />
									</div>
								}

								<div className="Open-Event" onClick={() => { this.askForWeddingEventPass(index) }}>
									{this.props.intl.formatMessage({id: 'events.event_card.click_to_open'})}
								</div>

								<div className="Title font-family-poppins-700-bold" onClick={() => { this.askForWeddingEventPass(index) }}>
									{getWeddingTitle(event)}
								</div>

								<div className="Icons">

									{
										window.flutter_inappwebview ?
											<div className="Data" onClick={() => {
												window.flutter_inappwebview.callHandler('addToCalendar', getWeddingTitle(event), '', '', firstDate.date)
											}}>{firstDate.preview}</div>
											:
											window.alityAppView ?
												<div className="Data" onClick={() => {
													window.ReactNativeWebView.postMessage(JSON.stringify({
														action: 'addToCalendar',
														title: getWeddingTitle(event),
														date: firstDate.date
													}))
												}}>{firstDate.preview}</div> :
												<div className="Data">{firstDate.preview}</div>
									}

									<div className="PrivateEvent">
										<Icon icon="lock" />
										{this.props.intl.formatMessage({id: 'events.attending_events.private'})}
									</div>

									<div className="Share" onClick={() => this.openShareModal(event, "wedding")} >
										<Icon icon="share" />
										SHARE
									</div>
								</div>

								<div className={this.state.weddingDescriptionIndex === index ? "Full-Description" : "Description"}>
									{event.eventDescription}
								</div>

								<div style={{ alignSelf: "center", color: "black" }}>
									{
										event.eventDescription.length > 25
											? <p onClick={() => { this.state.weddingDescriptionIndex === index ? this.setState({ weddingDescriptionIndex: -1 }) : this.setState({ eventDescriptionIndex: -1, weddingDescriptionIndex: index }) }}>
												{this.state.weddingDescriptionIndex === index ? <FormattedMessage id={"event_page.general_tab.less_details"} /> : <FormattedMessage id={"event_page.general_tab.more_details"} />}
												{this.state.weddingDescriptionIndex === index ? <BsChevronUp /> : <BsChevronDown />}
											</p>
											: <p></p>
									}
								</div>
							</div>
						})}

					</div>
				</div>

				<CodeModal moveToEvent={this.checkCode} value={this.state.eventCode} onChange={this.handleChange} eventCode={this.state.eventCode} showModal={this.state.showModal} toggleModal={this.showModal} />

				<CodeModal moveToEvent={this.moveToWedding} value={this.state.eventCode} onChange={this.handleChange} eventCode={this.state.eventCode} showModal={this.state.showModalWedding} toggleModal={this.showModalWedding} />

				<ShareModal link={this.state.linkToShare} customUrl={this.state.customUrl} showModal={this.state.showShareModal} closeModal={this.closeShareModal} linkCategory={this.state.linkCategory} />

				<NavbarColor history={this.props.history} active={"Events"} />
			</div>
		)
	}
}

export default injectIntl(Events);
