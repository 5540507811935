import React from 'react'
import './AdminEventPage.scss'
import HeaderWhite from '../../../components/HeaderWhite'
import NavbarColor from '../../../components/NavbarColor'

import MenuAdmin from './components/Menu/MenuAdmin'
import axios from 'axios'
import { toast } from 'react-toastify'
import Loader from "../../components/Loader";
import {Button} from "rsuite";
import Modal from "react-responsive-modal";
import HeaderWhiteBack from "../../../components/HeaderWhite";
import {getWeddingParticipantsPackageNumber} from "../../../functions";
import WalkthroughOverlay from "../../../components/walkthrough/WalkthroughtOverlay";
import Countdown from "react-countdown";

class AdminEventPage extends React.Component {

	// eslint-disable-next-line
	constructor(props) {
		super(props);
	}

	state = {
		current_event: null,
		loading: true,
		hasNewResponses: false,
		activePage: "General",
		std_modal: false,
		inv_modal: false,
		general_modal: false,
		tutorialModal: false,
		showWalkthrough: false,
		walkthroughStep: '1',

		offerDate: null,
		showOfferModal: false
	}

	params = new URLSearchParams(window.location.search) // id=123


	componentDidMount() {
		let isAndroid = navigator.userAgent.toLowerCase().indexOf("android") > -1

		if (isAndroid) {
			let viewHeight = window.innerHeight
			let viewport = document.querySelector("meta[name=viewport]");
			viewport.setAttribute("content", "height=" + viewHeight + "px, width=device-width, initial-scale=1.0")
		}

		window.scrollTo(0, 0);

		let walkthroughStep = '1'

		if(this.params && this.params.get('walkthroughStep')) {
			walkthroughStep = this.params.get('walkthroughStep')+''
		}

		let auth = {
			headers: {
				'Authorization': 'Bearer ' + localStorage.getItem('jwt')
			}
		}

		axios.get(process.env.REACT_APP_DOMAIN + 'weddings/getThisWedding/' + this.props.match.params.id, auth)
			.then(async response => {
				let hasNewResponses = false

				let inviteResponses = await axios.get(process.env.REACT_APP_DOMAIN + 'responses/list/byEvent/' + this.props.match.params.id, auth)
				if (inviteResponses.data.totalResponses > response.data.count_seen_invite_responses) {
					hasNewResponses = true
				} else {
					let saveThedateResponses = await axios.get(process.env.REACT_APP_DOMAIN + 'response-save-the-dates/list/byEvent/' + this.props.match.params.id, auth)

					if (saveThedateResponses.data.totalResponses > response.data.count_seen_save_the_date_responses) {
						hasNewResponses = true
					}
				}

				let showTutorial = false
				if(this.params && this.params.get('tutorial')) {
					showTutorial = true
				}

				this.setState({
					current_event: response.data,
					loading: false,
					hasNewResponses: hasNewResponses,
					tutorialModal: showTutorial,
					showWalkthrough: !response.data.tutorials || (response.data.tutorials && !response.data.tutorials.walkthrough),
					walkthroughStep: walkthroughStep
				}, async () => {
					//this.getAvailablePackages()
				})
			})
			.catch(err => {
				console.log(err);
				toast.error("A aparut o eroare in cautarea evenimentului, reincercati")
				this.props.history.push("/organizer/viewEvents")
			})
	}

	async getAvailablePackages() {

		let auth = {
			headers: {
				'Authorization': 'Bearer ' + localStorage.getItem('jwt')
			}
		}

		let payload = {
			id_eveniment: this.props.match.params.id
		}

		await axios.post(process.env.REACT_APP_DOMAIN + 'weddings/packages/getAvailablePackages', payload, auth)
			.then(response => {

				if (!this.state.current_event.packages || this.state.current_event.packages.length === 0) {


					this.setState({
						offerDate: new Date(response.data.offerUntil),
						showOfferModal: !this.state.showWalkthrough && (new Date().getTime() < new Date(response.data.offerUntil).getTime()),
					})
				}
			})
			.catch((e) => {

			})

	}

	renderCountdown({days, hours, minutes, seconds, completed}) {
		if (completed) {
			// Render a completed state
			return '';
		} else {
			// Render a countdown
			return <div className={"Package-countdown"}>
				<div className={"item"}>
					<div className={"value"}>{days}</div>
					<div className={"title"}>{days === 1 ? 'Zi' : 'Zile'}</div>
				</div>

				<div className={"item"}>
					<div className={"value"}>{hours}</div>
					<div className={"title"}>{hours === 1 ? 'Oră' : 'Ore'}</div>
				</div>

				<div className={"item"}>
					<div className={"value"}>{minutes}</div>
					<div className={"title"}>{minutes === 1 ? 'Minut' : 'Minute'}</div>
				</div>

				<div className={"item"}>
					<div className={"value"}>{seconds}</div>
					<div className={"title"}>{'Secunde'}</div>
				</div>

			</div>
		}
	};

	moveTo = (where, active) => {
		if (where === "plan")
			this.props.history.push('/organizer/plan/' + this.props.match.params.id)

		if (where === "info")
			this.props.history.push('/organizer/info/' + this.props.match.params.id)

		if (where === "guests")
			this.props.history.push('/organizer/guests/' + this.props.match.params.id)

		if (where === "extra")
			this.props.history.push('/organizer/extraServices/' + this.props.match.params.id)

		if (where === "exports") {
			if(getWeddingParticipantsPackageNumber(this.state.current_event) === 0) {
				this.props.history.push('/organizer/noPremiumPackage/' + this.props.match.params.id)
				return;
			}

			this.props.history.push('/organizer/exports/' + this.props.match.params.id)
		}

		if (where === "contact_us")
			this.props.history.push('/organizer/contact_us/' + this.props.match.params.id)

		if (where === "package")
			this.props.history.push('/organizer/package/' + this.props.match.params.id)
		if (where === "administrators")
			this.props.history.push('/organizer/administrators/' + this.props.match.params.id)
		if (where === "participants_photos")
			this.props.history.push('/organizer/participants_photos/' + this.props.match.params.id)


	}

	render() {
		if (this.state.loading) {
			return <div className="AdminEventPage-container">
				<HeaderWhite shadow={true} />

					<Loader/>

				<NavbarColor history={this.props.history} />
			</div>
		}

		let toRender = null;
		if (this.state.activePage === "General")
			toRender = <MenuAdmin walkthroughActiveIndex={this.state.showWalkthrough ? this.state.walkthroughStep : 0} history={this.props.history} moveTo={this.moveTo} id={this.props.match.params.id} event={this.state.current_event}
				seating={(this.props.location.state !== undefined) ? this.props.location.state.seating : null} hasNewResponses={this.state.hasNewResponses} />

		return (
			<div className="AdminEventPage-container">
				<HeaderWhiteBack shadow={true} goBack={() => this.props.history.push('/organizer/viewEvents')} />

				<div className="AdminEventPage-contents" >
					{toRender}
				</div>


				<Modal
					classNames={{
						overlay: 'modal-overlay',
						modal: 'modal-no-top',
						closeButton: 'modal-close-button',
					}}
					open={this.state.tutorialModal}
					focusTrapped={false}
					onClose={() => this.setState({tutorialModal: false})}
				>
					<div style={{marginTop: "20px"}}>
						<h3>Informații utile</h3>
						<br/>
						<p>
							Felicitari! Evenimentul a fost creat! Vezi cat de ușor a fost?<br/>
							Acum e momentul sa discutam puțin mai multe despre detaliile organizatorice. Nu-ți face griji, informațiile pe care le-ai introdus pană acum se pot modifica oricand din meniul „Informații generale” -> „Descriere eveniment” -> „Editează”, de asemenea poți vedea și cum invitații tăi vor vedea evenimentul prin apăsarea butonului „Previzualizeaza” din meniul „Eveniment” -> „Previzualizeaza”
							<br/><br/>
							De asemenea, fiind un eveniment privat lăsăm la alegerea ta dacă sau când evenimentul va fi publicat în lista evenimentelor private, dar stai fără griji chiar dacă îl publicați nimeni nu poate avea acces la eveniment fără a introduce codul aferent evenimentului. Codul evenimentului îl găsești în meniul „Informații generale” -> „Descriere eveniment” -> „Editeaza” si se afla pe primul rand.
							<br/><br/>
							Un alt aspect despre care dorim sa discutam sunt locatiile pe care le-ați adăugat, acestea se vor adăuga automat pe invitație și foarte important de reținut este faptul ca aceste adrese sunt integrate automat cu Waze și Google Maps astfel invitații nu vor trebui sa iti bată capul cu sa ajungă la eveniment.<br/>
							Suntem doar la început, ești pregatit pentru restul surprizelor?
						</p>

						<Button
							onClick={() => {
								this.setState({
									tutorialModal: false
								})
							}}
							color="green"
							style={{backgroundColor: "#05AFF2", borderRadius: "20px", width: "100%", marginTop:"20px"}}>Ok</Button>
					</div>
				</Modal>

				{
					this.state.showWalkthrough ?
						this.state.walkthroughStep === '1' ?
						<WalkthroughOverlay id_eveniment={this.props.match.params.id} history={this.props.history} callback={() => {
							this.setState({
								showWalkthrough:false
							})
						}}>
							<p style={{marginTop:'4rem'}}>Va mulțumim că v-ați alăturat comunității ALITY!<br/>
								Haideți să ne familiarizăm cu aplicația prin 4 pași simpli!</p>
						</WalkthroughOverlay>
							:
							this.state.walkthroughStep === '2' ?
								<WalkthroughOverlay id_eveniment={this.props.match.params.id} history={this.props.history} callback={() => {
									this.setState({
										showWalkthrough:false
									})
								}}>
								</WalkthroughOverlay>
								:
								this.state.walkthroughStep === '3' ?
									<WalkthroughOverlay id_eveniment={this.props.match.params.id} history={this.props.history} callback={() => {
										this.setState({
											showWalkthrough:false
										})
									}}>
									</WalkthroughOverlay>
									:
									this.state.walkthroughStep === '7' ?
										<WalkthroughOverlay id_eveniment={this.props.match.params.id} history={this.props.history} callback={() => {
											this.setState({
												showWalkthrough:false
											})
										}}>
										</WalkthroughOverlay>
										:null
						:null
				}

				<Modal
					classNames={{
						overlay: 'modal-overlay',
						modal: 'modal-profile',
						closeButton: 'modal-close-button',
					}}
					open={this.state.showOfferModal}
					onClose={() => this.setState({showOfferModal: false})}
					focusTrapped={false}
				>
					<div style={{marginTop: "20px", marginBottom:"30px"}}>
						<h3 style={{color: '#D82148'}}>Grăbește-te!</h3>
						<h5 style={{color: 'green'}}>Promoție -30%</h5>
						<h6 style={{marginTop: '30px'}}>Au mai rămas doar</h6>

						<Countdown
							date={this.state.offerDate}
							renderer={this.renderCountdown}
						/>

						<div className={"package-offer-description"}>

							{/*eslint-disable-next-line*/}
							✨ Organizarea unui eveniment nu a fost niciodată mai ușoară și eficientă! Planificarea unui întreg eveniment poate fi complicată, dar și o sarcina care necesita mult timp și atenție din partea dvs.
							<br/><br/>
							Cu ocazia Sărbătorilor Pascale, 🐰 suntem încântați să vă oferim un pachet special din cadrul serviciile noastre!
							<br/><br/>
							În perioada <span style={{color:'red'}}>1-7 mai</span>, vă oferim discount substantial la produsele noastre, cu reduceri de până la <span style={{color:'red'}}>30%</span> în funcție de pachetul ales. <span style={{textDecoration:'underline'}}>Atentie, reducerea nu se cumulează cu alte coduri de reducere.</span>
							<br/><br/>
							Ne dorim să vă oferim cea mai bună experiență posibilă cu Ality, și sperăm că această ofertă specială de Paște vă va aduce bucurie și confort în planificarea evenimentelor dumneavoastră!✨

						</div>

						<div>
						</div>

						<Button
							onClick={() => {
								this.setState({
									showOfferModal: false
								})
							}}
							color="green"
							style={{backgroundColor: "#05AFF2", borderRadius: "20px", width: "100%"}}>Ok</Button>
					</div>
				</Modal>

				<NavbarColor history={this.props.history} />
			</div>
		)
	}
}

export default AdminEventPage;
